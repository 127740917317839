import React from 'react';
import { Button } from '@mui/material';
import { HOME_POSTERS_IMAGES } from '../../../constants/actionTypes';
import Form from 'react-bootstrap/Form';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import * as api from "../../../api/index.js";


function HomePostersList({ homePostersData, setHomePostersData }) {

    const handlePosterDeletion = (poster) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Are you sure you want to delete this Blog!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const { data } = await api.deleteHomePoster(poster.id);
                if (data.success) {
                    Swal.fire({
                        title: 'Success!',
                        text: data.message,
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    });
                    setHomePostersData(homePostersData.filter(post => post.id !== poster.id));
                }
                else {
                    Swal.fire({
                        title: 'Error!',
                        text: data.error,
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                }
            }
        });
    }


    const handlePosterStatusChange = async (e, poster) => {
        try {
            const { data } = await api.changePosterStatus({ "posterId": poster.id, "isActive": e.target.value });
            if (data.success) {
                Swal.fire({
                    title: 'Good job!',
                    text: data.message,
                    icon: 'success',
                    showConfirmButton: true,
                    allowOutsideClick: false,
                    allowEscapeKey: true,
                    allowEnterKey: true,
                });

                // let index = homePostersData.findIndex(pos => pos.id === poster.id);
                // homePostersData[index] = data.poster;
                // setHomePostersData([...homePostersData]);
            }
            else {
                Swal.fire({
                    title: 'Failed!',
                    text: data.message,
                    icon: 'error',
                    showConfirmButton: true,
                    allowOutsideClick: false,
                    allowEscapeKey: true,
                    allowEnterKey: true,
                });
            }
        } catch (error) {
            Swal.fire({
                title: 'Failed!',
                text: "Server side error, please contact Mr. K Ahmadi for resolving issue !",
                icon: 'error',
                showConfirmButton: true,
                allowOutsideClick: false,
                allowEscapeKey: true,
                allowEnterKey: true,
            });
        }
    }

    return (
        <section>
            <div className="container h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col">
                        <div className="card-body p-4">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h5 className="mb-3">Posters</h5>

                                    {homePostersData && homePostersData.map((poster, index) => {
                                        return (
                                            <div className="card mb-3" key={index}>
                                                <div className="card-body">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="d-flex flex-row align-items-center">
                                                            <div className='m-2'>
                                                                <img
                                                                    src={`${HOME_POSTERS_IMAGES}${poster?.poster1Image}`}
                                                                    className="img-fluid rounded-3"
                                                                    alt="poster"
                                                                    style={{
                                                                        width: "150px",
                                                                        height: "150px"
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className='m-2'>
                                                                <img
                                                                    src={`${HOME_POSTERS_IMAGES}${poster?.poster2Image}`}
                                                                    className="img-fluid rounded-3"
                                                                    alt="poster2"
                                                                    style={{
                                                                        width: "150px",
                                                                        height: "150px"
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className='m-2'>
                                                                <img
                                                                    src={`${HOME_POSTERS_IMAGES}${poster?.poster3Image}`}
                                                                    className="img-fluid rounded-3"
                                                                    alt="poster3"
                                                                    style={{
                                                                        width: "150px",
                                                                        height: "150px"
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-row align-items-center flex-wrap">
                                                            <div className='m-1 p-1'>
                                                                <Form.Select
                                                                    size="sm"
                                                                    onChange={(e) =>
                                                                        handlePosterStatusChange(e, poster)
                                                                    }
                                                                    defaultValue={poster?.isPosterActive ? 1 : 0}
                                                                >
                                                                    <option value={1}>Active</option>
                                                                    <option value={0}>Inactive</option>
                                                                </Form.Select>
                                                            </div>
                                                            <div className='m-1 p-1'>
                                                                <Button
                                                                    size="small"
                                                                    variant='outlined'
                                                                    color='secondary'
                                                                    startIcon={<DeleteIcon />}
                                                                    onClick={() => handlePosterDeletion(poster)}
                                                                >DELETE</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomePostersList;