import React from 'react'
import "./css/MyCourses.css";
import { CircularProgress, Typography } from '@mui/material';
import CourseCard from './CourseCard';

function CoursesCards({ cartItems, setCartItems, courses, coursesRequesting, coursesRequestingError }) {

    return (
        <div>
            <div className='mt-2'>
                <Typography
                    className="mt-4"
                    variant="h6"
                    style={{
                        fontFamily: "Georgia, serif",
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                        color: "hotpink",
                        padding: 2,
                        margin: 2,
                    }}
                >CourseEdx offers you the best courses</Typography>
            </div>
            <div className='MyCourses__component'>
                {coursesRequesting && (
                    <div className='c-card-container'>
                        {Array(10).fill(1).map((el, i) => (
                            <>
                                <div class="c-card">
                                    <div class="c-card-image pulsate">
                                    </div>
                                    <div class="c-card-content">
                                        <div class="block-course-heading pulsate">
                                        </div>
                                        <div class="block-user pulsate">
                                        </div>
                                        <div class="block-sub-heading pulsate">
                                        </div>
                                        <hr
                                            style={{
                                                borderTop: "1px solid rgb(211, 223, 211)"
                                            }}
                                        />
                                        <div class="block-stars pulsate">
                                        </div>
                                        <div class="block-button pulsate">
                                        </div>
                                        <div
                                            style={{
                                                clear: "both"
                                            }}
                                        ></div>
                                    </div>
                                </div>

                            </>
                        ))}
                    </div>
                )}
                {coursesRequestingError && (
                    <div className="" style={{ display: "flex", justifyContent: "center", alignItems: "center", fontFamily: "monospace" }}>
                        Failed to load data, We are extremely sorry for inconvenience caused.
                    </div>
                )}
                {!courses ? (
                    <div className="" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        {courses.map((course) => {
                            return (
                                <CourseCard
                                    key={course.id}
                                    course={course}
                                    cartItems={cartItems}
                                    setCartItems={setCartItems}
                                />
                            )
                        })}
                    </>
                )}
            </div>
        </div>
    )
}

export default CoursesCards;