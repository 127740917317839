import { TextField, Button, Grid, Paper } from '@mui/material';
import React, { useState } from 'react';
import "./css/StartCourse.css";
import Form from 'react-bootstrap/Form'
import { assignCourseToInstructor } from '../../../actions/instructor';
import { useDispatch } from "react-redux";
import ReactQuill from 'react-quill';
import * as api from "../../../api/index.js";
import Swal from 'sweetalert2';
import { Container, ProgressBar } from 'react-bootstrap';

const initialState = {
    courseTitle: "",
    courseSubTitle: "",
    courseCategory: "",
    whatWillStudentLearn: "",
    whatArePrerequisites: "",
    whoIsThisCourseFor: "",
    courseLevel: "",
    coursePricing: "",
    courseLanguage: "",
    welcomeMessage: "",
    congratsMessage: "",
}


function StartCourse({ user, setUser }) {
    const [startCourseForm, setStartCourseForm] = useState(initialState);
    const [courseThumbnail, setCourseThumbnail] = useState(null);
    const [coursePromotionVideo, setCoursePromotionVideo] = useState(null);
    const [progress, setProgress] = useState();
    const [error, setError] = useState(false);
    const dispatch = useDispatch();
    const [courseDescription, setCourseDescription] = useState("");
    const [requesting, setRequesting] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (requesting) {
            Swal.fire({
                title: 'Processing your request...',
                text: 'Please wait...',
                showConfirmButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: true,
            });
            return;
        }

        setRequesting(true);

        if (startCourseForm.courseTitle === "" || startCourseForm.courseSubTitle === "" || startCourseForm.courseCategory === "" || courseThumbnail === null || coursePromotionVideo === null || courseDescription === "") {
            Swal.fire({
                title: 'Error!',
                text: 'Please fill all the required fields',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            setRequesting(false);
        }
        else {
            const formData = new FormData();
            formData.append("userId", user.result.id);
            formData.append("courseTitle", startCourseForm.courseTitle);
            formData.append("courseSubTitle", startCourseForm.courseSubTitle);
            formData.append("courseDescription", courseDescription);
            formData.append("courseCategory", startCourseForm.courseCategory);
            formData.append("whatWillStudentLearn", startCourseForm.whatWillStudentLearn);
            formData.append("whatArePrerequisites", startCourseForm.whatArePrerequisites);
            formData.append("whoIsThisCourseFor", startCourseForm.whoIsThisCourseFor);
            formData.append("courseLevel", startCourseForm.courseLevel);
            formData.append("coursePricing", startCourseForm.coursePricing);
            formData.append("courseLanguage", startCourseForm.courseLanguage);
            formData.append("welcomeMessage", startCourseForm.welcomeMessage);
            formData.append("congratsMessage", startCourseForm.congratsMessage);
            formData.append("courseThumbnail", courseThumbnail);
            formData.append("coursePromotionVideo", coursePromotionVideo);
            window.scrollTo(0, 0);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                },
                onUploadProgress: data => {
                    //Set the progress value to show the progress bar
                    setProgress(Math.round((100 * data.loaded) / data.total))
                },
            }

            try {
                const { data } = await api.assignCourseToInstructor(formData, config);
                if (data.success) {
                    Swal.fire(
                        'Good job!',
                        data.message,
                        'success'
                    );
                    // reset the form with id startCourseForm
                    let form = document.getElementById("startCourseForm");
                    if (form) {
                        form.reset();
                    }
                    setCourseDescription("");
                    setError(false);
                    setProgress(null);
                    setStartCourseForm(initialState);
                    setCourseThumbnail(null);
                    setCoursePromotionVideo(null);
                    dispatch(assignCourseToInstructor(data.course));
                    setRequesting(false);
                }
                else {
                    setError(true);
                    Swal.fire(
                        'Failed!',
                        data.error,
                        'error'
                    );
                    setProgress(null);
                    setRequesting(false);
                    let form = document.getElementById("startCourseForm");
                    if (form) {
                        form.reset();
                    }
                }

            } catch (error) {
                setError(true);
                Swal.fire(
                    'Failed!',
                    'Course is not added please try again, if problem persists contact Programmer',
                    'error'
                );
                setProgress(null);
                setRequesting(false);
                let form = document.getElementById("startCourseForm");
                if (form) {
                    form.reset();
                }
            }

            // dispatch(assignCourseToInstructor(formData, config));
            // setStartCourseForm(initialState);
            // setCourseThumbnail(null);

        }
    }

    const handleChange = (e) => {
        setStartCourseForm({ ...startCourseForm, [e.target.name]: e.target.value });
    }

    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setCourseThumbnail(null)
            return
        }
        setCourseThumbnail(e.target.files[0])
    }

    const onSelectCoursePromotionVideo = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setCoursePromotionVideo(null)
            return
        }
        setCoursePromotionVideo(e.target.files[0])
    }


    return (
        <div>

            <div className="card m-2">
                <div className="card-body">
                    <h5 className="card-title">Start Course</h5>
                    {!error && progress && (
                        <div className="card-text">
                            <span>
                                Submitting course for approval ...
                            </span>
                            <ProgressBar now={progress} label={`${progress}%`} />
                        </div>
                    )}
                </div>
            </div>

            <Paper elevation={6} className="p-2 m-2">
                <Container>
                    <form onSubmit={handleSubmit} className="startCourseForm" id='startCourseForm'>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4} lg={4}>
                                <TextField
                                    label="Course Title"
                                    value={startCourseForm.courseTitle}
                                    name="courseTitle"
                                    onChange={handleChange}
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <TextField
                                    label="Course Subtitle"
                                    value={startCourseForm.courseSubTitle}
                                    name='courseSubTitle'
                                    onChange={handleChange}
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <Form.Select
                                    name='courseCategory'
                                    value={startCourseForm.courseCategory}
                                    onChange={handleChange}
                                >
                                    <option value="">-- Select Course Category --</option>
                                    <option value="Development">Development</option>
                                    <option value="Business">Business</option>
                                    <option value="Finance / Accounting">Finance / Accounting</option>
                                    <option value="IT / Software">IT / Software</option>
                                    <option value="Office Productivity">Office Productivity</option>
                                    <option value="Personal Development">Personal Development</option>
                                    <option value="Design">Design</option>
                                    <option value="Marketing">Marketing</option>
                                    <option value="Lifestyle">Lifestyle</option>
                                    <option value="Photography / Video">Photography / Video</option>
                                    <option value="Health / Fitness">Health / Fitness</option>
                                    <option value="Music">Music</option>
                                    <option value="Teaching / Academics">Teaching / Academics</option>
                                </Form.Select>
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <TextField
                                    label="What will student learn"
                                    value={startCourseForm.whatWillStudentLearn}
                                    name="whatWillStudentLearn"
                                    onChange={handleChange}
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <TextField
                                    label="What are Prerequisites"
                                    value={startCourseForm.whatArePrerequisites}
                                    name="whatArePrerequisites"
                                    onChange={handleChange}
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <TextField
                                    label="Who is this course for"
                                    value={startCourseForm.whoIsThisCourseFor}
                                    name="whoIsThisCourseFor"
                                    onChange={handleChange}
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <Form.Select
                                    value={startCourseForm.courseLevel}
                                    onChange={handleChange}
                                    name="courseLevel"
                                >
                                    <option value={''}>Course Level</option>
                                    <option value="Beginner">Beginner</option>
                                    <option value="Intermediate">Intermediate</option>
                                    <option value="Advance">Advance</option>
                                </Form.Select>
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <TextField
                                    label="Course Pricing"
                                    value={startCourseForm.coursePricing}
                                    name="coursePricing"
                                    type={"number"}
                                    onChange={handleChange}
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <Form.Select
                                    name='courseLanguage'
                                    value={startCourseForm.courseLanguage}
                                    onChange={handleChange}
                                >
                                    <option value="">-- Select Course Language --</option>
                                    <option value="Afrikaans">Afrikaans</option>
                                    <option value="Albanian">Albanian</option>
                                    <option value="Arabic">Arabic</option>
                                    <option value="Armenian">Armenian</option>
                                    <option value="Basque">Basque</option>
                                    <option value="Bengali">Bengali</option>
                                    <option value="Bulgarian">Bulgarian</option>
                                    <option value="Catalan">Catalan</option>
                                    <option value="Cambodian">Cambodian</option>
                                    <option value="Chinese (Mandarin)">Chinese (Mandarin)</option>
                                    <option value="Croatian">Croatian</option>
                                    <option value="Czech">Czech</option>
                                    <option value="Danish">Danish</option>
                                    <option value="Dutch">Dutch</option>
                                    <option value="English">English</option>
                                    <option value="Estonian">Estonian</option>
                                    <option value="Fiji">Fiji</option>
                                    <option value="Finnish">Finnish</option>
                                    <option value="French">French</option>
                                    <option value="Georgian">Georgian</option>
                                    <option value="German">German</option>
                                    <option value="Greek">Greek</option>
                                    <option value="Gujarati">Gujarati</option>
                                    <option value="Hebrew">Hebrew</option>
                                    <option value="Hindi">Hindi</option>
                                    <option value="Hungarian">Hungarian</option>
                                    <option value="Icelandic">Icelandic</option>
                                    <option value="Indonesian">Indonesian</option>
                                    <option value="Irish">Irish</option>
                                    <option value="Italian">Italian</option>
                                    <option value="Japanese">Japanese</option>
                                    <option value="Javanese">Javanese</option>
                                    <option value="Korean">Korean</option>
                                    <option value="Latin">Latin</option>
                                    <option value="Latvian">Latvian</option>
                                    <option value="Lithuanian">Lithuanian</option>
                                    <option value="Macedonian">Macedonian</option>
                                    <option value="Malay">Malay</option>
                                    <option value="Malayalam">Malayalam</option>
                                    <option value="Maltese">Maltese</option>
                                    <option value="Maori">Maori</option>
                                    <option value="Marathi">Marathi</option>
                                    <option value="Mongolian">Mongolian</option>
                                    <option value="Nepali">Nepali</option>
                                    <option value="Norwegian">Norwegian</option>
                                    <option value="Pashto">Pashto</option>
                                    <option value="Persian">Persian</option>
                                    <option value="Polish">Polish</option>
                                    <option value="Portuguese">Portuguese</option>
                                    <option value="Punjabi">Punjabi</option>
                                    <option value="Quechua">Quechua</option>
                                    <option value="Romanian">Romanian</option>
                                    <option value="Russian">Russian</option>
                                    <option value="Samoan">Samoan</option>
                                    <option value="Serbian">Serbian</option>
                                    <option value="Slovak">Slovak</option>
                                    <option value="Slovenian">Slovenian</option>
                                    <option value="Spanish">Spanish</option>
                                    <option value="Swahili">Swahili</option>
                                    <option value="Swedish ">Swedish </option>
                                    <option value="Tamil">Tamil</option>
                                    <option value="Tatar">Tatar</option>
                                    <option value="Telugu">Telugu</option>
                                    <option value="Thai">Thai</option>
                                    <option value="Tibetan">Tibetan</option>
                                    <option value="Tonga">Tonga</option>
                                    <option value="Turkish">Turkish</option>
                                    <option value="Ukrainian">Ukrainian</option>
                                    <option value="Urdu">Urdu</option>
                                    <option value="Uzbek">Uzbek</option>
                                    <option value="Vietnamese">Vietnamese</option>
                                    <option value="Welsh">Welsh</option>
                                    <option value="Xhosa">Xhosa</option>
                                </Form.Select>
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <TextField
                                    label="Welcome Message for course"
                                    value={startCourseForm.welcomeMessage}
                                    name="welcomeMessage"
                                    onChange={handleChange}
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <TextField
                                    label="Congrats Message after completion"
                                    value={startCourseForm.congratsMessage}
                                    name="congratsMessage"
                                    onChange={handleChange}
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <TextField
                                    label="Course Thumbnail"
                                    size="small"
                                    type="file"
                                    multiple={false}
                                    onChange={(e) => onSelectFile(e)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <TextField
                                    label="Promotion Video"
                                    size="small"
                                    type="file"
                                    multiple={false}
                                    onChange={(e) => onSelectCoursePromotionVideo(e)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}
                                style={{
                                    maxHeight: "450px",
                                    overflowY: "auto",
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                    marginTop: "10px",
                                    marginLeft: "20px"
                                }}
                            >
                                <ReactQuill
                                    onChange={
                                        (e) => setCourseDescription(e)
                                    }
                                    modules={StartCourse.modules}
                                    formats={StartCourse.formats}
                                    value={courseDescription}
                                    style={{
                                        width: '100%',
                                        margin: '0 auto',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <Button style={{ margin: "10px" }} variant="contained" color="primary" type="submit">
                                    Submit Course For Review
                                </Button>
                                {/* <Button style={{ margin: "10px" }} variant="contained" color="primary" type="reset">
                                    Reset Form
                                </Button> */}
                            </Grid>

                        </Grid>
                    </form>
                </Container>

            </Paper>

        </div>
    )
}




StartCourse.modules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
        [
            'link',
            'image',
            // 'video',
        ],
        ['clean']
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    }
}

StartCourse.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'color',
]













export default StartCourse;


























// <div className="start__course_component">
//     <div className="">
//         <form className="" onSubmit={handleSubmit}>
//             <Typography className="heading" variant="h4">Start Course</Typography>
//             {!error && progress && (
//                 <Container>
//                     <span>
//                         Submitting course for approval ...
//                     </span>
//                     <ProgressBar now={progress} label={`${progress}%`} />
//                 </Container>
//             )}
//             <div className='start_course_form_text'>
//                 <Typography className="start__course__headings" variant="h6">Provide basic information about your course, course Title , SubTitle and Category</Typography>
//             </div>
//             <div className='start__course_form_group'>
//                 <div>
//                     <TextField
//                         label="Course Title"
//                         value={startCourseForm.courseTitle}
//                         name="courseTitle"
//                         onChange={handleChange}
//                         size="small"
//                         className="start__course__input"
//                         inputProps={{ maxLength: 100 }}
//                     />
//                 </div>
//                 <div>
//                     <TextField
//                         label="Course Subtitle"
//                         value={startCourseForm.courseSubTitle}
//                         name='courseSubTitle'
//                         onChange={handleChange}
//                         size="small"
//                         className="start__course__input"
//                         inputProps={{ maxLength: 250 }}
//                     />
//                 </div>
//                 <div>
//                     <Form.Select
//                         name='courseCategory'
//                         value={startCourseForm.courseCategory}
//                         onChange={handleChange}
//                         className="start__course__select"
//                     >
//                         <option value="">-- Select Course Category --</option>
//                         <option value="Development">Development</option>
//                         <option value="Business">Business</option>
//                         <option value="Finance / Accounting">Finance / Accounting</option>
//                         <option value="IT / Software">IT / Software</option>
//                         <option value="Office Productivity">Office Productivity</option>
//                         <option value="Personal Development">Personal Development</option>
//                         <option value="Design">Design</option>
//                         <option value="Marketing">Marketing</option>
//                         <option value="Lifestyle">Lifestyle</option>
//                         <option value="Photography / Video">Photography / Video</option>
//                         <option value="Health / Fitness">Health / Fitness</option>
//                         <option value="Music">Music</option>
//                         <option value="Teaching / Academics">Teaching / Academics</option>
//                     </Form.Select>
//                 </div>
//             </div>

//             <div className='start_course_form_text'>
//                 <Typography className="start__course__headings" variant="h6">Provide the Prerequisites of the course, how will your course improve the student and who can be benefited from it</Typography>
//             </div>
//             <div className='start__course_form_group'>
//                 <div>
//                     <TextField
//                         label="What will student learn"
//                         value={startCourseForm.whatWillStudentLearn}
//                         name="whatWillStudentLearn"
//                         onChange={handleChange}
//                         size="small"
//                         className="start__course__input"
//                     />
//                 </div>
//                 <div>
//                     <TextField
//                         label="What are Prerequisites"
//                         value={startCourseForm.whatArePrerequisites}
//                         name="whatArePrerequisites"
//                         onChange={handleChange}
//                         size="small"
//                         className="start__course__input"
//                     />
//                 </div>
//                 <div>
//                     <TextField
//                         label="Who is this course for"
//                         value={startCourseForm.whoIsThisCourseFor}
//                         name="whoIsThisCourseFor"
//                         onChange={handleChange}
//                         size="small"
//                         className="start__course__input"
//                     />
//                 </div>
//             </div>
//             <div className='start_course_form_text'>
//                 <Typography className="start__course__headings" variant="h6">Provide some basic info about the course level, pricing, what language is this course in and also provide the Welcome mesage for student when he or she starts the course and as well as provide the Congratulation message for student after successfully completing the course. In addition add the beautiful Thumbnail of the course</Typography>
//             </div>
//             <div className='start__course_form_group'>
//                 <div>
//                     <Form.Select
//                         value={startCourseForm.courseLevel}
//                         onChange={handleChange}
//                         name="courseLevel"
//                         className="start__course__select"
//                     >
//                         <option value={''}>Course Level</option>
//                         <option value="Beginner">Beginner</option>
//                         <option value="Intermediate">Intermediate</option>
//                         <option value="Advance">Advance</option>
//                     </Form.Select>
//                 </div>
//                 <div>
//                     <TextField
//                         label="Course Pricing"
//                         value={startCourseForm.coursePricing}
//                         name="coursePricing"
//                         type={"number"}
//                         onChange={handleChange}
//                         size="small"
//                         className="start__course__input"
//                     />
//                 </div>
//                 <div>
//                     <Form.Select
//                         name='courseLanguage'
//                         value={startCourseForm.courseLanguage}
//                         onChange={handleChange}
//                         className="start__course__select"
//                     >
//                         <option value="">-- Select Course Language --</option>
//                         <option value="Afrikaans">Afrikaans</option>
//                         <option value="Albanian">Albanian</option>
//                         <option value="Arabic">Arabic</option>
//                         <option value="Armenian">Armenian</option>
//                         <option value="Basque">Basque</option>
//                         <option value="Bengali">Bengali</option>
//                         <option value="Bulgarian">Bulgarian</option>
//                         <option value="Catalan">Catalan</option>
//                         <option value="Cambodian">Cambodian</option>
//                         <option value="Chinese (Mandarin)">Chinese (Mandarin)</option>
//                         <option value="Croatian">Croatian</option>
//                         <option value="Czech">Czech</option>
//                         <option value="Danish">Danish</option>
//                         <option value="Dutch">Dutch</option>
//                         <option value="English">English</option>
//                         <option value="Estonian">Estonian</option>
//                         <option value="Fiji">Fiji</option>
//                         <option value="Finnish">Finnish</option>
//                         <option value="French">French</option>
//                         <option value="Georgian">Georgian</option>
//                         <option value="German">German</option>
//                         <option value="Greek">Greek</option>
//                         <option value="Gujarati">Gujarati</option>
//                         <option value="Hebrew">Hebrew</option>
//                         <option value="Hindi">Hindi</option>
//                         <option value="Hungarian">Hungarian</option>
//                         <option value="Icelandic">Icelandic</option>
//                         <option value="Indonesian">Indonesian</option>
//                         <option value="Irish">Irish</option>
//                         <option value="Italian">Italian</option>
//                         <option value="Japanese">Japanese</option>
//                         <option value="Javanese">Javanese</option>
//                         <option value="Korean">Korean</option>
//                         <option value="Latin">Latin</option>
//                         <option value="Latvian">Latvian</option>
//                         <option value="Lithuanian">Lithuanian</option>
//                         <option value="Macedonian">Macedonian</option>
//                         <option value="Malay">Malay</option>
//                         <option value="Malayalam">Malayalam</option>
//                         <option value="Maltese">Maltese</option>
//                         <option value="Maori">Maori</option>
//                         <option value="Marathi">Marathi</option>
//                         <option value="Mongolian">Mongolian</option>
//                         <option value="Nepali">Nepali</option>
//                         <option value="Norwegian">Norwegian</option>
//                         <option value="Pashto">Pashto</option>
//                         <option value="Persian">Persian</option>
//                         <option value="Polish">Polish</option>
//                         <option value="Portuguese">Portuguese</option>
//                         <option value="Punjabi">Punjabi</option>
//                         <option value="Quechua">Quechua</option>
//                         <option value="Romanian">Romanian</option>
//                         <option value="Russian">Russian</option>
//                         <option value="Samoan">Samoan</option>
//                         <option value="Serbian">Serbian</option>
//                         <option value="Slovak">Slovak</option>
//                         <option value="Slovenian">Slovenian</option>
//                         <option value="Spanish">Spanish</option>
//                         <option value="Swahili">Swahili</option>
//                         <option value="Swedish ">Swedish </option>
//                         <option value="Tamil">Tamil</option>
//                         <option value="Tatar">Tatar</option>
//                         <option value="Telugu">Telugu</option>
//                         <option value="Thai">Thai</option>
//                         <option value="Tibetan">Tibetan</option>
//                         <option value="Tonga">Tonga</option>
//                         <option value="Turkish">Turkish</option>
//                         <option value="Ukrainian">Ukrainian</option>
//                         <option value="Urdu">Urdu</option>
//                         <option value="Uzbek">Uzbek</option>
//                         <option value="Vietnamese">Vietnamese</option>
//                         <option value="Welsh">Welsh</option>
//                         <option value="Xhosa">Xhosa</option>
//                     </Form.Select>
//                 </div>
//             </div>

//             <div className='start__course_form_group'>
//                 <div>
//                     <TextField
//                         label="Welcome Message for course"
//                         value={startCourseForm.welcomeMessage}
//                         name="welcomeMessage"
//                         onChange={handleChange}
//                         size="small"
//                         className="start__course__input"
//                     />
//                 </div>
//                 <div>
//                     <TextField
//                         label="Congrats Message after completion"
//                         value={startCourseForm.congratsMessage}
//                         name="congratsMessage"
//                         onChange={handleChange}
//                         size="small"
//                         className="start__course__input"
//                     />
//                 </div>
//                 <div>
//                     <TextField
//                         label="Course Thumbnail"
//                         size="small"
//                         type="file"
//                         multiple={false}
//                         onChange={(e) => onSelectFile(e)}
//                         className="start__course__input"
//                         InputLabelProps={{
//                             shrink: true,
//                         }}
//                     />
//                 </div>
//             </div>

//             <div className='start__course_form_group'>
//                 <TextField
//                     label="Promotion Video"
//                     size="small"
//                     type="file"
//                     multiple={false}
//                     onChange={(e) => onSelectCoursePromotionVideo(e)}
//                     className="start__course__input"
//                     InputLabelProps={{
//                         shrink: true,
//                     }}
//                     style={{
//                         marginBottom: '10px',
//                     }}
//                 />
//             </div>

//             <div className='start__course_form_group'>
//                 <ReactQuill
//                     onChange={
//                         (e) => setCourseDescription(e)
//                     }
//                     modules={StartCourse.modules}
//                     formats={StartCourse.formats}
//                     value={courseDescription}
//                     style={{
//                         height: '400px',
//                         width: '69%',
//                     }}
//                 />
//             </div>

//             <div
//                 style={{
//                     marginTop: '50px',
//                 }}
//             >
//                 <Button style={{ margin: "10px" }} variant="contained" color="primary" type="submit">
//                     Submit Course For Review
//                 </Button>
//                 <Button style={{ margin: "10px" }} variant="contained" color="primary" type="reset">
//                     Reset Form
//                 </Button>
//             </div>
//         </form>
//     </div>
// </div>