import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import * as api from "../../api/index";
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { CircularProgress, Grid, Paper, Rating } from '@mui/material';
import { COURSE_THUMBNAIL_URL } from '../../constants/actionTypes';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import "./css/SearchCourses.css";

function SearchCourses() {
    const { searchTerm } = useParams();
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [zeroResults, setZeroResults] = useState(false);
    const [rating, setRating] = useState(Math.floor(Math.random() * 3) + 2);

    React.useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const { data } = await api.searchCourses(searchTerm);
            if (data.success) {
                setCourses(data.courses);
                setLoading(false);
                setError(false);
                setZeroResults(false);
                if (data.courses.length <= 0) {
                    setZeroResults(true);
                }
            }
            else {
                setError(true);
                setLoading(false);
                setZeroResults(false);
            }
        }
        fetchData();
    }, [searchTerm]);


    return (
        <div>
            <Paper elevation={3} className="m-3 p-3">
                <Typography variant="h5" component="h3" className='heading'>
                    Search Results for "{searchTerm}"
                </Typography>
                <Divider variant="fullWidth" />
                {loading ? (
                    <div className='text-center m-2 p-2'>
                        <CircularProgress />
                    </div>
                ) : null}

                {error ? (
                    <div className='text-center m-2 p-2'>
                        <Typography variant="h6" component="h3" className='heading'>
                            Error fetching courses. Please try again later.
                        </Typography>
                    </div>
                ) : null}

                {zeroResults ? (
                    <div className='text-center m-2 p-2'>
                        <Typography variant="h6" component="h3" className='heading'>
                            No courses found.
                        </Typography>
                    </div>
                ) : null}


                {!loading && !error && !zeroResults && courses ? (
                    <div className='p-2' style={{ width: '100%', maxWidth: 900, bgcolor: 'background.paper' }}>
                        {courses.map((course, index) => (
                            <div key={index} className="mt-3">
                                <Paper elevation={4}>
                                    <Grid container spacing={0}>
                                        <Grid item sm={12} md={4} lg={4} className="p-0">
                                            <Link to={`/courseDetails/${course.id}`}>
                                                <img src={`${COURSE_THUMBNAIL_URL}/${course.courseThumbnail}`} alt="Jinga lala hoo hoo" className='rounded search_courses_img' width={300} height={200} />
                                            </Link>
                                        </Grid>
                                        <Grid item sm={12} md={8} lg={8} className="p-0 text-start p-2">
                                            <Stack gap={2}>
                                                <div>
                                                    <h5 className='fw-bold'>This is heading for course</h5>
                                                </div>
                                                <div>
                                                    <b>{course.firstName} {course.lastName}</b>
                                                    <span> {` — ${course.courseSubTitle.slice(0, 110)}...`}</span>
                                                </div>
                                                <div>
                                                    <span dangerouslySetInnerHTML={{ __html: course.courseDescription.slice(0, 100) }} />
                                                </div>
                                                <div>
                                                    <Grid container spacing={2} justifyContent="space-between">
                                                        <Grid item>
                                                            <Rating
                                                                value={rating}
                                                                size="small"
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="body2" color="text.secondary">
                                                                {course.coursePricing <= 0 ? (
                                                                    <Badge variant="success">Free</Badge>
                                                                ) : (
                                                                    <span className='text-start text-black'>
                                                                        Price <Badge bg="warning" className='text-black'>{"$ " + course.coursePricing}/-</Badge>
                                                                    </span>
                                                                )}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </div>
                        ))}
                    </div>
                ) : null}

            </Paper>
        </div >
    )
}

export default SearchCourses;