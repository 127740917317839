import React from 'react'
import { Route, Routes } from 'react-router-dom';
import SearchSoftwares from './SearchSoftwares';


function Softwares() {
  return (
    <div>
      <Routes>
        <Route path="/" exact element={
          <SearchSoftwares />
        }
        />

      </Routes>
    </div>
  )
}

export default Softwares;