import React from 'react';
import { TextField, Typography, Button, Grid, Paper } from '@mui/material';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

function EditInstituteForm({ editInstitute, setEditInstitute, handleEditInstituteSubmit, handleEditInstituteChange, setEditInstituteModalShow, onSelectEditInstituteImage, onSelectEditInstituteLogo,

    affiliationsOptions,
    instituteSectorsOptions,
    instituteTypesOptions,
    instituteProgramsOptions,
    provincesOptions,
    citiesOptions,

    handleEditInsCityIdChange,
    handleEditInsProvinceIdChange,
    handleEditInsSectorIdChange,
    handleEditInsAffiliationsChange,
    handleEditInsInstituteTypesChange,
    handleEditInsProgramsChange,
}) {

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            textAlign: 'start',
            color: 'black',
        }),
        control: (provided, state) => ({
            ...provided,
            textAlign: 'start',
            color: 'black',
        }),
        option: (provided, state) => ({
            ...provided,
            textAlign: 'start',
            color: 'black',
        }),
    }

    const selectedPrograms = () => {
        let defaults = JSON.parse(editInstitute.programs);
        let options = instituteProgramsOptions.filter(option => {
            return defaults.includes(option.value);
        });

        return options;
    }


    const selectedInsTypes = () => {
        let defaults = JSON.parse(editInstitute.instituteTypes);
        let options = instituteTypesOptions.filter(option => {
            return defaults.includes(option.value);
        });

        return options;
    }

    const selectedAffiliations = () => {
        let defaults = JSON.parse(editInstitute.affiliations);
        let options = affiliationsOptions.filter(option => {
            return defaults.includes(option.value);
        });

        return options;
    }


    return (
        <div>
            <Paper style={{ padding: '15px' }} elevation={8}>
                <form
                    onSubmit={handleEditInstituteSubmit}
                    id="add-institute-form"
                >
                    <Typography className="heading" variant="h4">Add new Institute</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                label="Institute Name"
                                value={editInstitute?.instituteName}
                                name="instituteName"
                                onChange={handleEditInstituteChange}
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                label="Website"
                                value={editInstitute?.website}
                                name="website"
                                onChange={handleEditInstituteChange}
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                label="Founder Name"
                                value={editInstitute?.founderName}
                                name="founderName"
                                onChange={handleEditInstituteChange}
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                label="Date of Establishment"
                                value={editInstitute?.dateOfEstablishment}
                                name="dateOfEstablishment"
                                onChange={handleEditInstituteChange}
                                size="small"
                                type={'date'}
                                inputProps={{ maxLength: 100 }}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={1}>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                label="Facebook"
                                value={editInstitute?.facebook}
                                name="facebook"
                                onChange={handleEditInstituteChange}
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                label="Twitter"
                                value={editInstitute?.twitter}
                                name="twitter"
                                onChange={handleEditInstituteChange}
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                label="Linkedin"
                                value={editInstitute?.linkedin}
                                name="linkedin"
                                onChange={handleEditInstituteChange}
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                label="Instagram"
                                value={editInstitute?.instagram}
                                name="instagram"
                                onChange={handleEditInstituteChange}
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={1}>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                label="Results"
                                value={editInstitute?.results}
                                name="results"
                                onChange={handleEditInstituteChange}
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Select
                                options={affiliationsOptions}
                                placeholder="-- Select Affiliations --"
                                styles={customStyles}
                                onChange={handleEditInsAffiliationsChange}
                                isMulti
                                defaultValue={selectedAffiliations}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Select
                                options={provincesOptions}
                                placeholder="-- Select Province --"
                                styles={customStyles}
                                onChange={handleEditInsProvinceIdChange}
                                defaultValue={
                                    provincesOptions.filter(option => option.value === editInstitute.provinceId)
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Select
                                options={citiesOptions}
                                placeholder="-- Select City --"
                                styles={customStyles}
                                onChange={handleEditInsCityIdChange}
                                defaultValue={
                                    citiesOptions.filter(option => option.value === editInstitute.cityId)
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={1}>
                        <Grid item xs={12} md={6} lg={3}>
                            <Select
                                options={instituteSectorsOptions}
                                placeholder="-- Select Sector --"
                                styles={customStyles}
                                onChange={handleEditInsSectorIdChange}
                                defaultValue={
                                    instituteSectorsOptions.filter(option => option.value === editInstitute.sectorId)
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Select
                                options={instituteTypesOptions}
                                placeholder="-- Select Institute Types --"
                                styles={customStyles}
                                isMulti
                                onChange={handleEditInsInstituteTypesChange}
                                defaultValue={selectedInsTypes}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Select
                                options={instituteProgramsOptions}
                                placeholder="-- Select Programs --"
                                styles={customStyles}
                                onChange={handleEditInsProgramsChange}
                                isMulti
                                defaultValue={selectedPrograms}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={1}>
                        <Grid item xs={12} md={6} lg={3}>
                            <Form.Control type="file" onChange={(e) => onSelectEditInstituteImage(e)} size="sm" />
                            <Form.Label>Edit Image</Form.Label>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Form.Control type="file" onChange={(e) => onSelectEditInstituteLogo(e)} size="sm" />
                            <Form.Label>Edit Logo</Form.Label>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={1} justifyContent="center">
                        <Grid item xs={12} md={4} lg={3}>
                            <Button variant="contained" color="primary" type="submit" fullWidth>
                                UPDATE INSTITUTE DATA
                            </Button>
                        </Grid>
                    </Grid>
                    <div>
                    </div>
                </form>
            </Paper>
        </div>
    )
}

export default EditInstituteForm;