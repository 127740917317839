import React, { useEffect } from 'react';
import SideBar from "../InstructorSideBar/InstructorSidebar";
import { Routes, Route, useNavigate, Link } from "react-router-dom";
import "./InstructorHome.css";
import MyCourses from "../MyCourses/MyCourses";
import StartCourse from "../MyCourses/StartCourse.js";
import CourseDetails from "../MyCourses/CourseDetails.js";
import { useDispatch} from 'react-redux';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { logoutFunc } from '../../../actions/auth';
import decode from "jwt-decode";
import ReviewCourses from '../MyCourses/ReviewCourses';
import { Button } from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';

const InstructorDashboard = () => {
    return (
        <Container className='mt-4'>
            <Row>

                <Col xs={12} md={12} lg={12}>
                    <Card
                        text={'white'}
                        className="mb-2"
                        style={{
                            background: "rgb(11 142 150)"
                        }}
                    >
                        <div style={{margin: "5px"}}>
                            <Link to={`/instructorDashboard/myCourses`}>
                                <Button
                                    size='small'
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<PreviewIcon />}
                                    className="m-1"
                                >
                                    View all Courses
                                </Button>
                            </Link>
                            <Link to={`/instructorDashboard/newCourse`}>
                                <Button
                                    size='small'
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<PreviewIcon />}
                                    className="m-1"
                                >
                                    Create new Course
                                </Button>
                            </Link>
                            <Link to={`/instructorDashboard/reviewCourses`}>
                                <Button
                                    size='small'
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<PreviewIcon />}
                                    className="m-1"
                                >
                                    View Review Courses
                                </Button>
                            </Link>
                        </div>
                    </Card>
                </Col>

            </Row>
        </Container>
    )
}

function InstructorHome({ user, setUser }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const token = user?.token;
        if (token) {
            try {
                const decodedToken = decode(token);
                if (decodedToken.exp * 1000 < new Date().getTime()) {
                    dispatch(logoutFunc(setUser, navigate));
                }
            } catch (error) {
                localStorage.removeItem("profile");
            }
        }
        else {
            localStorage.removeItem("profile");
        }
        if (!user) {
            navigate("/");
            return
        }
    }, [dispatch, user, navigate, setUser]);

    return (
        <div>
            <SideBar />
            <div className='instructor_home_content'>
                <Routes>
                    <Route path="/" exact element={<InstructorDashboard />} />
                    <Route path='/myCourses' exact element={<MyCourses user={user} setUser={setUser} />} />
                    <Route path='/newCourse' exact element={<StartCourse user={user} setUser={setUser} />} />
                    <Route path='/courseDetails/:courseId' exact element={<CourseDetails user={user} setUser={setUser} />} />
                    <Route path='/reviewCourses' exact element={<ReviewCourses user={user} setUser={setUser} />} />
                </Routes>
            </div>
        </div>
    )
}

export default InstructorHome;
