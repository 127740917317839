import { Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Swal from "sweetalert2";
import * as api from "../../api/index.js";
import "./css/PostBlog.css";


function PostBlog({ user, setUser }) {
    const [blogContent, setBlogContent] = useState("");
    const [blogTitle, setBlogTitle] = useState("");
    const [requesting, setRequesting] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (requesting) {
            Swal.fire({
                title: 'Processing your request...',
                text: 'Please wait...',
                showConfirmButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: true,
            });
            return;
        }

        setRequesting(true);
        
        if (blogContent === "" || blogTitle === "") {
            Swal.fire(
                'Failed!',
                'Blog post can not be empty',
                'error'
            );
            setRequesting(false);
        }
        else {
            const formData = { "userId": user.result.id, "blogContent": blogContent, "blogTitle": blogTitle }
            try {
                const { data } = await api.postBlogContent(formData);
                if (data.success) {
                    Swal.fire({
                        title: 'Good job!',
                        text: data.message,
                        icon: 'success',
                        showConfirmButton: true,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    });
                    setBlogContent("");
                    setBlogTitle("");
                    setRequesting(false);
                }
                else {
                    Swal.fire(
                        'Failed!',
                        data.error,
                        'error'
                    );
                    setRequesting(false);
                }
            } catch (error) {
                Swal.fire(
                    'Failed!',
                    error.error,
                    'error'
                );
                setRequesting(false);
            }
        }

    }

    const handleChange = (value) => {
        setBlogContent(value);
    }

    return (
        <Container>
            <form onSubmit={(e) => handleSubmit(e)} className="postBlogForm">
                <h2 style={{ fontFamily: "monospace" }}><u>Post A blog.</u></h2>
                <TextField
                    label="Blog Title"
                    size="small"
                    fullWidth
                    className='mb-2'
                    value={blogTitle}
                    onChange={(e) => { setBlogTitle(e.target.value) }}
                    inputProps={{ maxLength: 200 }}
                />
                <ReactQuill
                    onChange={handleChange}
                    modules={PostBlog.modules}
                    formats={PostBlog.formats}
                    value={blogContent}
                />
                <Button type="submit" variant='contained' color='primary' className='mt-4 w-50 mb-4'>Post</Button>
            </form>
        </Container>
    )
}

PostBlog.modules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
        [
            'link',
            'image',
            // 'video',
        ],
        ['clean']
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    }
}

PostBlog.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'color',
]


export default PostBlog;