import { Button, CircularProgress, Divider, Grid, Paper, TextField } from '@mui/material';
import React, { useState } from 'react'
import { Container } from 'react-bootstrap';
import Swal from 'sweetalert2';
import * as api from "../../api/index.js";

function StudentVerification() {
    const [regOrRollNo, setRegOrRollNo] = useState('');
    const [loading, setLoading] = useState(false);
    const [zeroResults, setZeroResults] = useState(false);
    const [foundStudent, setFoundStudent] = useState([]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const { data } = await api.checkSingleStudentVerification(regOrRollNo);
            if (data.success) {
                setLoading(false);
                if (!data.zero_results) {
                    setZeroResults(false);
                    setFoundStudent(data.student);
                }
                else {
                    setZeroResults(true);
                }
            }
            else {
                setLoading(false);
                Swal.fire({
                    title: 'Failed!',
                    text: data.message,
                    icon: 'warning',
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                title: 'Failed!',
                text: "Failed to Search for Books",
                icon: 'warning',
            });
        }
    }



    return (
        <div>
            <Container maxwidth="lg">
                <Paper elevation={12}>
                    <form autoComplete='false' onSubmit={handleSubmit}>
                        <Grid mx={2} my={2} className="p-4">
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <h3 style={{
                                        fontFamily: "monospace"
                                    }}>
                                        Type [ Registration no ] OR [ Roll no ] for Verification
                                    </h3>
                                </Grid>
                            </Grid>
                            <Divider
                                variant='fullWidth'
                            />
                            <br />
                            <Grid
                                container
                                spacing={3}
                                className="text-start"
                                justifyContent={"center"}
                            >
                                <Grid item xs={12} md={12} lg={12}>
                                    <TextField
                                        id="regOrRollNo"
                                        name="regOrRollNo"
                                        label="Reg No | Roll No"
                                        variant="outlined"
                                        size='small'
                                        fullWidth
                                        onChange={(e) => {
                                            setRegOrRollNo(e.target.value)
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} className="mt-1" justifyContent={"center"}>
                                <Grid item xs={12} md={6} lg={4}>
                                    <Button type='submit' variant="contained" color="primary" className="mt-4">
                                        Verify
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Container>
            <Container maxwidth="lg">

                {loading && (
                    <Container maxwidth="lg">
                        <CircularProgress />
                    </Container>
                )
                }
                {zeroResults && (
                    <div
                        style={{
                            marginTop: '2.5rem',
                        }}
                    >
                        <Paper elevation={12} className="mt-3">
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <h3 style={{
                                        fontFamily: "monospace"
                                    }}>
                                        No Info found.
                                    </h3>
                                </Grid>
                            </Grid>
                        </Paper>
                    </div>
                )
                }
                {zeroResults == false && Object.keys(foundStudent)?.length > 0 && (
                    <div
                        key={foundStudent?.roll_no}
                        style={{
                            marginTop: '1.5rem',
                        }}
                    >
                        <Paper elevation={12} className="p-2">
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <h3 style={{
                                        fontFamily: "monospace",
                                        background: "#25d4df",
                                    }}>
                                        <hr />
                                        Verification Successfull
                                        <hr />
                                    </h3>
                                    <table className="table text-start">
                                        <tbody>
                                            <tr>
                                                <th>Student Name</th>
                                                <td>{foundStudent.student_name}</td>
                                            </tr>
                                            <tr>
                                                <th>Father Name</th>
                                                <td>{foundStudent?.father_name}</td>
                                            </tr>
                                            <tr>
                                                <th>Roll No</th>
                                                <td>{foundStudent?.roll_no}</td>
                                            </tr>
                                            <tr>
                                                <th>Course</th>
                                                <td>{foundStudent?.course}</td>
                                            </tr>
                                            <tr>
                                                <th>Registeration Id</th>
                                                <td>{foundStudent?.registeration_id}</td>
                                            </tr>
                                            <tr>
                                                <th>Session Name</th>
                                                <td>{foundStudent?.session_name}</td>
                                            </tr>
                                            <tr>
                                                <th>Grade</th>
                                                <td>{foundStudent?.grade}</td>
                                            </tr>
                                            <tr>
                                                <th>Obtained Marks</th>
                                                <td>{foundStudent?.obtained_marks}</td>
                                            </tr>
                                            <tr>
                                                <th>Total Marks</th>
                                                <td>{foundStudent?.total_marks}</td>
                                            </tr>
                                            <tr>
                                                <th>Duration</th>
                                                <td>{foundStudent?.duration}</td>
                                            </tr>
                                            <tr>
                                                <th>Percentage</th>
                                                <td>{foundStudent?.percentage}</td>
                                            </tr>
                                            <tr>
                                                <th>Result</th>
                                                <td>{foundStudent?.result}</td>
                                            </tr>
                                            <tr>
                                                <th>Institute</th>
                                                <td>{foundStudent?.institute}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Grid>
                            </Grid>
                        </Paper>
                    </div>
                )
                }
            </Container>
        </div>
    )
}

export default StudentVerification;