import * as api from "../api/index";
import Swal from "sweetalert2";
import { ADD_COURSE_TO_INSTRUCTOR, DELETE_COURSE_OF_INSTRUCTOR, DELETE_COURSE_VIDEO_OF_INSTRUCTOR, FETCH_COURSES_FOR_INSTUCTOR, GET_VIDEOS_OF_COURSE_OF_INSTRUCTOR } from "../constants/actionTypes";



export const fetchCoursesForInstructor = (userId, navigate) => async (dispatch) => {
    try {
        const { data } = await api.fetchCoursesForInstructor(userId);
        if (data.success === false) {
            // window.alert(data.message)
            navigate("/");
        }
        else {
            dispatch({ type: FETCH_COURSES_FOR_INSTUCTOR, payload: data });
        }
    } catch (error) {
        console.log(error);
    }
}


// export const assignCourseToInstructor = (formData, config) => async (dispatch) => {
//     try {
//         const { data } = await api.assignCourseToInstructor(formData, config);
//         if (data.success === true) {
//             Swal.fire(
//                 'Good job!',
//                 data.message,
//                 'success'
//             )
//             dispatch({ type: ADD_COURSE_TO_INSTRUCTOR, payload: data.course });
//         }
//         else {
//             Swal.fire(
//                 'Failed!',
//                 data.error,
//                 'error'
//             )
//         }
//     } catch (error) {
//         Swal.fire(
//             'Failed!',
//             'Course is not added please try again, if problem persists contact Programmer',
//             'error'
//         )
//     }
// }

export const assignCourseToInstructor = (course) => async (dispatch) => {
    dispatch({ type: ADD_COURSE_TO_INSTRUCTOR, payload: course });
}

export const deleteCourseOfInstructor = (id) => async (dispatch) => {
    try {
        const { data } = await api.deleteCourseOfInstructor(id);
        if (data.success) {
            dispatch({ type: DELETE_COURSE_OF_INSTRUCTOR, payload: id });
            Swal.fire(
                'Good job!',
                'Course is Deleted Successfully',
                'success'
            )
        }
        else {
            Swal.fire(
                'Failed!',
                data.error,
                'error'
            )
        }
    } catch (error) {
        console.log(error);
    }
}



export const getVideosOfCourseOfInstructor = (courseId, userId) => async (dispatch) => {
    try {
        const { data } = await api.getVideosOfCourseOfInstructor(courseId, userId);
        dispatch({ type: GET_VIDEOS_OF_COURSE_OF_INSTRUCTOR, payload: data });
    } catch (error) {
        console.log(error);
    }
}

export const deleteCourseVideoOfInstructor = (id) => async (dispatch) => {
    try {
        const { data } = await api.deleteCourseVideoOfInstructor(id);
        if (data.success) {
            dispatch({ type: DELETE_COURSE_VIDEO_OF_INSTRUCTOR, payload: id });
            Swal.fire(
                'Good job!',
                'Video is Deleted Successfully',
                'success'
            )
        }
        else {
            Swal.fire(
                'Failed!',
                data.error,
                'error'
            )
        }
    } catch (error) {
        console.log(error);
    }
}


