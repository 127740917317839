import React, { useState, useEffect, useCallback } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Button, CircularProgress, TextField } from '@mui/material';
import "./css/StudentsCourses.css";
import * as api from "../../api/index";
import Swal from 'sweetalert2';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import { PAGE_SIZES, tableDataToPdf } from '../../Globals/PDFVariables';
import { CSVLink } from 'react-csv';

const columns = [
    { id: 'sno', label: 'S.No', minWidth: 10, width: '5%' },
    { id: 'courseName', label: 'Course Name', minWidth: 10, width: '65%' },
    { id: 'coursePrice', label: 'Course Price', minWidth: 10, width: '10%' },
    { id: 'studentName', label: 'Student Name', minWidth: 10, width: '10%' },
    { id: 'courseStatus', label: 'Course Status', minWidth: 10, width: '10%' },
];

function StudentsCourses() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchTerm, setSearchTerm] = useState([]);
    const [studentsCourses, setStudentsCourses] = useState([]);
    const [studentCoursesBackup, setStudentCoursesBackup] = useState([]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };



    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        const searchText = e.target.value;
        if (e.target.value) {
            const results = studentCoursesBackup.filter(course =>
                course.courseTitle.toLowerCase().includes(searchText.toLowerCase()) ||
                course.coursePricing.toString().toLowerCase().includes(searchText.toLowerCase()) ||
                course.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
                course.lastName.toLowerCase().includes(searchText.toLowerCase())
            );
            setStudentsCourses(results);
        } else {
            setStudentsCourses(studentCoursesBackup);
        }
    }

    const handleCourseStatusChange = async (e, studentCourse) => {
        const status = e.target.value === "1" ? true : false;
        try {
            const { data } = await api.changeStudentCourseStatusFromTransaction({ "courseId": studentCourse.id, "status": status });
            if (data.success) {
                getStudentCourses();
                Swal.fire({
                    title: 'Good job!',
                    text: data.message,
                    icon: 'success',
                    showConfirmButton: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                });
            }
            else {
                Swal.fire(
                    'Failed!',
                    data.error,
                    'error'
                );
            }
        } catch (error) {
            Swal.fire(
                'Failed!',
                error,
                'error'
            );
        }
    }

    const getStudentCourses = useCallback(async () => {
        try {
            const { data } = await api.getStudentsCoursesForAdmin();
            if (data.success) {
                setStudentsCourses(data.studentsCourses);
                setStudentCoursesBackup(data.studentsCourses);
            }
            else {
                Swal.fire(
                    'Failed!',
                    data.error,
                    'error'
                );
            }
        } catch (error) {
            Swal.fire(
                'Failed!',
                error,
                'error'
            );
        }
    }, []);



    const createPdf = () => {
        const tableColumns = [['S.No', 'Course Name', 'Course Price', 'Student Name', 'Course Status']];
        const tableRows = studentsCourses.map((studentCourse, index) => [
            index + 1,
            studentCourse.courseTitle,
            studentCourse.coursePricing,
            studentCourse.firstName + " " + studentCourse.lastName,
            studentCourse.courseStatus ? "Active" : "Inactive"
        ]);

        tableDataToPdf(tableColumns, tableRows, "Students Courses");
    }

    useEffect(() => {
        getStudentCourses();
    }, [getStudentCourses]);



    return (
        <div className='students_courses__main_div'>
            <div className='card'>
                <div className='card-header'>
                    <h3>Students Courses</h3>
                </div>
            </div>
            <div className='students_courses__top_div'>
                <div>
                    <TextField
                        label="Search Here"
                        name="search"
                        size="small"
                        className='m-2'
                        type={'search'}
                        value={searchTerm}
                        style={{
                            width: "300px",
                        }}
                        onChange={(e) => handleSearch(e)}
                    />
                </div>
                <div className='users__button_div'>
                    <Button
                        variant="contained"
                        color="primary"
                        className='users__button m-1'
                        startIcon={<CollectionsBookmarkIcon />}
                        size="small"
                    >
                        <CSVLink style={{
                            textDecoration: 'none',
                            color: 'white',
                        }}
                            data={
                                studentsCourses.map((course, index) => {
                                    return {
                                        "S.No": index + 1,
                                        "Course Name": course.courseTitle,
                                        "Course Price": course.coursePricing,
                                        "Student Name": `${course.firstName} ${course.lastName}`,
                                        "Course Status": course.courseStatus ? "Active" : "Inactive",
                                    }
                                })
                            }
                            filename="students_courses.csv"
                        >CSV/EXCEL</CSVLink>
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className='users__button m-1'
                        startIcon={<PictureAsPdfIcon />}
                        size="small"
                        onClick={createPdf}
                    >
                        PDF
                    </Button>
                </div>
            </div>
            <div className='users__table_div'>
                <Paper sx={{
                    width: '100%', overflow: 'hidden',
                    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
                    borderRadius: '8px',
                    backgroundColor: '#fafafa'
                }}>
                    <TableContainer sx={{ maxHeight: 570 }}>
                        <Table stickyHeader aria-label="sticky table"
                            size='small'
                        >
                            <TableHead
                                sx={{
                                    backgroundColor: '#81d690',
                                    borderRadius: '8px',
                                    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
                                }}
                            >
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                            width={column.width}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {!studentsCourses && (
                                    <TableRow>
                                        <TableCell colSpan={6}>
                                            <CircularProgress />
                                        </TableCell>
                                    </TableRow>
                                )}

                                {studentsCourses && studentsCourses.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((course, index) => {
                                    return (
                                        <TableRow hover role="checkbox" key={course.id}>

                                            <TableCell component="td" scope="row">
                                                {++index}
                                            </TableCell>
                                            <TableCell component="td" scope="row">
                                                {course.courseTitle}
                                            </TableCell>
                                            <TableCell component="td" scope="row">
                                                {course.coursePricing}
                                            </TableCell>
                                            <TableCell component="td" scope="row">
                                                {course.firstName} {course.lastName}
                                            </TableCell>
                                            <TableCell component="td" scope="row">
                                                <select className="course__status"
                                                    value={course.approvedByAdmin ? "1" : "0"}
                                                    onChange={(e) => handleCourseStatusChange(e, course)}>
                                                    <option value="1">Approved</option>
                                                    <option value="0">Not Approved</option>
                                                </select>
                                            </TableCell>

                                        </TableRow>
                                    );
                                })}

                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={PAGE_SIZES}
                        component="div"
                        count={studentsCourses.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        className='table_pagination'
                    />
                </Paper>
            </div>
        </div>
    );
}



export default StudentsCourses;



































// import React, { useState, useEffect } from 'react';
// import { CircularProgress, TextField } from '@mui/material';
// import Table from 'react-bootstrap/Table'
// import { useDispatch, useSelector } from 'react-redux';
// import "./css/StudentsCourses.css";
// import * as api from "../../api/index";
// import Swal from 'sweetalert2';
// import { getStudentsCoursesForAdmin } from '../../actions/admin';

// function StudentsCourses() {
//     const [searchTerm, setSearchTerm] = useState([]);
//     const studentsCoursesData = useSelector((state) => state.studentsCoursesForAdmin);
//     const [studentsCourses, setStudentsCourses] = useState(studentsCoursesData);
//     const dispatch = useDispatch();


//     const handleSearch = (e) => {
//         setSearchTerm(e.target.value);
//         if (e.target.value) {
//             const results = studentsCoursesData.filter(course =>
//                 // course.courseId.courseTitle.toLowerCase().includes(e.target.value) ||
//                 // course.userId.firstName.toLowerCase().includes(e.target.value) ||
//                 // course.userId.lastName.toLowerCase().includes(e.target.value)
//                 `
//                 ${course.courseTitle}
//                 ${course.coursePricing}
//                 ${course.firstName}
//                 ${course.lastName}
//                 `.toLowerCase().includes(e.target.value)
//             );
//             setStudentsCourses(results);
//         } else {
//             setStudentsCourses(studentsCoursesData);
//         }
//     }

//     const handleCourseStatusChange = async (e, studentCourse) => {
//         const status = e.target.value === "1" ? true : false;
//         try {
//             const { data } = await api.changeStudentCourseStatusFromTransaction({ "courseId": studentCourse.id, "status": status });
//             if (data.success) {
//                 dispatch(getStudentsCoursesForAdmin());
//                 Swal.fire(
//                     'Good job!',
//                     data.message,
//                     'success'
//                 );
//             }
//             else {
//                 Swal.fire(
//                     'Failed!',
//                     data.error,
//                     'error'
//                 );
//             }
//         } catch (error) {
//             Swal.fire(
//                 'Failed!',
//                 error,
//                 'error'
//             );
//         }
//     }

//     useEffect(() => {
//         setStudentsCourses(studentsCoursesData);
//     }, [studentsCoursesData]);


//     return (
//         <div className='students_courses__main_div'>
//             <div className='students_courses__top_div'>
//                 <div>
//                     <TextField
//                         label="Search Here"
//                         name="search"
//                         size="small"
//                         className='m-2'
//                         type={'search'}
//                         value={searchTerm}
//                         style={{ width: "220px" }}
//                         onChange={(e) => handleSearch(e)}
//                     />
//                 </div>
//                 {/* <div><Button variant='contained' className='m-2'>CSV</Button></div>
//             <div><Button variant='contained' className='m-2'>PDF</Button></div> */}
//             </div>
//             <div>
//                 <Table striped bordered hover size="sm" style={{ textAlign: "start" }} responsive>
//                     <thead>
//                         <tr>
//                             <th>S.No</th>
//                             <th>Course Name</th>
//                             <th>Course Pricing</th>
//                             <th>User Name</th>
//                             <th>Course Status</th>
//                         </tr>
//                     </thead>
//                     <tbody>

//                         {!studentsCourses ? (
//                             <tr>
//                                 <td colSpan="5">
//                                     <CircularProgress />
//                                 </td>
//                             </tr>
//                         ) : (
//                             <>
//                                 {studentsCourses.map((course, index) => {
//                                     return (
//                                         <tr key={index}>
//                                             <td>{index + 1}</td>
//                                             <td>{course?.courseTitle}</td>
//                                             <td>{course?.coursePricing}</td>
//                                             <td>{`${course?.firstName} ${course?.lastName}`}</td>
//                                             <td>
//                                                 <select className="course__status"
//                                                     value={course.approvedByAdmin ? "1" : "0"}
//                                                     onChange={(e) => handleCourseStatusChange(e, course)}>
//                                                     <option value="1">Approved</option>
//                                                     <option value="0">Not Approved</option>
//                                                 </select>
//                                             </td>
//                                         </tr>
//                                     )
//                                 })}
//                             </>
//                         )}
//                     </tbody>
//                 </Table>
//             </div>
//         </div>
//     );
// }

// export default StudentsCourses;
