import Swal from "sweetalert2";
import * as api from "../api/index";
import { AUTH, LOGOUT } from "../constants/actionTypes";

export const signUp = (formData, navigate, setUser, requesting, setRequesting) => async (dispatch) => {
    try {
        const { data } = await api.signUp(formData);
        if (data.success) {
            Swal.fire({
                title: "Account Created Successfully!",
                text: data.message,
                icon: "success",
                showConfirmButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            });

            // setUser(data);
            // dispatch({ type: AUTH, payload: data });
            setRequesting(false);
            // navigate("/");
        }
        else {
            Swal.fire(
                'Failed!',
                data.error,
                'error'
            );
            setRequesting(false);
        }
    } catch (error) {
        Swal.fire(
            'Failed!',
            'Failed to Sign Up',
            'error'
        );
        setRequesting(false);
    }
}


export const signIn = (formData, navigate, setUser, requesting, setRequesting) => async (dispatch) => {
    try {
        const { data } = await api.signIn(formData);
        if (data.success) {
            setUser(data);
            dispatch({ type: AUTH, payload: data });
            setRequesting(false);
            navigate("/");
        }
        else {
            Swal.fire({
                title: "Failed to Sign In!",
                text: data.error,
                icon: "error",
                showConfirmButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            });
            setRequesting(false);
        }
    } catch (error) {
        Swal.fire(
            'Failed!',
            'Failed to Sign In',
            'error'
        );
        setRequesting(false);
    }
}


export const logoutFunc = (setUser, navigate) => async (dispatch) => {
    try {
        const { data } = await api.logout();
        if (data.success) {
            Swal.fire(
                'Logged Out!',
                'You have been logged out',
                'success'
            );
            dispatch({ type: LOGOUT });
            setUser(null);
            navigate("/");
        }
        else {
            Swal.fire(
                'Failed!',
                data.error || data.message,
                'error'
            );
        }
    } catch (error) {
        localStorage.removeItem("profile");
        setUser(null);
        navigate("/");
        Swal.fire(
            'Alert!',
            "You have been logged out",
            'success'
        );
    }
}