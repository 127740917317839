import React, { useEffect } from 'react'
import "./css/AdminHome.css";
import MainNavbar from './MainNavbar';
import HomeMain from './HomeMain';
import { Routes, Route, useNavigate } from "react-router-dom";
import { Divider } from '@mui/material';
import Transactions from './Transactions';
import { useDispatch } from 'react-redux';
import TransactionDetails from './TransactionDetails';
import StudentsCourses from './StudentsCourses';
import InstructorsCourses from './InstructorsCourses';
import SignUp from "./SignUp.js";
import PostBlog from './PostBlog';
import AssignCourse from './AssignCourse';
import Blogs from './Blogs';
import { logoutFunc } from '../../actions/auth';
import decode from "jwt-decode";
import Users from './Users';
import UserDetails from './UserDetails';
import Institutes from './Institutes/Institutes';
import PastPapers from './PastPapers/PastPapers';
import GeneralManagement from './GeneralManagement/GeneralManagement';
import Admissions from './Admissions/Admissions';
import Downloads from './Downloads/Downloads';
import VerifiedStudents from './StudentVerification/VerifiedStudents';

function AdminHome({ user, setUser, cartItems, setCartItems }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        const token = user?.token;
        if (token) {
            const decodedToken = decode(token);
            if (decodedToken.exp * 1000 < new Date().getTime()) {
                dispatch(logoutFunc(setUser, navigate));
            }
        }
        if (user.result.role !== "admin") {
            navigate("/");
        }
    }, [dispatch, navigate, user, setUser]);

    return (
        <div>
            <MainNavbar user={user} setUser={setUser} cartItems={cartItems} setCartItems={setCartItems} />
            <Divider variant="middle" />
            <div style={{ marginTop: 55 }}>
                <Routes>
                    <Route path="/" exact element={<HomeMain user={user} cartItems={cartItems} setCartItems={setCartItems} />} />
                    <Route path='/studentsCourses' exact element={<StudentsCourses />} />
                    <Route path='/instructorsCourses' exact element={<InstructorsCourses />} />
                    <Route path='/transactions' exact element={<Transactions />} />
                    <Route path="/transactions/:transactionId" element={<TransactionDetails />} />
                    <Route path="/postBlog" element={<PostBlog user={user} setUser={setUser} />} />
                    <Route path="/adminSignup" element={<SignUp />} />
                    <Route path="/assignCourse" element={<AssignCourse />} />
                    <Route path="/blogs" element={<Blogs user={user} setUser={setUser} />} />
                    <Route path="/users" element={<Users user={user} setUser={setUser} />} />
                    <Route path="/users/:userId" element={<UserDetails />} />
                    <Route path="/generalManagement" element={<GeneralManagement user={user} setUser={setUser} />} />
                    <Route path="/institutes" element={<Institutes />} />
                    <Route path="/pastPapers" element={<PastPapers user={user} setUser={setUser} />} />
                    <Route path="/admissions" element={<Admissions user={user} setUser={setUser} />} />
                    <Route path="/downloads" element={<Downloads user={user} setUser={setUser} />} />
                    <Route path="/studentVerification" element={<VerifiedStudents user={user} setUser={setUser} />} />
                </Routes>
            </div>
        </div>
    )
}

export default AdminHome;