import React from 'react';
import { Link } from 'react-router-dom';
import "./css/Footer.css";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import LanguageIcon from '@mui/icons-material/Language';

function Footer() {
    const currentYear = new Date().getFullYear();

    const redirectTo = (url) => {
        window.open(url, '_blank');
    }

    return (
        <div className="footer-dark">
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 col-md-3 terms">
                            <h3>Terms</h3>
                            <ul>
                                <li>
                                    <Link to="/privacyPolicy" >Privacy Policy</Link>
                                </li>
                                <li>
                                    <Link to="/termsAndConditions">Term and Condtions</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-sm-6 col-md-3 about">
                            <h3>About</h3>
                            <ul>
                                <li>
                                    <span onClick={() => redirectTo("https://aghazsolutions.com/")}>Aghaz IT Solutions</span>
                                </li>
                                <li>
                                    <span onClick={() => redirectTo("https://ukicsel.com/")}>UKICSEL</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 text mt-2">
                            <h3><u>Developed By:</u> Aghaz IT Solutions</h3>
                            <p>Aghaz IT Solutions Incepted in 2020, Business Technologies (Pvt) Ltd is a leading Development & Design expert company providing superior Software, Web, Mobile and Creative Designing solutions and services to companies globally. Having a strong Technology Development and Innovation center in Pakistan and with more than 700+ satisfied clients worldwide, raging from startups - fortune 500 companies across diverse industries.</p>
                        </div>
                        <div className="col social">
                            {/* <Link to="https://www.facebook.com/Courseedxpakistan" target={"_blank"}>
                                <FacebookIcon />
                            </Link> */}
                            <span onClick={() => redirectTo("https://www.facebook.com/Courseedxpakistan")}>
                                <FacebookIcon />
                            </span>
                            <span onClick={() => redirectTo("https://twitter.com/ukicsel/")}>
                                <TwitterIcon />
                            </span>
                            <span onClick={() => redirectTo("https://www.linkedin.com/company/81079761/admin/")}>
                                <LinkedInIcon />
                            </span>
                            <span onClick={() => redirectTo("https://www.instagram.com/courseedxpakistan/")}>
                                <InstagramIcon />
                            </span>
                            <span onClick={() => redirectTo("http://www.courseedx.com/")}>
                                <LanguageIcon />
                            </span>
                        </div>
                    </div>
                    <p className="copyright">Aghaz IT Solutions {currentYear}</p>
                </div>
            </footer>
        </div>
    )
}

export default Footer;