import { Button, Divider, Grid, Paper, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import * as api from "../../../api/index";



const initialState = {
    student_name: '',
    father_name: '',
    roll_no: '',
    course: '',
    issue_date: '',
    registeration_id: '',
    session_name: '',
    grade: '',
    obtained_marks: '',
    total_marks: '',
    duration: '',
    percentage: '',
    result: '',
    institute: '',
}


function AddStudentVerification({ verifiedStudentsData, setVerifiedStudentsData, user, setUser }) {
    const [studentVerificationForm, setStudentVerificationForm] = useState(initialState);
    const [requesting, setRequesting] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (requesting) {
            Swal.fire({
                title: "Please wait",
                text: "Your request is being processed",
                icon: "info",
                showConfirmButton: false,
                showCancelButton: false,
                allowOutsideClick: true,
                allowEscapeKey: true,
                allowEnterKey: true,
            });
            return;
        }

        if (
            !studentVerificationForm.student_name ||
            !studentVerificationForm.father_name ||
            !studentVerificationForm.roll_no ||
            !studentVerificationForm.course ||
            !studentVerificationForm.issue_date ||
            !studentVerificationForm.registeration_id ||
            !studentVerificationForm.session_name ||
            !studentVerificationForm.grade ||
            !studentVerificationForm.obtained_marks ||
            !studentVerificationForm.total_marks ||
            !studentVerificationForm.duration ||
            !studentVerificationForm.percentage ||
            !studentVerificationForm.result ||
            !studentVerificationForm.institute
        ) {
            Swal.fire({
                title: "Warning",
                text: "Please select all the fields",
                icon: "warning",
            });
            return;
        }

        setRequesting(true);
        try {

            const formData = new FormData();
            formData.append('student_name', studentVerificationForm.student_name);
            formData.append('father_name', studentVerificationForm.father_name);
            formData.append('roll_no', studentVerificationForm.roll_no);
            formData.append('course', studentVerificationForm.course);
            formData.append('issue_date', studentVerificationForm.issue_date);
            formData.append('registeration_id', studentVerificationForm.registeration_id);
            formData.append('session_name', studentVerificationForm.session_name);
            formData.append('grade', studentVerificationForm.grade);
            formData.append('obtained_marks', studentVerificationForm.obtained_marks);
            formData.append('total_marks', studentVerificationForm.total_marks);
            formData.append('duration', studentVerificationForm.duration);
            formData.append('percentage', studentVerificationForm.percentage);
            formData.append('result', studentVerificationForm.result);
            formData.append('institute', studentVerificationForm.institute);

            const { data } = await api.addStudentVerificationDetails(formData);
            if (data.success) {
                Swal.fire({
                    title: "Success",
                    text: data.message,
                    icon: "success",
                });
                setRequesting(false);
                setVerifiedStudentsData([...verifiedStudentsData, data.studentDetails]);
                setStudentVerificationForm(initialState);
                window.document.getElementById("studentVerificationForm").reset();
            } else {
                Swal.fire({
                    title: "Error",
                    text: data.message,
                    icon: "error",
                });
                setRequesting(false);
            }

        } catch (error) {
            Swal.fire({
                title: "Error",
                text: "Failed to add data, Please contact the one and only Mr. Programmer",
                icon: "error",
            });
            setRequesting(false);
        }

    }

    const handleChange = (e) => {
        setStudentVerificationForm({ ...studentVerificationForm, [e.target.name]: e.target.value });
    }


    return (
        <div>
            <Paper style={{ padding: '15px' }} elevation={8}>
                <form onSubmit={handleSubmit} id="studentVerificationForm">
                    <Typography className="heading" variant="h4">New Entry</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                label="Student Name"
                                value={studentVerificationForm.student_name}
                                name="student_name"
                                onChange={handleChange}
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                label="Father Name"
                                value={studentVerificationForm.father_name}
                                name="father_name"
                                onChange={handleChange}
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                label="Roll No"
                                value={studentVerificationForm.roll_no}
                                name="roll_no"
                                onChange={handleChange}
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                label="Course"
                                value={studentVerificationForm.course}
                                name="course"
                                onChange={handleChange}
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                fullWidth
                            />

                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={1}>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                label="Issue Date"
                                value={studentVerificationForm.issue_date}
                                name="issue_date"
                                onChange={handleChange}
                                size="small"
                                type={'date'}
                                inputProps={{ maxLength: 100 }}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                label="Registeration ID"
                                value={studentVerificationForm.registeration_id}
                                name="registeration_id"
                                onChange={handleChange}
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                label="Session"
                                value={studentVerificationForm.session_name}
                                name="session_name"
                                onChange={handleChange}
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                label="Grade"
                                value={studentVerificationForm.grade}
                                name="grade"
                                onChange={handleChange}
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={1}>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                label="Obtained Marks"
                                value={studentVerificationForm.obtained_marks}
                                name="obtained_marks"
                                onChange={handleChange}
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                label="Total Marks"
                                value={studentVerificationForm.total_marks}
                                name="total_marks"
                                onChange={handleChange}
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                label="Duration"
                                value={studentVerificationForm.duration}
                                name="duration"
                                onChange={handleChange}
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                label="Percentage"
                                value={studentVerificationForm.percentage}
                                name="percentage"
                                onChange={handleChange}
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={1}>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                label="Result"
                                value={studentVerificationForm.result}
                                name="result"
                                onChange={handleChange}
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                label="Institute"
                                value={studentVerificationForm.institute}
                                name="institute"
                                onChange={handleChange}
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={1} justifyContent="center">
                        <Grid item xs={12} md={4} lg={3}>
                            <hr />
                            <Button variant="contained" color="primary" type="submit" fullWidth>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </div>
    )
}

export default AddStudentVerification;