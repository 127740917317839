import { Button, CircularProgress, Paper, Typography, Grid } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import * as api from "../../api/index.js";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';

function Blogs({ user, setUser }) {
    const [blogs, setBlogs] = useState([]);
    const [zeroResults, setZeroResults] = useState(false);
    const [loading, setLoading] = useState(true);

    const fetchBlogs = useCallback(async () => {
        try {
            const { data } = await api.blogsForNonAuthUsers();
            setBlogs(data);
            setLoading(false);
            if (data.length === 0) {
                setZeroResults(true);
            }
            else {
                setZeroResults(false);
            }
        } catch (error) {
            setLoading(false);
            setZeroResults(true);
            Swal.fire({
                title: 'Error',
                text: 'Something went wrong',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        }
    }, []);

    useEffect(() => {
        fetchBlogs();
    }, [fetchBlogs]);

    return (
        <div>
            <div className='m-2 p-2 mb-0'>
                <Paper elevation={6}>
                    <Typography variant="h6" className='heading p-2'>CourseEdx Blogs</Typography>
                </Paper>
            </div>
            {zeroResults && (
                <div className='m-3 mb-0'>
                    <Paper elevation={6}>
                        <Typography variant="h6 heading">No blogs found</Typography>
                    </Paper>
                </div>
            )}
            {loading && (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="mt-4">
                    <CircularProgress />
                </div>
            )}
            <div className='m-3 mt-0'>
                <Grid justifyContent="flex-start" alignItems={"flex-start"}>

                    {!blogs ? (
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="mt-4">
                            loading...
                        </div>
                    ) : (
                        <>
                            {blogs.map((blog, index) => {
                                return (
                                    <Grid item xs={12} md={6} lg={8} className="mt-2 text-start" key={index}>
                                        <Paper elevation={4} className="p-2">
                                            <p className='px-2 opacity-50 fw-bolder'>{blog.addedDate}</p>
                                            <Typography variant='h6' className='fw-bold text-wrap px-2'>{blog.blogTitle.slice(0, 80)}...</Typography>
                                            <Link to={`/blogDetails/${blog.id}`} className='text-start'>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className="m-2 opacity-75"
                                                    size="small"
                                                >Read More</Button>
                                            </Link>
                                        </Paper>
                                    </Grid>
                                )
                            })}
                        </>
                    )}

                </Grid>
            </div>
        </div >
    )
}

export default Blogs;