import { Button, Divider, Grid, Paper, Typography } from '@mui/material';
import React, { useState } from 'react';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import * as api from "../../../api/index";



const initialState = {
  boardId: '',
  classId: '',
  yearId: '',
  subjectId: '',
}

function AddPastPaper({ pastPapersData, setPastPapersData, boardsOptions, classesOptions, yearsOptions, subjectsOptions, user, setUser }) {
  const [pastPaperForm, setPastPaperForm] = useState(initialState);
  const [requesting, setRequesting] = useState(false);
  const [paperImage, setPaperImage] = useState(null);
  const [paperFile, setPaperFile] = useState(null);

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      textAlign: 'start',
      color: 'black',
    }),
    control: (provided, state) => ({
      ...provided,
      textAlign: 'start',
      color: 'black',
    }),
    option: (provided, state) => ({
      ...provided,
      textAlign: 'start',
      color: 'black',
    }),
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (requesting) {
      Swal.fire({
        title: "Please wait",
        text: "Your request is being processed",
        icon: "info",
        showConfirmButton: false,
        showCancelButton: false,
        allowOutsideClick: true,
        allowEscapeKey: true,
        allowEnterKey: true,
      });
      return;
    }

    if (!paperImage && !paperFile) {
      Swal.fire({
        title: "Error",
        text: "Please select a paper image or paper file",
        icon: "error",
      });
      return;
    }
    if (!pastPaperForm.boardId || !pastPaperForm.classId || !pastPaperForm.yearId || !pastPaperForm.yearId) {
      Swal.fire({
        title: "Warning",
        text: "Please select all the fields",
        icon: "warning",
      });
      return;
    }

    setRequesting(true);
    try {

      const formData = new FormData();
      formData.append("entryUserId", user.result.id);
      formData.append('boardId', pastPaperForm.boardId);
      formData.append('classId', pastPaperForm.classId);
      formData.append('yearId', pastPaperForm.yearId);
      formData.append('subjectId', pastPaperForm.subjectId);
      formData.append('paperImage', paperImage);
      formData.append('paperFile', paperFile);

      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }

      const { data } = await api.addPastPaper(formData, config);
      if (data.success) {
        Swal.fire({
          title: "Success",
          text: data.message,
          icon: "success",
        });
        setRequesting(false);
        setPastPapersData([...pastPapersData, data.pastPaper]);
        // setPastPaperForm(initialState);
        setPaperImage(null);
        setPaperFile(null);
        window.document.getElementById("pastPaperForm").reset();
      } else {
        Swal.fire({
          title: "Error",
          text: data.message,
          icon: "error",
        });
        setRequesting(false);
      }

    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Failed to add past paper, Please contact the one and only Mr. Programmer",
        icon: "error",
      });
      setRequesting(false);
    }

  }

  const onSelectPaperImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setPaperImage(null);
      return
    }
    setPaperImage(e.target.files[0]);
  }

  const onSelectPaperFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setPaperFile(null);
      return
    }
    setPaperFile(e.target.files[0]);
  }

  return (
    <div>
      <Paper style={{ padding: '15px' }} elevation={8}>
        <form onSubmit={handleSubmit} id="pastPaperForm">
          <Typography className="heading" variant="h4">Add Past Paper</Typography>
          <Divider
            variant="fullWidth"
            component="hr"
          />
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} md={6} lg={3}>
              <Select
                options={boardsOptions}
                placeholder="-- Select Board --"
                styles={customStyles}
                onChange={(e) => {
                  setPastPaperForm({ ...pastPaperForm, boardId: e.value })
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Select
                options={classesOptions}
                placeholder="-- Select Class --"
                styles={customStyles}
                onChange={(e) => {
                  setPastPaperForm({ ...pastPaperForm, classId: e.value })
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Select
                options={yearsOptions}
                placeholder="-- Select Year --"
                styles={customStyles}
                onChange={(e) => {
                  setPastPaperForm({ ...pastPaperForm, yearId: e.value })
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Select
                options={subjectsOptions}
                placeholder="-- Select Subject --"
                styles={customStyles}
                onChange={(e) => {
                  setPastPaperForm({ ...pastPaperForm, subjectId: e.value })
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} md={6} lg={3}>
              <Form.Control type="file" onChange={(e) => onSelectPaperImage(e)} size="sm" />
              <Form.Label>Paper Image</Form.Label>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Form.Control type="file" onChange={(e) => onSelectPaperFile(e)} size="sm" />
              <Form.Label>Paper PDF</Form.Label>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1} justifyContent="center">
            <Grid item xs={12} md={4} lg={3}>
              <Button variant="contained" color="primary" type="submit" fullWidth>
                Submit
              </Button>
            </Grid>
          </Grid>
          <div>
          </div>
        </form>
      </Paper>
    </div>
  )
}

export default AddPastPaper;