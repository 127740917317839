import React, { useState } from 'react';
import { TextField, Typography, Button, Grid, Paper } from '@mui/material';
import Collapse from 'react-bootstrap/Collapse';
import AddBoxIcon from '@mui/icons-material/AddBox';
import RemoveIcon from '@mui/icons-material/Remove';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import * as api from "../../../api/index";
import StaffTable from './StaffTable';


const initialForm = {
    teacherName: '',
    teacherDegree: '',
    teacherExperience: '',
    teacherSubjects: ''
}


function AddStaff({ options, staff, setStaff }) {
    const [instituteId, setInstituteId] = useState('');
    const [staffForm, setStaffForm] = useState(initialForm);
    const [teacherImage, setTeacherImage] = useState(null);
    const [open, setOpen] = useState(false);

    // const options = [
    //     { value: '1', label: 'Chocolate' },
    //     { value: '1', label: 'Chocolate' },
    // ]

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (staffForm.teacherName === '' || staffForm.teacherDegree === '' || staffForm.teacherExperience === '' || instituteId === '' || teacherImage === null || staffForm.teacherSubjects === '') {
            Swal.fire({
                title: 'Error!',
                text: 'Please fill all the fields',
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        } else {
            const formData = new FormData();
            formData.append('teacherName', staffForm.teacherName);
            formData.append('teacherDegree', staffForm.teacherDegree);
            formData.append('teacherExperience', staffForm.teacherExperience);
            formData.append('teacherSubjects', staffForm.teacherSubjects);
            formData.append('teacherImage', teacherImage);
            formData.append('instituteId', instituteId);
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            const { data } = await api.addStaffToInstitute(formData, config);
            if (data.success) {
                Swal.fire({
                    title: 'Success!',
                    text: data.message,
                    icon: 'success',
                    confirmButtonText: 'Ok'
                })
                setStaffForm(initialForm);
                setStaff([...staff, data.staff])
            }
            else {
                Swal.fire({
                    title: 'Error!',
                    text: data.error,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }

        }
    }

    const handleChange = (e) => {
        setStaffForm({ ...staffForm, [e.target.name]: e.target.value });
    }

    const handleInstituteChange = (selectedOption) => {
        setInstituteId(selectedOption.value);
    }

    const onSelectTeacherImage = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setTeacherImage(null)
            return
        }
        setTeacherImage(e.target.files[0]);
    }


    return (
        <div>
            <div>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => setOpen(!open)}
                    aria-controls="add-institute-form-container"
                    aria-expanded={open}
                >
                    Add Staff Members to Institute Data &nbsp; {open ? <RemoveIcon /> : <AddBoxIcon />}
                </Button>
                <Collapse in={open}>
                    <div id="add-institute-form-container">
                        <Paper style={{ padding: '15px' }} elevation={8}>
                            <form onSubmit={handleSubmit}>
                                <Typography className="heading" variant="h4">Add Staff</Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <Select options={options} onChange={handleInstituteChange} />
                                        <label className='fw-bolder'>Select Institute</label>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <TextField
                                            label="Teacher Name"
                                            name="teacherName"
                                            value={staffForm.teacherName}
                                            onChange={handleChange}
                                            size="small"
                                            inputProps={{ maxLength: 100 }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <TextField
                                            label="Teacher Degree"
                                            name="teacherDegree"
                                            value={staffForm.teacherDegree}
                                            onChange={handleChange}
                                            size="small"
                                            inputProps={{ maxLength: 100 }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <TextField
                                            label="Teacher Experience"
                                            name="teacherExperience"
                                            value={staffForm.teacherExperience}
                                            onChange={handleChange}
                                            size="small"
                                            inputProps={{ maxLength: 100 }}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <TextField
                                            label="Teacher Subjects"
                                            name="teacherSubjects"
                                            value={staffForm.teacherSubjects}
                                            onChange={handleChange}
                                            size="small"
                                            inputProps={{ maxLength: 100 }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <Form.Control type="file" onChange={(e) => onSelectTeacherImage(e)} />
                                        <Form.Label>Teacher Image</Form.Label>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} mt={1} justifyContent="center">
                                    <Grid item xs={12} md={4} lg={3}>
                                        <Button variant="contained" color="primary" type="submit" fullWidth>
                                            Submit
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={2}>
                                        <Button variant="contained" color="error" type="reset" fullWidth>
                                            Reset Form
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Paper>
                    </div>
                </Collapse>
            </div>
            <div>
                <StaffTable staffData={staff} setStaffData={setStaff} />
            </div>
        </div>
    )
}

export default AddStaff;