import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs'
import { Button, Tab } from 'react-bootstrap';
import "./css/CourseDetails.css";
import UploadedVideos from './UploadedVideos';
import UploadVideo from './UploadVideo';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EmbedYoutubeVideo from './EmbedYoutubeVideo';
import * as api from "../../../api/index";
import Swal from 'sweetalert2';
import EmbedDriveVideo from './EmbedDriveVideo';

function CourseDetails({ user, setUser }) {
    const navigate = useNavigate();
    let { courseId } = useParams();
    const [key, setKey] = useState('uploadedVideos');
    const [youtubeEmbeddedVideos, setYoutubeEmbeddedVideos] = useState([]);
    const [driveEmbeddedVideos, setDriveEmbeddedVideos] = useState([]);
    const [uploadedVideos, setUploadedVideos] = useState([]);

    const [uploadedVideosCount, setUploadedVideosCount] = useState(0);
    const [driveVideosCount, setDriveVideosCount] = useState(0);
    const [youtubeVideosCount, setYoutubeVideosCount] = useState(0);
    const [loading, setLoading] = useState(false);

    const getAllVideos = useCallback(
        async () => {
            setLoading(true);
            try {
                const { data } = await api.getAllVideosOfCourseOfInstructor(courseId, user.result.id);
                if (data.success) {
                    setUploadedVideos(data.uploadedVideos);
                    setYoutubeEmbeddedVideos(data.youtubeVideos);
                    setDriveEmbeddedVideos(data.driveVideos);

                    setUploadedVideosCount(data.uploadedVideos.length);
                    setYoutubeVideosCount(data.youtubeVideos.length);
                    setDriveVideosCount(data.driveVideos.length);
                    setLoading(false);
                }
                else {
                    Swal.fire(
                        'Error!',
                        data.error,
                        'error'
                    );
                    setLoading(false);
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                });
                setLoading(false);
            }

        }, [courseId, user.result.id]
    );

    useEffect(() => {
        getAllVideos();
        return () => {
            setYoutubeEmbeddedVideos([]);
        }
    }, [getAllVideos]);


    return (
        <div className='CourseDetails__component'>
            <Button variant="info mx-1 my-1 btn-sm" onClick={() => navigate(-1)}><ArrowBackIcon style={{ width: 20, height: 20 }} /> Back</Button>
            <Tabs
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
                style={{ background: "rgb(86 224 233)", padding: "3px" }}
            >
                <Tab eventKey="uploadedVideos" title="Uploaded Videos">
                    <UploadedVideos
                        uploadedVideos={uploadedVideos}
                        setUploadedVideos={setUploadedVideos}
                        youtubeEmbeddedVideos={youtubeEmbeddedVideos}
                        setYoutubeEmbeddedVideos={setYoutubeEmbeddedVideos}
                        driveEmbeddedVideos={driveEmbeddedVideos}
                        setDriveEmbeddedVideos={setDriveEmbeddedVideos}


                        uploadedVideosCount={uploadedVideosCount}
                        driveVideosCount={driveVideosCount}
                        youtubeVideosCount={youtubeVideosCount}

                        loading={loading}
                    />
                </Tab>
                <Tab eventKey="uploadNewVideo" title="Upload New Video">
                    <UploadVideo
                        courseId={courseId}
                        user={user} setUser={setUser}
                        uploadedVideos={uploadedVideos}
                        setUploadedVideos={setUploadedVideos}

                        uploadedVideosCount={uploadedVideosCount}
                        setUploadedVideosCount={setUploadedVideosCount}
                    />
                </Tab>
                <Tab eventKey="embedYoutubeVideo" title="Embed YouTube Video">
                    <EmbedYoutubeVideo
                        courseId={courseId}
                        user={user}
                        setUser={setUser}
                        youtubeEmbeddedVideos={youtubeEmbeddedVideos}
                        setYoutubeEmbeddedVideos={setYoutubeEmbeddedVideos}

                        youtubeVideosCount={youtubeVideosCount}
                        setYoutubeVideosCount={setYoutubeVideosCount}
                    />
                </Tab>
                <Tab eventKey="embedDriveVideo" title="Embed Drive Video">
                    <EmbedDriveVideo
                        courseId={courseId}
                        user={user}
                        setUser={setUser}
                        driveEmbeddedVideos={driveEmbeddedVideos}
                        setDriveEmbeddedVideos={setDriveEmbeddedVideos}

                        driveVideosCount={driveVideosCount}
                        setDriveVideosCount={setDriveVideosCount}
                    />
                </Tab>

            </Tabs>
        </div>
    );
}

export default CourseDetails;