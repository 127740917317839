import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea, CardActions, Divider, Rating, Typography } from '@mui/material';
import "./css/Course.css";
import { Link } from 'react-router-dom';
import { COURSE_THUMBNAIL_URL } from '../../constants/actionTypes';
import { Grid } from '@mui/material';
import Badge from 'react-bootstrap/Badge'

function CourseCard({ course, cartItems, setCartItems }) {
    const [rating, setRating] = React.useState(Math.floor(Math.random() * 3) + 2);

    return (
        <Card sx={{ maxWidth: 400, m: 2 }} className="Course__component">
            <CardActionArea>
                <Link to={`/courseDetails/${course.id}`}>
                    <CardMedia
                        component="img"
                        height="190"
                        image={`${COURSE_THUMBNAIL_URL}/${course.courseThumbnail}`}
                        alt="green iguana"
                        style={{
                            objectFit: 'contain',
                            objectPosition: 'center',
                        }}
                    />
                </Link>
                <CardContent sx={{ height: 140, textAlign: "start" }}>
                    <Link to={`/courseDetails/${course.id}`}>
                        <h6 style={{
                            fontSize: '0.9rem',
                            fontWeight: 'bold',
                            color: '#000',
                            textDecoration: 'none',
                            marginBottom: '0.5rem',
                        }}>
                            {course.courseTitle.slice(0, 70)}
                        </h6>
                    </Link>
                    <b
                        className='opacity-75'
                        style={{
                            fontSize: '0.8rem',
                            fontWeight: 'bold',
                            color: '#000',
                            textDecoration: 'none',
                            marginBottom: '0.5rem',
                        }}
                    >
                        {`${course?.firstName} ${course?.lastName}`.slice(0, 20)}
                    </b>
                    <h6
                        style={{
                            fontSize: '0.8rem',
                            color: '#000',
                            textDecoration: 'none',
                            marginBottom: '0.5rem',
                        }}
                    >
                        {course.courseSubTitle.slice(0, 70)}...
                    </h6>
                </CardContent>
                <Divider variant="fullWidth" />
                <CardContent
                    sx={{ height: 40, textAlign: "start" }}
                >
                    <Grid container spacing={2} justifyContent="space-between">
                        <Grid item>
                            <Rating
                                value={rating}
                                size="small"
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" color="text.secondary">
                                {course.coursePricing <= 0 ? (
                                    <Badge variant="success">Free</Badge>
                                ) : (
                                    <span className='text-start text-black'>
                                        Price <Badge bg="warning" className='text-black'>{"$ " + course.coursePricing}/-</Badge>
                                    </span>
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Link to={`/courseDetails/${course.id}`}>
                    <Button size="small" color="secondary" variant="outlined">
                        Course Details
                    </Button>
                </Link>
            </CardActions>
        </Card>
    )
}

export default CourseCard;