import React from 'react';
import Carousel from 'react-bootstrap/Carousel'
import "./css/WelcomeHome.css";
import car_1 from '../../images/car_1.jpg';
import car_2 from '../../images/car_2.jpg';
import car_3 from '../../images/car_3.jpg';
import { HOME_POSTERS_IMAGES } from '../../constants/actionTypes';

// <Carousel.Caption>

function WelcomeHome({ user, homePoster }) {
    return (
        <Carousel variant='dark'>
            <Carousel.Item>
                <img
                    className="d-block w-100 carousel_image"
                    src={homePoster !== null ? `${HOME_POSTERS_IMAGES}${homePoster?.poster1Image}` : car_1}
                    alt="First slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100 carousel_image"
                    src={homePoster !== null ? `${HOME_POSTERS_IMAGES}${homePoster?.poster2Image}` : car_2}
                    alt="Second slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100 carousel_image"
                    src={homePoster !== null ? `${HOME_POSTERS_IMAGES}${homePoster?.poster3Image}` : car_3}
                    alt="Third slide"
                />
            </Carousel.Item>
        </Carousel>
    );
}

export default WelcomeHome;














// import React from 'react';
// import image1 from "../../images/1.jpg";
// import { Link } from "react-router-dom";
// import { Button } from 'react-bootstrap';

// function WelcomeHome({ user }) {
//     return (
//         <header className="bg-dark py-5">
//             <div className="container px-5">
//                 <div className="row gx-5 align-items-center justify-content-center">
//                     <div className="col-lg-8 col-xl-7 col-xxl-6">
//                         <div className="my-5 text-center text-xl-start">
//                             <h2 className="text-white">Welcome to <span className="text-primary">CourseEdx.</span></h2>
//                             <p className="mt-2 text-sm text-white">Confused where to study? we have got you platform for easy learning. Browse courses and find out the best course for you. Its free! CourseEdx is our best attempt to teach basics and those coding techniques to people in short time which are not taught properly in the market.</p>
//                             <div className="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">

//                                 {!user && (
//                                     <>
//                                         <Link to={`/signin`}>
//                                             <Button variant="primary mx-1 my-1 btn-sm">Get Started</Button>
//                                         </Link>
//                                     </>
//                                 )}

//                                 {/* <a className="btn btn-primary btn-sm px-4 me-sm-3" href="#features">Get Started</a> */}
//                                 {/* <a className="btn btn-outline-light btn-sm px-4" href="#!">Learn More</a> */}
//                             </div>
//                         </div>
//                     </div>
//                     <div className="col-xl-5 col-xxl-6 d-none d-xl-block text-center"><img className="img-fluid rounded-3 my-5" src={image1} alt="..." /></div>
//                 </div>
//             </div>
//         </header>
//     );
// }

// export default WelcomeHome;
