import { Button, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Swal from 'sweetalert2';
import * as api from "../../../api/index";
import TableAffiliations from './TableAffiliations';

function GeneralAffiliations({
    generalAffiliations,
    setGeneralAffiliations,
}) {
    const [affiliation, setAffiliation] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (affiliation === "") {
                Swal.fire({
                    title: 'Error!',
                    text: 'Board name is required',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            } else {
                const formData = {
                    affiliation: affiliation
                }

                const { data } = await api.addGeneralAffiliation(formData);

                if (data.success) {
                    Swal.fire({
                        title: 'Success!',
                        text: data.message,
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    })
                    setAffiliation("");
                    setGeneralAffiliations([...generalAffiliations, data.affiliation]);
                }

                else {
                    Swal.fire({
                        title: 'Error!',
                        text: data.message,
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                }
            }
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: "Something went wrong, Please contact the Mr. Programmer",
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        }
    }


    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
                <Card>
                    <Card.Header>
                        <Typography className="heading" variant="h4">Add Board</Typography>
                    </Card.Header>
                    <Card.Body>
                        <form onSubmit={handleSubmit} autoComplete={"false"}>
                            <Grid container spacing={3} justifyContent="center">
                                <Grid item xs={12} md={6} lg={6}>
                                    <TextField
                                        label="Affiliation"
                                        value={affiliation}
                                        onChange={(e) => setAffiliation(e.target.value)}
                                        size="small"
                                        inputProps={{ maxLength: 100 }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                    >Submit</Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Card.Body>
                </Card>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <TableAffiliations
                    generalAffiliations={generalAffiliations}
                    setGeneralAffiliations={setGeneralAffiliations}
                />
            </Grid>
        </Grid>
    )
}


export default GeneralAffiliations;