import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import { Button, CircularProgress, TextField } from '@mui/material';
import * as api from "../../api/index.js";
import DeleteIcon from '@mui/icons-material/Delete';
import "./css/Blogs.css";
import Swal from 'sweetalert2';
import { PAGE_SIZES } from "../../Globals/PDFVariables.js";

const columns = [
    { id: 'sno', label: 'S.No', minWidth: 10, width: '5%' },
    { id: 'username', label: 'Username', minWidth: 10, width: '15%' },
    { id: 'blogtitle', label: 'Blog Title', minWidth: 10, width: '70%' },
    { id: 'action', label: 'Action', minWidth: 10, width: '10%' },
];

function Blogs() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchTerm, setSearchTerm] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [allBlogs, setAllBlogs] = useState([]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        const searchText = e.target.value;
        if (e.target.value) {
            const results = allBlogs.filter(blog =>
                blog.blogTitle.toLowerCase().includes(searchText.toLowerCase()) ||
                blog.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
                blog.lastName.toLowerCase().includes(searchText.toLowerCase())
            );
            setBlogs(results);
        } else {
            setBlogs(allBlogs);
        }
    }

    const fetchBlogs = async () => {
        try {
            const { data } = await api.blogsForNonAuthUsers();
            setBlogs(data);
            setAllBlogs(data);
        } catch (error) {
            window.alert("Failed to load blogs")
        }

    }


    const handleBlogDeletion = (blog) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Are you sure you want to delete this Blog!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const { data } = await api.deleteBlog(blog.id);
                    if (data.success) {
                        fetchBlogs();
                        Swal.fire(
                            'Good job!',
                            data.message,
                            'success'
                        )
                    }
                    else {
                        Swal.fire(
                            'Failed!',
                            "Failed to delete Blog",
                            'error'
                        )
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        });
    }

    useEffect(() => {
        fetchBlogs();
    }, []);



    return (
        <div className='blogs__main_div'>
            <div className='card'>
                <div className='card-header'>
                    <h3>Blogs</h3>
                </div>
            </div>
            <div className='blogs__top_div'>
                <div>
                    <TextField
                        label="Search Here"
                        name="search"
                        size="small"
                        className='m-2'
                        type={'search'}
                        value={searchTerm}
                        style={{
                            width: "300px",
                        }}
                        onChange={(e) => handleSearch(e)}
                    />
                </div>
                {/* <div className='users__button_div'>
                    <Button
                        variant="contained"
                        color="primary"
                        className='users__button m-1'
                        startIcon={<PictureAsPdfIcon />}
                        size="small"
                    >
                        PDF
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className='users__button m-1'
                        startIcon={<CollectionsBookmarkIcon />}
                        size="small"
                    >
                        EXCEL
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className='users__button m-1'
                        startIcon={<CollectionsBookmarkIcon />}
                        size="small"
                    >
                        CSV
                    </Button>
                </div> */}
            </div>
            <div className='users__table_div'>
                <Paper sx={{
                    width: '100%', overflow: 'hidden',
                    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
                    borderRadius: '8px',
                    backgroundColor: '#fafafa'
                }}>
                    <TableContainer sx={{ maxHeight: 570 }}>
                        <Table stickyHeader aria-label="sticky table"
                            size='small'
                        >
                            <TableHead
                                sx={{
                                    backgroundColor: '#81d690',
                                    borderRadius: '8px',
                                    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
                                }}
                            >
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                            width={column.width}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {!blogs && (
                                    <TableRow>
                                        <TableCell colSpan={6}>
                                            <CircularProgress />
                                        </TableCell>
                                    </TableRow>
                                )}

                                {blogs && blogs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((blog, index) => {
                                    return (
                                        <TableRow hover role="checkbox" key={blog.id}>

                                            <TableCell component="td" scope="row">
                                                {++index}
                                            </TableCell>
                                            <TableCell align="left">{blog.firstName} {blog.lastName}</TableCell>
                                            <TableCell align="left">{blog.blogTitle.slice(0, 150)}</TableCell>
                                            <TableCell align="left">
                                                <Button
                                                    size="small"
                                                    variant='outlined'
                                                    color='secondary'
                                                    startIcon={<DeleteIcon />}
                                                    onClick={() => handleBlogDeletion(blog)}
                                                >DELETE</Button>
                                            </TableCell>

                                        </TableRow>
                                    );
                                })}

                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={PAGE_SIZES}
                        component="div"
                        count={blogs.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        className='table_pagination'
                    />
                </Paper>
            </div>
        </div>
    );
}



export default Blogs;
























// import { Button, CircularProgress, TextField } from '@mui/material';
// import React, { useEffect, useState } from 'react'
// import * as api from "../../api/index.js";
// import DeleteIcon from '@mui/icons-material/Delete';
// import "./css/Blogs.css";
// import { Table } from 'react-bootstrap';
// import Swal from 'sweetalert2';

// function Blogs({ user, setUser }) {
//     const [searchTerm, setSearchTerm] = useState([]);
//     const [blogs, setBlogs] = useState();
//     const [allBlogs, setAllBlogs] = useState([]);

//     const handleSearch = (e) => {
//         setSearchTerm(e.target.value);
//         if (e.target.value) {
//             const results = allBlogs.filter(blog =>
//                 `
//                 ${blog.blogTitle}
//                 ${blog.userId.firstName}
//                 ${blog.userId.lastName}
//                 `.toLowerCase().includes(e.target.value)
//             );
//             setBlogs(results);
//         } else {
//             setBlogs(allBlogs);
//         }
//     }

//     const fetchBlogs = async () => {
//         try {
//             const { data } = await api.blogsForNonAuthUsers();
//             setBlogs(data);
//             setAllBlogs(data);
//         } catch (error) {
//             window.alert("Failed to load blogs")
//         }

//     }


//     const handleBlogDeletion = (blog) => {
//         Swal.fire({
//             title: 'Are you sure?',
//             text: "Are you sure you want to delete this Blog!",
//             icon: 'warning',
//             showCancelButton: true,
//             confirmButtonColor: '#3085d6',
//             cancelButtonColor: '#d33',
//             confirmButtonText: 'Delete!'
//         }).then(async (result) => {
//             if (result.isConfirmed) {
//                 try {
//                     const { data } = await api.deleteBlog(blog.id);
//                     if (data.success) {
//                         fetchBlogs();
//                         Swal.fire(
//                             'Good job!',
//                             data.message,
//                             'success'
//                         )
//                     }
//                     else {
//                         Swal.fire(
//                             'Failed!',
//                             "Failed to delete Blog",
//                             'error'
//                         )
//                     }
//                 } catch (error) {
//                     console.log(error);
//                 }
//             }
//         });
//     }

//     useEffect(() => {
//         fetchBlogs();
//     }, []);


//     return (

//         <div className='blogs__main_div'>
//             <div className='blogs__top_div'>
//                 <div>
//                     <TextField
//                         label="Search Here"
//                         name="search"
//                         size="small"
//                         className='m-2'
//                         type={'search'}
//                         value={searchTerm}
//                         style={{ width: "220px" }}
//                         onChange={(e) => handleSearch(e)}
//                     />
//                 </div>
//             </div>
//             <div>
//                 <Table striped bordered hover size="sm" style={{ textAlign: "start" }}>
//                     <thead>
//                         <tr>
//                             <th width="10%">S.No</th>
//                             <th width="20%">User</th>
//                             <th width="60%">Blog Title</th>
//                             <th width="10%">Action</th>
//                         </tr>
//                     </thead>
//                     <tbody>

//                         {!blogs ? (
//                             <tr>
//                                 <td colSpan="4">
//                                     <CircularProgress />
//                                 </td>
//                             </tr>
//                         ) : (
//                             <>
//                                 {blogs.map((blog, index) => {
//                                     return (
//                                         <tr key={index}>
//                                             <td>{index + 1}</td>
//                                             <td>{blog?.firstName} {blog?.lastName}</td>
//                                             <td>{blog.blogTitle.slice(0, 50)}...</td>
//                                             <td>
//                                                 <Button size="small" variant='outlined' color='secondary' onClick={() => handleBlogDeletion(blog)}><DeleteIcon /></Button>
//                                             </td>
//                                         </tr>
//                                     )
//                                 })}
//                             </>
//                         )}
//                     </tbody>
//                 </Table>
//             </div>
//         </div>
//     )
// }

// export default Blogs;