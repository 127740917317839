import { TextField, Typography, Button, Grid, Paper } from '@mui/material';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import AddBoxIcon from '@mui/icons-material/AddBox';
import RemoveIcon from '@mui/icons-material/Remove';
import Swal from 'sweetalert2';
import * as api from "../../../api/index";
import Select from 'react-select';



const initialForm = {
  instituteName: "",
  website: "",
  founderName: "",
  dateOfEstablishment: "",
  facebook: "",
  twitter: "",
  linkedin: "",
  instagram: "",
  results: "",
}

function AddInstitute({ setInstitutes, institutesData, setOptions, options, affiliationsOptions,
  instituteSectorsOptions, instituteTypesOptions, instituteProgramsOptions, provincesOptions, citiesOptions }) {
  const [instituteForm, setInstituteForm] = useState(initialForm);
  const [instituteImage, setInstituteImage] = useState('');
  const [InstituteLogo, setInstituteLogo] = useState('');
  const [open, setOpen] = useState(false);
  const [cityId, setCityId] = useState('');
  const [provinceId, setProvinceId] = useState('');
  const [sectorId, setSectorId] = useState('');
  const [instituteTypes, setInstituteTypes] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [affiliations, setAffiliations] = useState();
  const [requesting, setRequesting] = useState(false);


  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      textAlign: 'start',
      color: 'black',
    }),
    control: (provided, state) => ({
      ...provided,
      textAlign: 'start',
      color: 'black',
    }),
    option: (provided, state) => ({
      ...provided,
      textAlign: 'start',
      color: 'black',
    }),
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (requesting) {
      Swal.fire({
        title: 'Please wait',
        text: 'We are adding your institute',
        icon: 'info',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: true,
      });
      return;
    }

    setRequesting(true);

    if (instituteImage === null || InstituteLogo === null) {
      Swal.fire({
        title: 'Error!',
        text: 'Please upload an images',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
      setRequesting(false);
      return;
    }

    if (
      !instituteForm.instituteName
      || !provinceId
      || !cityId
      || !sectorId
      || !affiliations.length
      || !instituteTypes.length
      || !programs.length
    ) {
      Swal.fire({
        title: 'Error!',
        text: 'Please fill all the fields',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
      setRequesting(false);
      return;
    }


    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    const formData = new FormData();
    formData.append('instituteName', instituteForm.instituteName);
    formData.append('website', instituteForm.website);
    formData.append('founderName', instituteForm.founderName);
    formData.append('dateOfEstablishment', instituteForm.dateOfEstablishment);
    formData.append('facebook', instituteForm.facebook);
    formData.append('twitter', instituteForm.twitter);
    formData.append('linkedin', instituteForm.linkedin);
    formData.append('instagram', instituteForm.instagram);
    formData.append('results', instituteForm.results);

    formData.append('cityId', cityId);
    formData.append('provinceId', provinceId);
    formData.append('sectorId', sectorId);
    formData.append('affiliations', JSON.stringify(affiliations));
    formData.append('instituteTypes', JSON.stringify(instituteTypes));
    formData.append('programs', JSON.stringify(programs));

    formData.append('instituteImage', instituteImage);
    formData.append('instituteLogo', InstituteLogo);

    const { data } = await api.registerNewInstitute(formData, config);
    if (data.success) {
      Swal.fire({
        title: 'Success!',
        text: data.message,
        icon: 'success',
        confirmButtonText: 'Ok'
      });
      setInstituteForm(initialForm);
      setInstituteImage(null);
      setInstituteLogo(null);

      // setCityId('');
      // setProvinceId('');
      // setSectorId('');
      // setInstituteTypeId('');
      // setPrograms([]);

      document.getElementById('add-institute-form').reset();

      setInstitutes([...institutesData, data.institute]);
      const optionsArray = [...options, { value: data.institute.id, label: data.institute.instituteName }];
      setOptions(optionsArray);
      setRequesting(false);
    }
    else {
      Swal.fire({
        title: 'Error!',
        text: data.message,
        icon: 'error',
        confirmButtonText: 'Ok'
      });
      setRequesting(false);
    }

  }


  const handleChange = (e) => {
    setInstituteForm({ ...instituteForm, [e.target.name]: e.target.value });
  }

  const handleCityIdChange = (e) => {
    setCityId(e.value);
  }
  const handleProvinceIdChange = (e) => {
    setProvinceId(e.value);
  }
  const handleSectorIdChange = (e) => {
    setSectorId(e.value);
  }

  const handleAffiliationsChange = (e) => {
    let arr = [];
    e.forEach(element => {
      arr.push(element.value);
    });

    setAffiliations(arr);
  }

  const handleInstituteTypesChange = (e) => {
    let arr = [];
    e.forEach(element => {
      arr.push(element.value);
    });

    setInstituteTypes(arr);
  }

  const handleProgramsChange = (e) => {
    let arr = [];
    e.forEach(element => {
      arr.push(element.value);
    });
    setPrograms(arr);
  }

  const onSelectInstituteImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setInstituteImage(null)
      return
    }
    setInstituteImage(e.target.files[0]);
  }

  const onSelectInstituteLogo = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setInstituteLogo(null)
      return
    }
    setInstituteLogo(e.target.files[0]);
  }




  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => setOpen(!open)}
        aria-controls="add-institute-form-container"
        aria-expanded={open}
      >
        Add new Institute &nbsp; {open ? <RemoveIcon /> : <AddBoxIcon />}
      </Button>
      <Collapse in={open}>
        <div id="add-institute-form-container">
          <Paper style={{ padding: '15px' }} elevation={8}>
            <form
              onSubmit={handleSubmit}
              id="add-institute-form"
            >
              <Typography className="heading" variant="h4">Add new Institute</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    label="Institute Name"
                    value={instituteForm.instituteName}
                    name="instituteName"
                    onChange={handleChange}
                    size="small"
                    inputProps={{ maxLength: 100 }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    label="Website"
                    value={instituteForm.website}
                    name="website"
                    onChange={handleChange}
                    size="small"
                    inputProps={{ maxLength: 100 }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    label="Founder Name"
                    value={instituteForm.founderName}
                    name="founderName"
                    onChange={handleChange}
                    size="small"
                    inputProps={{ maxLength: 100 }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    label="Date of Establishment"
                    value={instituteForm.dateOfEstablishment}
                    name="dateOfEstablishment"
                    onChange={handleChange}
                    size="small"
                    type={'date'}
                    inputProps={{ maxLength: 100 }}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    label="Facebook"
                    value={instituteForm.facebook}
                    name="facebook"
                    onChange={handleChange}
                    size="small"
                    inputProps={{ maxLength: 100 }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    label="Twitter"
                    value={instituteForm.twitter}
                    name="twitter"
                    onChange={handleChange}
                    size="small"
                    inputProps={{ maxLength: 100 }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    label="Linkedin"
                    value={instituteForm.linkedin}
                    name="linkedin"
                    onChange={handleChange}
                    size="small"
                    inputProps={{ maxLength: 100 }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    label="Instagram"
                    value={instituteForm.instagram}
                    name="instagram"
                    onChange={handleChange}
                    size="small"
                    inputProps={{ maxLength: 100 }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    label="Results"
                    value={instituteForm.results}
                    name="results"
                    onChange={handleChange}
                    size="small"
                    inputProps={{ maxLength: 100 }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Select
                    options={affiliationsOptions}
                    placeholder="-- Select Affiliations --"
                    styles={customStyles}
                    onChange={handleAffiliationsChange}
                    isMulti
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Select
                    options={provincesOptions}
                    placeholder="-- Select Province --"
                    styles={customStyles}
                    onChange={handleProvinceIdChange}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Select
                    options={citiesOptions}
                    placeholder="-- Select City --"
                    styles={customStyles}
                    onChange={handleCityIdChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={6} lg={3}>
                  <Select
                    options={instituteSectorsOptions}
                    placeholder="-- Select Sector --"
                    styles={customStyles}
                    onChange={handleSectorIdChange}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Select
                    options={instituteTypesOptions}
                    placeholder="-- Select Institute Types --"
                    styles={customStyles}
                    isMulti
                    onChange={handleInstituteTypesChange}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Select
                    options={instituteProgramsOptions}
                    placeholder="-- Select Programs --"
                    styles={customStyles}
                    onChange={handleProgramsChange}
                    isMulti
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={6} lg={3}>
                  <Form.Control type="file" onChange={(e) => onSelectInstituteImage(e)} size="sm" />
                  <Form.Label>Institute Image</Form.Label>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Form.Control type="file" onChange={(e) => onSelectInstituteLogo(e)} size="sm" />
                  <Form.Label>Institute Logo</Form.Label>
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={1} justifyContent="center">
                <Grid item xs={12} md={4} lg={3}>
                  <Button variant="contained" color="primary" type="submit" fullWidth>
                    Submit
                  </Button>
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                  <Button variant="contained" color="error" type="reset" fullWidth>
                    Reset Form
                  </Button>
                </Grid>
              </Grid>
              <div>
              </div>
            </form>
          </Paper>
        </div>
      </Collapse>
    </div>
  )
}

export default AddInstitute;