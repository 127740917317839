import React from 'react';
// import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';
import RateReviewIcon from '@mui/icons-material/RateReview';

export const StudentSidebarData = [
  {
    title: 'Dashboard',
    path: '/studentDashboard',
    icon: <AiIcons.AiFillHome />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
];


export const StudentSidebarDataWithDrop = [
  {
    title: 'Courses',
    path: '/studentDashboard/courses',
    icon: <IoIcons.IoIosPaper />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'My Courses',
        path: '/studentDashboard/myCourses',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: 'Approval Courses',
        path: '/studentDashboard/approvalCourses',
        icon: <RateReviewIcon style={{ fontSize: '16px', }} />,
        cName: 'sub-nav'
      },
    ]
  },
  // {
  //   title: 'My Courses',
  //   path: '/studentDashboard/team',
  //   icon: <FaIcons.FaEnvelopeOpenText />,

  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,

  //   subNav: [
  //     {
  //       title: 'Message 1',
  //       path: '/studentDashboard/team',
  //       icon: <IoIcons.IoIosPaper />
  //     },
  //     {
  //       title: 'Message 2',
  //       path: '/studentDashboard/team',
  //       icon: <IoIcons.IoIosPaper />
  //     }
  //   ]
  // },
];
