import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { logoutFunc } from '../../actions/auth';
import "./css/MainNavbar.css";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
// headers imports
import logo from "../../images/logo.png";
import NavServices from './NavServices';
import { Button } from 'react-bootstrap';

// import UserIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import ArrowDropDownCircleRoundedIcon from '@mui/icons-material/ArrowDropDownCircleRounded';
import { USERS_IMAGES } from '../../constants/actionTypes';

function MainNavbar({ user, setUser, cartItems, setCartItems }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            if (e.target.value.length > 0) {
                navigate("/searchCourses/" + e.target.value);
            }
        }
    }

    const toggleProfileContent = () => {
        const main_navbar_user_profile_content = document.getElementsByClassName("main_navbar_user_profile_content")[0];
        main_navbar_user_profile_content.classList.toggle("main_navbar_user_profile_content_show");
    }

    const logout = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Are you sure you want to logout!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Logout!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(logoutFunc(setUser, navigate))
            }
        })
    }

    const toggleServiceDiv = () => {
        // check if header div is open
        const main_navbar_header_custom_links = document.getElementsByClassName("main_navbar_header_custom_links")[0];
        main_navbar_header_custom_links.style.top = "-300px";

        // now toggling the service div
        const main_navbar_services = document.getElementsByClassName("main_navbar_services")[0];
        if (main_navbar_services.style.top === "-500px") {
            main_navbar_services.style.top = "100px";
        }
        else {
            main_navbar_services.style.top = "-500px";
        }
    }

    const toggleHeaderDiv = () => {
        // check if the service div is visible
        const main_navbar_services = document.getElementsByClassName("main_navbar_services")[0];
        main_navbar_services.style.top = "-500px";

        // now toggling the header div
        const main_navbar_header_custom_links = document.getElementsByClassName("main_navbar_header_custom_links")[0];
        if (main_navbar_header_custom_links.style.top === "-300px") {
            main_navbar_header_custom_links.style.top = "100px";
        }
        else {
            main_navbar_header_custom_links.style.top = "-300px";
        }
    }

    useEffect(() => {
        if (window.innerWidth <= 992) {
            // check if header div is open
            const main_navbar_header_custom_links = document.getElementsByClassName("main_navbar_header_custom_links")[0];
            main_navbar_header_custom_links.style.top = "-300px";
            // check if the service div is visible
            const main_navbar_services = document.getElementsByClassName("main_navbar_services")[0];
            main_navbar_services.style.top = "-500px";
            // remove class main_navbar_user_profile_content 
            const main_navbar_user_profile_content = document.getElementsByClassName("main_navbar_user_profile_content")[0];
            if (main_navbar_user_profile_content !== undefined || main_navbar_user_profile_content === null) {
                main_navbar_user_profile_content.classList.add("main_navbar_user_profile_content_show");
            }
        }
    }, [location.pathname]);

    return (
        <div>
            <NavServices />
            <div className='main_navbar_header'>
                <div className='main_navbar_header_item'>
                    <div className='main_navbar_header_logo'>
                        <Link to='/' className='main_navbar_header_logo_link'>
                            <img src={logo} alt='logo' className='main_navbar_header_logo_img' />
                        </Link>
                    </div>
                    <div className='main_navbar_header_search'>
                        <div className='main_navbar_header_search_bar'>
                            <div className='main_navbar_header_search_div'>
                                <SearchRoundedIcon />
                            </div>
                            <div className='main_navbar_header_search_div'>
                                <input className='main_navbar_header_search_input' type='search' placeholder='Search Courses'
                                    onKeyPress={handleSearch}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='main_navbar_header_item main_navbar_header_custom_links'>
                    <div className='main_navbar_header_item_child main_navbar_category'>
                        <Link to='#' className='main_navbar_header_item_link'>
                            <p>Categories <ArrowDropDownCircleRoundedIcon
                                style={{
                                    transform: "scale(0.8)"
                                }}
                            /></p>
                        </Link>

                        <div className='main_navbar_header_categories'>
                            <ul className='main_navbar_header_categories_ul'>

                                <li>
                                    <Link to='/' className='main_navbar_header_categories_ul_link'>
                                        <p>Development</p>
                                    </Link>
                                </li>

                                <li>
                                    <Link to='/' className='main_navbar_header_categories_ul_link'>
                                        <p>Business</p>
                                    </Link>
                                </li>

                                <li>
                                    <Link to='/' className='main_navbar_header_categories_ul_link'>
                                        <p>Finance &amp; Accounting</p>
                                    </Link>
                                </li>

                                <li>
                                    <Link to='/' className='main_navbar_header_categories_ul_link'>
                                        <p>IT &amp; Software</p>
                                    </Link>
                                </li>

                                <li>
                                    <Link to='/' className='main_navbar_header_categories_ul_link'>
                                        <p>Office Productivity</p>
                                    </Link>
                                </li>

                                <li>
                                    <Link to='/' className='main_navbar_header_categories_ul_link'>
                                        <p>Personal Development</p>
                                    </Link>
                                </li>

                                <li>
                                    <Link to='/' className='main_navbar_header_categories_ul_link'>
                                        <p>Design</p>
                                    </Link>
                                </li>

                                <li>
                                    <Link to='/' className='main_navbar_header_categories_ul_link'>
                                        <p>Marketing</p>
                                    </Link>
                                </li>

                                <li>
                                    <Link to='/' className='main_navbar_header_categories_ul_link'>
                                        <p>Lifestyle</p>
                                    </Link>
                                </li>

                                <li>
                                    <Link to='/' className='main_navbar_header_categories_ul_link'>
                                        <p>Photography &amp; Video</p>
                                    </Link>
                                </li>

                                <li>
                                    <Link to='/' className='main_navbar_header_categories_ul_link'>
                                        <p>Health &amp; Fitness</p>
                                    </Link>
                                </li>

                                <li>
                                    <Link to='/' className='main_navbar_header_categories_ul_link'>
                                        <p>Music</p>
                                    </Link>
                                </li>

                                <li>
                                    <Link to='/' className='main_navbar_header_categories_ul_link'>
                                        <p>Teaching &amp; Academics</p>
                                    </Link>
                                </li>

                            </ul>
                        </div>

                    </div>

                    <div className='main_navbar_header_item_child'>
                        <Link to='/' className='main_navbar_header_item_link'>
                            <p>CourseEdx Business</p>
                        </Link>
                    </div>
                    <div className='main_navbar_header_item_child'>
                        <Link to={`${!user ? "/signin" : "/instructorDashboard"}`} className='main_navbar_header_item_link'>
                            <p>Teach on CourseEdx</p>
                        </Link>
                    </div>
                    <div className='main_navbar_header_item_child'>
                        <Link to='/checkout' className='main_navbar_header_item_link'>
                            <p><b>{cartItems.length} </b><ShoppingCartRoundedIcon /></p>
                        </Link>
                    </div>

                </div>
                <div className='main_navbar_header_item main_navbar_header_auth_buttons_and_profile'>
                    {!user && (
                        <div className='main_navbar_header_auth_buttons'>
                            <Link to='/signin' className='main_navbar_header_item_link'>
                                <div className='main_navbar_header_item_auth_button'>
                                    <p>Login</p>
                                </div>
                            </Link>
                            <Link to='/signup' className='main_navbar_header_item_link'>
                                <div className='main_navbar_header_item_auth_button'>
                                    <p>Sign Up</p>
                                </div>
                            </Link>
                        </div>
                    )}

                    {user && (
                        <div className='main_navbar_header_profile'>
                            <div className='main_navbar_profile_img_button' onClick={toggleProfileContent}>
                                {user && user.result.userImage && (
                                    <img
                                        src={`${USERS_IMAGES}${user.result.userImage}`}
                                        alt='...'
                                        className='main_navbar_profile_img'
                                    />
                                )}

                                {/* <UserIcon
                                         style={{
                                             fontSize: '2rem',
                                             color: 'black',
                                         }}
                                     /> */}
                            </div>
                            <div className='main_navbar_user_profile_content main_navbar_user_profile_content_show'>
                                <div className='main_navbar_user_profile_name'>
                                    {`${user.result.firstName} ${user.result.lastName}`}
                                </div>
                                <div className='main_navbar_user_profile_content_item'>
                                    <Link to={`/userProfile`}>
                                        <Button variant="outline-primary mx-1 my-1 btn-sm">Profile</Button>
                                    </Link>
                                </div>
                                {user.result.role === "admin" && (
                                    <div className='main_navbar_user_profile_content_item'>
                                        <Link to={`/admin`}>
                                            <Button variant="outline-primary mx-1 my-1 btn-sm">Admin Dashboard</Button>
                                        </Link>
                                    </div>
                                )}
                                <div className='main_navbar_user_profile_content_item'>
                                    <Link to={`/studentDashboard`}>
                                        <Button variant="outline-primary mx-1 my-1 btn-sm">Student Dashboard</Button>
                                    </Link>
                                </div>
                                <div className='main_navbar_user_profile_content_item'>
                                    <Link to={`/instructorDashboard`}>
                                        <Button variant="outline-primary mx-1 my-1 btn-sm">Instructor Dashboard</Button>
                                    </Link>
                                </div>
                                <div className='main_navbar_user_profile_content_item'>
                                    <Button variant="outline-primary mx-1 my-1 btn-sm" onClick={logout}>Logout</Button>
                                </div>
                            </div>
                        </div>
                    )}


                </div>
            </div >

            <div className='main_navbar_service_and_header_toggle_buttons'>
                <div className='main_navbar_service_toggle_button' onClick={toggleHeaderDiv}>
                    <MenuIcon fontSize='medium' />
                </div>
                <div className='main_navbar_header_toggle_button' onClick={toggleServiceDiv}>
                    <MenuOpenIcon fontSize='medium' />
                </div>
            </div>

        </div >
    )
}

export default MainNavbar;



























// import React from 'react';
// import { Navbar, Nav, Container, NavDropdown, Form, FormControl, Button } from "react-bootstrap";
// import { useDispatch } from 'react-redux';
// import { Link, useNavigate } from 'react-router-dom';
// import Swal from 'sweetalert2';
// import { logoutFunc } from '../../actions/auth';
// import "./css/MainNavbar.css";
// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// // import userLogo from "../../images/user_logo.png";
// import UserIcon from '@mui/icons-material/AccountCircle';

// function MainNavbar({ user, setUser, cartItems, setCartItems }) {
//     const dispatch = useDispatch();
//     const navigate = useNavigate();


//     const toggleProfileContent = () => {
//         const main_navbar_user_profile_content = document.getElementsByClassName("main_navbar_user_profile_content")[0];
//         main_navbar_user_profile_content.classList.toggle("main_navbar_user_profile_content_show");
//     }

//     const logout = () => {
//         Swal.fire({
//             title: 'Are you sure?',
//             text: "Are you sure you want to logout!",
//             icon: 'warning',
//             showCancelButton: true,
//             confirmButtonColor: '#3085d6',
//             cancelButtonColor: '#d33',
//             confirmButtonText: 'Logout!'
//         }).then((result) => {
//             if (result.isConfirmed) {
//                 dispatch(logoutFunc(setUser, navigate))
//             }
//         })
//     }


//     return (
//         <Navbar bg="dark" expand="lg" variant="dark" style={{ position: "fixed", width: "100%", zIndex: 100 }}>
//             <Container fluid>
//                 <Link to={`/`}>
//                     <Navbar.Brand>CourseEdx</Navbar.Brand>
//                 </Link>
//                 <Navbar.Toggle aria-controls="navbarScroll" />
//                 <Navbar.Collapse id="navbarScroll">
//                     <Nav
//                         className="me-auto my-2 my-lg-0"
//                         style={{ maxHeight: '400px' }}
//                         navbarScroll
//                     >
//                         <Link to={`/`}>
//                             {/* <Nav.Link href="#action1">Home</Nav.Link> */}
//                             <Button variant="outline-primary btn-sm mx-1 my-1">Home</Button>
//                         </Link>
//                         {/* <Link to={`/checkout`}>
//                             <Button variant="outline-primary mx-1 my-1 btn-sm" style={{ height: 32 }}> <b className='text-white'>{cartItems.length} </b> <ShoppingCartIcon /></Button>
//                         </Link> */}
//                         {user && (
//                             // <>
//                             //     <Link to={`/instructorDashboard`}>
//                             //         <Button variant="outline-primary mx-1 my-1 btn-sm">Instructor Dashboard</Button>
//                             //     </Link>
//                             //     <Link to={`/studentDashboard`}>
//                             //         <Button variant="outline-primary mx-1 my-1 btn-sm">Student Dashboard</Button>
//                             //     </Link>
//                             //     {user.result.role === "admin" && (
//                             //         <Link to={`/admin`}>
//                             //             <Button variant="outline-primary mx-1 my-1 btn-sm">Admin Dashboard</Button>
//                             //         </Link>
//                             //     )}
//                             // </>
//                             <NavDropdown title="Dashboards " id="navbarScrollingDropdown">
//                                 {user.result.role === "admin" && (
//                                     <NavDropdown.Item as={Link} to={`/admin`}>Admin Dashboard</NavDropdown.Item>
//                                 )}
//                                 <NavDropdown.Item as={Link} to={`/studentDashboard`}>Student Dashboard</NavDropdown.Item>
//                                 <NavDropdown.Item as={Link} to={`/instructorDashboard`}>Instructor Dashboard</NavDropdown.Item>
//                             </NavDropdown>
//                         )}
//                         {/* <NavDropdown title="More " id="navbarScrollingDropdown">
//                             <NavDropdown.Item as={Link} to="/blogs">
//                                 Blogs
//                             </NavDropdown.Item>
//                             <NavDropdown.Divider />
//                         </NavDropdown> */}
//                         <Link to={`/blogs`}>
//                             {/* <Nav.Link href="#action1">Home</Nav.Link> */}
//                             <Button variant="outline-primary btn-sm mx-1 my-1">Blogs</Button>
//                         </Link>
//                     </Nav>
//                     <Form className="d-flex">
//                         <FormControl
//                             type="search"
//                             placeholder="Search"
//                             htmlSize={30}
//                             className="mainNavbar__search_input me-2"
//                             aria-label="Search"
//                         />
//                         <Button variant="outline-success btn-sm mx-1 my-1">Search</Button>
//                     </Form>
//                     {user ? (
//                         <>
//                             <div className="main_navbar_mobile_view">
//                                 <div className="btn-primary btn-sm mx-1 my-1">
//                                     Logged in as {`${user.result.firstName} ${user.result.lastName}`}
//                                 </div>
//                                 <Button variant="outline-primary mx-1 my-1 btn-sm" onClick={logout}>Logout</Button>
//                             </div>
//                             <div className='main_navbar_desktop_view'>
//                                 <div className='main_navbar_profile_img_button' onClick={toggleProfileContent}>
//                                     {/* <img src={userLogo} alt="profile" className='main_navbar_profile_img' /> */}
//                                     <UserIcon
//                                         style={{
//                                             fontSize: '2rem',
//                                             color: 'black',
//                                         }}
//                                     />
//                                 </div>
//                                 <div className='main_navbar_user_profile_content main_navbar_user_profile_content_show'>
//                                     <div className='main_navbar_user_profile_name'>
//                                         {`${user.result.firstName} ${user.result.lastName}`}
//                                     </div>
//                                     <div className='main_navbar_user_profile_content_item'>
//                                         <Link to={`/profile`}>
//                                             <Button variant="outline-primary mx-1 my-1 btn-sm">Profile</Button>
//                                         </Link>
//                                     </div>
//                                     <div className='main_navbar_user_profile_content_item'>
//                                         <Link to={`/checkout`}>
//                                             <Button variant="outline-primary mx-1 my-1 btn-sm" style={{ height: 32 }}><b>{cartItems.length} </b> <ShoppingCartIcon /></Button>
//                                         </Link>
//                                     </div>
//                                     <div className='main_navbar_user_profile_content_item'>
//                                         <Button variant="outline-primary mx-1 my-1 btn-sm" onClick={logout}>Logout</Button>
//                                     </div>
//                                 </div>
//                             </div>
//                         </>
//                     ) : (
//                         <>
//                             <Link to={`/signin`}>
//                                 <Button variant="outline-primary mx-1 my-1 btn-sm">Sign In</Button>
//                             </Link>
//                             <Link to={`/signup`}>
//                                 <Button variant="outline-primary mx-1 my-1 btn-sm">Sign UP</Button>
//                             </Link>
//                         </>
//                     )}
//                 </Navbar.Collapse>
//             </Container>
//         </Navbar>
//     )
// }

// export default MainNavbar;