import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as api from '../../../api/index';
import AdmissionDetails from './AdmissionDetails';
import SearchAdmissions from './SearchAdmissions';


function Admissions() {
    const [classesOptions, setClassesOptions] = useState([]);
    const [yearsOptions, setYearsOptions] = useState([]);
    const [countriesOptions, setCountriesOptions] = useState([]);
    const [provincesOptions, setProvincesOptions] = useState([]);
    const [citiesOptions, setCitiesOptions] = useState([]);
    const [loadingOptions, setLoadingOptions] = useState(false);

    const fetchOptions = async () => {
        setLoadingOptions(true);
        try {
            const { data } = await api.getAdmissionsManagementOptions();
            if (data.success) {
                setClassesOptions(data.classesOptions);
                setYearsOptions(data.yearsOptions);
                setCountriesOptions(data.countriesOptions);
                setProvincesOptions(data.provincesOptions);
                setCitiesOptions(data.citiesOptions);

                setLoadingOptions(false);
            }
            else {
                Swal.fire({
                    title: 'Error',
                    text: data.message,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchOptions();
    }, []);

    return (
        <div>
            <Routes>
                <Route path="/" exact element={
                    <SearchAdmissions
                        classesOptions={classesOptions}
                        yearsOptions={yearsOptions}
                        countriesOptions={countriesOptions}
                        provincesOptions={provincesOptions}
                        citiesOptions={citiesOptions}
                        loadingOptions={loadingOptions}
                    />
                } />
                <Route path="/admissionDetails/:admissionDetailsId" element={<AdmissionDetails />} />
            </Routes>
        </div>
    )
}

export default Admissions;