import { Button, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Swal from 'sweetalert2';
import * as api from "../../../api/index";
import TableBoards from './TableBoards';

function AddBoards({
  generalBoardsData,
  setGeneralBoardsData,
}) {
  const [boardName, setBoardName] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (boardName === "") {
        Swal.fire({
          title: 'Error!',
          text: 'Board name is required',
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      } else {
        const formData = {
          boardName: boardName
        }

        const { data } = await api.addGeneralBoard(formData);

        if (data.success) {
          Swal.fire({
            title: 'Success!',
            text: data.message,
            icon: 'success',
            confirmButtonText: 'Ok'
          })
          setBoardName("");
          setGeneralBoardsData([...generalBoardsData, data.board]);
        }

        else {
          Swal.fire({
            title: 'Error!',
            text: data.message,
            icon: 'error',
            confirmButtonText: 'Ok'
          })
        }
      }
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: "Something went wrong, Please contact the Mr. Programmer",
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }
  }


  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <Card>
          <Card.Header>
            <Typography className="heading" variant="h4">Add Board</Typography>
          </Card.Header>
          <Card.Body>
            <form onSubmit={handleSubmit} autoComplete={"false"}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    label="Board Name"
                    value={boardName}
                    onChange={(e) => setBoardName(e.target.value)}
                    size="small"
                    inputProps={{ maxLength: 100 }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                  >Submit</Button>
                </Grid>
              </Grid>
            </form>
          </Card.Body>
        </Card>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <TableBoards
          generalBoardsData={generalBoardsData}
          setGeneralBoardsData={setGeneralBoardsData}
        />
      </Grid>
    </Grid>
  )
}

export default AddBoards;
