import { Button, Divider, Grid, Paper, Typography } from '@mui/material'
import React from 'react';
import Select from 'react-select';
import { Form } from 'react-bootstrap';


function EditeditPastPaper({
    editPastPaper,
    setEditPastPaper,
    onSelectEditPaperImage,
    onSelectEditPaperFile,
    handleEditPastPaperSubmit,
    boardsOptions,
    classesOptions,
    yearsOptions,
    subjectsOptions,
}) {

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            textAlign: 'start',
            color: 'black',
        }),
        control: (provided, state) => ({
            ...provided,
            textAlign: 'start',
            color: 'black',
        }),
        option: (provided, state) => ({
            ...provided,
            textAlign: 'start',
            color: 'black',
        }),
    }


    return (
        <div>
            <Paper style={{ padding: '15px' }} elevation={8}>
                <form onSubmit={handleEditPastPaperSubmit} id="editPastPaper">
                    <Typography className="heading" variant="h4">Add Past Paper</Typography>
                    <Divider
                        variant="fullWidth"
                        component="hr"
                    />
                    <Grid container spacing={2} mt={1}>
                        <Grid item xs={12} md={6} lg={3}>
                            <Select
                                options={boardsOptions}
                                placeholder="-- Select Board --"
                                styles={customStyles}
                                onChange={(e) => {
                                    setEditPastPaper({ ...editPastPaper, boardId: e.value })
                                }}
                                defaultValue={
                                    boardsOptions.filter(option => option.value === editPastPaper.boardId)
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Select
                                options={classesOptions}
                                placeholder="-- Select Class --"
                                styles={customStyles}
                                onChange={(e) => {
                                    setEditPastPaper({ ...editPastPaper, classId: e.value })
                                }}
                                defaultValue={
                                    classesOptions.filter(option => option.value === editPastPaper.classId)
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Select
                                options={yearsOptions}
                                placeholder="-- Select Year --"
                                styles={customStyles}
                                onChange={(e) => {
                                    setEditPastPaper({ ...editPastPaper, yearId: e.value })
                                }}
                                defaultValue={
                                    yearsOptions.filter(option => option.value === editPastPaper.yearId)
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Select
                                options={subjectsOptions}
                                placeholder="-- Select Subject --"
                                styles={customStyles}
                                onChange={(e) => {
                                    setEditPastPaper({ ...editPastPaper, subjectId: e.value })
                                }}
                                defaultValue={
                                    subjectsOptions.filter(option => option.value === editPastPaper.subjectId)
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={1}>
                        <Grid item xs={12} md={6} lg={3}>
                            <Form.Control type="file" onChange={(e) => onSelectEditPaperImage(e)} size="sm" />
                            <Form.Label>Change Image</Form.Label>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Form.Control type="file" onChange={(e) => onSelectEditPaperFile(e)} size="sm" />
                            <Form.Label>Change PDF</Form.Label>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={1} justifyContent="center">
                        <Grid item xs={12} md={4} lg={3}>
                            <Button variant="contained" color="primary" type="submit" fullWidth>
                                UPDATE
                            </Button>
                        </Grid>
                    </Grid>
                    <div>
                    </div>
                </form>
            </Paper>
        </div>
    )
}

export default EditeditPastPaper;