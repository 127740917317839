import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, Divider, Grid, Rating } from '@mui/material';
import "./css/Course.css";
import { Link } from 'react-router-dom';
import { COURSE_THUMBNAIL_URL } from '../../../constants/actionTypes';
import Badge from 'react-bootstrap/Badge';

function Course({ course, showButtons }) {
    const [rating, setRating] = React.useState(Math.floor(Math.random() * 3) + 2);

    return (
        <Card sx={{ maxWidth: 400, m: 2 }} className="Course__component">
            <CardActionArea>
                <CardMedia
                    component="img"
                    height="190"
                    image={`${COURSE_THUMBNAIL_URL}${course?.courseThumbnail}`}
                    alt="green iguana"
                />
                <CardContent sx={{ height: 140, textAlign: "start" }}>
                    <h6 style={{
                        fontSize: '0.9rem',
                        fontWeight: 'bold',
                        color: '#000',
                        textDecoration: 'none',
                        marginBottom: '0.5rem',
                    }}>
                        {course.courseTitle.slice(0, 70)}
                    </h6>
                    <b
                        className='opacity-75'
                        style={{
                            fontSize: '0.8rem',
                            fontWeight: 'bold',
                            color: '#000',
                            textDecoration: 'none',
                            marginBottom: '0.5rem',
                        }}
                    >
                        {`${course?.firstName} ${course?.lastName}`.slice(0, 20)}
                    </b>
                    <h6
                        style={{
                            fontSize: '0.8rem',
                            color: '#000',
                            textDecoration: 'none',
                            marginBottom: '0.5rem',
                        }}
                    >
                        {course.courseSubTitle.slice(0, 70)}...
                    </h6>
                </CardContent>
                <Divider variant="fullWidth" />
                <CardContent sx={{ height: 50, textAlign: "start" }}>
                    <Grid container spacing={2} justifyContent="space-between">
                        <Grid item>
                            <Rating
                                value={rating}
                                size="small"
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" color="text.secondary">
                                {course.coursePricing <= 0 ? (
                                    <Badge variant="success">Free</Badge>
                                ) : (
                                    <span className='text-start text-black'>
                                        Price <Badge bg="warning" className='text-black'>{"$ " + course.coursePricing}/-</Badge>
                                    </span>
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>
            <Divider variant="fullWidth" />
            {showButtons && (
                <CardActions style={{ display: "flex", justifyContent: "space-between" }}>
                    <Link to={`/studentDashboard/courseDetails/${course?.courseId}`}>
                        <Button size="small" color="primary">
                            Open Course
                        </Button>
                    </Link>
                </CardActions>
            )}
        </Card>
    )
}

export default Course;