import { Button, CircularProgress, Container, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import React, { useState } from 'react';
import Select from 'react-select';
import FoundInstitutes from './FoundInstitutes';
import Swal from 'sweetalert2';
import * as api from "../../../api/index.js";

function SearchInstitute({
    instituteSectorsOptions,
    instituteTypesOptions,
    instituteProgramsOptions,
    instituteProvincesOptions,
    instituteCitiesOptions,
    loadingOptions
}) {
    const [instituteSector, setInstituteSector] = useState("");
    const [instituteType, setInstituteType] = useState("");
    const [instituteProgram, setInstituteProgram] = useState("");
    const [instituteProvince, setInstituteProvince] = useState("");
    const [instituteCity, setInstituteCity] = useState("");
    const [foundInstitutesData, setFoundInstitutesData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [zeroResults, setZeroResults] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            const formData = {
                instituteSector: instituteSector,
                instituteType: instituteType,
                instituteProgram: instituteProgram,
                instituteProvince: instituteProvince,
                instituteCity: instituteCity
            }
            const { data } = await api.searchInstitutes(formData);
            if (data.success) {
                setLoading(false);
                setFoundInstitutesData(data.institutes);
                if (data.institutes.length === 0) {
                    setZeroResults(true);
                }
                else {
                    setZeroResults(false);
                }
            }
            else {
                setLoading(false);
                Swal.fire(
                    'Failed!',
                    data.error,
                    'warning'
                )
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                title: 'Lala!',
                text: error,
                icon: 'error',
            })
        }
    }


    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            textAlign: 'start',
            color: 'black',
        }),
        control: (provided, state) => ({
            ...provided,
            textAlign: 'start',
            color: 'black',
        }),
        option: (provided, state) => ({
            ...provided,
            textAlign: 'start',
            color: 'black',
        }),
    }

    const handleSectorChange = (event) => {
        const { value } = event;
        setInstituteSector(value);
    }

    const handleInstituteTypeChange = (event) => {
        const { value } = event;
        setInstituteType(value);
    }

    const handleProgramChange = (event) => {
        const { value } = event;
        setInstituteProgram(value);
    }

    const handleProvinceChange = (event) => {
        const { value } = event;
        setInstituteProvince(value);
    }

    const handleCityChange = (event) => {
        const { value } = event;
        setInstituteCity(value);
    }



    return (
        <div>
            <Container maxWidth="lg">
                <Paper elevation={12}>
                    <form autoComplete='false' onSubmit={handleSubmit}>
                        <Grid mx={2} my={2} className="p-4">
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography constiant="h6" className="mt-4 heading">
                                        Search Institutes
                                    </Typography>
                                    {loadingOptions ? (
                                        <div>
                                            <div className='fw-bolder opacity-75'>
                                                Loading Options..
                                            </div>
                                            <div>
                                                <LinearProgress variant="indeterminate" color="info" />
                                            </div>
                                        </div>
                                    ) : null}
                                </Grid>
                            </Grid>

                            <Grid container spacing={3} className="text-start">
                                <Grid item xs={12} md={6} lg={4}>
                                    <label className='fw-bolder opacity-50'>Sector</label>
                                    <Select
                                        options={instituteSectorsOptions}
                                        placeholder="-- Select Sector --"
                                        styles={customStyles}
                                        onChange={(val) => handleSectorChange(val)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <label className='fw-bolder opacity-50'>Institute Type</label>
                                    <Select
                                        options={instituteTypesOptions}
                                        placeholder="-- Select Institute Type --"
                                        styles={customStyles}
                                        onChange={(val) => handleInstituteTypeChange(val)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <label className='fw-bolder opacity-50'>Program</label>
                                    <Select
                                        options={instituteProgramsOptions}
                                        placeholder="-- Select Program --"
                                        styles={customStyles}
                                        onChange={(val) => handleProgramChange(val)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} className="mt-1 text-start" justifyContent={"center"}>
                                <Grid item xs={12} md={6} lg={4}>
                                    <label className='fw-bolder opacity-50'>Province</label>
                                    <Select
                                        options={instituteProvincesOptions}
                                        placeholder="-- Select Province --"
                                        styles={customStyles}
                                        onChange={(val) => handleProvinceChange(val)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <label className='fw-bolder opacity-50'>City</label>
                                    <Select
                                        options={instituteCitiesOptions}
                                        placeholder="-- Select City --"
                                        styles={customStyles}
                                        onChange={(val) => handleCityChange(val)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} className="mt-1" justifyContent={"center"}>
                                <Grid item xs={12} md={6} lg={4}>
                                    <Button type='submit' variant="contained" color="primary" className="mt-4">
                                        Search
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Container>
            {loading && (
                <Container maxWidth="lg">
                    <CircularProgress />
                </Container>
            )
            }
            {zeroResults && (
                <div
                    style={{
                        marginTop: '2.5rem',
                    }}
                >
                    <Paper elevation={12} className="m-3">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography constiant="h6" className="mt-4 heading">
                                    No Institutes Found.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            )
            }
            {
                foundInstitutesData.length > 0 && (
                    <Paper elevation={6} className="m-3">
                        <Grid>
                            <FoundInstitutes foundInstitutesData={foundInstitutesData} />
                        </Grid>
                    </Paper>
                )
            }
        </div>
    )
}

export default SearchInstitute;