import { Box, Button, Grid, Paper, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import "./css/Checkout.css";
import DeleteIcon from '@mui/icons-material/Delete';
import { COURSE_THUMBNAIL_URL } from '../../constants/actionTypes';
import Swal from 'sweetalert2';
import * as api from "../../api/index";
import { Form } from 'react-bootstrap';

const initialState = {
    transactionId: "",
    transactionAmountPaidByUser: "",
}

function Checkout({ user, cartItems, setCartItems }) {
    const [checkoutForm, setCheckoutForm] = useState(initialState);
    const [totalAmount, setTotalAmount] = useState(0);
    const [transactionScreenShot, setTransactionScreenShot] = useState(null);
    const [requesting, setRequesting] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (requesting) {
            Swal.fire({
                title: 'Processing your request...',
                text: 'Please wait...',
                showConfirmButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: true,
            });
            return;
        }

        setRequesting(true);

        if (!user) {
            Swal.fire(
                'Failed!',
                "Please login first to proceed!",
                'error'
            )
            setRequesting(false);
            return
        }

        if (checkoutForm.transactionId === "" || transactionScreenShot === null) {
            Swal.fire(
                'Failed!',
                "Please fill all the fields!",
                'error'
            )
            setRequesting(false);
        }
        else {
            const formData = new FormData();
            formData.append("userId", user.result.id);
            formData.append("courses", JSON.stringify(cartItems));
            formData.append("transactionAmountPaidByUser", Number(checkoutForm.transactionAmountPaidByUser));
            formData.append("transactionId", checkoutForm.transactionId);
            formData.append("transactionScreenShot", transactionScreenShot);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            try {
                var { data } = await api.assignCheckoutCoursesToStudents(formData, config);
                if (data.success) {
                    Swal.fire(
                        'Good job!',
                        data.message,
                        'success'
                    );
                    Swal.fire({
                        title: 'Good job!',
                        text: data.message,
                        icon: 'success',
                        showConfirmButton: true,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: true,
                    });
                    setCheckoutForm(initialState);
                    setCartItems([]);
                    document.getElementById("checkoutForm").reset();
                    setRequesting(false);
                }
                else {
                    Swal.fire({
                        title: 'Failed!',
                        text: data.error,
                        icon: 'error',
                    });
                    setRequesting(false);
                }
            } catch (error) {
                Swal.fire(
                    'Failed!',
                    "Server Side error occured please contact khan sahib to fix issue for you!",
                    'error'
                )
                setRequesting(false);
            }
        }

    }

    const removeCourseFromCartItems = (clickedCourse) => {
        setCartItems(
            cartItems.filter((course) =>
                course.id !== clickedCourse.id
            )
        )
    }

    const handleChange = (e) => {
        setCheckoutForm({ ...checkoutForm, [e.target.name]: e.target.value });
    }

    const onSelectTransactionScreenShot = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setTransactionScreenShot(null)
            return
        }
        setTransactionScreenShot(e.target.files[0])
    }

    useEffect(() => {
        let coursesPrice = 0
        cartItems.map((course, index) => {
            return coursesPrice = coursesPrice + Number(course.coursePricing);
        });
        setTotalAmount(coursesPrice);
    }, [cartItems]);


    return (
        <div
            style={{ marginTop: "30px" }}
        >
            <Grid container spacing={0.5}>
                <Grid item sm={12} md={5} lg={5} mx={2}>
                    <Paper elevation={3}>
                        <Typography className="heading pt-2" variant="h6">Checkout Courses using EasyPaisa OR Jazz Cash</Typography>

                        <form id="checkoutForm" onSubmit={(e) => handleSubmit(e)}>

                            <Grid container spacing={2} paddingRight={2} paddingLeft={2}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <TextField
                                        label="Transaction ID"
                                        value={checkoutForm.transactionId}
                                        name='transactionId'
                                        onChange={handleChange}
                                        size="small"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <TextField
                                        label="Paid Amount"
                                        value={checkoutForm.transactionAmountPaidByUser}
                                        name='transactionAmountPaidByUser'
                                        onChange={handleChange}
                                        size="small"
                                        type="number"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Form.Control type="file" multiple={false} onChange={(e) => onSelectTransactionScreenShot(e)} />
                                    <Form.Label className='fw-bold'>Transaction ScreenShot</Form.Label>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Button style={{ margin: "5px" }} variant="contained" size="small" color="primary" type="submit">
                                        Place Order
                                    </Button>
                                    <Button style={{ margin: "5px" }} variant="contained" size="small" color="primary" type="reset">
                                        Reset
                                    </Button>
                                </Grid>
                            </Grid>

                        </form>

                    </Paper>
                </Grid>
                <Grid item sm={12} md={5} lg={5} mx={2}>
                    <Paper elevation={3} style={{ minHeight: "288px" }}>
                        <Typography className="heading pt-2" variant="h6">Checkout Courses and summary Details</Typography>

                        {!cartItems.length > 0 ? (
                            <div className='fw-bold'>
                                You dont have any course in your cart yet!
                            </div>
                        ) : (
                            <>
                                <Grid item xs={12} md={12} lg={12} className="px-2 mb-2">
                                    <div className='bg-light rounded fw-bold'>
                                        Your Total Bill is: <b>{totalAmount}/-</b>
                                    </div>
                                </Grid>
                                <Box style={{ maxHeight: '500px', overflow: 'auto', padding: "5px" }}>
                                    <Grid container spacing={2} paddingRight={2} paddingLeft={2}>

                                        {cartItems.map((course, index) => {
                                            return (
                                                <Grid item xs={12} md={12} lg={12} key={index}>
                                                    <Paper elevation={3}>
                                                        <div className='checout__course'>
                                                            <img src={`${COURSE_THUMBNAIL_URL}${course.courseThumbnail}`} alt='Coruse Thumbnail' className='checkout__course_image p-1' />
                                                            <div>
                                                                <h6>{course.courseTitle.slice(0, 20)}</h6>
                                                                <p>Price: {course.coursePricing}</p>
                                                            </div>
                                                            <Button style={{ margin: "10px" }} variant="contained" color="primary" size='small' onClick={() => removeCourseFromCartItems(course)}>
                                                                <DeleteIcon />
                                                            </Button>
                                                        </div>
                                                    </Paper>
                                                </Grid>
                                            )
                                        })}



                                    </Grid>
                                </Box>
                            </>
                        )}

                    </Paper>
                </Grid>
            </Grid>

        </div>
    );
}

export default Checkout;

