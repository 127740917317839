import React, { useCallback, useEffect, useState } from 'react';
import { Button, Container, Table } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { TRANSACTION_THUMBNAIL_URL } from '../../constants/actionTypes';
import "./css/TransactionDetails.css";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import * as api from "../../api/index";
import Swal from 'sweetalert2';
import { CircularProgress } from '@mui/material';


function TransactionDetails() {
    // const navigate = useNavigate();
    // const [user, setUser] = useState([]);
    // const [transaction, setTransaction] = useState([]);
    // const [transactionCourses, setTransactionCourses] = useState([]);
    // let { transactionId } = useParams();
    // const dispatch = useDispatch();
    // const findTrans = useSelector((state) => state.transactions.find(trans => trans.id == transactionId));

    // useEffect(() => {
    //     setTransaction(findTrans);
    //     setUser(`${findTrans?.firstName} ${findTrans?.lastName}`);
    // }, [transaction, findTrans]);


    // // const getCourses = async () => {
    // //     if (!transaction.checkedOutCourses) {
    // //         return false;
    // //     }
    // //     const { data } = await api.getTransactionCourses({ "courseIds": transaction.checkedOutCourses, "userId": transaction.userId._id });
    // //     setTransactionCourses(data);
    // // }

    // const getCourses = useCallback(async () => {
    //     if (!transaction.checkedOutCourses) {
    //         return false;
    //     }
    //     const { data } = await api.getTransactionCourses({ "courseIds": transaction.checkedOutCourses, "userId": transaction.userId });
    //     setTransactionCourses(data);
    // }, [transaction]);


    // useEffect(() => {
    //     getCourses();
    // }, [transaction, user, getCourses]);


    const navigate = useNavigate();
    const [user, setUser] = useState([]);
    const [transaction, setTransaction] = useState([]);
    const [transactionCourses, setTransactionCourses] = useState([]);
    let { transactionId } = useParams();

    const handleCourseStatusChange = async (e, studentCourse) => {
        const status = e.target.value === "1" ? true : false;
        try {
            const { data } = await api.changeStudentCourseStatusFromTransaction({ "courseId": studentCourse.id, "status": status });
            if (data.success) {
                // getCourses();
                // dispatch(getStudentsCoursesForAdmin());
                getTransactionDetails();
                Swal.fire({
                    title: 'Good job!',
                    text: data.message,
                    icon: 'success',
                    showConfirmButton: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                })
            }
            else {
                Swal.fire({
                    title: 'Failed!',
                    text: data.error,
                    icon: 'error',
                    showConfirmButton: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                })
            }
        } catch (error) {
            Swal.fire(
                'Failed!',
                error,
                'error'
            );
        }
    }

    const getTransactionDetails = useCallback(async () => {
        const { data } = await api.getTransactionDetails(transactionId);

        if (data.success) {
            setTransaction(data.transaction);
            setUser(data.user);
            setTransactionCourses(data.transactionCourses);
        }
        else {
            Swal.fire(
                'Failed!',
                data.error,
                'error'
            );
        }
    }, [transactionId]);

    useEffect(() => {
        getTransactionDetails();
    }
        , [transactionId, getTransactionDetails]);



    return (
        <div style={{ marginTop: "55px" }}>
            {!transaction ? (
                <div className='mt-2'>
                    No Data found
                </div>
            ) : (
                <>
                    <div className='bg-light'>
                        <Button variant="info mx-1 my-1 btn-sm" onClick={() => navigate(-1)}><ArrowBackIcon style={{ width: 20, height: 20 }} /> Back</Button>
                    </div>
                    <div className='transaction_details__top_info'>
                        <div className='transaction_details__text'>
                            <h5>Transaction ID: {transaction.transactionId}</h5>
                            <div><AccountCircleIcon /> Transacted by: <u>{!user ? "" : `${user?.firstName} ${user?.lastName}`}</u></div>
                            <div><AccessTimeIcon /> Transaction Date: {transaction.checkoutDate}</div>
                            <div><PriceChangeIcon />Total Transaction Amount: {transaction.transactionAmountOfAllCourses}/-</div>
                            <div><PriceChangeIcon /> Paid by User: {transaction.transactionAmountPaidByUser}/-</div>
                        </div>
                        {transaction.transactionScreenShot ? (
                            <div className='transaction_details__thumbnail'>
                                <img src={`${TRANSACTION_THUMBNAIL_URL}${transaction.transactionScreenShot}`} alt="Course Thumbnail" />
                            </div>
                        ) : (
                            <div className='transaction_details__thumbnail'>
                                <CircularProgress />
                            </div>
                        )}
                    </div>
                    <Container
                        className='mt-4'
                        style={{
                            border: "1px solid #e6e6e6",
                            borderRadius: "5px",
                            backgroundColor: "rgba(0, 0, 0, 0.1)",
                            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                        }}
                    >
                        <h6 className='heading mt-2 h4'>Transaction Courses</h6>
                        <Table striped bordered hover style={{ textAlign: "start" }} responsive className='bg-light'>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Course Name</th>
                                    <th>Course Pricing</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>

                                {!transactionCourses ? (
                                    <tr>
                                        <td colSpan={4}>No Courses Found</td>
                                    </tr>
                                ) : (
                                    <>
                                        {transactionCourses.map((course, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{course?.courseTitle?.slice(0, 70)}</td>
                                                    <td>{course?.coursePricing}</td>
                                                    <td>
                                                        <select className="course__status" value={course.approvedByAdmin === 1 ? "1" : "0"} name="" onChange={(e) => handleCourseStatusChange(e, course)}>
                                                            <option value="1">Approved</option>
                                                            <option value="0">Not Approved</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </>
                                )}
                            </tbody>
                        </Table>
                    </Container>
                </>
            )}
        </div>
    );
}

export default TransactionDetails;