import React, { useCallback, useEffect, useState } from 'react';
import CoursesCards from './CoursesCards';
import { Container } from 'react-bootstrap';
import WelcomeHome from './WelcomeHome';
import * as api from "../../api/index";
import Swal from 'sweetalert2';

function HomeMain({ user, cartItems, setCartItems }) {
    const [courses, setCourses] = useState([]);
    const [homePoster, setHomePoster] = useState(null);
    const [coursesRequesting, setCoursesRequesting] = useState(false);
    const [coursesRequestingError, setCoursesRequestingError] = useState(false);

    const getNonAuthCourses = useCallback(async () => {
        setCoursesRequesting(true);
        setCoursesRequestingError(false);
        const { data } = await api.coursesForNonAuthUsers();
        if (data.success) {
            setCourses(data.courses);
            if (data.poster !== null || data.poster !== undefined) {
                setHomePoster(data.poster);
            }
            setCoursesRequesting(false);
            setCoursesRequestingError(false);
        }
        else {
            Swal.fire({
                title: 'Error',
                text: data.error,
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            setCoursesRequesting(false);
            setCoursesRequestingError(true);
        }
    }, [setCourses]);


    useEffect(() => {
        getNonAuthCourses();
    }, [getNonAuthCourses]);


    return (
        <div>
            <WelcomeHome
                user={user}
                homePoster={homePoster}
            />
            <Container
                fluid="true"
            >
                <CoursesCards
                    cartItems={cartItems}
                    setCartItems={setCartItems}
                    courses={courses}
                    coursesRequesting={coursesRequesting}
                    coursesRequestingError={coursesRequestingError}
                />
            </Container>
        </div>
    )
}

export default HomeMain;
