import React, { useCallback, useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import * as api from "../../../api/index";
import Swal from 'sweetalert2';
import { CircularProgress } from "@mui/material";
import AddBook from './AddBook';
import AddSoftware from './AddSoftware';

function Downloads({ user, setUser }) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [booksData, setBooksData] = useState([]);
    const [softwaresData, setSoftwaresData] = useState([]);

    const getDownloadsData = useCallback(async () => {
        const { data } = await api.getDownloadsDataForAdmin();
        if (data.success) {
            setBooksData(data.books);
            setSoftwaresData(data.softwares);
            setLoading(false);
            setError(false);
        }
        else {
            Swal.fire({
                title: 'Error!',
                text: data.error,
                icon: 'error',
                confirmButtonText: 'Ok',
            });
            setLoading(false);
            setError(true);
        }
    }, []);

    useEffect(() => {
        getDownloadsData();
    }, [getDownloadsData]);


    return (
        <div className='m-1 p-4'>
            {error && <div className='text-danger text-center'>Something went wrong. Please try again later. or contact the developer sahib to check the masla</div>}
            {loading ?
                <div className='text-center'>
                    <CircularProgress />
                </div>
                : (
                    <Tabs
                        defaultActiveKey="booksData"
                        transition={true}
                        id="noanim-tab-example"
                        className="mb-3"
                        style={{
                            padding: "3px",
                            backgroundColor: "rgb(11 142 150)",
                        }}
                    >
                        <Tab eventKey="booksData" title="Books">
                            <AddBook
                                booksData={booksData}
                                setBooksData={setBooksData}
                            />
                        </Tab>
                        <Tab eventKey="softwares" title="Softwares">
                            <AddSoftware
                                softwaresData={softwaresData}
                                setSoftwaresData={setSoftwaresData}
                            />
                        </Tab>
                    </Tabs>
                )}
        </div>
    )
}



export default Downloads;