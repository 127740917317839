import React, { useEffect, useRef } from 'react';
import { VIDEO_URL } from '../../../constants/actionTypes';

function PlayedVideo({ video, playUploaded, playYoutubeEmbedded, playDriveEmbedded }) {
  // let videoExt = video.videoFile.slice(video.videoFile.length - 10, video.videoFile.length);
  // var ext =  videoExt.split('.').pop();

  const videoRef = useRef();
  const previousUrl = useRef(video.videoFile);

  useEffect(() => {
    if (previousUrl.current === video.videoFile) {
      return;
    }

    if (videoRef.current) {
      videoRef.current.load();
    }

    previousUrl.current = video.videoFile;
  }, [video.videoFile]);

  return (
    <div>
      {playUploaded === true && (
        <div onContextMenu={e => e.preventDefault()}>
          <video
            style={{
              width: "100%",
              height: "auto",
              borderRadius: "5px"
            }}
            controls ref={videoRef} autoPlay controlsList="nodownload">
            <source src={`${VIDEO_URL}${video.videoFile}`} type="video/mp4" />
          </video>
          <h4 className='heading p-2'>{video.videoTitle}</h4>
        </div>
      )}

      {playYoutubeEmbedded === true && (
        < div onContextMenu={e => e.preventDefault()} >
          <div
            className="played_video_div"
            dangerouslySetInnerHTML={{ __html: video.videoFileLink }}
          />
          <h4 className='heading p-2'>{video.videoTitle}</h4>
        </div >
      )}

      {playDriveEmbedded === true && (
        <div onContextMenu={e => e.preventDefault()} >
          <div
            className="played_video_div"
            dangerouslySetInnerHTML={{ __html: video.videoFileLink }}
          />
          <h4 className='heading p-2'>{video.videoTitle}</h4>
        </div>
      )}
    </div>
  )
}

export default PlayedVideo;