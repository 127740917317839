import React, { useEffect, useState } from 'react';
import { Button, Grid, TextField, Typography, CircularProgress, Paper } from '@mui/material';
import Card from 'react-bootstrap/Card'
import Swal from 'sweetalert2';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { PAGE_SIZES, tableDataToPdf } from '../../../Globals/PDFVariables';
import { CSVLink } from 'react-csv';
import Select from "react-select";

import * as api from '../../../api/index';

const columns = [
  { id: 'sno', label: 'S.No', minWidth: 10, width: '5%' },
  { id: 'instituteType', label: 'Institute Type', minWidth: 10, width: '40%' },
  { id: 'programName', label: 'Program Name', minWidth: 10, width: '40%' },
  { id: 'action', label: 'Action', minWidth: 10, width: '10%' }
];


function InstitutePrograms({
  instituteProgramsData,
  setInstituteProgramsData,

  instituteTypesOptions,
}) {
  const [instituteTypeId, setInstituteTypeId] = useState('');
  const [programs, setPrograms] = useState([]);
  const [programName, setProgramName] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (programName === "") {
        Swal.fire({
          title: 'Error!',
          text: 'Please fill all the fields',
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      } else {
        const formData = {
          instituteTypeId: instituteTypeId,
          programName: programName,
        }
        const { data } = await api.addInstituteProgram(formData);
        if (data.success) {
          Swal.fire({
            title: 'Success!',
            text: data.message,
            icon: 'success',
            confirmButtonText: 'Ok'
          })
          setInstituteProgramsData([...instituteProgramsData, data.program]);
          setProgramName("");
        }
        else {
          Swal.fire({
            title: 'Error!',
            text: data.message,
            icon: 'error',
            confirmButtonText: 'Ok'
          })
        }
      }
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: "Something went wrong",
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }
  }


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };



  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    const searchText = e.target.value;
    if (e.target.value) {
      const results = instituteProgramsData.filter(programName =>
        programName.programName.toLowerCase().includes(searchText.toLowerCase())
      );
      setPrograms(results);
    } else {
      setPrograms([...instituteProgramsData]);
    }
  }

  const handleDelete = async (id) => {
    try {
      const { value } = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });

      if (value) {
        const { data } = await api.deleteInstituteProgram(id);
        if (data.success) {
          Swal.fire({
            title: 'Success!',
            text: data.message,
            icon: 'success',
            confirmButtonText: 'Ok'
          });
          setInstituteProgramsData(instituteProgramsData.filter(program => program.id !== id));
        }
        else {
          Swal.fire({
            title: 'Error!',
            text: data.message,
            icon: 'error',
            confirmButtonText: 'Ok'
          })
        }
      }

    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: "Something went wrong",
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }
  }


  const createPdf = () => {
    const tableColumns = [['S.No', 'Institute Type']];
    const tableRows = programs.map((insType, index) => [
      index + 1,
      insType.programName,
    ]);

    tableDataToPdf(tableColumns, tableRows, "Institutes Types");
  }


  useEffect(() => {
    setPrograms(instituteProgramsData);
  }, [instituteProgramsData]);


  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Card>
            <Card.Header>
              <Typography className="heading" variant="h4">Add Programs</Typography>
            </Card.Header>
            <Card.Body>
              <form onSubmit={handleSubmit} autoComplete={"false"}>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12} md={6} lg={6}>
                    <Select options={instituteTypesOptions}
                      onChange={(e) => setInstituteTypeId(e.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      label="Program Name"
                      value={programName}
                      onChange={(e) => setProgramName(e.target.value)}
                      size="small"
                      inputProps={{ maxLength: 100 }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                    >Submit</Button>
                  </Grid>
                </Grid>
              </form>
            </Card.Body>
          </Card>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Card>
            <Card.Header>
              <Typography className="heading" variant="h4">Programs</Typography>
            </Card.Header>
            <Card.Body>
              <div className='d-flex flex-row justify-content-start align-items-center flex-wrap'>
                <div>
                  <TextField
                    label="Search Here"
                    name="search"
                    size="small"
                    className='m-2'
                    type={'search'}
                    value={searchTerm}
                    style={{
                      width: "300px",
                    }}
                    onChange={(e) => handleSearch(e)}
                  />
                </div>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    className='m-1'
                    startIcon={<CollectionsBookmarkIcon />}
                  >
                    <CSVLink style={{
                      textDecoration: 'none',
                      color: 'white',
                    }}
                      data={
                        programs.map((insType, index) => {
                          return {
                            "S.No": index + 1,
                            "Institute Type": insType.programName,
                          }
                        })
                      }
                      filename="programs.csv"
                    >CSV/EXCEL</CSVLink>
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className='m-1'
                    startIcon={<PictureAsPdfIcon />}
                    onClick={createPdf}
                  >
                    PDF
                  </Button>
                </div>
              </div>
              <div className='mt-2'>
                <Paper sx={{
                  width: '100%', overflow: 'hidden',
                  boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
                  borderRadius: '8px',
                  backgroundColor: '#fafafa'
                }}>
                  <TableContainer sx={{ maxHeight: 570 }}>
                    <Table stickyHeader aria-label="sticky table"
                      size='small'
                    >
                      <TableHead
                        sx={{
                          backgroundColor: '#81d690',
                          borderRadius: '8px',
                          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
                        }}
                      >
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                              width={column.width}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>

                        {!programs && (
                          <TableRow>
                            <TableCell colSpan={6}>
                              <CircularProgress />
                            </TableCell>
                          </TableRow>
                        )}

                        {programs && programs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((insType, index) => {
                          return (
                            <TableRow hover role="checkbox" key={insType.id}>

                              <TableCell component="td" scope="row">
                                {++index}
                              </TableCell>
                              <TableCell component="td" scope="row">
                                {insType.instituteType}
                              </TableCell>
                              <TableCell component="td" scope="row">
                                {insType.programName}
                              </TableCell>
                              <TableCell component="td" scope="row">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  className='m-1'
                                  size='small'
                                  onClick={() => handleDelete(insType.id)}
                                >
                                  <DeleteRoundedIcon />
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}

                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={PAGE_SIZES}
                    component="div"
                    count={programs.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className='table_pagination'
                  />
                </Paper>
              </div>
            </Card.Body>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default InstitutePrograms;