import React from 'react';
import { Button } from '@mui/material';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import { SOFTWARES_FILES, SOFTWARES_THUMBNAILS } from '../../../constants/actionTypes';

function SoftwaresList({ foundSoftwares }) {
    return (
        <section className="h-100 h-custom"
            style={{
                // backgroundColor: "#eee"
            }}
        >
            <div className="container py-2 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col">
                        <div className="card-body p-4">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h5 className="mb-3">Found softwares</h5>

                                    {foundSoftwares && foundSoftwares.map((software, index) => {
                                        return (
                                            <div className="card mb-3" key={index}>
                                                <div className="card-body">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="d-flex flex-row align-items-center">
                                                            <div>
                                                                <img
                                                                    src={`${SOFTWARES_THUMBNAILS}${software?.softwareThumnail}`}

                                                                    className="img-fluid rounded-3"
                                                                    alt="software"
                                                                    style={{
                                                                        width: "150px"
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="ms-3">
                                                                <h5>{software?.softwareName}</h5>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-row align-items-center">
                                                            <Button variant="contained"
                                                                href={`${SOFTWARES_FILES}${software?.softwareFile}`}
                                                                color="primary"
                                                                size='small'
                                                                target="_blank"
                                                                download
                                                            >
                                                                <DownloadForOfflineRoundedIcon />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default SoftwaresList