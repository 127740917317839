import React, { useCallback, useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Button, CircularProgress, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import "./css/Transactions.css";
import PreviewIcon from '@mui/icons-material/Preview';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import { PAGE_SIZES, tableDataToPdf } from '../../Globals/PDFVariables';
import { CSVLink } from "react-csv";
import Swal from 'sweetalert2';
import * as api from "../../api/index";

const columns = [
  { id: 'sno', label: 'S.No', minWidth: 10, width: '5%' },
  { id: 'transactionId', label: 'Transaction ID', minWidth: 10, width: '5%' },
  { id: 'transactionAmount', label: 'Transaction Amount', minWidth: 10, width: '15%' },
  { id: 'transactionPaidAmount', label: 'Transaction Paid Amount', minWidth: 10, width: '15%' },
  { id: 'user', label: 'User', minWidth: 10, width: '15%' },
  { id: 'action', label: 'Action', minWidth: 10, width: '15%' },
];

function Transactions() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [searchTerm, setSearchTerm] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [transactionsBackup, setTransactionsBackup] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    const searchText = e.target.value.replace(/\s/g, '');
    if (e.target.value) {
      const results = transactionsBackup.filter(transaction =>
        transaction.transactionId.toLowerCase().includes(searchText.toLowerCase()) ||
        transaction.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
        transaction.lastName.toLowerCase().includes(searchText.toLowerCase())
      );
      setTransactions(results);
    } else {
      setTransactions(transactionsBackup);
    }
  }

  const createPdf = () => {
    const tableColumns = [['S.No', 'Transaction ID', 'Transaction Amount', 'Transaction Paid Amount', 'User']];
    const tableRows = transactions.map((transaction, index) => [
      index + 1,
      transaction.transactionId,
      transaction.transactionAmountOfAllCourses,
      transaction.transactionAmountPaidByUser,
      transaction.firstName + ' ' + transaction.lastName
    ]);

    tableDataToPdf(tableColumns, tableRows, 'Transactions');
  }

  const getTransactions = useCallback(async () => {
    const { data } = await api.getTransactions();
    if (data.success) {
      setTransactions(data.transactions);
      setTransactionsBackup(data.transactions);
    }
    else {
      Swal.fire({
        title: 'Error',
        text: data.error,
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }
  }, []);

  useEffect(() => {
    getTransactions();
  }, [getTransactions]);

  return (
    <div className='transactions__main_div'>
      <div className='card'>
        <div className='card-header'>
          <h3>Transactions</h3>
        </div>
      </div>
      <div className='transactions__top_div'>
        <div>
          <TextField
            label="Search Here"
            name="search"
            size="small"
            className='m-2'
            type={'search'}
            value={searchTerm}
            style={{
              width: "300px",
            }}
            onChange={(e) => handleSearch(e)}
          />
        </div>
        <div className='users__button_div'>
          <Button
            variant="contained"
            color="primary"
            className='users__button m-1'
            startIcon={<CollectionsBookmarkIcon />}
            size="small"
          >
            <CSVLink style={{
              textDecoration: 'none',
              color: 'white',
            }}
              data={
                transactions.map(transaction => ({
                  "Transaction ID": transaction.transactionId,
                  "Transaction Amount": transaction.transactionAmountOfAllCourses,
                  "Transaction Paid Amount": transaction.transactionAmountPaidByUser,
                  "User": transaction.firstName + " " + transaction.lastName,
                }))
              }
              filename="transactions.csv"
            >CSV/EXCEL</CSVLink>
          </Button>
          <Button
            variant="contained"
            color="primary"
            className='users__button m-1'
            startIcon={<PictureAsPdfIcon />}
            size="small"
            onClick={createPdf}
          >
            PDF
          </Button>
        </div>
      </div>
      <div className='users__table_div'>
        <Paper sx={{
          width: '100%', overflow: 'hidden',
          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
          borderRadius: '8px',
          backgroundColor: '#fafafa'
        }}>
          <TableContainer sx={{ maxHeight: 570 }}>
            <Table stickyHeader aria-label="sticky table"
              size='small'
            >
              <TableHead
                sx={{
                  backgroundColor: '#81d690',
                  borderRadius: '8px',
                  boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
                }}
              >
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                      width={column.width}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>

                {!transactions && (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}

                {transactions && transactions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((transaction, index) => {
                  return (
                    <TableRow hover role="checkbox" key={transaction.id}>

                      <TableCell component="td" scope="row">
                        {++index}
                      </TableCell>
                      <TableCell align="left">{transaction.transactionId}</TableCell>
                      <TableCell align="left">{transaction.transactionAmountOfAllCourses}</TableCell>
                      <TableCell align="left">{transaction.transactionAmountPaidByUser}</TableCell>
                      <TableCell align="left">{transaction.firstName} {transaction.lastName}</TableCell>
                      <TableCell align="left">
                        <Link to={`/admin/transactions/${transaction.id}`}>
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<PreviewIcon />}
                            size="small"
                          >
                            View
                          </Button>
                        </Link>
                      </TableCell>

                    </TableRow>
                  );
                })}

              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={PAGE_SIZES}
            component="div"
            count={transactions.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className='table_pagination'
          />
        </Paper>
      </div>
    </div>
  );
}



export default Transactions;



















// import { Button, CircularProgress, TextField } from '@mui/material';
// import React, { useState } from 'react';
// import Table from 'react-bootstrap/Table'
// import { useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';
// import "./css/Transactions.css";

// function Transactions() {
//   const [searchTerm, setSearchTerm] = useState([]);
//   const transactionsData = useSelector((state) => state.transactions);
//   const [transactions, setTransactions] = useState(transactionsData);


//   const handleSearch = (e) => {
//     setSearchTerm(e.target.value);
//     if (e.target.value) {
//       const results = transactionsData.filter(transaction =>
//         // transaction.transactionId.toLowerCase().includes(e.target.value) ||
//         // transaction.userId.firstName.toLowerCase().includes(e.target.value) ||
//         // transaction.userId.lastName.toLowerCase().includes(e.target.value)
//         `
//           ${transaction.transactionId}
//           ${transaction.firstName}
//           ${transaction.lastName}
//         `.toLowerCase().includes(e.target.value)
//       );
//       setTransactions(results);
//     } else {
//       setTransactions(transactionsData);
//     }
//   }


//   return (
//     <div className='transactions__main_div'>
//       <div className='transactions__top_div'>
//         <div>
//           <TextField
//             label="Search Here"
//             name="search"
//             size="small"
//             className='m-2'
//             type={'search'}
//             value={searchTerm}
//             style={{ width: "220px" }}
//             onChange={(e) => handleSearch(e)}
//           />
//         </div>
//         {/* <div><Button variant='contained' className='m-2'>CSV</Button></div>
//         <div><Button variant='contained' className='m-2'>PDF</Button></div> */}
//       </div>
//       <div>
//         <Table striped bordered hover size="sm" style={{ textAlign: "start" }} responsive>
//           <thead>
//             <tr>
//               <th>S.No</th>
//               <th>Transaction ID</th>
//               <th>Transaction Amount</th>
//               <th>Transaction Paid Amount</th>
//               <th>User</th>
//               <th>Action</th>
//             </tr>
//           </thead>
//           <tbody>

//             {!transactions ? (
//               <tr>
//                 <td colSpan="6">
//                   <CircularProgress />
//                 </td>
//               </tr>
//             ) : (
//               <>
//                 {transactions.map((transaction, index) => {
//                   return (
//                     <tr key={index}>
//                       <td>{index + 1}</td>
//                       <td>{transaction.transactionId}</td>
//                       <td>{transaction.transactionAmountOfAllCourses}</td>
//                       <td>{transaction.transactionAmountPaidByUser}</td>
//                       <td>{transaction.firstName} {transaction.lastName}</td>
//                       <td>
//                         <Link to={`/admin/transactions/${transaction.id}`}>
//                           <Button size="small" color="secondary" variant="outlined">
//                             Details
//                           </Button>
//                         </Link>
//                       </td>
//                     </tr>
//                   )
//                 })}
//               </>
//             )}
//           </tbody>
//         </Table>
//       </div>
//     </div>
//   );
// }

// export default Transactions;

