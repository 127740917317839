import { Button, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Select from 'react-select'
import "./css/AssignCourse.css";
import * as api from "../../api/index.js";
import Swal from 'sweetalert2';

const initialState = {
    userId: null,
    courseId: null,
}

function AssignCourse() {
    const [users, setUsers] = useState(null);
    const [courses, setCourses] = useState(null);
    const [assignCourseForm, setAssignCourseForm] = useState(initialState);
    const [requesting, setRequesting] = useState(false);

    // const options = [
    //     { value: 'chocolate', label: 'Chocolate' },
    // ]

    const getCoursesAndUsers = async () => {
        try {
            const { data } = await api.getCoursesAndUsers();
            let coursesData = data.courses;
            let usersData = data.users;
            let usersList = [];
            let coursesList = [];
            usersData.map(user => usersList.push({ value: user.id, label: `${user.email}` }))
            coursesData.map(course => coursesList.push({ value: course.id, label: `${course.courseTitle} -- By -- ${course.email}` }));
            setUsers(usersList);
            setCourses(coursesList);
        } catch (error) {
            window.alert("Failed to load users and courses")
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (requesting) {
            Swal.fire({
                title: 'Processing your request...',
                text: 'Please wait...',
                showConfirmButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: true,
            });
            return;
        }
        setRequesting(true);

        try {
            if (assignCourseForm.userId === null || assignCourseForm.courseId === null) {
                Swal.fire(
                    'Lala!',
                    "Select Course and User Both",
                    'warning'
                )
                setRequesting(false);
                return;
            }

            const { data } = await api.assignCourseToStudent(assignCourseForm);
            if (data.success) {
                Swal.fire({
                    title: 'Good job!',
                    text: data.message,
                    icon: 'success',
                    showConfirmButton: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                });
                setAssignCourseForm(initialState);
                setRequesting(false);
            }
            else {
                Swal.fire(
                    'Lala!',
                    data.message,
                    'warning'
                );
                setRequesting(false);
            }
        } catch (error) {
            Swal.fire(
                'Failed!',
                "There was a server side error please contact the developer for resolving issue",
                'error'
            );
            setRequesting(false);
        }
    };

    const handleUserChange = (selectedOption) => {
        setAssignCourseForm({ ...assignCourseForm, userId: selectedOption.value });
    }

    const handleCourseChange = (selectedOption) => {
        setAssignCourseForm({ ...assignCourseForm, courseId: selectedOption.value });
    }

    useEffect(() => {
        getCoursesAndUsers();
    }, []);



    return (
        <div style={{
            marginTop: "75px",
        }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                    <Paper elevation={3} variant="elevation" className="m-2 p-2">
                        <Typography variant="h5" className='heading'>Assign Course</Typography>
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Select
                                        options={users}
                                        onChange={handleUserChange}
                                        placeholder="Select User"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Select
                                        options={courses}
                                        onChange={handleCourseChange}
                                        placeholder="Select Course"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button type="submit" size='small' variant="contained" color="primary" className="submit-btn">
                                        Assign Course
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        </div >
    )
}

export default AssignCourse;