import { Button, CircularProgress, Container, Divider, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import React, { useState } from 'react'
import FoundPastPapers from './FoundPastPapers';
import Select from 'react-select';
import * as api from "../../../api/index.js";
import Swal from 'sweetalert2';


function SearchPastPaper({ boardsOptions, classesOptions, yearsOptions, subjectsOptions, loadingOptions }) {
    const [board, setBoard] = useState("");
    const [classs, setClasss] = useState("");
    const [year, setYear] = useState("");
    const [subject, setSubject] = useState("");
    const [foundPastPapersData, setFoundPastPapersData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [zeroResults, setZeroResults] = useState(false);

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            textAlign: 'start',
            color: 'black',
        }),
        control: (provided, state) => ({
            ...provided,
            textAlign: 'start',
            color: 'black',
        }),
        option: (provided, state) => ({
            ...provided,
            textAlign: 'start',
            color: 'black',
        }),
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const formData = {
                boardId: board,
                classId: classs,
                yearId: year,
                subjectId: subject,
            }
            const { data } = await api.searchPastPapers(formData);
            if (data.success) {
                setLoading(false);
                setFoundPastPapersData(data.pastPapers);
                if (data.pastPapers.length === 0) {
                    setZeroResults(true);
                }
                else {
                    setZeroResults(false);
                }
            }
            else {
                setLoading(false);
                Swal.fire({
                    title: 'Failed!',
                    text: data.message,
                    icon: 'warning',
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                title: 'Failed!',
                text: "Failed to search past papers",
                icon: 'warning',
            });
        }
    }
    return (
        <div>
            <Container maxWidth="lg">
                <Paper elevation={12}>
                    <form autoComplete='false' onSubmit={handleSubmit}>
                        <Grid mx={2} my={2} className="p-4">
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography constiant="h6" className="mt-4 heading">
                                        Search Past Papers
                                    </Typography>
                                    {loadingOptions ? (
                                        <div>
                                            <div className='fw-bolder opacity-75'>
                                                Loading Options..
                                            </div>
                                            <div>
                                                <LinearProgress variant="indeterminate" color="info" />
                                            </div>
                                        </div>
                                    ) : null}
                                </Grid>
                            </Grid>
                            <Divider
                                variant='fullWidth'
                            />
                            <br />
                            <Grid container spacing={3} className="text-start">
                                <Grid item xs={12} md={3} lg={3}>
                                    <label className='fw-bolder opacity-50'>Board</label>
                                    <Select
                                        options={boardsOptions}
                                        placeholder="-- Select Board Type --"
                                        styles={customStyles}
                                        onChange={(e) => setBoard(e.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3} lg={3}>
                                    <label className='fw-bolder opacity-50'>Class</label>
                                    <Select
                                        options={classesOptions}
                                        placeholder="-- Select Class --"
                                        styles={customStyles}
                                        onChange={(e) => setClasss(e.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3} lg={3}>
                                    <label className='fw-bolder opacity-50'>Year</label>
                                    <Select
                                        options={yearsOptions}
                                        placeholder="-- Select Year --"
                                        styles={customStyles}
                                        onChange={(e) => setYear(e.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3} lg={3}>
                                    <label className='fw-bolder opacity-50'>Subject</label>
                                    <Select
                                        options={subjectsOptions}
                                        placeholder="-- Select Subject --"
                                        styles={customStyles}
                                        onChange={(e) => setSubject(e.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} className="mt-1" justifyContent={"center"}>
                                <Grid item xs={12} md={6} lg={4}>
                                    <Button type='submit' variant="contained" color="primary" className="mt-4">
                                        Search
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Container>
            {loading && (
                <Container maxWidth="lg">
                    <CircularProgress />
                </Container>
            )
            }
            {zeroResults && (
                <div
                    style={{
                        marginTop: '2.5rem',
                    }}
                >
                    <Paper elevation={12} className="m-3">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography constiant="h6" className="mt-4 heading">
                                    No Papers Found.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            )
            }
            {
                foundPastPapersData.length > 0 && (
                    <Paper elevation={6} className="m-3">
                        <Grid>
                            <FoundPastPapers foundPastPapersData={foundPastPapersData} />
                        </Grid>
                    </Paper>
                )
            }
        </div>
    )
}

export default SearchPastPaper;