import React, { useCallback, useEffect, useState } from 'react'
import Course from './Course';
// import { useSelector } from 'react-redux';
import "./css/MyCourses.css";
import { CircularProgress, Typography } from '@mui/material';
import * as api from "../../../api/index"
import Swal from 'sweetalert2';

function MyCourses({ user, setUser }) {
    const [studentCourses, setStudentCourses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const getCourses = useCallback(
        async () => {
            try {
                setLoading(true);
                const { data } = await api.fetchCoursesForStudent(user.result.id);
                if (data.success) {
                    setStudentCourses(data.studentCourses);
                    setLoading(false);
                    setError(false);
                }
                else {
                    setError(true);
                    Swal.fire(
                        'Error!',
                        data.error,
                        'error'
                    );
                }
            } catch (error) {
                setError(true);
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong please report the issue in support section, Thank you!"
                });
            }
        }, [user.result.id]);

    useEffect(() => {
        getCourses();
    }, [getCourses]);


    return (
        <div>
            <div>
                <Typography className="allemployees__title" variant="h6">Your Courses</Typography>
            </div>
            {error === true && (
                <div className='text-center fw-bold'>
                    Some error occured Please report us your issue in support section.
                </div>
            )}
            {loading === true && error === false && (
                <div className='text-center fw-bold'>
                    <CircularProgress />
                </div>
            )}

            {!error && !loading && (

                <div className='MyCourses__component'>
                    {studentCourses.length <= 0 ? (
                        <div className="" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            You are not studying any course currently
                        </div>
                    ) : (
                        <>
                            {studentCourses.map((course) => {
                                return (
                                    <Course
                                        key={course.id}
                                        course={course}
                                        showButtons={true}
                                    />
                                )
                            })}
                        </>
                    )}
                </div>

            )}

        </div>
    )
}

export default MyCourses;