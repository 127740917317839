import React from 'react'
import { Route, Routes } from 'react-router-dom';
import SearchBooks from './SearchBooks';


function Books() {
  return (
    <div>
      <Routes>
        <Route path="/" exact element={
          <SearchBooks />
        }
        />

      </Routes>
    </div>
  )
}
export default Books;