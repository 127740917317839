import { Button, CircularProgress, Divider, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import React, { useState } from 'react'
import { Container } from 'react-bootstrap';
import Swal from 'sweetalert2';
import * as api from "../../../api/index.js";
import TableAdmissions from './TableAdmissions.js';
import Select from 'react-select';



function SearchAdmissions({ classesOptions, yearsOptions, countriesOptions, provincesOptions, citiesOptions, loadingOptions }) {
    const [classId, setClassId] = useState('');
    const [yearId, setYearId] = useState('');
    const [countryId, setCountryId] = useState('');
    const [provinceId, setProvinceId] = useState('');
    const [cityId, setCityId] = useState('');
    const [loading, setLoading] = useState(false);
    const [zeroResults, setZeroResults] = useState(false);
    const [foundAdmissionsData, setFoundAdmissionsData] = useState([]);


    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            textAlign: 'start',
            color: 'black',
        }),
        control: (provided, state) => ({
            ...provided,
            textAlign: 'start',
            color: 'black',
        }),
        option: (provided, state) => ({
            ...provided,
            textAlign: 'start',
            color: 'black',
        }),
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const formData = {
                classId: classId,
                yearId: yearId,
                countryId: countryId,
                provinceId: provinceId,
                cityId: cityId
            }
            const { data } = await api.searchAdmissionsData(formData);
            if (data.success) {
                setLoading(false);
                setFoundAdmissionsData(data.admissionsData);
                if (data.admissionsData.length === 0) {
                    setZeroResults(true);
                }
                else {
                    setZeroResults(false);
                }
            }
            else {
                setLoading(false);
                Swal.fire({
                    title: 'Failed!',
                    text: data.message,
                    icon: 'warning',
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                title: 'Failed!',
                text: "Failed to Search Admissions data",
                icon: 'warning',
            });
        }
    }


    return (
        <div>
            <Container maxwidth="lg">
                <Paper elevation={12}>
                    <form autoComplete='false' onSubmit={handleSubmit}>
                        <Grid mx={2} my={2} className="p-4">
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography constiant="h6" className="mt-4 heading">
                                        Search for Admissions
                                    </Typography>
                                    {loadingOptions ? (
                                        <div>
                                            <div className='fw-bolder opacity-75'>
                                                Loading Options..
                                            </div>
                                            <div>
                                                <LinearProgress variant="indeterminate" color="info" />
                                            </div>
                                        </div>
                                    ) : null}
                                </Grid>
                            </Grid>
                            <Divider
                                variant='fullWidth'
                            />
                            <br />
                            <Grid
                                container
                                spacing={3}
                                className="text-start"
                                justifyContent={"center"}
                            >
                                <Grid item xs={12} md={4} lg={4}>
                                    <label className='fw-bolder opacity-50'>Class</label>
                                    <Select
                                        options={classesOptions}
                                        placeholder="-- Select Class --"
                                        styles={customStyles}
                                        onChange={(e) => setClassId(e.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                    <label className='fw-bolder opacity-50'>Year</label>
                                    <Select
                                        options={yearsOptions}
                                        placeholder="-- Select Year --"
                                        styles={customStyles}
                                        onChange={(e) => setYearId(e.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                    <label className='fw-bolder opacity-50'>Country</label>
                                    <Select
                                        options={countriesOptions}
                                        placeholder="-- Select Country --"
                                        styles={customStyles}
                                        onChange={(e) => setCountryId(e.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                    <label className='fw-bolder opacity-50'>Province</label>
                                    <Select
                                        options={provincesOptions}
                                        placeholder="-- Select Province --"
                                        styles={customStyles}
                                        onChange={(e) => setProvinceId(e.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                    <label className='fw-bolder opacity-50'>City</label>
                                    <Select
                                        options={citiesOptions}
                                        placeholder="-- Select City --"
                                        styles={customStyles}
                                        onChange={(e) => setCityId(e.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} className="mt-1" justifyContent={"center"}>
                                <Grid item xs={12} md={6} lg={4}>
                                    <Button type='submit' variant="contained" color="primary" className="mt-4">
                                        Search
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Container>
            {loading && (
                <Container maxwidth="lg">
                    <CircularProgress />
                </Container>
            )
            }
            {zeroResults && (
                <div
                    style={{
                        marginTop: '2.5rem',
                    }}
                >
                    <Paper elevation={12} className="m-3">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography constiant="h6" className="mt-4 heading">
                                    No data found.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            )
            }
            {
                foundAdmissionsData.length > 0 && (
                    <Paper elevation={6} className="m-3">
                        <Grid>
                            <TableAdmissions foundAdmissionsData={foundAdmissionsData} />
                        </Grid>
                    </Paper>
                )
            }
        </div>
    )
}

export default SearchAdmissions;