import axios from "axios";
import { URL } from "../constants/actionTypes";

const API = axios.create({ baseURL: URL });

API.interceptors.request.use((req) => {
    if (localStorage.getItem("profile")) {
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem("profile")).token}`;
    }
    return req;
})


// routes for instructor
export const fetchCoursesForInstructor = (userId) => API.get(`/instructor?userId=${userId}`);
export const assignCourseToInstructor = (formData, config) => API.post(`/instructor/assignCourseToInstructor`, formData, config);
export const deleteCourseOfInstructor = (id) => API.delete(`/instructor/deleteCourseOfInstructor?courseId=${id}`);
export const getReviewCoursesOfInstructor = (userId) => API.get(`/instructor/getReviewCoursesOfInstructor?userId=${userId}`);

// routes for videos of instructor courses 
export const getVideosOfCourseOfInstructor = (courseId, userId) => API.get(`/instructor/getVideosOfCourseOfInstructor?courseId=${courseId}&userId=${userId}`);
export const addVideoToCourseOfInstructor = (formData, config) => API.post(`/instructor/addVideoToCourseOfInstructor`, formData, config);
export const deleteCourseVideoOfInstructor = (formData) => API.post(`/instructor/deleteCourseVideoOfInstructor`, formData);
export const updateCourseVideoOfInstructor = (formData, config) => API.post(`/instructor/updateCourseVideoOfInstructor`, formData, config);
export const getAllVideosOfCourseOfInstructor = (courseId, userId) => API.get(`/instructor/getAllVideosOfCourseOfInstructor?courseId=${courseId}&userId=${userId}`);
export const embedYoutubeVideoToCourseOfInstructor = (formData, config) => API.post(`/instructor/embedYoutubeVideoToCourseOfInstructor`, formData, config);
export const embedDriveVideoToCourseOfInstructor = (formData, config) => API.post(`/instructor/embedDriveVideoToCourseOfInstructor`, formData, config);
export const updateInstructorCourseDetails = (formData, config) => API.post(`/instructor/updateInstructorCourseDetails`, formData, config);

// routes for assigning course to student
export const fetchCoursesForStudent = (userId) => API.get(`/student?userId=${userId}`);
export const assignCheckoutCoursesToStudents = (formData, config) => API.post(`/student/checkoutCourses`, formData, config);
export const getVideosOfCourseOfStudent = (courseId) => API.get(`/student/getVideosOfCourseOfStudent?courseId=${courseId}`);
export const getApprovalCoursesOfStudent = (userId) => API.get(`/student/getApprovalCoursesOfStudent?userId=${userId}`);
export const enrollStudentInCourse = (formData) => API.post(`/student/enrollStudentInCourse`, formData);

// Admin Routes
export const getTransactions = () => API.get(`/admin/getTransactions`);
export const getTransactionDetails = (transactionId) => API.get(`/admin/getTransactionDetails?transactionId=${transactionId}`);
export const getTransactionCourses = (formData) => API.post(`/admin/getTransactionCourses`, formData);
export const changeStudentCourseStatusFromTransaction = (formData) => API.post(`/admin/changeStudentCourseStatusFromTransaction`, formData);
export const getStudentsCoursesForAdmin = () => API.get(`/admin/getStudentsCoursesForAdmin`);
export const getInstructorsCoursesForAdmin = () => API.get(`/admin/getInstructorsCoursesForAdmin`);
export const changeInstructorCourseStatus = (formData) => API.post(`/admin/changeInstructorCourseStatus`, formData);
export const postBlogContent = (formData) => API.post(`/blog/postBlogContent`, formData);
export const signUpAdmin = (formData) => API.post("/admin/signUpAdmin", formData);
export const getCoursesAndUsers = () => API.get("/admin/getCoursesAndUsers");
export const getUsers = () => API.get(`/admin/getUsers`);
export const getUserDetails = (id) => API.get(`/admin/getUserDetails?userId=${id}`);
export const assignCourseToStudent = (formData) => API.post(`/admin/assignCourseToStudent`, formData);
export const deleteBlog = (id) => API.delete(`/blog/deleteBlog?blogId=${id}`);
export const changeUserAccountValidityStatus = (formData) => API.post(`/admin/changeUserAccountValidityStatus`, formData);

// rotues allowed for unauthenticated users
export const coursesForNonAuthUsers = () => API.get(`/nonAuthUsers/coursesForNonAuthUsers`);
export const getCourseDetails = (courseId) => API.get(`/nonAuthUsers/getCourseDetails?courseId=${courseId}`);
export const blogsForNonAuthUsers = () => API.get(`/nonAuthUsers/blogsForNonAuthUsers`);
export const blogForNonAuthUsers = (blogId) => API.get(`/nonAuthUsers/blogForNonAuthUsers?blogId=${blogId}`);
export const searchCourses = (searchTerm) => API.get(`/nonAuthUsers/searchCourses?searchTerm=${searchTerm}`);


// routes for authentication
export const signIn = (formData) => API.post("/user/signin", formData);
export const signUp = (formData) => API.post("/user/signup", formData);
export const logout = () => API.post("/user/logout");
export const udpateUserProfile = (formData, config) => API.post("/user/udpateUserProfile", formData, config);
export const udpateUserPassword = (formData) => API.post("/user/udpateUserPassword", formData);


// *************************************    Routes for Management start here   **********************************
export const getManagementDataForAdmin = () => API.get(`/management/getManagementDataForAdmin`);
export const addInstituteSector = (formData) => API.post(`/management/addInstituteSector`, formData);
export const deleteInstituteSector = (id) => API.delete(`/management/deleteInstituteSector?sectorId=${id}`);
export const addInstituteType = (formData) => API.post(`/management/addInstituteType`, formData);
export const deleteInstituteType = (id) => API.delete(`/management/deleteInstituteType?instituteTypeId=${id}`);
export const addInstituteProgram = (formData) => API.post(`/management/addInstituteProgram`, formData);
export const deleteInstituteProgram = (id) => API.delete(`/management/deleteInstituteProgram?programId=${id}`);
export const addCountry = (formData) => API.post(`/management/addCountry`, formData);
export const deleteCountry = (id) => API.delete(`/management/deleteCountry?countryId=${id}`);
export const addProvince = (formData) => API.post(`/management/addProvince`, formData);
export const deleteProvince = (id) => API.delete(`/management/deleteProvince?provinceId=${id}`);
export const addCity = (formData) => API.post(`/management/addCity`, formData);
export const deleteCity = (id) => API.delete(`/management/deleteCity?cityId=${id}`);
export const addGeneralClass = (formData) => API.post(`/management/addGeneralClass`, formData);
export const deleteGeneralClass = (id) => API.delete(`/management/deleteGeneralClass?classId=${id}`);
export const addGeneralBoard = (formData) => API.post(`/management/addGeneralBoard`, formData);
export const deleteGeneralBoard = (id) => API.delete(`/management/deleteGeneralBoard?boardId=${id}`);
export const addGeneralAffiliation = (formData) => API.post(`/management/addGeneralAffiliation`, formData);
export const deleteGeneralAffiliation = (id) => API.delete(`/management/deleteGeneralAffiliation?affiliationId=${id}`);
export const addGeneralYear = (formData) => API.post(`/management/addGeneralYear`, formData);
export const deleteGeneralYear = (id) => API.delete(`/management/deleteGeneralYear?yearId=${id}`);
export const addGeneralSubject = (formData) => API.post(`/management/addGeneralSubject`, formData);
export const deleteGeneralSubject = (id) => API.delete(`/management/deleteGeneralSubject?subjectId=${id}`);


// *************************************    Routes for Institute start here   **********************************
// ***************** admin access
export const getInstitutesDataForAdmin = () => API.get(`/institute/getInstitutesDataForAdmin`);
export const registerNewInstitute = (formData, config) => API.post("/institute/registerNewInstitute", formData, config);
export const addEmailToInstitute = (formData) => API.post("/institute/addEmailToInstitute", formData);
export const addContactToInstitute = (formData) => API.post("/institute/addContactToInstitute", formData);
export const addAddressToInstitute = (formData) => API.post("/institute/addAddressToInstitute", formData);
export const addStaffToInstitute = (formData, config) => API.post("/institute/addStaffToInstitute", formData, config);
export const deleteInstituteEmail = (id) => API.delete(`/institute/deleteInstituteEmail?instituteEmailId=${id}`);
export const deleteInstituteContact = (id) => API.delete(`/institute/deleteInstituteContact?instituteContactId=${id}`);
export const deleteInstituteAddress = (id) => API.delete(`/institute/deleteInstituteAddress?instituteAddressId=${id}`);
export const deleteInstituteStaffMember = (id) => API.delete(`/institute/deleteInstituteStaffMember?instituteStaffMemberId=${id}`);
export const deleteInstitute = (id) => API.delete(`/institute/deleteInstitute?instituteId=${id}`);

export const updateInstituteData = (formData, config) => API.post("/institute/updateInstituteData", formData, config);

// **************** non auth users access
export const searchInstitutes = (formData) => API.post(`/institute/searchInstitutes`, formData);
export const getInstituteDetailsNonAuth = (instituteId) => API.get(`/institute/getInstituteDetailsNonAuth?instituteId=${instituteId}`);
export const getInstitutesManagementOptions = () => API.get(`/institute/getInstitutesManagementOptions`);



// *************************************    Routes for Past Papers start here   **********************************
export const getPastPapersDataForAdmin = () => API.get(`/pastPapers/getPastPapersDataForAdmin`);
export const addPastPaper = (formData, config) => API.post(`/pastPapers/addPastPaper`, formData, config);
export const deletePastPaper = (id) => API.delete(`/pastPapers/deletePastPaper?pastPaperId=${id}`);

// Routes without auth
export const getPastPapersManagementOptions = () => API.get(`/pastPapers/getPastPapersManagementOptions`);
export const searchPastPapers = (formData) => API.post(`/pastPapers/searchPastPapers`, formData);
export const getPastPaperDetails = (pastPaperId) => API.get(`/pastPapers/getPastPaperDetails?pastPaperId=${pastPaperId}`);

export const updatePastPaperData = (formData, config) => API.post("/pastPapers/updatePastPaperData", formData, config);


// *************************************    Routes for Admissions   **********************************
export const getAdmissionsDataForAdmin = () => API.get(`/admissions/getAdmissionsDataForAdmin`);
export const addAdmissionData = (formData, config) => API.post(`/admissions/addAdmissionData`, formData, config);

// Routes without auth
export const getAdmissionsManagementOptions = () => API.get(`/admissions/getAdmissionsManagementOptions`);
export const searchAdmissionsData = (formData) => API.post(`/admissions/searchAdmissionsData`, formData);
export const getAdmissionDetails = (admissionDetailsId) => API.get(`/admissions/getAdmissionDetails?admissionDetailsId=${admissionDetailsId}`);
export const deleteAdmissionData = (id) => API.delete(`/admissions/deleteAdmissionData?admissionDetailsId=${id}`);

export const updateAdmissionData = (formData, config) => API.post("/admissions/updateAdmissionData", formData, config);


// *************************************    Routes for Downloadable materials   **********************************
export const getDownloadsDataForAdmin = () => API.get(`/downloads/getDownloadsDataForAdmin`);

export const addNewBook = (formData, config) => API.post(`/downloads/addNewBook`, formData, config);
export const deleteBook = (id) => API.delete(`/downloads/deleteBook?bookId=${id}`);
export const searchBooksData = (formData) => API.post(`/downloads/searchBooksData`, formData);
export const updateBookData = (formData, config) => API.post("/downloads/updateBookData", formData, config);


export const addNewSoftware = (formData, config) => API.post(`/downloads/addNewSoftware`, formData, config);
export const deleteSofware = (id) => API.delete(`/downloads/deleteSofware?softwareId=${id}`);
export const searchSoftwaresData = (formData) => API.post(`/downloads/searchSoftwaresData`, formData);
export const updateSoftwareData = (formData, config) => API.post("/downloads/updateSoftwareData", formData, config);


// *************************************    Routes for Home Posters   **********************************
export const addNewHomePoster = (formData, config) => API.post(`/homePosters/addNewHomePoster`, formData, config);
export const deleteHomePoster = (id) => API.delete(`/homePosters/deleteHomePoster?posterId=${id}`);
export const changePosterStatus = (formData) => API.post(`/homePosters/changePosterStatus`, formData);

// *************************************    Student Verification Routes   **********************************
export const fetchAllVerifiedStudents = () => API.get(`/studentVerification/fetchAllVerifiedStudents`);
export const checkSingleStudentVerification = (regOrRollNo) => API.get(`/studentVerification/checkSingleStudentVerification?regOrRollNo=${regOrRollNo}`);
export const addStudentVerificationDetails = (formData) => API.post(`/studentVerification/addStudentVerificationDetails`, formData);
export const updateVerificationDetails = (formData) => API.post(`/studentVerification/updateVerificationDetails`, formData);
export const deleteVerificationDetails = (record_id) => API.delete(`/studentVerification/deleteVerificationDetails?record_id=${record_id}`);




