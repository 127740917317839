import React, { useState } from 'react'
import VideoCard from './VideoCard';
import "./css/UploadedVideos.css";
import { Box, Grid, Paper } from '@mui/material';
import PlayedVideo from './PlayedVideo';
import Swal from 'sweetalert2';

function UploadedVideos({ uploadedVideos, setUploadedVideos, youtubeEmbeddedVideos, setYoutubeEmbeddedVideos, driveEmbeddedVideos, setDriveEmbeddedVideos, uploadedVideosCount, driveVideosCount, youtubeVideosCount, loading }) {
    const [playedVideo, setPlayedVideo] = useState(null);
    const [playYoutubeEmbedded, setPlayYoutubeEmbedded] = useState(false);
    const [playUploaded, setPlayUploaded] = useState(false);
    const [playDriveEmbedded, setPlayDriveEmbedded] = useState(false);
    let videoIndex = -1;

    const playVideo = (video, uploaded, youtubeEmbedded, driveEmbedded) => {
        try {
            if (uploaded === true) {
                setPlayYoutubeEmbedded(false);
                setPlayDriveEmbedded(false);
                setPlayUploaded(true);
            }
            else if (youtubeEmbedded === true) {
                setPlayUploaded(false);
                setPlayDriveEmbedded(false);
                setPlayYoutubeEmbedded(true);
            }
            else if (driveEmbedded === true) {
                setPlayUploaded(false);
                setPlayYoutubeEmbedded(false);
                setPlayDriveEmbedded(true);
            }
            setPlayedVideo(video);
        }
        catch (error) {
            console.log(error);
            Swal.fire({
                icon: "error",
                title: "failed to load video",
                text: "Failed to laod video please report the issue to us. Thank You!",
            });
            setPlayedVideo(null);
        }
    }


    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8} lg={8}>
                    <Paper elevation={5}>
                        {!playedVideo ? (
                            <div className="fw-bolder" style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "70vh" }}>
                                Click on video to play
                            </div>
                        ) : (
                            <>
                                <PlayedVideo video={playedVideo} playUploaded={playUploaded} playYoutubeEmbedded={playYoutubeEmbedded} playDriveEmbedded={playDriveEmbedded} />
                            </>
                        )}
                    </Paper>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                    <Paper elevation={10}>


                        <Box style={{ maxHeight: '70vh', overflow: 'auto', padding: "5px" }}>

                            <div className='card p-1 m-1'>
                                <div className='card-header'>
                                    <h6 className='fw-bold'>Course Videos</h6>
                                </div>
                                <div className='card-body'>
                                    {loading && (
                                        <button className="btn btn-primary m-2 p-2" type="button" disabled>
                                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                            &nbsp; Loading videos...
                                        </button>
                                    )}

                                    {uploadedVideosCount === 0 ? (
                                        <>
                                        </>
                                    ) : (
                                        <>
                                            {uploadedVideos.map((video) => {
                                                videoIndex += 1;
                                                return (
                                                    <VideoCard
                                                        key={videoIndex}
                                                        video={video}
                                                        playVideo={playVideo}
                                                        index={videoIndex}
                                                        driveEmbedded={false}
                                                        youtubeEmbedded={false}
                                                        uploaded={true}
                                                    />
                                                );
                                            }
                                            )}
                                        </>
                                    )}

                                    {youtubeVideosCount === 0 ? (
                                        <>
                                        </>
                                    ) : (
                                        <>
                                            {youtubeEmbeddedVideos.map((video) => {
                                                videoIndex += 1;
                                                return (
                                                    <VideoCard
                                                        key={videoIndex}
                                                        video={video}
                                                        playVideo={playVideo}
                                                        index={videoIndex}
                                                        uploaded={false}
                                                        youtubeEmbedded={true}
                                                        driveEmbedded={false}
                                                    />
                                                )
                                            })}
                                        </>
                                    )}


                                    {driveVideosCount === 0 ? (
                                        <>
                                        </>
                                    ) : (
                                        <>
                                            {driveEmbeddedVideos.map((video) => {
                                                videoIndex += 1;
                                                return (
                                                    <VideoCard
                                                        key={videoIndex}
                                                        video={video}
                                                        playVideo={playVideo}
                                                        index={videoIndex}
                                                        uploaded={false}
                                                        youtubeEmbedded={false}
                                                        driveEmbedded={true}
                                                    />
                                                )
                                            })}
                                        </>
                                    )}

                                    {uploadedVideosCount === 0 && youtubeVideosCount === 0 && driveVideosCount === 0 && !loading ? (
                                        <div className="fw-bolder">
                                            No videos uploaded yet.
                                        </div>
                                    ) : (
                                        <>
                                        </>
                                    )}


                                </div>
                            </div>

                        </Box>

                    </Paper>
                </Grid>

            </Grid>
        </div>
    )
}

export default UploadedVideos;
















// <div>
//     <Grid container spacing={2}>
//         <Grid item xs={12} md={8} lg={8}>
//             <Paper elevation={5}>
//                 {!playedVideo ? (
//                     <div className="fw-bolder" style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "70vh" }}>
//                         Click on video to play
//                     </div>
//                 ) : (
//                     <>
//                         <PlayedVideo video={playedVideo} playUploaded={playUploaded} playYoutubeEmbedded={playYoutubeEmbedded} playDriveEmbedded={playDriveEmbedded} />
//                     </>
//                 )}
//             </Paper>
//         </Grid>

//         <Grid item xs={12} md={4} lg={4}>
//             <Paper elevation={10}>


//                 <Box style={{ maxHeight: '70vh', overflow: 'auto', padding: "5px" }}>

//                     <div className='card p-1 m-1'>
//                         <div className='card-header'>
//                             <h6 className='fw-bold'>Uploaded videos</h6>
//                         </div>
//                         <div className='card-body'>

//                             {uploadedVideosCount === 0 ? (
//                                 <div className="fw-bolder" style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "green" }}>
//                                     No uploaded videos yet.
//                                 </div>
//                             ) : (
//                                 <>
//                                     {uploadedVideos.map((video, index) => {
//                                         return (
//                                             <VideoCard
//                                                 key={index}
//                                                 video={video}
//                                                 playVideo={playVideo}
//                                                 index={index}
//                                                 driveEmbedded={false}
//                                                 youtubeEmbedded={false}
//                                                 uploaded={true}
//                                             />
//                                         )
//                                     }
//                                     )}
//                                 </>
//                             )}


//                         </div>
//                     </div>

//                     <div className='card p-1 m-1'>
//                         <div className='card-header'>
//                             <h6 className='fw-bold'>Youtube Embedded videos</h6>
//                         </div>
//                         <div className='card-body'>

//                             {youtubeVideosCount === 0 ? (
//                                 <div className="fw-bolder" style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "green" }}>
//                                     No youtube embedded videos yet.
//                                 </div>
//                             ) : (
//                                 <>
//                                     {youtubeEmbeddedVideos.map((video, index) => {
//                                         return (
//                                             <VideoCard
//                                                 key={index}
//                                                 video={video}
//                                                 playVideo={playVideo}
//                                                 index={index}
//                                                 uploaded={false}
//                                                 youtubeEmbedded={true}
//                                                 driveEmbedded={false}
//                                             />
//                                         )
//                                     })}
//                                 </>
//                             )}

//                         </div>
//                     </div>

//                     <div className='card p-1 m-1'>
//                         <div className='card-header'>
//                             <h6 className='fw-bold'>Drive Embedded videos</h6>
//                         </div>
//                         <div className='card-body'>

//                             {driveVideosCount === 0 ? (
//                                 <div className="fw-bolder" style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "green" }}>
//                                     No drive embedded videos yet.
//                                 </div>
//                             ) : (
//                                 <>
//                                     {driveEmbeddedVideos.map((video, index) => {
//                                         return (
//                                             <VideoCard
//                                                 key={index}
//                                                 video={video}
//                                                 playVideo={playVideo}
//                                                 index={index}
//                                                 uploaded={false}
//                                                 youtubeEmbedded={false}
//                                                 driveEmbedded={true}
//                                             />
//                                         )
//                                     })}
//                                 </>
//                             )}

//                         </div>
//                     </div>

//                 </Box>

//             </Paper>
//         </Grid>

//     </Grid>
// </div>