import React from 'react';
import { Container } from 'react-bootstrap';
// import WelcomeHome from './WelcomeHome';

function HomeMain({ user, cartItems, setCartItems }) {

    return (
        <>
            {/* <WelcomeHome /> */}
            <Container>
                <h3>This is the Dashboard i have been talking to you about.</h3>
            </Container>
        </>
    )
}

export default HomeMain;
