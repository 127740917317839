import { Button, Grid, Paper, Typography } from '@mui/material';
import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import poster from "../../../images/poster.jpg";
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import * as api from "../../../api/index.js";
import HomePostersList from './HomePostersList';

function HomePosters({ homePostersData, setHomePostersData }) {
    const [poster1Image, setPoster1Image] = useState(null);
    const [poster2Image, setPoster2Image] = useState(null);
    const [poster3Image, setPoster3Image] = useState(null);
    const [isPosterActive, setIsPosterActive] = useState(1);
    const [requesting, setRequesting] = useState(false);

    const [poster1ImagePreview, setPoster1ImagePreview] = useState(null);
    const [poster2ImagePreview, setPoster2ImagePreview] = useState(null);
    const [poster3ImagePreview, setPoster3ImagePreview] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            if (requesting) {
                Swal.fire({
                    title: 'Please wait',
                    text: 'We are adding your institute',
                    icon: 'info',
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: true,
                });
                return;
            }

            setRequesting(true);


            if (poster1Image === null || poster2Image === null || poster3Image === null) {
                Swal.fire({
                    title: 'Error!',
                    text: 'Please Select all 3 images',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                });
                setRequesting(false);
                return;
            } else {

                const formData = new FormData();
                formData.append("poster1Image", poster1Image);
                formData.append("poster2Image", poster2Image);
                formData.append("poster3Image", poster3Image);
                formData.append("isPosterActive", isPosterActive);

                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                };


                const { data } = await api.addNewHomePoster(formData, config);

                if (data.success) {
                    Swal.fire({
                        title: 'Success!',
                        text: data.message,
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    });

                    setPoster1Image(null);
                    setPoster2Image(null);
                    setPoster3Image(null);
                    setPoster1ImagePreview(null);
                    setPoster2ImagePreview(null);
                    setPoster3ImagePreview(null);
                    setRequesting(false);

                    setHomePostersData([...homePostersData, data.newPoster]);
                }
                else {
                    Swal.fire({
                        title: 'Error!',
                        text: data.message,
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    });
                    setRequesting(false);
                }
            }
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: "Server side error, please contact website king the K Ahmadi !",
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            setRequesting(false);
        }

    }

    const onSelectposter1Image = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setPoster1Image(null)
            return
        }
        setPoster1Image(e.target.files[0]);
        // setUserProfileImage(URL.createObjectURL(e.target.files[0]));
        setPoster1ImagePreview(URL.createObjectURL(e.target.files[0]));
    }

    const onSelectposter2Image = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setPoster1Image(null)
            return
        }
        setPoster2Image(e.target.files[0]);
        setPoster2ImagePreview(URL.createObjectURL(e.target.files[0]));
    }

    const onSelectposter3Image = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setPoster3Image(null)
            return
        }
        setPoster3Image(e.target.files[0]);
        setPoster3ImagePreview(URL.createObjectURL(e.target.files[0]));
    }


    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Card>
                        <Card.Header>
                            <Typography className="heading" variant="h4">Home Posters</Typography>
                        </Card.Header>
                        <Card.Body>
                            <form onSubmit={handleSubmit} autoComplete={"false"}>
                                <Grid container spacing={3} justifyContent="center">
                                    <Grid item xs={12} md={4} lg={4}>
                                        <img src={poster1ImagePreview ? poster1ImagePreview : poster}
                                            className="mx-auto"
                                            alt="Profile ..."
                                            style={{
                                                borderRadius: "50%",
                                                width: "150px",
                                                height: "150px",
                                                cursor: "pointer",
                                                border: "2px solid black",
                                            }}
                                            onClick={() => {
                                                document.getElementById("poster1Image").click();
                                            }}
                                            title="Click to Select an Image"
                                        />
                                        <input type="file" id="poster1Image" style={{ display: "none" }} onChange={onSelectposter1Image} />
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={4}>
                                        <img src={poster2ImagePreview ? poster2ImagePreview : poster}
                                            className="mx-auto"
                                            alt="Profile ..."
                                            style={{
                                                borderRadius: "50%",
                                                width: "150px",
                                                height: "150px",
                                                cursor: "pointer",
                                                border: "2px solid black",
                                            }}
                                            onClick={() => {
                                                document.getElementById("poster2Image").click();
                                            }}
                                            title="Click to Select an Image"
                                        />
                                        <input type="file" id="poster2Image" style={{ display: "none" }} onChange={onSelectposter2Image} />
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={4}>
                                        <img src={poster3ImagePreview ? poster3ImagePreview : poster}
                                            className="mx-auto"
                                            alt="Profile ..."
                                            style={{
                                                borderRadius: "50%",
                                                width: "150px",
                                                height: "150px",
                                                cursor: "pointer",
                                                border: "2px solid black",
                                            }}
                                            onClick={() => {
                                                document.getElementById("poster3Image").click();
                                            }}
                                            title="Click to Select an Image"
                                        />
                                        <input type="file" id="poster3Image" style={{ display: "none" }} onChange={onSelectposter3Image} />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Form.Select
                                            size="sm"
                                            onChange={(e) => {
                                                setIsPosterActive(e.target.value);
                                            }}
                                        >
                                            <option value={1}>Active</option>
                                            <option value={0}>Inactive</option>
                                        </Form.Select>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                        >SAVE</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Card.Body>
                    </Card>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    {
                        homePostersData.length > 0 && (
                            <Paper elevation={6}>
                                <Grid>
                                    <HomePostersList
                                        homePostersData={homePostersData}
                                        setHomePostersData={setHomePostersData}
                                    />
                                </Grid>
                            </Paper>
                        )
                    }
                </Grid>
            </Grid>
        </div>
    )
}

export default HomePosters;