import { CircularProgress, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import * as api from "../../../api/index.js";
import Course from './Course';

function ApprovalCourses({ user, setUser }) {
    const [approvalCourses, setApprovalCourses] = React.useState([]);
    const [loading, setLoading] = useState(true);

    const getApprovalCourses = useCallback(
        async () => {
            try {
                const { data } = await api.getApprovalCoursesOfStudent(user.result.id);
                if (data.success) {
                    setApprovalCourses(data.courses);
                    setLoading(false);
                }
                else {
                    Swal.fire(
                        'Error!',
                        data.error,
                        'error'
                    )
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                })
            }

        }, [user.result.id]
    );

    useEffect(() => {
        getApprovalCourses();
        return () => {
            setApprovalCourses([]);
        }
    }, [getApprovalCourses]);

    return (
        <div>
            <div>
                <Typography variant="h5">Your Courses in State of Approval.</Typography>
            </div>
            <div className='MyCourses__component'>
                {loading ? (
                    <div className="" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        {approvalCourses.map((course) => {
                            return (
                                <Course
                                    key={course.id}
                                    course={course}
                                    showButtons={false}
                                />
                            )
                        })}
                    </>
                )}
            </div>
            <div>
                {
                    approvalCourses.length === 0 && (
                        <Typography variant="h6" className='mt-4'>No Courses of yours are in Approval State</Typography>
                    )
                }
            </div>
        </div>
    )
}

export default ApprovalCourses;


