import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import * as api from '../../../api/index';
import Swal from 'sweetalert2';
import SearchPastPaper from './SearchPastPaper';
import PastPaperDetails from './PastPaperDetails';

function PastPapers() {
    const [boardsOptions, setBoardsOptions] = useState([]);
    const [classesOptions, setClassesOptions] = useState([]);
    const [yearsOptions, setYearsOptions] = useState([]);
    const [subjectsOptions, setSubjectsOptions] = useState([]);
    const [loadingOptions, setLoadingOptions] = useState(false);


    const fetchOptions = async () => {
        setLoadingOptions(true);
        try {
            const { data } = await api.getPastPapersManagementOptions();
            if (data.success) {
                setBoardsOptions(data.generalBoardsOptions);
                setClassesOptions(data.generalClassesOptions);
                setYearsOptions(data.generalYearsOptions);
                setSubjectsOptions(data.generalSubjectsOptions);
                setLoadingOptions(false);
            }
            else {
                Swal.fire({
                    title: 'Error',
                    text: data.error,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchOptions();
    }, []);

    return (
        <div>
            <Routes>
                <Route path="/" exact element={<SearchPastPaper
                    boardsOptions={boardsOptions}
                    classesOptions={classesOptions}
                    yearsOptions={yearsOptions}
                    subjectsOptions={subjectsOptions}
                    loadingOptions={loadingOptions}
                />} />
                <Route path="/pastPaperDetails/:pastPaperId" element={<PastPaperDetails />} />
            </Routes>
        </div>
    )
}

export default PastPapers;