import { Button, Grid, Paper, TextField, Typography } from '@mui/material'
import React from 'react';

function EditVerificationDetailsForm({
  editVerifiedStudentData,
  setEditVerifiedStudentData,
  handleEditStudentVerificationSubmit,
  handleEditStudentVerificationFormChange
}) {

  return (
    <div>
      <Paper style={{ padding: '15px' }} elevation={8}>
        <form onSubmit={handleEditStudentVerificationSubmit} id="editPastPaper">
          <Typography className="heading" variant="h4">Update Record</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                label="Student Name"
                value={editVerifiedStudentData.student_name}
                name="student_name"
                onChange={handleEditStudentVerificationFormChange}
                size="small"
                inputProps={{ maxLength: 100 }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                label="Father Name"
                value={editVerifiedStudentData.father_name}
                name="father_name"
                onChange={handleEditStudentVerificationFormChange}
                size="small"
                inputProps={{ maxLength: 100 }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                label="Roll No"
                value={editVerifiedStudentData.roll_no}
                name="roll_no"
                onChange={handleEditStudentVerificationFormChange}
                size="small"
                inputProps={{ maxLength: 100 }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                label="Course"
                value={editVerifiedStudentData.course}
                name="course"
                onChange={handleEditStudentVerificationFormChange}
                size="small"
                inputProps={{ maxLength: 100 }}
                fullWidth
              />

            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                label="Issue Date"
                value={editVerifiedStudentData.issue_date}
                name="issue_date"
                onChange={handleEditStudentVerificationFormChange}
                size="small"
                type={'date'}
                inputProps={{ maxLength: 100 }}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                label="Registeration ID"
                value={editVerifiedStudentData.registeration_id}
                name="registeration_id"
                onChange={handleEditStudentVerificationFormChange}
                size="small"
                inputProps={{ maxLength: 100 }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                label="Session"
                value={editVerifiedStudentData.session_name}
                name="session_name"
                onChange={handleEditStudentVerificationFormChange}
                size="small"
                inputProps={{ maxLength: 100 }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                label="Grade"
                value={editVerifiedStudentData.grade}
                name="grade"
                onChange={handleEditStudentVerificationFormChange}
                size="small"
                inputProps={{ maxLength: 100 }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                label="Obtained Marks"
                value={editVerifiedStudentData.obtained_marks}
                name="obtained_marks"
                onChange={handleEditStudentVerificationFormChange}
                size="small"
                inputProps={{ maxLength: 100 }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                label="Total Marks"
                value={editVerifiedStudentData.total_marks}
                name="total_marks"
                onChange={handleEditStudentVerificationFormChange}
                size="small"
                inputProps={{ maxLength: 100 }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                label="Duration"
                value={editVerifiedStudentData.duration}
                name="duration"
                onChange={handleEditStudentVerificationFormChange}
                size="small"
                inputProps={{ maxLength: 100 }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                label="Percentage"
                value={editVerifiedStudentData.percentage}
                name="percentage"
                onChange={handleEditStudentVerificationFormChange}
                size="small"
                inputProps={{ maxLength: 100 }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                label="Result"
                value={editVerifiedStudentData.result}
                name="result"
                onChange={handleEditStudentVerificationFormChange}
                size="small"
                inputProps={{ maxLength: 100 }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                label="Institute"
                value={editVerifiedStudentData.institute}
                name="institute"
                onChange={handleEditStudentVerificationFormChange}
                size="small"
                inputProps={{ maxLength: 100 }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1} justifyContent="center">
            <Grid item xs={12} md={4} lg={3}>
              <hr />
              <Button variant="contained" color="primary" type="submit" fullWidth>
                UPDATE
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  )
}

export default EditVerificationDetailsForm;