import { Box, Grid, Paper } from '@mui/material';

import { CircularProgress, Button as MButton } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { COURSE_PROMOTION_VIDEO_URL, COURSE_THUMBNAIL_URL } from '../../constants/actionTypes';
import "./css/CourseDetails.css";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LanguageIcon from '@mui/icons-material/Language';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LocalLibraryRoundedIcon from '@mui/icons-material/LocalLibraryRounded';
import PersonSearchRoundedIcon from '@mui/icons-material/PersonSearchRounded';
import LabelImportantRoundedIcon from '@mui/icons-material/LabelImportantRounded';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import Swal from 'sweetalert2';
import * as api from "../../api/index";



function CourseDetails({ user, setUser, cartItems, setCartItems }) {
    const [openLearn, setOpenLearn] = useState(false);
    const [openPrerequisites, setOpenPrerequisites] = useState(false);
    const [openCourseFor, setOpenCourseFor] = useState(false);
    const [enrollingRequest, setEnrollingRequest] = useState(false);

    const handleOpenLearnChange = () => {
        setOpenLearn(!openLearn);
    };
    const handleOpenPrerequisitesChange = () => {
        setOpenPrerequisites(!openPrerequisites);
    };
    const handleOpenCourseForChange = () => {
        setOpenCourseFor(!openCourseFor);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    // backend related code starts here
    const [course, setCourse] = useState([]);
    const [courseVideos, setCourseVideos] = useState([]);
    const [isInCart, setIsInCart] = useState(false);
    let { courseId } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const checkCourseInCartItems = (clickedCourse) => {
        const exist = cartItems.find((course) => course.id == clickedCourse.id);
        if (exist === undefined) {
            setCartItems([...cartItems, { ...course }]);
        }
        else {
            setCartItems(
                cartItems.filter((course) =>
                    course.id !== clickedCourse.id
                )
            )
        }
    }

    const checkInCart = useCallback(
        (clickedCourse) => {
            if (clickedCourse === undefined) {
                return false;
            }

            const exist = cartItems.find((course) => course.id == clickedCourse.id);
            if (exist === undefined) {
                return false;
            }
            else {
                return true;
            }
        }, [cartItems]
    )

    const getCourse = useCallback(async () => {
        const { data } = await api.getCourseDetails(courseId);
        if (data.success) {
            setCourse(data.course);
            setCourseVideos(data.courseVideos);
            setIsInCart(checkInCart(data.course));
            setLoading(false);
            setError(false);
        }
        else {
            Swal.fire({
                title: 'Error',
                text: data.error,
                icon: 'error',
                confirmButtonText: 'Ok'
            });

            setLoading(false);
            setError(true);

        }
    }, [checkInCart, courseId]);

    const enrollInCourseNow = async (course) => {
        setEnrollingRequest(true);
        if (!user || !user.result) {
            Swal.fire({
                title: 'Warning',
                text: 'Please login to enroll in this course',
                icon: 'warning',
                confirmButtonText: 'Ok'
            });
            setEnrollingRequest(false);
            return;
        }
        if (enrollingRequest) {
            Swal.fire({
                title: 'Error',
                text: "Please wait while we are enrolling you in this course",
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            return;
        }
        if (course.coursePricing <= 0) {
            const formData = {
                courseId: course.id,
                userId: user.result.id
            }
            const { data } = await api.enrollStudentInCourse(formData);
            if (data.success) {
                Swal.fire({
                    title: 'Success',
                    text: data.message,
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    allowEnterKey: false,
                    allowOutsideClick: false
                });
                setEnrollingRequest(false);
            }
            else {
                Swal.fire({
                    title: 'Failed',
                    text: data.error,
                    icon: 'info',
                    confirmButtonText: 'Ok',
                    allowEnterKey: false,
                    allowOutsideClick: false,
                });
                setEnrollingRequest(false);
            }
        }
        else {
            Swal.fire({
                title: 'Error',
                text: 'You need to pay for this course',
                icon: 'error',
                confirmButtonText: 'Ok',
                allowEnterKey: false,
                allowOutsideClick: false,
            });
            setEnrollingRequest(false);
        }
    }


    useEffect(() => {
        getCourse();
    }, [getCourse]);



    return (
        <div>
            {loading ? (
                <div
                    className="d-flex justify-content-center mt-4"
                >
                    <CircularProgress />
                </div>
            ) : null}
            {error ? <div>Failed to load data of course, course my no longer exist.</div> : null}
            {course &&
                error === false &&
                loading === false &&
                (
                    <>
                        <Paper elevation={3}
                            style={{ backgroundColor: 'rgb(51 132 253)', color: '#fff', padding: '10px' }}
                        // style={{ backgroundColor: '#1c1d1f', color: '#fff', padding: '10px' }}
                        >
                            <Grid container spacing={2} justifyContent="flex-start" p={4}>

                                <Grid item xs={12} md={8} lg={8}>

                                    <Grid item xs={12} md={6} lg={6}
                                        style={{
                                            textAlign: "start"
                                        }}
                                    >
                                        <h3>{course.courseTitle.slice(0, 100)}</h3>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}
                                        style={{
                                            textAlign: "start"
                                        }}
                                    >
                                        <h6>{course.courseSubTitle}</h6>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} justifyContent="flex-start"
                                        style={{
                                            textAlign: "start"
                                        }}
                                        mt={4}
                                    >
                                        <div className="course_details_top_icons">
                                            <div className="course_details_top_icons_div">
                                                <AccountCircleIcon /> <b><u>{`${course.firstName} ${course.lastName}`}</u></b>
                                            </div>

                                            <div className="course_details_top_icons_div">
                                                <BarChartRoundedIcon /> <b>{course.courseLevel}</b>
                                            </div>

                                            <div className="course_details_top_icons_div">
                                                <LanguageIcon /> <b>{course.courseLanguage}</b>
                                            </div>

                                            <div className="course_details_top_icons_div">
                                                <PriceChangeIcon /> <b>Rs: {course.coursePricing}/-</b>
                                            </div>

                                        </div>

                                    </Grid>

                                </Grid>

                                <Grid item xs={12} md={4} lg={4} >

                                    <Paper elevation={3}
                                        style={{
                                            maxWidth: "350px"
                                        }}
                                    >
                                        <Grid item xs={12} md={12} lg={12} p={1}>
                                            <img src={`${COURSE_THUMBNAIL_URL}${course.courseThumbnail}`} alt="Course Thumbnail"
                                                style={{
                                                    width: "100%",
                                                    height: "200px",
                                                    borderRadius: "5px"
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12} lg={12} p={1}>

                                            <Grid container spacing={0.5} justifyContent="space-between">
                                                <Grid item xs={12} md={12} lg={12}>
                                                    {course.coursePricing > 0 ? (
                                                        <h5>Rs: {course.coursePricing}/-</h5>
                                                    ) : (
                                                        <h5>Free</h5>
                                                    )}
                                                </Grid>
                                                {course.coursePricing > 0 ? (
                                                    <Grid item xs={12} md={12} lg={12}>
                                                        <Grid item xs={12} md={12} lg={12}>
                                                            <MButton variant="contained" size='small' onClick={() => checkCourseInCartItems(course)} color={isInCart ? "success" : "secondary"} fullWidth>
                                                                {isInCart ? "REMOVE FROM CART" : "ADD TO CART"}
                                                            </MButton>
                                                        </Grid>
                                                    </Grid>
                                                ) : (
                                                    <Grid item xs={12} md={12} lg={12}>
                                                        <MButton
                                                            variant="contained"
                                                            size='small'
                                                            onClick={() => enrollInCourseNow(course)}
                                                            color="primary"
                                                            fullWidth
                                                        >
                                                            Enroll Now
                                                        </MButton>
                                                    </Grid>
                                                )}

                                            </Grid>

                                        </Grid>

                                    </Paper>


                                </Grid>

                            </Grid>
                        </Paper>

                        <Container fluid className='mt-2 mb-2'>

                            <Grid container spacing={1}>

                                <Grid item xs={12} md={6} lg={6}>

                                    <Paper elevation={3}>
                                        <Tabs
                                            defaultActiveKey="curriculum"
                                            transition={false}
                                            id="noanim-tab-example"
                                            className="mb-3"
                                        >
                                            <Tab eventKey="curriculum" title="Curriculum" className='p-2'>


                                                <Paper elevation={3}>
                                                    <h6 className='heading p-2'>Course Curriculum</h6>

                                                    <Box style={{ maxHeight: '80vh', overflow: 'auto' }}>

                                                        {courseVideos.length > 0 ? (
                                                            courseVideos.map((video, index) => (
                                                                <Accordion key={index}>
                                                                    <AccordionSummary
                                                                        expandIcon={<ExpandMoreIcon />}
                                                                        aria-controls="panel1a-content"
                                                                        id="panel1a-header"
                                                                    >
                                                                        <h6
                                                                            className='fw-bolder opacity-100'
                                                                        >
                                                                            <b>{++index}:</b> {video.videoTitle?.slice(0, 50)}
                                                                        </h6>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails
                                                                        style={{
                                                                            display: "flex",
                                                                            flexDirection: "row",
                                                                            justifyContent: "space-between"
                                                                        }}
                                                                        className='opacity-75'
                                                                    >
                                                                        <h6>
                                                                            {video.videoDescription}
                                                                        </h6>
                                                                        <div>
                                                                            <LockRoundedIcon />
                                                                        </div>
                                                                    </AccordionDetails>
                                                                </Accordion>

                                                            ))
                                                        ) : (
                                                            <div>
                                                                <h5>No course content uploaded  yet!</h5>
                                                            </div>
                                                        )}

                                                    </Box>

                                                </Paper>

                                            </Tab>
                                            <Tab eventKey="description" title="Description">
                                                <div
                                                    style={{
                                                        textAlign: "start",
                                                        padding: "10px"
                                                    }}
                                                    className="courseDescription"
                                                    dangerouslySetInnerHTML={{ __html: course.courseDescription }} />
                                            </Tab>
                                        </Tabs>
                                    </Paper>

                                </Grid>


                                <Grid item xs={12} md={6} lg={6}>


                                    <Grid item xs={12} md={12} lg={12} p={1}>

                                        <Paper elevation={3}>
                                            <video controls controlsList="nodownload"
                                                style={{
                                                    width: "100%",
                                                    height: "auto",
                                                    borderRadius: "5px"
                                                }}
                                            >
                                                <source src={`${COURSE_PROMOTION_VIDEO_URL}${course.coursePromotionVideo}`} type="video/mp4" />
                                            </video>
                                        </Paper>

                                    </Grid>


                                    <Grid item xs={12} md={12} lg={12} p={1}>

                                        <Paper elevation={3}>

                                            <List
                                                sx={{ width: '100%', bgcolor: 'background.paper' }}
                                                component="nav"
                                                aria-labelledby="nested-list-subheader"
                                            >
                                                <ListItemButton onClick={handleOpenLearnChange}>
                                                    <ListItemIcon>
                                                        <LocalLibraryRoundedIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary="What will Students Learn" />
                                                    {openLearn ? <ExpandLess /> : <ExpandMore />}
                                                </ListItemButton>
                                                <Collapse in={openLearn} timeout="auto" unmountOnExit>
                                                    <p className='text-start p-2'>
                                                        {course.whatWillStudentLearn}
                                                    </p>
                                                </Collapse>
                                            </List>

                                            <List
                                                sx={{ width: '100%', bgcolor: 'background.paper' }}
                                                component="nav"
                                                aria-labelledby="nested-list-subheader"
                                            >
                                                <ListItemButton onClick={handleOpenPrerequisitesChange}>
                                                    <ListItemIcon>
                                                        <LabelImportantRoundedIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary="What are Prerequisites" />
                                                    {openPrerequisites ? <ExpandLess /> : <ExpandMore />}
                                                </ListItemButton>
                                                <Collapse in={openPrerequisites} timeout="auto" unmountOnExit>
                                                    <p className='text-start p-2'>
                                                        {course.whatArePrerequisites}
                                                    </p>
                                                </Collapse>
                                            </List>

                                            <List
                                                sx={{ width: '100%', bgcolor: 'background.paper' }}
                                                component="nav"
                                                aria-labelledby="nested-list-subheader"
                                            >
                                                <ListItemButton onClick={handleOpenCourseForChange}>
                                                    <ListItemIcon>
                                                        <PersonSearchRoundedIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Who is this course for" />
                                                    {openCourseFor ? <ExpandLess /> : <ExpandMore />}
                                                </ListItemButton>
                                                <Collapse in={openCourseFor} timeout="auto" unmountOnExit>
                                                    <p className='text-start p-2'>
                                                        {course.whoIsThisCourseFor}
                                                    </p>
                                                </Collapse>
                                            </List>


                                        </Paper>


                                    </Grid>

                                </Grid>


                            </Grid>

                        </Container>

                    </>
                )}

        </div >
    )
}

export default CourseDetails;








// import { CircularProgress, Button as MButton } from '@mui/material';
// import React, { useCallback, useEffect, useState } from 'react';
// import { Button, Card, Col, Container, Row } from 'react-bootstrap';
// import { useNavigate, useParams } from 'react-router-dom';
// import { COURSE_PROMOTION_VIDEO_URL, COURSE_THUMBNAIL_URL } from '../../constants/actionTypes';
// import "./css/CourseDetails.css";
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import AccessTimeIcon from '@mui/icons-material/AccessTime';
// import LanguageIcon from '@mui/icons-material/Language';
// import PriceChangeIcon from '@mui/icons-material/PriceChange';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import Swal from 'sweetalert2';
// import * as api from "../../api/index";


// function CourseDetails({ cartItems, setCartItems }) {
//     window.scrollTo(0, 0);
//     const [course, setCourse] = useState(null);
//     const [isInCart, setIsInCart] = useState(false);
//     let { courseId } = useParams();
//     const navigate = useNavigate();

//     const checkCourseInCartItems = (clickedCourse) => {
//         const exist = cartItems.find((course) => course.id == clickedCourse.id);
//         if (exist === undefined) {
//             setCartItems([...cartItems, { ...course }]);
//         }
//         else {
//             setCartItems(
//                 cartItems.filter((course) =>
//                     course.id !== clickedCourse.id
//                 )
//             )
//         }
//     }

//     const checkInCart = useCallback(
//         (clickedCourse) => {
//             if (clickedCourse === undefined) {
//                 return false;
//             }

//             const exist = cartItems.find((course) => course.id == clickedCourse.id);
//             if (exist === undefined) {
//                 return false;
//             }
//             else {
//                 return true;
//             }
//         }, [cartItems]
//     )

//     const getCourse = useCallback(async () => {
//         const { data } = await api.getCourseDetails(courseId);
//         if (data.success) {
//             setCourse(data.course);
//             setIsInCart(checkInCart(data.course));
//         }
//         else {
//             Swal.fire({
//                 title: 'Error',
//                 text: data.error,
//                 icon: 'error',
//                 confirmButtonText: 'Ok'
//             })
//         }
//     }, [checkInCart, courseId]);


//     useEffect(() => {
//         getCourse();
//     }, [getCourse]);


//     return (
//         <div
//         // style={{ marginTop: "55px" }}
//         >
//             {!course ? (
//                 <div className='mt-2'>
//                     <CircularProgress />
//                 </div>
//             ) : (
//                 <>
//                     <div className='bg-light'>
//                         <Button variant="info mx-1 my-1 btn-sm" onClick={() => navigate(-1)}><ArrowBackIcon style={{ width: 20, height: 20 }} /> Back</Button>
//                     </div>
//                     <div className='course_details__top_info'>
//                         <div className='course_details__text'>
//                             {/* <Link to={`/`} style={{ textAlign: "start" }}>
//                                 <Button variant="primary mx-1 my-1 btn-sm p-0" style={{height: 30}}><ArrowBackIcon style={{ width: 30, height: 25 }} /> &nbsp;</Button>
//                             </Link> */}
//                             <p style={{ color: "hotpink" }}>Course Category {`>`} {course.courseCategory}</p>
//                             <h5>{course.courseTitle}</h5>
//                             <p>{course.courseSubTitle}</p>
//                             <br /><br /><br />
//                             <div><AccountCircleIcon /> Created by: <u>{`${course.firstName} ${course.lastName}`}</u></div>
//                             <div><AccessTimeIcon /> Last Updated: 23/23/2020</div>
//                             <div><LanguageIcon /> Language: {course.courseLanguage}</div>
//                             <div><PriceChangeIcon /> Pricing: Rs: {course.coursePricing}/-</div>
//                             <MButton className='mt-2' size='small' variant="contained" onClick={() => checkCourseInCartItems(course)} color={isInCart ? "success" : "primary"}>
//                                 {isInCart ? "REMOVE FROM CART" : "ADD TO CART"}
//                             </MButton>
//                         </div>
//                         <div className='course_details__thumbnail'>
//                             <img src={`${COURSE_THUMBNAIL_URL}${course.courseThumbnail}`} alt="Course Thumbnail" />
//                         </div>
//                     </div>
//                     {/* show course description */}
//                     <div className='course_details__description'>
//                         <h5>Course Description</h5>
//                         <p
//                             dangerouslySetInnerHTML={{ __html: course.courseDescription }} />
//                     </div>

//                     <Container className='mt-4'>
//                         <Row>
//                             <Col xs={12} md={6} lg={4}>
//                                 <Card
//                                     bg={'primary'}
//                                     text={'white'}
//                                     className="mb-2"
//                                 >
//                                     <Card.Header>What will you learn.</Card.Header>
//                                     <Card.Body>
//                                         <Card.Text>
//                                             {course.whatWillStudentLearn}
//                                         </Card.Text>
//                                     </Card.Body>
//                                 </Card>
//                             </Col>
//                             <Col xs={12} md={6} lg={4}>
//                                 <Card
//                                     bg={'primary'}
//                                     text={'white'}
//                                     className="mb-2"
//                                 >
//                                     <Card.Header>What are Prerequisites.</Card.Header>
//                                     <Card.Body>
//                                         <Card.Text>
//                                             {course.whatArePrerequisites}
//                                         </Card.Text>
//                                     </Card.Body>
//                                 </Card>
//                             </Col>
//                             <Col xs={12} md={6} lg={4}>
//                                 <Card
//                                     bg={'primary'}
//                                     text={'white'}
//                                     className="mb-2"
//                                 >
//                                     <Card.Header>Who is this course for!</Card.Header>
//                                     <Card.Body>
//                                         <Card.Text>
//                                             {course.whoIsThisCourseFor}
//                                         </Card.Text>
//                                     </Card.Body>
//                                 </Card>
//                             </Col>
//                         </Row>
//                     </Container>


//                     {/* show course promotion video */}
//                     <div className='course_details__promotion_video'>
//                         <video controls controlsList="nodownload">
//                             <source src={`${COURSE_PROMOTION_VIDEO_URL}${course.coursePromotionVideo}`} type="video/mp4" />
//                         </video>
//                     </div>
//                 </>
//             )}
//         </div>
//     );
// }

// export default CourseDetails;