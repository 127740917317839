import * as React from 'react';
import "./css/VideoCard.css";
import { YOUTUBE_EMBEDDED_VIDEO_THUMBNAIL_URL, VIDEO_THUMBNAIL_URL, DRIVE_EMBEDDED_VIDEO_THUMBNAIL_URL } from '../../../constants/actionTypes';


import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

function VideoCard({ video, playVideo, index, youtubeEmbedded, uploaded, driveEmbedded }) {
    var videoThumbnail = ``;

    if (youtubeEmbedded === true) {
        videoThumbnail = `${YOUTUBE_EMBEDDED_VIDEO_THUMBNAIL_URL}${video.videoThumbnail}`;
    }
    else if (driveEmbedded === true) {
        videoThumbnail = `${DRIVE_EMBEDDED_VIDEO_THUMBNAIL_URL}${video.videoThumbnail}`;
    }
    else {
        videoThumbnail = `${VIDEO_THUMBNAIL_URL}${video.videoThumbnail}`;
    }

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <h6
                    className='fw-bolder opacity-100'
                >
                    <b>{++index} :</b> {video.videoTitle.slice(0, 30)}
                </h6>
            </AccordionSummary>
            <AccordionDetails
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center"
                }}
            >
                <Card sx={{ maxWidth: 345, width: 345 }}>
                    <CardMedia
                        component="img"
                        alt="green iguana"
                        height="140"
                        src={videoThumbnail}
                        className="video_card_video_thumbnail"
                        onClick={() => playVideo(video, uploaded, youtubeEmbedded, driveEmbedded)}
                    />
                    <CardContent>
                        <Typography variant="body2" color="text.secondary" className='text-start'>
                            {video.videoDescription?.slice(0, 150)}
                        </Typography>
                    </CardContent>
                </Card>
            </AccordionDetails>
        </Accordion>
    )
}

export default VideoCard;