import { Button, Grid, Paper, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import "./css/UploadVideo.css";
import * as api from "../../../api/index";
import Swal from "sweetalert2";
import { Form } from 'react-bootstrap';


const initialState = {
    videoTitle: "",
    videoDescription: "",
    videoFileLink: "",
}

function EmbedYoutubeVideo({ courseId, user, setUser, youtubeEmbeddedVideos, setYoutubeEmbeddedVideos, youtubeVideosCount, setYoutubeVideosCount }) {
    const [embedVideoForm, setEmbedVideoForm] = useState(initialState);
    const [videoThumbnail, setVideoThumbnail] = useState(null);
    const [requesting, setRequesting] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (requesting) {
            Swal.fire({
                title: 'Processing your request...',
                text: 'Please wait...',
                showConfirmButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: true,
            });
            return;
        }
        setRequesting(true);
        if (embedVideoForm.videoTitle === "" || embedVideoForm.videoDescription === "" || embedVideoForm.videoFileLink === "" || videoThumbnail === null) {
            Swal.fire(
                'Failed!',
                "Please fill all input fields",
                'error'
            )
            setRequesting(false);
        }
        else {
            const formData = new FormData();
            formData.append("userId", user.result.id);
            formData.append("courseId", courseId);
            formData.append("videoTitle", embedVideoForm.videoTitle);
            formData.append("videoDescription", embedVideoForm.videoDescription);
            formData.append("videoFileLink", embedVideoForm.videoFileLink);
            formData.append("videoThumbnail", videoThumbnail);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                },
            }

            try {
                const { data } = await api.embedYoutubeVideoToCourseOfInstructor(formData, config);
                if (data.success) {
                    Swal.fire(
                        'Good job!',
                        data.message,
                        'success'
                    );
                    setEmbedVideoForm(initialState);
                    setVideoThumbnail(null);
                    document.getElementById("embedYoutubeVideoHtmlForm").reset();
                    setRequesting(false);
                    setYoutubeEmbeddedVideos([...youtubeEmbeddedVideos, data.video]);
                    setYoutubeVideosCount(youtubeVideosCount + 1);
                }
                else {
                    Swal.fire(
                        'Failed!',
                        data.error,
                        'error'
                    );
                    setRequesting(false);
                }
            } catch (error) {
                Swal.fire(
                    'Failed!',
                    'Video is not embedded please try again, if problem persists contact Programmer',
                    'error'
                )
                setRequesting(false);
            }
        }

    }


    const handleChange = (e) => {
        setEmbedVideoForm({ ...embedVideoForm, [e.target.name]: e.target.value });
    }

    const onSelectVideoThumbnail = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setVideoThumbnail(null)
            return
        }
        setVideoThumbnail(e.target.files[0])
    }

    return (
        <Grid container spacing={0.5}>
            <Grid item sm={12} md={12} lg={12} mx={2}>
                <Paper elevation={3}>

                    <Typography className='fw-bold p-2 bg-light' variant="h5">Embed YouTube video</Typography>

                    <form className='m-2' id="embedYoutubeVideoHtmlForm" onSubmit={(e) => handleSubmit(e)} autoComplete="off">

                        <Grid container spacing={2} paddingRight={2} paddingLeft={2}>
                            <Grid item xs={12} md={3} lg={3}>
                                <TextField
                                    label="Video Title"
                                    value={embedVideoForm.videoTitle}
                                    name="videoTitle"
                                    onChange={handleChange}
                                    size="small"
                                    inputProps={{ maxLength: 100 }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={3} lg={3}>
                                <TextField
                                    label="Video Description"
                                    value={embedVideoForm.videoDescription}
                                    name='videoDescription'
                                    onChange={handleChange}
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={3} lg={3}>
                                <Form.Control type="file"
                                    multiple={false}
                                    onChange={(e) => onSelectVideoThumbnail(e)}
                                />
                                <Form.Label className='fw-bold'>Video Thumbnail</Form.Label>
                            </Grid>
                            <Grid item xs={12} md={3} lg={3}>
                                <TextField
                                    label="Video IFrame Link"
                                    value={embedVideoForm.videoFileLink}
                                    name='videoFileLink'
                                    onChange={handleChange}
                                    size="small"
                                    fullWidth
                                />
                            </Grid>

                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Button style={{ margin: "10px" }} size="small" variant="contained" color="primary" type="submit">
                                Embed Video
                            </Button>
                            <Button style={{ margin: "10px" }} size="small" variant="contained" color="primary" type="reset">
                                Reset
                            </Button>
                        </Grid>
                    </form>

                </Paper>
            </Grid>
        </Grid>
    )
}

export default EmbedYoutubeVideo;