import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import { Button, CircularProgress, TextField } from '@mui/material';
import * as api from "../../api/index.js";
import "./css/Users.css";
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import PreviewIcon from '@mui/icons-material/Preview';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import { PAGE_SIZES, tableDataToPdf } from '../../Globals/PDFVariables';
import { CSVLink } from "react-csv";

const columns = [
    { id: 'sno', label: 'S.No', minWidth: 10, width: '5%' },
    { id: 'firstName', label: 'First Name', minWidth: 100, width: '15%' },
    { id: 'lastName', label: 'Last Name', minWidth: 100, width: '15%' },
    { id: 'email', label: 'Email', minWidth: 100, width: '30%' },
    { id: 'role', label: 'Role', minWidth: 100, width: '10%' },
    { id: 'action', label: 'Action', minWidth: 100, width: '10%' },
];


function Users() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchTerm, setSearchTerm] = useState([]);
    const [users, setUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        const searchText = e.target.value;
        if (e.target.value) {
            const results = allUsers.filter(sUser =>
                sUser.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
                sUser.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
                sUser.role.toLowerCase().includes(searchText.toLowerCase()) ||
                sUser.email.toLowerCase().includes(searchText.toLowerCase())
            );
            setUsers(results);
        } else {
            setUsers(allUsers);
        }
    }

    const fetchUsers = async () => {
        try {
            const { data } = await api.getUsers();
            if (data.success) {
                setUsers(data.users);
                setAllUsers(data.users);
            }
            else {
                Swal.fire(
                    'failed!',
                    "There was a server side error please contact the developer for resolving issue",
                    'error'
                )
            }
        } catch (error) {
            window.alert("Failed to load blogs")
        }

    }

    const createPdf = () => {
        const tableColumns = [['S.No', 'First Name', 'Last Name', 'Email', 'Role']];
        const usersRow = users.map((user, index) => [index + 1, user.firstName, user.lastName, user.email, user.role]);
        tableDataToPdf(tableColumns, usersRow, 'Users');
    }


    useEffect(() => {
        fetchUsers();
    }, []);

    return (
        <div className='users__main_div'>
            <div className='card'>
                <div className='card-header'>
                    <h3>Users</h3>
                </div>
            </div>
            <div className='users__top_div'>
                <div
                    className='users__search_div'
                // style={{
                //     display: 'flex',
                //     justifyContent: 'center',
                //     alignItems: 'center',
                //     marginBottom: '10px',
                //     boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
                //     borderRadius: '5px',
                //     border: '1px solid #ccc',
                // }}
                >
                    <TextField
                        label="Search Here"
                        name="search"
                        size="small"
                        className='m-2'
                        type={'search'}
                        value={searchTerm}
                        style={{
                            width: "300px",
                        }}
                        onChange={(e) => handleSearch(e)}
                    />
                </div>
                <div className='users__button_div'>
                    <Button
                        variant="contained"
                        color="primary"
                        className='users__button m-1'
                        startIcon={<CollectionsBookmarkIcon />}
                        size="small"
                    >
                        <CSVLink style={{
                            textDecoration: 'none',
                            color: 'white',
                        }}
                            data={
                                users.map(user => {
                                    return {
                                        sno: user.sno,
                                        firstName: user.firstName,
                                        lastName: user.lastName,
                                        email: user.email,
                                        role: user.role,
                                    }
                                })
                            }
                            filename="users.csv"
                        >CSV/EXCEL</CSVLink>
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className='users__button m-1'
                        startIcon={<PictureAsPdfIcon />}
                        size="small"
                        onClick={createPdf}
                    >
                        PDF
                    </Button>
                </div>
            </div>
            <div className='users__table_div'>
                <Paper sx={{
                    width: '100%', overflow: 'hidden',
                    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
                    borderRadius: '8px',
                    backgroundColor: '#fafafa'
                }}>
                    <TableContainer sx={{ maxHeight: 570 }}>
                        <Table stickyHeader aria-label="sticky table"
                            size='small'
                        >
                            <TableHead
                                sx={{
                                    backgroundColor: '#81d690',
                                    borderRadius: '8px',
                                    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
                                }}
                            >
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                            width={column.width}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {!users && (
                                    <TableRow>
                                        <TableCell colSpan={6}>
                                            <CircularProgress />
                                        </TableCell>
                                    </TableRow>
                                )}

                                {users && users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, index) => {
                                    return (
                                        <TableRow hover role="checkbox" key={user.id}>

                                            <TableCell component="td" scope="row">
                                                {++index}
                                            </TableCell>
                                            <TableCell component="td" scope="row">
                                                {user.firstName}
                                            </TableCell>
                                            <TableCell component="td" scope="row">
                                                {user.lastName}
                                            </TableCell>
                                            <TableCell component="td" scope="row">
                                                {user.email}
                                            </TableCell>
                                            <TableCell component="td" scope="row">
                                                {user.role}
                                            </TableCell>
                                            <TableCell component="td" scope="row">
                                                <Link to={`/admin/users/${user.id}`}>
                                                    <Button
                                                        size='small'
                                                        variant="contained"
                                                        color="primary"
                                                        startIcon={<PreviewIcon />}
                                                    >
                                                        View
                                                    </Button>
                                                </Link>
                                            </TableCell>

                                        </TableRow>
                                    );
                                })}

                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={PAGE_SIZES}
                        component="div"
                        count={users.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        className='table_pagination'
                    />
                </Paper>
            </div>
        </div>
    );
}



export default Users;


















































// import { Button, CircularProgress, TextField } from '@mui/material';
// import React, { useEffect, useState } from 'react'
// import * as api from "../../api/index.js";
// // import DeleteIcon from '@mui/icons-material/Delete';
// import "./css/Users.css";
// import { Table } from 'react-bootstrap';
// import Swal from 'sweetalert2';
// import { Link } from 'react-router-dom';

// function Users({ user, setUser }) {
//     const [searchTerm, setSearchTerm] = useState([]);
//     const [users, setUsers] = useState();
//     const [allUsers, setAllUsers] = useState([]);

//     const handleSearch = (e) => {
//         setSearchTerm(e.target.value);
//         if (e.target.value) {
//             const results = allUsers.filter(sUser =>
//                 `
//                 ${sUser.firstName}
//                 ${sUser.lastName}
//                 ${sUser.role}
//                 ${sUser.email}
//                 `.toLowerCase().includes(e.target.value)
//             );
//             setUsers(results);
//         } else {
//             setUsers(allUsers);
//         }
//     }

//     const fetchUsers = async () => {
//         try {
//             const { data } = await api.getUsers();
//             if (data.success) {
//                 setUsers(data.users);
//                 setAllUsers(data.users);
//             }
//             else {
//                 Swal.fire(
//                     'failed!',
//                     "There was a server side error please contact the developer for resolving issue",
//                     'error'
//                 )
//             }
//         } catch (error) {
//             window.alert("Failed to load blogs")
//         }

//     }


//     useEffect(() => {
//         fetchUsers();
//     }, []);


//     return (

//         <div className='users__main_div'>
//             <div className='users__top_div'>
//                 <div>
//                     <TextField
//                         label="Search Here"
//                         name="search"
//                         size="small"
//                         className='m-2'
//                         type={'search'}
//                         value={searchTerm}
//                         style={{ width: "220px" }}
//                         onChange={(e) => handleSearch(e)}
//                     />
//                 </div>
//             </div>
//             <div>
//                 <Table striped bordered hover size="sm" style={{ textAlign: "start" }} responsive>
//                     <thead>
//                         <tr>
//                             <th width="10%">S.No</th>
//                             <th width="20%">First Name</th>
//                             <th width="20%">Last Name</th>
//                             <th width="20%">Email</th>
//                             <th width="20%">Role</th>
//                             <th width="10%">Action</th>
//                         </tr>
//                     </thead>
//                     <tbody>

//                         {!users ? (
//                             <tr>
//                                 <td colSpan={6}>
//                                     <CircularProgress />
//                                 </td>
//                             </tr>
//                         ) : (
//                             <>
//                                 {users.map((sUser, index) => {
//                                     return (
//                                         <tr key={index}>
//                                             <td>{index + 1}</td>
//                                             <td>{sUser?.firstName}</td>
//                                             <td>{sUser?.lastName}</td>
//                                             <td>{sUser?.email}</td>
//                                             <td>{sUser?.role}</td>
//                                             <td>
//                                                 <Link to={`/admin/users/${sUser.id}`}>
//                                                     <Button size="small" color="secondary" variant="outlined">
//                                                         Details
//                                                     </Button>
//                                                 </Link>
//                                             </td>
//                                         </tr>
//                                     )
//                                 })}
//                             </>
//                         )}
//                     </tbody>
//                 </Table>
//             </div>
//         </div>
//     )
// }

// export default Users;