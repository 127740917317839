import { Button, Divider, Grid, Paper, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import Swal from 'sweetalert2';
import * as api from "../../../api/index";
import Select from 'react-select';
import { Form } from 'react-bootstrap';


const initialState = {
    "classId": "",
    "yearId": "",
    "countryId": "",
    "provinceId": "",
    "cityId": "",
    "instituteName": "",
    "admissionLink": "",
}


function NewAdmission({ classesOptions, yearsOptions, countriesOptions, provincesOptions, citiesOptions, admissionsData, setAdmissionsData, user, setUser }) {
    const [admissionForm, setAdmissionForm] = useState(initialState);
    const [requesting, setRequesting] = useState(false);
    const [admissionImage, setAdmissionImage] = useState(null);
    const [admissionPdf, setAdmissionPdf] = useState(null);

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            textAlign: 'start',
            color: 'black',
        }),
        control: (provided, state) => ({
            ...provided,
            textAlign: 'start',
            color: 'black',
        }),
        option: (provided, state) => ({
            ...provided,
            textAlign: 'start',
            color: 'black',
        }),
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (requesting) {
            Swal.fire({
                title: "Please wait",
                text: "Your request is being processed",
                icon: "info",
                showConfirmButton: false,
                showCancelButton: false,
                allowOutsideClick: true,
                allowEscapeKey: true,
                allowEnterKey: true,
            });
            return;
        }

        if (!admissionImage && !admissionPdf) {
            Swal.fire({
                title: "Error",
                text: "Please select admission image and pdf file both",
                icon: "error",
            });
            return;
        }

        if (!admissionForm.classId || !admissionForm.yearId || !admissionForm.countryId || !admissionForm.provinceId || !admissionForm.cityId || !admissionForm.instituteName || !admissionForm.admissionLink) {
            Swal.fire({
                title: "Warning",
                text: "Please fill all the fields",
                icon: "warning",
            });
            return;
        }

        setRequesting(true);
        try {

            const formData = new FormData();
            formData.append("entryUserId", user.result.id);
            formData.append('classId', admissionForm.classId);
            formData.append('yearId', admissionForm.yearId);
            formData.append('countryId', admissionForm.countryId);
            formData.append('provinceId', admissionForm.provinceId);
            formData.append('cityId', admissionForm.cityId);
            formData.append('instituteName', admissionForm.instituteName);
            formData.append('admissionLink', admissionForm.admissionLink);
            formData.append('admissionImage', admissionImage);
            formData.append('admissionPdf', admissionPdf);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            const { data } = await api.addAdmissionData(formData, config);
            if (data.success) {
                Swal.fire({
                    title: "Success",
                    text: data.message,
                    icon: "success",
                });
                setRequesting(false);
                setAdmissionsData([...admissionsData, data.admissionData]);
                setAdmissionImage(null);
                setAdmissionPdf(null);
                window.document.getElementById("admissionForm").reset();
            } else {
                Swal.fire({
                    title: "Error",
                    text: data.message,
                    icon: "error",
                });
                setRequesting(false);
            }

        } catch (error) {
            Swal.fire({
                title: "Error",
                text: "Failed to add admission data, Please contact the one and only Mr. Programmer",
                icon: "error",
            });
            setRequesting(false);
        }

    }

    const onSelectAdmissionImage = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setAdmissionImage(null);
            return;
        }
        setAdmissionImage(e.target.files[0]);
    }

    const onSelectAdmissionPdf = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setAdmissionPdf(null);
            return;
        }
        setAdmissionPdf(e.target.files[0]);
    }

    return (
        <div>
            <Paper style={{ padding: '15px' }} elevation={8}>
                <form onSubmit={handleSubmit} id="admissionForm">
                    <Typography className="heading" variant="h4">Add Admission Data</Typography>
                    <Divider
                        variant="fullWidth"
                        component="hr"
                    />
                    <Grid container spacing={2} mt={1}>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                id="instituteName"
                                name="instituteName"
                                label="Institute Name"
                                variant="outlined"
                                size='small'
                                fullWidth
                                onChange={(e) => {
                                    setAdmissionForm({ ...admissionForm, instituteName: e.target.value })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                id="admissionLink"
                                name="admissionLink"
                                label="Admission Link"
                                variant="outlined"
                                size='small'
                                fullWidth
                                onChange={(e) => {
                                    setAdmissionForm({ ...admissionForm, admissionLink: e.target.value })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Select
                                options={classesOptions}
                                placeholder="-- Select Class --"
                                styles={customStyles}
                                onChange={(e) => {
                                    setAdmissionForm({ ...admissionForm, classId: e.value })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Select
                                options={yearsOptions}
                                placeholder="-- Select Year --"
                                styles={customStyles}
                                onChange={(e) => {
                                    setAdmissionForm({ ...admissionForm, yearId: e.value })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Select
                                options={countriesOptions}
                                placeholder="-- Select Country --"
                                styles={customStyles}
                                onChange={(e) => {
                                    setAdmissionForm({ ...admissionForm, countryId: e.value })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Select
                                options={provincesOptions}
                                placeholder="-- Select Province --"
                                styles={customStyles}
                                onChange={(e) => {
                                    setAdmissionForm({ ...admissionForm, provinceId: e.value })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Select
                                options={citiesOptions}
                                placeholder="-- Select City --"
                                styles={customStyles}
                                onChange={(e) => {
                                    setAdmissionForm({ ...admissionForm, cityId: e.value })
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={1}>
                        <Grid item xs={12} md={6} lg={3}>
                            <Form.Control type="file" onChange={(e) => onSelectAdmissionImage(e)} size="sm" />
                            <Form.Label>Admission Image</Form.Label>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Form.Control type="file" onChange={(e) => onSelectAdmissionPdf(e)} size="sm" />
                            <Form.Label>Admission PDF</Form.Label>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={1} justifyContent="center">
                        <Grid item xs={12} md={4} lg={3}>
                            <Button variant="contained" color="primary" type="submit" fullWidth>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                    <div>
                    </div>
                </form>
            </Paper>
        </div>
    )
}

export default NewAdmission;