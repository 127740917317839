import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

import { Button, CircularProgress, Grid, TextField } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import { PAGE_SIZES, tableDataToPdf } from '../../../Globals/PDFVariables';
import { CSVLink } from 'react-csv';
import Swal from 'sweetalert2';

import * as api from "../../../api/index";

const columns = [
  { id: 'sno', label: 'S.No', minWidth: 10, width: '5%' },
  { id: 'subject', label: 'Subject', minWidth: 10, width: '80%' },
  { id: 'action', label: 'Action', minWidth: 10, width: '10%' }
];

function TableSubjects({ generalSubjectsData, setGeneralSubjectsData }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchTerm, setSearchTerm] = useState([]);
  const [subjects, setSubjects] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };



  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    const searchText = e.target.value.replace(/\s+/g, '');
    if (e.target.value) {
      const results = generalSubjectsData.filter(subject =>
        subject.subjectName.toLowerCase().includes(searchText.toLowerCase())
      );
      setSubjects(results);
    } else {
      setSubjects(generalSubjectsData);
    }
  }


  const createPdf = () => {
    const tableColumns = [['S.No', 'Subject Name']];
    const tableRows = subjects.map((subject, index) => [
      index + 1,
      subject.subjectName,
    ]);

    tableDataToPdf(tableColumns, tableRows, "pastPapers");
  }

  const handleDelete = async (id) => {
    try {
      const { value } = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });

      if (value) {
        const { data } = await api.deleteGeneralSubject(id);
        if (data.success) {
          Swal.fire({
            title: 'Success!',
            text: data.message,
            icon: 'success',
            confirmButtonText: 'Ok'
          });
          const newSubjects = generalSubjectsData.filter(subject => subject.id !== id);
          setGeneralSubjectsData(newSubjects);
        }
        else {
          Swal.fire({
            title: 'Error!',
            text: data.message,
            icon: 'error',
            confirmButtonText: 'Ok'
          })
        }
      }

    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: "Something went wrong",
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }
  }

  useEffect(() => {
    setSubjects(generalSubjectsData);
  }, [generalSubjectsData]);


  return (
    <div>
      <Paper elevation={3} className="p-0">
        <Grid container spacing={3} className="mt-1">
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              label="Search Here"
              name="search"
              size="small"
              className='m-2'
              type={'search'}
              value={searchTerm}
              onChange={(e) => handleSearch(e)}
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4} className="text-start">
            <Button
              variant="contained"
              color="primary"
              startIcon={<CollectionsBookmarkIcon />}
              size="small"
              className='m-3'
            >
              <CSVLink style={{
                textDecoration: 'none',
                color: 'white',
              }}
                data={
                  subjects.map((subject, index) => {
                    return {
                      "S.No": index + 1,
                      "Subject": subject.subjectName,
                    }
                  })
                }
                filename="past_papers_subjects.csv"
              >CSV/EXCEL</CSVLink>
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<PictureAsPdfIcon />}
              size="small"
              className='m-3'
              onClick={createPdf}
            >
              PDF
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <div>
        <Paper sx={{
          width: '100%', overflow: 'hidden',
          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
          borderRadius: '8px',
          backgroundColor: '#fafafa',
          marginTop: '1rem',
        }}>
          <TableContainer sx={{ maxHeight: 570 }}>
            <Table stickyHeader aria-label="sticky table"
              size='small'
            >
              <TableHead
                sx={{
                  backgroundColor: '#81d690',
                  borderRadius: '8px',
                  boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
                }}
              >
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                      width={column.width}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>

                {!subjects && (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}

                {subjects && subjects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((subject, index) => {
                  return (
                    <TableRow hover role="checkbox" key={subject.id}>

                      <TableCell component="td" scope="row">
                        {++index}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {subject.subjectName}
                      </TableCell>

                      <TableCell component="td" scope="row">
                        <Button
                          variant="contained"
                          color="primary"
                          className='m-1'
                          size='small'
                          onClick={() => handleDelete(subject.id)}
                        >
                          <DeleteRoundedIcon />
                        </Button>
                      </TableCell>

                    </TableRow>
                  );
                })}

              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={PAGE_SIZES}
            component="div"
            count={subjects.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className='table_pagination'
          />
        </Paper>
      </div>
    </div>
  )
}


export default TableSubjects;