import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import { Button, CircularProgress, TextField } from '@mui/material';
import "./css/AllInstitutes.css";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { PAGE_SIZES, tableDataToPdf } from '../../../Globals/PDFVariables';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import DeleteIcon from '@mui/icons-material/Delete';
import * as api from '../../../api/index';


const columns = [
    { id: 'sno', label: 'S.No', minWidth: 10, width: '5%' },
    { id: 'instituteName', label: 'Institute Name', minWidth: 10, width: '20%' },
    { id: 'website', label: 'Website', minWidth: 10, width: '15%' },
    { id: 'founderName', label: 'Founder Name', minWidth: 10, width: '10%' },
    { id: 'dateOfEstablishment', label: 'Date Of Establishment', minWidth: 10, width: '10%' },
    { id: 'city', label: 'City', minWidth: 10, width: '10%' },
    { id: 'province', label: 'Province', minWidth: 10, width: '10%' },
    { id: 'action', label: 'Action', minWidth: 10, width: '20%' }
];

function AllInstitutes({ institutesData, setInstitutesData, options, setOptions, emails, contacts, addresses, staff, setEmails, setContacts, setAddresses, setStaff, handleInstituteModalShowAndEditInstitute }) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchTerm, setSearchTerm] = useState([]);
    const [institutes, setInstitutes] = useState([]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    const handleDelete = (id) => {
        try {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.value) {
                    const { data } = await api.deleteInstitute(id);
                    if (data.success) {
                        Swal.fire({
                            title: 'Success!',
                            text: data.message,
                            icon: 'success',
                            confirmButtonText: 'Ok'
                        });
                        setInstitutesData(institutesData.filter(institute => institute.id !== id));
                        setOptions(options.filter(option => option.id !== id));

                        setEmails(emails.filter(email => email.instituteId !== id));
                        setContacts(contacts.filter(contact => contact.instituteId !== id));
                        setAddresses(addresses.filter(address => address.instituteId !== id));
                        setStaff(staff.filter(staff => staff.instituteId !== id));
                    }
                    else {
                        Swal.fire({
                            title: 'Error!',
                            text: data.error,
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        });
                    }
                }
            });
        }
        catch (err) {
            Swal.fire({
                title: 'Error!',
                text: err.message,
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        }
    }

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        const searchText = e.target.value;
        if (e.target.value) {
            const results = institutesData.filter(institute =>
                institute?.instituteName.toLowerCase().includes(searchText.toLowerCase()) ||
                institute?.websit?.toLowerCase().includes(searchText.toLowerCase()) ||
                institute?.founderName?.toLowerCase().includes(searchText.toLowerCase()) ||
                institute?.dateOfEstablishment?.toLowerCase().includes(searchText.toLowerCase()) ||
                institute?.cityName?.toLowerCase().includes(searchText.toLowerCase()) ||
                institute?.provinceName?.toLowerCase().includes(searchText.toLowerCase())
            );
            setInstitutes(results);
        } else {
            setInstitutes(institutesData);
        }
    }


    const createPdf = () => {
        const tableColumns = [['S.No', 'Institute Name', 'Website', 'Founder Name', 'Date of Establishment', 'City', 'Province']];
        const tableRows = institutes.map((institute, index) => [
            index + 1,
            institute.instituteName,
            institute.website,
            institute.founderName,
            institute.dateOfEstablishment,
            institute.cityName,
            institute.provinceName
        ]);

        tableDataToPdf(tableColumns, tableRows, "Institutes");
    }

    useEffect(() => {
        setInstitutes(institutesData);
    }, [institutesData]);

    return (
        <div className='all_institutes_main_div'>
            <div className='card'>
                <div className='card-header'>
                    <h3 className='heading'>Institutes list</h3>
                </div>
            </div>
            <div className='all_institutes_top_div'>
                <div>
                    <TextField
                        label="Search Here"
                        name="search"
                        size="small"
                        className='m-2'
                        type={'search'}
                        value={searchTerm}
                        style={{
                            width: "300px",
                        }}
                        onChange={(e) => handleSearch(e)}
                    />
                </div>
                <div className='users__button_div'>
                    <Button
                        variant="contained"
                        color="primary"
                        className='users__button m-1'
                        startIcon={<CollectionsBookmarkIcon />}
                        size="small"
                    >
                        <CSVLink style={{
                            textDecoration: 'none',
                            color: 'white',
                        }}
                            data={
                                institutes.map((institute, index) => {
                                    return {
                                        "S.No": index + 1,
                                        "Institute Name": institute.instituteName,
                                        "Website": institute.website,
                                        "Founder Name": institute.founderName,
                                        "Date of Establishment": institute.dateOfEstablishment,
                                        "City": institute.city,
                                        "Province": institute.province,
                                    }
                                })
                            }
                            filename="Institutes_list.csv"
                        >CSV/EXCEL</CSVLink>
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className='users__button m-1'
                        startIcon={<PictureAsPdfIcon />}
                        size="small"
                        onClick={createPdf}
                    >
                        PDF
                    </Button>
                </div>
            </div>
            <div className='users__table_div'>
                <Paper sx={{
                    width: '100%', overflow: 'hidden',
                    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
                    borderRadius: '8px',
                    backgroundColor: '#fafafa'
                }}>
                    <TableContainer sx={{ maxHeight: 570 }}>
                        <Table stickyHeader aria-label="sticky table"
                            size='small'
                        >
                            <TableHead
                                sx={{
                                    backgroundColor: '#81d690',
                                    borderRadius: '8px',
                                    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
                                }}
                            >
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                            width={column.width}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {!institutes && (
                                    <TableRow>
                                        <TableCell colSpan={6}>
                                            <CircularProgress />
                                        </TableCell>
                                    </TableRow>
                                )}

                                {institutes && institutes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((institute, index) => {
                                    return (
                                        <TableRow hover role="checkbox" key={institute.id}>

                                            <TableCell component="td" scope="row">
                                                {++index}
                                            </TableCell>
                                            <TableCell component="td" scope="row">
                                                {institute.instituteName}
                                            </TableCell>
                                            <TableCell component="td" scope="row">
                                                {institute.website}
                                            </TableCell>
                                            <TableCell component="td" scope="row">
                                                {institute.founderName}
                                            </TableCell>
                                            <TableCell component="td" scope="row">
                                                {institute.dateOfEstablishment}
                                            </TableCell>
                                            <TableCell component="td" scope="row">
                                                {institute.cityName}
                                            </TableCell>
                                            <TableCell component="td" scope="row">
                                                {institute.provinceName}
                                            </TableCell>

                                            <TableCell component="td" scope="row">
                                                <Link to={`/institutes/instituteDetails/${institute.id}`} target="_blank">
                                                    <Button variant="contained"
                                                        color="primary"
                                                        size='small'
                                                        className='m-1'
                                                    >
                                                        <VisibilityIcon />
                                                    </Button>
                                                </Link>
                                                <Button variant="contained"
                                                    color="secondary"
                                                    size='small'
                                                    onClick={() => handleInstituteModalShowAndEditInstitute(institute)}
                                                    className="m-1"
                                                >
                                                    <EditRoundedIcon />
                                                </Button>
                                                <Button variant="contained"
                                                    color="secondary"
                                                    size='small'
                                                    onClick={() => handleDelete(institute.id)}
                                                    className="m-1"
                                                >
                                                    <DeleteIcon />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}

                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={PAGE_SIZES}
                        component="div"
                        count={institutes.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        className='table_pagination'
                    />
                </Paper>
            </div>
        </div>
    );
}



export default AllInstitutes;