import jsPDF from "jspdf";
import "jspdf-autotable";

export const PAGE_SIZES = [10, 30, 50, 100];

export const tableDataToPdf = (columns, rows, title) => {
    const doc = new jsPDF("p", "mm", "a4");
    doc.setFontSize(12);
    doc.text(20, 20, title);
    doc.autoTable({
        head: columns,
        body: rows,
        startY: 30,
        styles: {
            overflow: "linebreak",
            fontSize: 8,
            minCellHeight: 10,
            font: "helvetica"
        }
    });
    doc.save(`${title}.pdf`);
}
