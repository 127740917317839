import React, { useState } from 'react'
import VideoCard from './VideoCard';
import "./css/UploadedVideos.css";
import { Box, Button, Grid, Paper, TextField } from '@mui/material';
import PlayedVideo from './PlayedVideo';
import Swal from 'sweetalert2';
import * as api from "../../../api/index";
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';


function UploadedVideos({ uploadedVideos, setUploadedVideos, youtubeEmbeddedVideos, setYoutubeEmbeddedVideos, driveEmbeddedVideos, setDriveEmbeddedVideos, uploadedVideosCount, driveVideosCount, youtubeVideosCount, loading }) {
    const [playedVideo, setPlayedVideo] = useState(null);
    const [playYoutubeEmbedded, setPlayYoutubeEmbedded] = useState(false);
    const [playUploaded, setPlayUploaded] = useState(false);
    const [playDriveEmbedded, setPlayDriveEmbedded] = useState(false);

    const [editModalShow, setEditModalShow] = useState(false);
    const [editVideo, setEditVideo] = useState(null);
    const [editVideoRequesting, setEditVideoRequesting] = useState(false);

    const setEditVideoAndShowModal = (video, uploaded, youtubeEmbedded, driveEmbedded) => {
        const editVideo = {
            id: video.id,
            videoTitle: video.videoTitle,
            videoDescription: video.videoDescription,
            uploaded: uploaded,
            youtubeEmbedded: youtubeEmbedded,
            driveEmbedded: driveEmbedded,
            videoThumbnail: null,
        }
        setEditVideo(editVideo);
        setEditModalShow(true);
    }

    const handleEditModalClose = () => setEditModalShow(false);
    // const handleEditModalShow = () => setEditModalShow(true);

    const playVideo = (video, uploaded, youtubeEmbedded, driveEmbedded) => {
        try {
            if (uploaded === true) {
                setPlayYoutubeEmbedded(false);
                setPlayDriveEmbedded(false);
                setPlayUploaded(true);
            }
            else if (youtubeEmbedded === true) {
                setPlayUploaded(false);
                setPlayDriveEmbedded(false);
                setPlayYoutubeEmbedded(true);
            }
            else if (driveEmbedded === true) {
                setPlayUploaded(false);
                setPlayYoutubeEmbedded(false);
                setPlayDriveEmbedded(true);
            }
            setPlayedVideo(video);
        }
        catch (error) {
            console.log(error);
            Swal.fire({
                icon: "error",
                title: "failed to load video",
                text: "Failed to laod video please report the issue to us. Thank You!",
            });
            setPlayedVideo(null);
        }
    }

    const deleteVideo = (videoId, uploaded, youtubeEmbedded, driveEmbedded) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Are you sure you want to delete the video!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const formData = {
                    videoId: videoId,
                    uploaded: uploaded,
                    youtubeEmbedded: youtubeEmbedded,
                    driveEmbedded: driveEmbedded
                }
                const { data } = await api.deleteCourseVideoOfInstructor(formData);
                if (data.success) {
                    if (uploaded === true) {
                        setUploadedVideos(uploadedVideos.filter(video => video.id !== videoId))
                    }
                    else if (youtubeEmbedded === true) {
                        setYoutubeEmbeddedVideos(youtubeEmbeddedVideos.filter(video => video.id !== videoId))
                    }
                    else if (driveEmbedded === true) {
                        setDriveEmbeddedVideos(driveEmbeddedVideos.filter(video => video.id !== videoId))
                    }
                    Swal.fire({
                        title: 'Success!',
                        text: data.message,
                        icon: 'success',
                        confirmButtonText: 'Ok',
                        allowEnterKey: true,
                    });
                }
                else {
                    Swal.fire({
                        title: 'Error!',
                        text: data.error,
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        allowEnterKey: true,
                    });
                }

            }
        })
    }

    const onSelectEditVideoThumbnail = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setEditVideo({ ...editVideo, videoThumbnail: null });
            return
        }
        setEditVideo({ ...editVideo, videoThumbnail: e.target.files[0] });
    }

    const handleEditVideoSubmit = async (e) => {
        e.preventDefault();
        if (editVideoRequesting) {
            Swal.fire({
                icon: "warning",
                title: "Please wait",
                text: "Please wait while we are updating the video",
            });
            return;
        }
        setEditVideoRequesting(true);
        const formData = new FormData();
        formData.append("id", editVideo.id);
        formData.append("videoTitle", editVideo.videoTitle);
        formData.append("videoDescription", editVideo.videoDescription);
        formData.append("uploaded", editVideo.uploaded);
        formData.append("youtubeEmbedded", editVideo.youtubeEmbedded);
        formData.append("driveEmbedded", editVideo.driveEmbedded);

        if (editVideo.videoThumbnail != null) {
            formData.append("videoThumbnail", editVideo.videoThumbnail);
        }

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
        }

        try {
            const { data } = await api.updateCourseVideoOfInstructor(formData, config);
            if (data.success) {
                if (editVideo.uploaded === true) {
                    let index = uploadedVideos.findIndex(video => video.id === editVideo.id);
                    uploadedVideos[index] = data.video;
                    setUploadedVideos(uploadedVideos);
                }
                else if (editVideo.youtubeEmbedded === true) {
                    let index = youtubeEmbeddedVideos.findIndex(video => video.id === editVideo.id);
                    youtubeEmbeddedVideos[index] = data.video;
                    setYoutubeEmbeddedVideos(youtubeEmbeddedVideos);
                }
                else if (editVideo.driveEmbedded === true) {
                    let index = driveEmbeddedVideos.findIndex(video => video.id === editVideo.id);
                    driveEmbeddedVideos[index] = data.video;
                    setDriveEmbeddedVideos(driveEmbeddedVideos);
                }
                setEditVideoRequesting(false);
                Swal.fire({
                    title: 'Success!',
                    text: data.message,
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    allowEnterKey: true,
                });
            }
            else {
                setEditVideoRequesting(false);
                Swal.fire({
                    title: 'Error!',
                    text: data.message,
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    allowEnterKey: true,
                });
            }
        } catch (error) {
            setEditVideoRequesting(false);
            Swal.fire({
                title: 'Error!',
                text: "Failed to update video please report the issue to us. Thank You!",
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        }
    }


    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8} lg={8}>
                    <Paper elevation={5}>
                        {!playedVideo ? (
                            <div className="fw-bolder" style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "70vh" }}>
                                Click on video to play
                            </div>
                        ) : (
                            <>
                                <PlayedVideo video={playedVideo} playUploaded={playUploaded} playYoutubeEmbedded={playYoutubeEmbedded} playDriveEmbedded={playDriveEmbedded} />
                            </>
                        )}
                    </Paper>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                    <Paper elevation={10}>

                        <Box style={{ maxHeight: '70vh', overflow: 'auto', padding: "5px" }}>

                            <div className='card p-1 m-1'>
                                <div className='card-header'>
                                    <h6 className='fw-bold'>Uploaded videos</h6>
                                </div>
                                <div className='card-body'>

                                    {loading && (
                                        <button className="btn btn-primary m-2 p-2" type="button" disabled>
                                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                            &nbsp; Loading videos...
                                        </button>
                                    )}

                                    {uploadedVideos.length >= 0 ? (
                                        <>
                                            {uploadedVideos.map((video, index) => {
                                                return (
                                                    <VideoCard
                                                        key={video.id}
                                                        video={video}
                                                        playVideo={playVideo}
                                                        index={index}
                                                        deleteVideo={deleteVideo}
                                                        driveEmbedded={false}
                                                        youtubeEmbedded={false}
                                                        uploaded={true}
                                                        setEditVideoAndShowModal={setEditVideoAndShowModal}
                                                    />
                                                )
                                            })}
                                        </>
                                    ) : (
                                        <>
                                        </>
                                    )}

                                    {uploadedVideosCount === 0 && !loading && (
                                        <div className="fw-bolder text-success">
                                            No videos uploaded yet.
                                        </div>
                                    )}

                                </div>
                            </div>

                            <div className='card p-1 m-1'>
                                <div className='card-header'>
                                    <h6 className='fw-bold'>Youtube Embedded videos</h6>
                                </div>
                                <div className='card-body'>

                                    {loading && (
                                        <button className="btn btn-primary m-2 p-2" type="button" disabled>
                                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                            &nbsp; Loading videos...
                                        </button>
                                    )}

                                    {youtubeEmbeddedVideos.length >= 0 ? (
                                        <>
                                            {youtubeEmbeddedVideos.map((video, index) => {
                                                return (
                                                    <VideoCard
                                                        key={video.id}
                                                        video={video}
                                                        playVideo={playVideo}
                                                        index={index}
                                                        deleteVideo={deleteVideo}
                                                        uploaded={false}
                                                        youtubeEmbedded={true}
                                                        driveEmbedded={false}
                                                        setEditVideoAndShowModal={setEditVideoAndShowModal}
                                                    />
                                                )
                                            })}
                                        </>
                                    ) : (
                                        <>
                                        </>
                                    )}

                                    {youtubeVideosCount === 0 && !loading && (
                                        <div className="fw-bolder text-success">
                                            No youtube videos embedded yet.
                                        </div>
                                    )}

                                </div>
                            </div>

                            <div className='card p-1 m-1'>
                                <div className='card-header'>
                                    <h6 className='fw-bold'>Drive Embedded videos</h6>
                                </div>
                                <div className='card-body'>

                                    {loading && (
                                        <button className="btn btn-primary m-2 p-2" type="button" disabled>
                                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                            &nbsp; Loading videos...
                                        </button>
                                    )}

                                    {driveEmbeddedVideos.length >= 0 ? (
                                        <>
                                            {driveEmbeddedVideos.map((video, index) => {
                                                return (
                                                    <VideoCard
                                                        key={video.id}
                                                        video={video}
                                                        playVideo={playVideo}
                                                        index={index}
                                                        deleteVideo={deleteVideo}
                                                        uploaded={false}
                                                        youtubeEmbedded={false}
                                                        driveEmbedded={true}
                                                        setEditVideoAndShowModal={setEditVideoAndShowModal}
                                                    />
                                                )
                                            })}
                                        </>
                                    ) : (
                                        <>
                                        </>
                                    )}

                                    {driveVideosCount === 0 && !loading && (
                                        <div className="fw-bolder text-success">
                                            No drive videos embedded yet.
                                        </div>
                                    )}

                                </div>
                            </div>

                        </Box>

                    </Paper>
                </Grid>
            </Grid>
            {/* modal code start here */}
            <div>
                <Modal show={editModalShow} onHide={handleEditModalClose}>
                    <form onSubmit={handleEditVideoSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Video</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {editVideo && editVideo.id ? (
                                <>
                                    <Grid item xs={12} md={3} lg={3}>
                                        <TextField
                                            label="Video Title"
                                            value={editVideo.videoTitle}
                                            name="videoTitle"
                                            onChange={
                                                (e) => {
                                                    setEditVideo({ ...editVideo, videoTitle: e.target.value })
                                                }
                                            }
                                            size="small"
                                            inputProps={{ maxLength: 100 }}
                                            fullWidth
                                            className='mt-3'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3} lg={3}>
                                        <TextField
                                            label="Video Description"
                                            value={editVideo.videoDescription}
                                            name='videoDescription'
                                            onChange={
                                                (e) => {
                                                    setEditVideo({ ...editVideo, videoDescription: e.target.value })
                                                }
                                            }
                                            size="small"
                                            fullWidth
                                            className='mt-3'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3} lg={3}>
                                        <Form.Control type="file"
                                            multiple={false}
                                            onChange={(e) => onSelectEditVideoThumbnail(e)}
                                            className='mt-3'
                                            size='sm'
                                        />
                                        <Form.Label className='fw-bold px-2'>Change Thumbnail</Form.Label>
                                    </Grid>
                                </>
                            ) : (
                                <div className="alert alert-danger" role="alert">
                                    Please select a video to edit
                                </div>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="contained" className="m-1" type='submit' size="small">
                                Save changes
                            </Button>
                            <Button variant="contained" className="m-1" size="small" onClick={handleEditModalClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </div>
        </div>
    )
}

export default UploadedVideos;