import { Button, Divider, Grid, Paper, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import Swal from 'sweetalert2';
import * as api from "../../../api/index";
import { Form } from 'react-bootstrap';
import TableSoftwares from './TableSoftwares';
import Modal from 'react-bootstrap/Modal';
import EditSoftwareForm from './EditSoftwareForm';


const initialState = {
    "softwareName": "",
}


function AddSoftware({ softwaresData, setSoftwaresData }) {
    const [softwareForm, setSoftwareForm] = useState(initialState);
    const [requesting, setRequesting] = useState(false);
    const [softwareThumnail, setSoftwareThumnail] = useState(null);
    const [softwareFile, setSoftwareFile] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (requesting) {
            Swal.fire({
                title: "Please wait",
                text: "Your request is being processed",
                icon: "info",
                showConfirmButton: false,
                showCancelButton: false,
                allowOutsideClick: true,
                allowEscapeKey: true,
                allowEnterKey: true,
            });
            return;
        }

        if (!softwareThumnail && !softwareFile) {
            Swal.fire({
                title: "Error",
                text: "Please select admission image and pdf file both",
                icon: "error",
            });
            return;
        }

        if (!softwareForm.softwareName) {
            Swal.fire({
                title: "Warning",
                text: "Please fill all the fields",
                icon: "warning",
            });
            return;
        }

        setRequesting(true);
        try {

            const formData = new FormData();
            formData.append('softwareName', softwareForm.softwareName);
            formData.append('softwareThumnail', softwareThumnail);
            formData.append('softwareFile', softwareFile);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            const { data } = await api.addNewSoftware(formData, config);
            if (data.success) {
                Swal.fire({
                    title: "Success",
                    text: data.message,
                    icon: "success",
                });
                setRequesting(false);
                setSoftwaresData([...softwaresData, data.newSoftware]);
                setSoftwareThumnail(null);
                setSoftwareFile(null);
                window.document.getElementById("softwareForm").reset();
            } else {
                Swal.fire({
                    title: "Error",
                    text: data.message,
                    icon: "error",
                });
                setRequesting(false);
            }

        } catch (error) {
            Swal.fire({
                title: "Error",
                text: "Failed to add admission data, Please contact the one and only Mr. Programmer",
                icon: "error",
            });
            setRequesting(false);
        }

    }

    const onSelectsoftwareThumnail = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSoftwareThumnail(null);
            return;
        }
        setSoftwareThumnail(e.target.files[0]);
    }

    const onSelectsoftwareFile = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSoftwareFile(null);
            return;
        }
        setSoftwareFile(e.target.files[0]);
    }



    const [editSoftwareModalShow, setEditSoftwareModalShow] = useState(false);
    const [editSoftware, setEditSoftware] = useState(null);
    const [editSoftwareRequesting, setEditSoftwareRequesting] = useState(false);
    const [editSoftwareThumnail, setEditSoftwareThumnail] = useState(null);
    const [editSoftwareFile, setEditSoftwareFile] = useState(null);

    const handleSoftwareModalShowAndEditSoftware = (software) => {
        setEditSoftware(software);
        setEditSoftwareModalShow(true);
    }

    const onSelectEditSoftwareThumnail = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setEditSoftwareThumnail(null);
            return
        }
        setEditSoftwareThumnail(e.target.files[0]);
    }

    const onSelectEditSoftwareFile = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setEditSoftwareFile(null);
            return
        }
        setEditSoftwareFile(e.target.files[0]);
    }


    const handleEditSoftwareSubmit = async (e) => {
        e.preventDefault();

        if (editSoftwareRequesting) {
            Swal.fire({
                title: 'Please wait!',
                text: 'We are currently processing one of your request.',
                icon: 'info',
                showConfirmButton: false,
                allowOutsideClick: true,
                allowEscapeKey: false,
                allowEnterKey: true,
            });
            return;
        }

        setEditSoftwareRequesting(true);

        if (
            !editSoftware.softwareName
        ) {
            Swal.fire({
                title: 'Error!',
                text: 'Please fill required fields',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            setEditSoftwareRequesting(false);
            return;
        }

        const formData = new FormData();
        formData.append('id', editSoftware.id);
        formData.append('softwareName', editSoftware.softwareName);

        if (editSoftwareThumnail !== null) {
            formData.append('softwareThumnail', editSoftwareThumnail);
        }
        if (editSoftwareFile !== null) {
            formData.append('softwareFile', editSoftwareFile);
        }

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };

        const { data } = await api.updateSoftwareData(formData, config);
        if (data.success) {
            Swal.fire({
                title: 'Success!',
                text: data.message,
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            setEditSoftwareThumnail(null);
            setEditSoftwareFile(null);
            let index = softwaresData.findIndex(b => b.id === editSoftware.id);
            softwaresData[index] = data.software;
            setSoftwaresData([...softwaresData]);

            document.getElementById('editSoftware').reset();
            setEditSoftwareRequesting(false);
        }
        else {
            Swal.fire({
                title: 'Error!',
                text: data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            setEditSoftwareRequesting(false);
        }
    }

    return (
        <div>
            <div>
                <Paper style={{ padding: '15px' }} elevation={8}>
                    <form onSubmit={handleSubmit} id="softwareForm">
                        <Typography className="heading" variant="h4">Softwares</Typography>
                        <Divider
                            variant="fullWidth"
                            component="hr"
                        />
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={12} md={6} lg={3}>
                                <TextField
                                    id="softwareName"
                                    name="softwareName"
                                    label="Software Name"
                                    variant="outlined"
                                    size='small'
                                    fullWidth
                                    onChange={(e) => {
                                        setSoftwareForm({ ...softwareForm, softwareName: e.target.value })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <Form.Control type="file" onChange={(e) => onSelectsoftwareThumnail(e)} size="md" />
                                <Form.Label className='fw-bolder'>Software Thumnail</Form.Label>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <Form.Control type="file" onChange={(e) => onSelectsoftwareFile(e)} size="md" />
                                <Form.Label className='fw-bolder'>Software Setup</Form.Label>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} mt={1} justifyContent="center">
                            <Grid item xs={12} md={4} lg={3}>
                                <Button variant="contained" color="primary" type="submit" fullWidth>
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                        <div>
                        </div>
                    </form>
                </Paper>
                <Paper style={{ marginTop: '15px' }} elevation={8}>
                    <TableSoftwares
                        softwaresData={softwaresData}
                        setSoftwaresData={setSoftwaresData}

                        handleSoftwareModalShowAndEditSoftware={handleSoftwareModalShowAndEditSoftware}
                    />
                </Paper>
            </div>
            <div>
                <Modal
                    show={editSoftwareModalShow}
                    onHide={() => setEditSoftwareModalShow(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Update Past Paper Record
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* if edit course or editcourse.id then show else dont show */}
                        {editSoftware && editSoftware.id ? (
                            <div>
                                <EditSoftwareForm
                                    editSoftware={editSoftware}
                                    setEditSoftware={setEditSoftware}
                                    onSelectEditSoftwareThumnail={onSelectEditSoftwareThumnail}
                                    onSelectEditSoftwareFile={onSelectEditSoftwareFile}
                                    handleEditSoftwareSubmit={handleEditSoftwareSubmit}
                                />
                            </div>
                        ) : (
                            <div>
                                Loading...
                            </div>
                        )}

                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}


export default AddSoftware;