import React, { useEffect, useState } from 'react';
// import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
// import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { Link, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { signIn } from '../../actions/auth';
import Logo from "../../images/logo.png";
import Swal from 'sweetalert2';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link to={`/`}>
                CourseEdx.com
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const initialState = { email: "", password: "" }

const theme = createTheme();

export default function SignIn({ user, setUser }) {
    const [signInForm, setSignInForm] = useState(initialState);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [requesting, setRequesting] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (requesting) {
            Swal.fire({
                title: 'Please wait',
                text: 'We are processing your request',
                icon: 'info',
                showConfirmButton: true,
                showCancelButton: false,
                allowOutsideClick: false,
                allowEscapeKey: true,
                allowEnterKey: true,
            });
            return;
        }

        setRequesting(true);
        dispatch(signIn(signInForm, navigate, setUser, requesting, setRequesting));
    };


    const handleChange = (e) => {
        setSignInForm({ ...signInForm, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        if (user) {
            navigate("/");
        }
    }, [user, navigate]);


    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                {/* <CssBaseline /> */}
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img src={Logo} style={{ padding: 5, width: 110, height: 110, objectFit: "contain", backgroundSize: "cover", border: "1px solid black", borderRadius: "50%" }} alt="Logo" />
                    <Typography component="h1" variant="h5" className='mt-2'>
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            value={signInForm.email}
                            onChange={handleChange}
                            label="Email Address"
                            name="email"
                            size='small'
                            autoComplete="email"
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            value={signInForm.password}
                            onChange={handleChange}
                            size='small'
                            autoComplete="current-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Link to={`/signin`}>
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item xs={12}>
                                <Link to={`/signup`}>
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}