import React from 'react';

function ComingSoon() {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50vh',
            }}
        >
            <div
                className="container m-4"
            >
                <div
                    className="card"
                    style={{
                        borderRadius: '10px',
                        padding: '20px',
                        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.50)',
                        background: 'linear-gradient(to right, #f5f5f5, #f5f5f5)',
                    }}
                >
                    <div
                        className="card-body"
                    >
                        <h4
                            className="card-title"
                            style={{
                                fontWeight: 'bold',
                                color: '#000',
                                fontSize: '1.5rem',
                            }}
                        >Coming Soon</h4>
                        <p
                            className="card-text"
                            style={{
                                fontSize: '1.2rem',
                                fontWeight: 'bold',
                                color: '#000',
                            }}
                        >This Service will be available soon.
                            <span
                                style={{
                                    fontSize: '1.5rem',
                                    fontWeight: 'bold',
                                    color: '#ff0000',
                                }}
                            >
                                Thank You!
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ComingSoon;