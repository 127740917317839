import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Container, Row, Table } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import "./css/UserDetails.css";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import * as api from "../../api/index";
import Swal from 'sweetalert2';
import userAlternateImage from "../../images/userImage.png";
import { USERS_IMAGES } from '../../constants/actionTypes';
import { Grid, Paper } from '@mui/material';

function UserDetails() {
    const navigate = useNavigate();
    const [user, setUser] = useState([]);
    const [teachCourses, setTeachCourses] = useState([]);
    const [studyCourses, setStudyCourses] = useState([]);
    const [userProfileImage, setUserProfileImage] = useState(userAlternateImage);
    var { userId } = useParams();


    const handleStudyCourseStatusChange = async (e, studentCourse) => {
        const status = e.target.value === "1" ? true : false;
        try {
            const { data } = await api.changeStudentCourseStatusFromTransaction({ "courseId": studentCourse.id, "status": status });
            if (data.success) {
                getUserDetails(userId);
                // let course = data.course;
                // let filtered = studyCourses.map((c) => c._id === course._id ? course : c);
                // setStudyCourses(filtered);
                Swal.fire({
                    title: 'Good job!',
                    text: data.message,
                    icon: 'success',
                    showConfirmButton: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                })
            }
            else {
                Swal.fire(
                    'Failed!',
                    data.error,
                    'error'
                )
            }
        } catch (error) {
            Swal.fire(
                'Failed!',
                error,
                'error'
            );
        }
    }


    const handleTeachCourseStatusChange = async (e, instructorCourse) => {
        const status = e.target.value === "1" ? true : false;
        try {
            const { data } = await api.changeInstructorCourseStatus({ "courseId": instructorCourse.id, "status": status });
            if (data.success) {
                getUserDetails(userId);
                // let course = data.course;
                // let filtered = teachCourses.map((c) => c._id === course._id ? course : c);
                // console.log(filtered);
                // setTeachCourses(filtered);
                Swal.fire({
                    title: 'Good job!',
                    text: data.message,
                    icon: 'success',
                    showConfirmButton: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                })
            }
            else {
                Swal.fire(
                    'Failed!',
                    data.error,
                    'error'
                )
            }
        } catch (error) {
            Swal.fire(
                'Failed!',
                error,
                'error'
            );
        }
    }


    // wrap getUserDetails in useCallback to avoid re-rendering
    const getUserDetails = useCallback(async (id) => {
        if (!userId) {
            return false;
        }
        try {
            const { data } = await api.getUserDetails(id);
            if (data.success) {
                setUser(data.user);
                setStudyCourses(data.studyCourses);
                setTeachCourses(data.teachCourses);
            }
            else {
                Swal.fire(
                    'failed!',
                    "There was a server side error please contact the developer for resolving issue",
                    'error'
                )
            }
        } catch (error) {

        }
    }, [userId]);


    const onChangeIsAccountValid = async (e) => {
        console.log(e.target.value);
        const isValid = e.target.value === "1" ? 1 : 0;
        try {
            const { data } = await api.changeUserAccountValidityStatus({ "userId": userId, "isValid": isValid });
            if (data.success) {
                Swal.fire({
                    title: 'Good job!',
                    text: data.message,
                    icon: 'success',
                    showConfirmButton: true,
                });
                setUser({ ...user, isValid: isValid });
            }
            else {
                Swal.fire({
                    title: 'Failed!',
                    text: data.message,
                    icon: 'warning',
                    showConfirmButton: true,
                })
            }
        } catch (error) {
            Swal.fire({
                title: 'Failed!',
                text: "There was a server side error please contact the developer for resolving issue",
                icon: 'warning',
                showConfirmButton: true,
            });
        }
    }



    useEffect(() => {
        getUserDetails(userId);
    }, [userId, getUserDetails]);

    useEffect(() => {
        if (user?.userImage) {
            setUserProfileImage(`${USERS_IMAGES}${user?.userImage}`);
        }
    }, [user]);



    return (
        <div style={{ marginTop: "55px" }}>
            {!user ? (
                <div className='mt-2'>
                    No Data found
                </div>
            ) : (
                <>
                    <div className='bg-light'>
                        <Button variant="info mx-1 my-1 btn-sm" onClick={() => navigate(-1)}><ArrowBackIcon style={{ width: 20, height: 20 }} /> Back</Button>
                    </div>
                    <Paper elevation={3} className='mt-4 bg-dark'>
                        <Grid
                            container
                            spacing={3}
                            justifyContent="center"
                            alignItems="center"
                            wrap='wrap'
                            className='p-4'
                        >
                            <Grid item xs={12} md={4} lg={4}>
                                <img src={userProfileImage} className="mx-auto" alt="Profile ..." style={{
                                    borderRadius: "50%",
                                    width: "250px",
                                    height: "250px",
                                    padding: "10px",
                                }}
                                />
                            </Grid>
                            <Grid item xs={12} md={8} lg={8} className="user_details__text mt-4">
                                <div>First Name: {user?.firstName}</div>
                                <div>Last Name: {user?.lastName}</div>
                                <div>Email: {user?.email}</div>
                                <div>Role: {user?.role}</div>
                                <div>Account Added Date: {user?.userAddedDate}</div>
                                <div>
                                    <select
                                        onChange={(e) => onChangeIsAccountValid(e)}
                                        value={user?.isValid}
                                    >
                                        <option value="1"
                                        >Active</option>

                                        <option value="0"
                                        >Inactive</option>
                                    </select>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Container
                        style={{
                            backgroundColor: "rgba(0,0,0,0.1)",
                            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
                            marginTop: "10px",
                        }}
                    >
                        <Row>
                            <Col xs={12} md={12} lg={6}>
                                <Container className='mt-4'>
                                    <h6
                                        className='text-center fw-bold'
                                    >Currently Studying Courses</h6>
                                    <Table size='sm' striped bordered hover style={{ textAlign: "start" }} responsive className='bg-light rounded border-top-0 border-left-0 border-right-0 border-bottom-0'>
                                        <thead>
                                            <tr>
                                                <th width="5%">S.No</th>
                                                <th>Course Name</th>
                                                <th width="5%">Pricing</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {!studyCourses ? (
                                                <tr>
                                                    <td colSpan="4">No Data Found</td>
                                                </tr>
                                            ) : (
                                                <>
                                                    {studyCourses.map((course, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{course?.courseTitle?.slice(0, 50)}</td>
                                                                <td>{course?.coursePricing}</td>
                                                                <td>
                                                                    <select className="course__status"
                                                                        value={course.approvedByAdmin ? "1" : "0"}
                                                                        onChange={(e) => handleStudyCourseStatusChange(e, course)}>
                                                                        <option value="1">Approved</option>
                                                                        <option value="0">Not Approved</option>
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </>
                                            )}
                                        </tbody>
                                    </Table>
                                </Container>
                            </Col>
                            <Col xs={12} md={12} lg={6}>
                                <Container className='mt-4'>
                                    <h6
                                        className='text-center fw-bold'
                                    >Currently Instructing Courses</h6>
                                    <Table size='sm' striped bordered hover style={{ textAlign: "start" }} responsive className='bg-light rounded
                                    border-top-0 border-left-0 border-right-0 border-bottom-0
                                    '>
                                        <thead>
                                            <tr>
                                                <th width="5%">S.No</th>
                                                <th>Course Name</th>
                                                <th width="5%">Pricing</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {!teachCourses ? (
                                                <tr>
                                                    <td colSpan="4">No Data Found</td>
                                                </tr>
                                            ) : (
                                                <>
                                                    {teachCourses.map((course, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{course?.courseTitle?.slice(0, 50)}</td>
                                                                <td>{course?.coursePricing}</td>
                                                                <td>
                                                                    <select className="course__status"
                                                                        value={course.approvedByAdmin ? "1" : "0"}
                                                                        onChange={(e) => handleTeachCourseStatusChange(e, course)}>
                                                                        <option value="1">Approved</option>
                                                                        <option value="0">Not Approved</option>
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </>
                                            )}
                                        </tbody>
                                    </Table>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </>
            )}
        </div>
    );
}

export default UserDetails;