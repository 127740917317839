import React, { useState } from 'react';
import { TextField, Typography, Button, Grid, Paper } from '@mui/material';
import Collapse from 'react-bootstrap/Collapse';
import AddBoxIcon from '@mui/icons-material/AddBox';
import RemoveIcon from '@mui/icons-material/Remove';
import Select from 'react-select'
import Swal from 'sweetalert2';
import * as api from "../../../api/index";
import ContactsTable from './ContactsTable';


const initialForm = {
  contactFor: '',
  contact: ''
}

function AddContacts({ contacts, setContacts, options }) {
  const [instituteId, setInstituteId] = useState('');
  const [contactForm, setContactForm] = useState(initialForm);
  const [open, setOpen] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (contactForm.contactFor === '' || contactForm.contact === '' || instituteId === '') {
      Swal.fire({
        title: 'Error!',
        text: 'Please fill all the fields',
        icon: 'error',
        confirmButtonText: 'Ok'
      })
    } else {
      const formData = {
        instituteId: instituteId,
        contactFor: contactForm.contactFor,
        contact: contactForm.contact
      }
      const { data } = await api.addContactToInstitute(formData);
      if (data.success) {
        Swal.fire({
          title: 'Success!',
          text: data.message,
          icon: 'success',
          confirmButtonText: 'Ok'
        })
        setContactForm(initialForm);
        setContacts([...contacts, data.contact]);
      }
      else {
        Swal.fire({
          title: 'Error!',
          text: data.error,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }

    }
  }

  const handleChange = (e) => {
    setContactForm({ ...contactForm, [e.target.name]: e.target.value });
  }

  const handleInstituteChange = (selectedOption) => {
    setInstituteId(selectedOption.value);
  }


  return (
    <div>
      <div>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => setOpen(!open)}
          aria-controls="add-institute-form-container"
          aria-expanded={open}
        >
          Add Contact Numbers to Institute Data &nbsp; {open ? <RemoveIcon /> : <AddBoxIcon />}
        </Button>
        <Collapse in={open}>
          <div id="add-institute-form-container">
            <Paper style={{ padding: '15px' }} elevation={8}>
              <form onSubmit={handleSubmit}>
                <Typography className="heading" variant="h4">Add Contact Numbers</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={4}>
                    <Select options={options} onChange={handleInstituteChange} />
                    <label className='fw-bolder'>Select Institute</label>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <TextField
                      label="Contact For"
                      name="contactFor"
                      value={contactForm.contactFor}
                      onChange={handleChange}
                      size="small"
                      inputProps={{ maxLength: 100 }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <TextField
                      label="Contact Number"
                      value={contactForm.contact}
                      name="contact"
                      onChange={handleChange}
                      size="small"
                      inputProps={{ maxLength: 100 }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={1} justifyContent="center">
                  <Grid item xs={12} md={4} lg={3}>
                    <Button variant="contained" color="primary" type="submit" fullWidth>
                      Submit
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={4} lg={2}>
                    <Button variant="contained" color="error" type="reset" fullWidth>
                      Reset Form
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </div>
        </Collapse>
      </div>
      <div>
        <ContactsTable contactsData={contacts} setContactsData={setContacts} />
      </div>
    </div>
  )
}

export default AddContacts;