import React, { useEffect } from 'react';
import SideBar from "../StudentSideBar/StudentSidebar";
import { Routes, Route, useNavigate, Link } from "react-router-dom";
import "./StudentHome.css";
import MyCourses from "../MyCourses/MyCourses";
import CourseDetails from "../MyCourses/CourseDetails.js";
import { useDispatch } from 'react-redux';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { logoutFunc } from '../../../actions/auth';
import decode from "jwt-decode";
import ApprovalCourses from '../MyCourses/ApprovalCourses';
import { Button } from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';


const StudentDashboard = () => {

    return (
        <Container className='mt-4'>
            <Row>

                <Col xs={12} md={12} lg={12}>
                    <Card
                        text={'white'}
                        className="mb-2"
                        style={{
                            background: "rgb(11 142 150)"
                        }}
                    >
                        <div style={{ margin: "5px" }}>
                            <Link to={`/studentDashboard/myCourses`}>
                                <Button
                                    size='small'
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<PreviewIcon />}
                                    className="m-1"
                                >
                                    View all Courses
                                </Button>
                            </Link>
                            <Link to={`/studentDashboard/approvalCourses`}>
                                <Button
                                    size='small'
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<PreviewIcon />}
                                    className="m-1"
                                >
                                    View Approval Courses
                                </Button>
                            </Link>
                        </div>
                    </Card>
                </Col>

            </Row>
        </Container>
    )
}


function StudentHome({ user, setUser }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // end 

    useEffect(() => {
        const token = user?.token;
        if (token) {
            try {
                const decodedToken = decode(token);
                if (decodedToken.exp * 1000 < new Date().getTime()) {
                    dispatch(logoutFunc(setUser, navigate));
                }
            } catch (error) {
                localStorage.removeItem("profile");
            }
        }
        else {
            localStorage.removeItem("profile");
        }
        if (!user) {
            navigate("/");
            return
        }
    }, [dispatch, user, navigate, setUser]);

    return (
        <div>
            <SideBar />
            <div className='student_home_content'>
                <Routes>
                    <Route path="/" exact element={<StudentDashboard />} />
                    <Route path='/myCourses' exact element={<MyCourses user={user} setUser={setUser} />} />
                    <Route path='/courseDetails/:courseId' exact element={<CourseDetails user={user} setUser={setUser} />} />
                    <Route path="/approvalCourses" element={<ApprovalCourses user={user} setUser={setUser} />} />
                </Routes>
            </div>
        </div>
    )
}

export default StudentHome;
