import React from 'react';
import { Navbar, Nav, Container, NavDropdown, Button } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { logoutFunc } from '../../actions/auth';
import "./css/MainNavbar.css";
// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

function MainNavbar({ user, setUser, cartItems, setCartItems }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logout = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Are you sure you want to logout!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Logout!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(logoutFunc(setUser, navigate))
            }
        })
    }


    return (
        <Navbar bg="dark" expand="lg" variant="dark" style={{ position: "fixed", width: "100%", zIndex: 100 }}>
            <Container fluid>
                <Link to={`/admin`}>
                    <Navbar.Brand>CourseEdx Admin Dashboard</Navbar.Brand>
                </Link>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav
                        className="me-auto my-2 my-lg-0"
                        style={{ maxHeight: '400px' }}
                        navbarScroll
                    >
                        <Link to={`/admin`}>
                            <Button variant="outline-primary btn-sm mx-1 my-1">Admin Home</Button>
                        </Link>
                        <Link to={`/`}>
                            <Button variant="outline-primary btn-sm mx-1 my-1">User Home</Button>
                        </Link>
                        <NavDropdown title="Users " id="navbarScrollingDropdown">
                            <NavDropdown.Item as={Link} to={`/admin/users`}>Users</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={`/admin/transactions`}>Transactions</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="Courses " id="navbarScrollingDropdown">
                            <NavDropdown.Item as={Link} to={`/admin/instructorsCourses`}>Instructors Courses</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={`/admin/studentsCourses`}>Students Courses</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/assignCourse">Assign Course</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#action5">
                                Something else here
                            </NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="Blogs " id="navbarScrollingDropdown">
                            <NavDropdown.Item as={Link} to={`/admin/postBlog`}>Post Blog</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={`/admin/blogs`}>All Blogs</NavDropdown.Item>
                            <NavDropdown.Divider />
                        </NavDropdown>
                        <NavDropdown title="Generals " id="navbarScrollingDropdown">
                            <NavDropdown.Item as={Link} to={`/admin/generalManagement`}>General Management</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={`/admin/institutes`}>Institutes</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={`/admin/pastPapers`}>Past Papers</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={`/admin/admissions`}>Admissions</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={`/admin/downloads`}>Downloads</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={`/admin/studentVerification`}>Student Verification</NavDropdown.Item>
                            <NavDropdown.Divider />
                        </NavDropdown>
                    </Nav>
                    {user && (
                        <>
                            <Link to={`/admin/adminSignup`}>
                                <Button variant="outline-primary mx-1 my-1 btn-sm">New Admin Account</Button>
                            </Link>
                            <Button variant="outline-primary mx-1 my-1 btn-sm" onClick={logout}>Logout</Button>
                        </>
                    )}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default MainNavbar;