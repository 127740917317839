import React, { useState } from 'react';
import { TextField, Typography, Button, Grid, Paper } from '@mui/material';
import Collapse from 'react-bootstrap/Collapse';
import AddBoxIcon from '@mui/icons-material/AddBox';
import RemoveIcon from '@mui/icons-material/Remove';
import Select from 'react-select';
import Swal from 'sweetalert2';
import * as api from "../../../api/index";
import AddressesTable from './AddressesTable';


const initialForm = {
  addressFor: '',
  address: '',
  googleMapLocation: '',
}


function AddAddresses({ addresses, setAddresses, options }) {
  const [instituteId, setInstituteId] = useState('');
  const [addressForm, setAddressForm] = useState(initialForm);
  const [open, setOpen] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (addressForm.addressFor === '' || addressForm.address === '' || addressForm.googleMapLocation === '' || instituteId === '') {
      Swal.fire({
        title: 'Error!',
        text: 'Please fill all the fields',
        icon: 'error',
        confirmButtonText: 'Ok'
      })
    } else {
      const formData = {
        instituteId: instituteId,
        addressFor: addressForm.addressFor,
        address: addressForm.address,
        googleMapLocation: addressForm.googleMapLocation
      }
      const { data } = await api.addAddressToInstitute(formData);
      if (data.success) {
        Swal.fire({
          title: 'Success!',
          text: data.message,
          icon: 'success',
          confirmButtonText: 'Ok'
        })
        setAddressForm(initialForm);
        setAddresses([...addresses, data.address])
      }
      else {
        Swal.fire({
          title: 'Error!',
          text: data.error,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }

    }
  }

  const handleChange = (e) => {
    setAddressForm({ ...addressForm, [e.target.name]: e.target.value });
  }

  const handleInstituteChange = (selectedOption) => {
    setInstituteId(selectedOption.value);
  }


  return (
    <div>
      <div>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => setOpen(!open)}
          aria-controls="add-institute-form-container"
          aria-expanded={open}
        >
          Add Addresses to Institute Data &nbsp; {open ? <RemoveIcon /> : <AddBoxIcon />}
        </Button>
        <Collapse in={open}>
          <div id="add-institute-form-container">
            <Paper style={{ padding: '15px' }} elevation={8}>
              <form onSubmit={handleSubmit}>
                <Typography className="heading" variant="h4">Add Address</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={3}>
                    <Select options={options} onChange={handleInstituteChange} />
                    <label className='fw-bolder'>Select Institute</label>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <TextField
                      label="Address for"
                      name="addressFor"
                      value={addressForm.addressFor}
                      onChange={handleChange}
                      size="small"
                      inputProps={{ maxLength: 100 }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <TextField
                      label="Address"
                      name="address"
                      value={addressForm.address}
                      onChange={handleChange}
                      size="small"
                      inputProps={{ maxLength: 100 }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <TextField
                      label="Google Map Location"
                      name="googleMapLocation"
                      value={addressForm.googleMapLocation}
                      onChange={handleChange}
                      size="small"
                      inputProps={{ maxLength: 100 }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={1} justifyContent="center">
                  <Grid item xs={12} md={4} lg={3}>
                    <Button variant="contained" color="primary" type="submit" fullWidth>
                      Submit
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={4} lg={2}>
                    <Button variant="contained" color="error" type="reset" fullWidth>
                      Reset Form
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </div>
        </Collapse>
      </div>
      <div>
        <AddressesTable addressesData={addresses} setAddressesData={setAddresses} />
      </div>
    </div>
  )
}

export default AddAddresses;