import { GET_VIDEOS_OF_COURSE_OF_INSTRUCTOR, ADD_VIDEO_TO_COURSE_OF_INSTRUCTOR, DELETE_COURSE_VIDEO_OF_INSTRUCTOR } from "../constants/actionTypes";

export const courseVideosOfInstructor = (courseVideosOfInstructorState = [], action) => {
    switch (action.type) {
        case GET_VIDEOS_OF_COURSE_OF_INSTRUCTOR:
            return action.payload;
        case ADD_VIDEO_TO_COURSE_OF_INSTRUCTOR:
            return [...courseVideosOfInstructorState, action.payload];
        case DELETE_COURSE_VIDEO_OF_INSTRUCTOR:
            return courseVideosOfInstructorState.filter((courseVideo) => courseVideo.id !== action.payload);
        default:
            return courseVideosOfInstructorState;
    }
}