import './App.css';
import { useEffect, useState } from 'react';
import Home from './components/Home/Home';
import { Routes, Route } from "react-router-dom";
import InstructorHome from './components/InstructorDashboard/Home/InstructorHome';
import StudentHome from './components/StudentDashboard/Home/StudentHome';
import AdminHome from './components/AdminDashboard/AdminHome';

function App() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const [cartItems, setCartItems] = useState(() => {
    const cart = window.localStorage.getItem("cartItems");
    return cart !== null
      ? JSON.parse(cart)
      : [];
  });

  useEffect(() => {
    window.localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);


  useEffect(() => {
    if (user) {
      window.localStorage.setItem("profile", JSON.stringify(user));
    }
  }, [user]);

  return (
    <div className="App">
      <Routes>
        <Route path="/*" exact element={<Home user={user} setUser={setUser} cartItems={cartItems} setCartItems={setCartItems} />} />
        <Route path="/admin/*" exact element={<AdminHome user={user} setUser={setUser} cartItems={cartItems} setCartItems={setCartItems} />} />
        <Route path="/instructorDashboard/*" element={<InstructorHome user={user} setUser={setUser} />} />
        <Route path="/studentDashboard/*" element={<StudentHome user={user} setUser={setUser} />} />
      </Routes>
    </div>
  );
}

export default App;


// "build": "react-scripts --max_old_space_size=4096 build"
// "build": "react-scripts build",
// "build": "react-scripts --max_old_space_size=4096 build",
