import React, { useCallback, useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import AllInstitutes from './AllInstitutes';
import AddInstitute from './AddInstitute';
import AddEmails from './AddEmails';
import AddContacts from './AddContacts';
import AddAddresses from './AddAddresses';
import AddStaff from './AddStaff';
import * as api from "../../../api/index";
import Swal from 'sweetalert2';
import { CircularProgress } from "@mui/material";
import EditInstituteForm from './EditInstituteForm';
import Modal from 'react-bootstrap/Modal';


function Institutes() {
    const [institutes, setInstitutes] = useState([]);
    const [emails, setEmails] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [addresses, setAddresses] = useState([]);
    const [staff, setStaff] = useState([]);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [affiliationsOptions, setAffiliationsOptions] = useState([]);
    const [instituteSectorsOptions, setInstituteSectorsOptions] = useState([]);
    const [instituteTypesOptions, setInstitutesTypesOptions] = useState([]);
    const [instituteProgramsOptions, setInstituteProgramsOptions] = useState([]);
    const [provincesOptions, setProvincesOptions] = useState([]);
    const [citiesOptions, setCitiesOptions] = useState([]);


    const getInstitutes = useCallback(async () => {
        const { data } = await api.getInstitutesDataForAdmin();
        if (data.success) {
            setInstitutes(data.institutes);

            setAddresses(data.instituteAddresses);
            setContacts(data.instituteContacts);
            setEmails(data.instituteEmails);
            setStaff(data.instituteStaff);
            setOptions(data.instituteOptions);

            setAffiliationsOptions(data.affiliationsOptions);
            setInstituteSectorsOptions(data.instituteSectorsOptions);
            setInstitutesTypesOptions(data.instituteTypesOptions);
            setInstituteProgramsOptions(data.instituteProgramsOptions);
            setProvincesOptions(data.provincesOptions);
            setCitiesOptions(data.citiesOptions);

            setLoading(false);
        }
        else {
            Swal.fire({
                title: 'Error!',
                text: data.error,
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            setLoading(false);
            setError(true);
        }
    }, []);

    useEffect(() => {
        getInstitutes();
    }, [getInstitutes]);



    const [editInstituteModalShow, setEditInstituteModalShow] = useState(false);
    const [editInstitute, setEditInstitute] = useState(null);
    const [editInstituteRequesting, setEditInstituteRequesting] = useState(false);
    const [editInstituteImage, setEditInstituteImage] = useState(null);
    const [editInstituteLogo, setEditInstituteLogo] = useState(null);
    const [editInsCityId, setEditInsCityId] = useState('');
    const [editInsProvinceId, setEditInsProvinceId] = useState('');
    const [editInsSectorId, setEditInsSectorId] = useState('');
    const [editInsInstituteTypes, setEditInsInstituteTypes] = useState([]);
    const [editInsPrograms, setEditInsPrograms] = useState([]);
    const [editInsAffiliations, setEditInsAffiliations] = useState([]);

    const handleInstituteModalShowAndEditInstitute = (institute) => {
        setEditInstitute(institute);

        setEditInsCityId(institute.cityId)
        setEditInsProvinceId(institute.provinceId)
        setEditInsSectorId(institute.sectorId)
        setEditInsInstituteTypes(JSON.parse(institute.instituteTypes))
        setEditInsPrograms(JSON.parse(institute.programs))
        setEditInsAffiliations(JSON.parse(institute.affiliations))

        setEditInstituteModalShow(true);
    }

    const handleEditInstituteChange = (e) => {
        setEditInstitute({ ...editInstitute, [e.target.name]: e.target.value });
    }

    const onSelectEditInstituteImage = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setEditInstituteImage(null);
            return
        }
        setEditInstituteImage(e.target.files[0]);
    }

    const onSelectEditInstituteLogo = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setEditInstituteLogo(null);
            return
        }
        setEditInstituteLogo(e.target.files[0]);
    }


    const handleEditInsCityIdChange = (e) => {
        setEditInsCityId(e.value);
    }
    const handleEditInsProvinceIdChange = (e) => {
        setEditInsProvinceId(e.value);
    }
    const handleEditInsSectorIdChange = (e) => {
        setEditInsSectorId(e.value);
    }

    const handleEditInsAffiliationsChange = (e) => {
        let arr = [];
        e.forEach(element => {
            arr.push(element.value);
        });

        setEditInsAffiliations(arr);
    }

    const handleEditInsInstituteTypesChange = (e) => {
        let arr = [];
        e.forEach(element => {
            arr.push(element.value);
        });

        setEditInsInstituteTypes(arr);
    }

    const handleEditInsProgramsChange = (e) => {
        let arr = [];
        e.forEach(element => {
            arr.push(element.value);
        });
        setEditInsPrograms(arr);
    }

    const handleEditInstituteSubmit = async (e) => {
        e.preventDefault();
        if (editInstituteRequesting) {
            Swal.fire({
                title: 'Please wait',
                text: 'We are adding your institute',
                icon: 'info',
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: true,
            });
            return;
        }

        setEditInstituteRequesting(true);

        if (
            !editInstitute.instituteName
            || !editInsProvinceId
            || !editInsCityId
            || !editInsSectorId
            || !editInsAffiliations.length
            || !editInsInstituteTypes.length
            || !editInsPrograms.length
        ) {
            Swal.fire({
                title: 'Error!',
                text: 'Please fill required fields',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            setEditInstituteRequesting(false);
            return;
        }

        const formData = new FormData();
        formData.append('id', editInstitute.id);
        formData.append('instituteName', editInstitute.instituteName);
        formData.append('website', editInstitute.website);
        formData.append('founderName', editInstitute.founderName);
        formData.append('dateOfEstablishment', editInstitute.dateOfEstablishment);
        formData.append('facebook', editInstitute.facebook);
        formData.append('twitter', editInstitute.twitter);
        formData.append('linkedin', editInstitute.linkedin);
        formData.append('instagram', editInstitute.instagram);
        formData.append('results', editInstitute.results);

        formData.append('provinceId', editInsProvinceId);
        formData.append('cityId', editInsCityId);
        formData.append('sectorId', editInsSectorId);
        formData.append('affiliations', JSON.stringify(editInsAffiliations));
        formData.append('instituteTypes', JSON.stringify(editInsInstituteTypes));
        formData.append('programs', JSON.stringify(editInsPrograms));

        if (editInstituteImage !== null) {
            formData.append('instituteImage', editInstituteImage);
        }
        if (editInstituteLogo !== null) {
            formData.append('instituteLogo', editInstituteLogo);
        }

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };

        const { data } = await api.updateInstituteData(formData, config);
        if (data.success) {
            Swal.fire({
                title: 'Success!',
                text: data.message,
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            setEditInstituteImage(null);
            setEditInstituteLogo(null);
            let index = institutes.findIndex(ins => ins.id === editInstitute.id);
            institutes[index] = data.institute;
            setInstitutes([...institutes]);

            document.getElementById('add-institute-form').reset();

            const optionsArray = [...options, { value: data.institute.id, label: data.institute.instituteName }];
            setOptions(optionsArray);
            setEditInstituteRequesting(false);
        }
        else {
            Swal.fire({
                title: 'Error!',
                text: data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            setEditInstituteRequesting(false);
        }

    }





    return (
        <div>

            <div className='m-1 p-4'>
                {error && <div className='text-danger text-center'>Something went wrong. Please try again later. or contact the developer sahib to check the masla</div>}
                {loading ?
                    <div className='text-center'>
                        <CircularProgress />
                    </div>
                    : (
                        <Tabs
                            defaultActiveKey="allInstitutes"
                            transition={true}
                            id="noanim-tab-example"
                            className="mb-3"
                            style={{
                                padding: "3px",
                                backgroundColor: "rgb(11 142 150)",
                            }}
                        >
                            <Tab eventKey="allInstitutes" title="All Institutes">
                                <AllInstitutes
                                    institutesData={institutes}
                                    setInstitutesData={setInstitutes}

                                    options={options}
                                    setOptions={setOptions}

                                    emails={emails}
                                    contacts={contacts}
                                    addresses={addresses}
                                    staff={staff}

                                    setEmails={setEmails}
                                    setContacts={setContacts}
                                    setAddresses={setAddresses}
                                    setStaff={setStaff}

                                    handleInstituteModalShowAndEditInstitute={handleInstituteModalShowAndEditInstitute}
                                />
                            </Tab>
                            <Tab eventKey="addInstitute" title="Add Institute">
                                <AddInstitute
                                    setInstitutes={setInstitutes}
                                    institutesData={institutes}
                                    setOptions={setOptions}
                                    options={options}

                                    affiliationsOptions={affiliationsOptions}
                                    instituteSectorsOptions={instituteSectorsOptions}
                                    instituteTypesOptions={instituteTypesOptions}
                                    instituteProgramsOptions={instituteProgramsOptions}
                                    provincesOptions={provincesOptions}
                                    citiesOptions={citiesOptions}
                                />
                            </Tab>
                            <Tab eventKey="addEmail" title="E-Mails">
                                <AddEmails emails={emails} setEmails={setEmails} options={options} />
                            </Tab>
                            <Tab eventKey="addContacts" title="Contacts">
                                <AddContacts contacts={contacts} setContacts={setContacts} options={options} />
                            </Tab>
                            <Tab eventKey="addAddresses" title="Addresses">
                                <AddAddresses addresses={addresses} setAddresses={setAddresses} options={options} />
                            </Tab>
                            <Tab eventKey="addStaff" title="Staff">
                                <AddStaff staff={staff} setStaff={setStaff} options={options} />
                            </Tab>
                        </Tabs>
                    )}
            </div>
            <div>
                <Modal
                    show={editInstituteModalShow}
                    onHide={() => setEditInstituteModalShow(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Edit Institute
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* if edit course or editcourse.id then show else dont show */}
                        {editInstitute && editInstitute.id ? (
                            <div>
                                <EditInstituteForm
                                    editInstitute={editInstitute}
                                    setEditInstitute={setEditInstitute}
                                    handleEditInstituteSubmit={handleEditInstituteSubmit}
                                    handleEditInstituteChange={handleEditInstituteChange}
                                    setEditInstituteModalShow={setEditInstituteModalShow}
                                    onSelectEditInstituteImage={onSelectEditInstituteImage}
                                    onSelectEditInstituteLogo={onSelectEditInstituteLogo}


                                    affiliationsOptions={affiliationsOptions}
                                    instituteSectorsOptions={instituteSectorsOptions}
                                    instituteTypesOptions={instituteTypesOptions}
                                    instituteProgramsOptions={instituteProgramsOptions}
                                    provincesOptions={provincesOptions}
                                    citiesOptions={citiesOptions}


                                    handleEditInsCityIdChange={handleEditInsCityIdChange}
                                    handleEditInsProvinceIdChange={handleEditInsProvinceIdChange}
                                    handleEditInsSectorIdChange={handleEditInsSectorIdChange}
                                    handleEditInsAffiliationsChange={handleEditInsAffiliationsChange}
                                    handleEditInsInstituteTypesChange={handleEditInsInstituteTypesChange}
                                    handleEditInsProgramsChange={handleEditInsProgramsChange}
                                />
                            </div>
                        ) : (
                            <div>
                                Loading...
                            </div>
                        )}

                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}

export default Institutes;