import { Button, CircularProgress, Grid, Paper } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as api from '../../../api/index';
import { ADMISSION_IMAGES, ADMISSION_PDFS } from '../../../constants/actionTypes';

function AdmissionDetails() {
    window.scrollTo(0, 0);
    let { admissionDetailsId } = useParams();
    const [admissionData, setAdmissionData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const fetchAdmissionDetails = useCallback(async () => {
        try {
            const { data } = await api.getAdmissionDetails(admissionDetailsId);
            if (data.success) {
                setLoading(false);
                setAdmissionData(data.admissionData);
                setError(false);
            }
            else {
                setError(true);
                setLoading(false);
                Swal.fire({
                    title: 'Failed',
                    text: data.message,
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: "Failed to fetch data",
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        }
    }, [admissionDetailsId]);

    useEffect(() => {
        fetchAdmissionDetails();
    }, [fetchAdmissionDetails]);


    return (
        <div>

            {loading && (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="m-4">
                    <CircularProgress />
                </div>
            )}
            {error && (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="m-4">
                    <h5 className='heading'>
                        Error occured loading while details.
                    </h5>
                </div>
            )}
            {!loading && !error && admissionData === null && (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="m-4">
                    <h5 className='heading'>
                        No results found yet...
                    </h5>
                </div>
            )}
            {!loading && !error && admissionData !== null && (
                <>

                    <Paper elevation={3} className="m-4 p-2">
                        <Grid container spacing={3}>

                            <Grid item xs={12} md={2} lg={2}>
                                <div>
                                    <h5 className='text-start fw-bold'>Class: </h5>
                                </div>
                                <div>
                                    <h5 className='text-start text-success'>{admissionData?.className} </h5>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={2} lg={2}>
                                <div>
                                    <h5 className='text-start fw-bold'>Year: </h5>
                                </div>
                                <div>
                                    <h5 className='text-start text-success'>{admissionData?.fullYear} </h5>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={2} lg={2}>
                                <div>
                                    <h5 className='text-start fw-bold'>Country: </h5>
                                </div>
                                <div>
                                    <h5 className='text-start text-success'>{admissionData?.countryName} </h5>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={2} lg={2}>
                                <div>
                                    <h5 className='text-start fw-bold'>Province: </h5>
                                </div>
                                <div>
                                    <h5 className='text-start text-success'>{admissionData?.provinceName} </h5>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={2} lg={2}>
                                <div>
                                    <h5 className='text-start fw-bold'>City: </h5>
                                </div>
                                <div>
                                    <h5 className='text-start text-success'>{admissionData?.cityName} </h5>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={2} lg={2}>
                                <div>
                                    <h5 className='text-start fw-bold'>Admission Link: </h5>
                                </div>
                                <div>
                                    <h5 className='text-start text-success'>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            onClick={() => {
                                                window.open(admissionData?.admissionLink, '_blank');
                                            }}
                                        >
                                            Get Admission
                                        </Button>
                                    </h5>
                                </div>
                            </Grid>

                        </Grid>
                    </Paper>

                    <Paper elevation={3} className="m-4 p-2">
                        <Grid
                            container
                            spacing={3}
                            style={{
                                height: '1000px',
                            }}
                        >
                            <Grid item xs={12} md={6} lg={6}>
                                <img
                                    src={`${ADMISSION_IMAGES}${admissionData?.admissionImage}`}
                                    alt="paper"
                                    className="w-100"
                                    style={{ height: '100%' }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div
                                    className="w-100"
                                    style={{
                                        height: '100%',
                                    }}
                                >
                                    <iframe
                                        src={`${ADMISSION_PDFS}${admissionData?.admissionPdf}`}
                                        className="w-100 h-100"
                                        title='pdf'
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>

                </>
            )}

        </div>
    )
}

export default AdmissionDetails;