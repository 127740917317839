import React, { useState } from 'react';
import { TextField, Typography, Button, Grid, Paper } from '@mui/material';
import Collapse from 'react-bootstrap/Collapse';
import AddBoxIcon from '@mui/icons-material/AddBox';
import RemoveIcon from '@mui/icons-material/Remove';
import Select from 'react-select'
import Swal from 'sweetalert2';
import * as api from "../../../api/index";
import EmailsTable from './EmailsTable';

const initialForm = {
    emailFor: '',
    email: '',
}

function AddEmails({ emails, setEmails, options }) {
    const [instituteId, setInstituteId] = useState('');
    const [emailForm, setEmailForm] = useState(initialForm);
    const [open, setOpen] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (emailForm.emailFor === '' || emailForm.email === '' || instituteId === '') {
            Swal.fire({
                title: 'Error!',
                text: 'Please fill all the fields',
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        } else {
            const formData = {
                instituteId: instituteId,
                emailFor: emailForm.emailFor,
                email: emailForm.email
            }
            const { data } = await api.addEmailToInstitute(formData);
            if (data.success) {
                Swal.fire({
                    title: 'Success!',
                    text: data.message,
                    icon: 'success',
                    confirmButtonText: 'Ok'
                })
                setEmails([...emails, data.email]);
                setEmailForm(initialForm);
            }
            else {
                Swal.fire({
                    title: 'Error!',
                    text: data.error,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }

        }
    }

    const handleChange = (e) => {
        setEmailForm({ ...emailForm, [e.target.name]: e.target.value });
    }

    const handleInstituteChange = (selectedOption) => {
        setInstituteId(selectedOption.value);
    }


    return (
        <div>
            <div>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => setOpen(!open)}
                    aria-controls="add-institute-form-container"
                    aria-expanded={open}
                >
                    Add Email to Institute Data &nbsp; {open ? <RemoveIcon /> : <AddBoxIcon />}
                </Button>
                <Collapse in={open}>
                    <div id="add-institute-form-container">
                        <Paper style={{ padding: '15px' }} elevation={8}>
                            <form onSubmit={handleSubmit}>
                                <Typography className="heading" variant="h4">Add Emails</Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <Select options={options} onChange={handleInstituteChange} />
                                        <label className='fw-bolder'>Select Institute</label>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <TextField
                                            label="Email For"
                                            value={emailForm.emailFor}
                                            name="emailFor"
                                            onChange={handleChange}
                                            size="small"
                                            inputProps={{ maxLength: 100 }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <TextField
                                            label="Email"
                                            value={emailForm.email}
                                            name="email"
                                            onChange={handleChange}
                                            size="small"
                                            type={'email'}
                                            inputProps={{ maxLength: 100 }}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} mt={1} justifyContent="center">
                                    <Grid item xs={12} md={4} lg={3}>
                                        <Button variant="contained" color="primary" type="submit" fullWidth>
                                            Submit
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={2}>
                                        <Button variant="contained" color="error" type="reset" fullWidth>
                                            Reset Form
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Paper>
                    </div>
                </Collapse>
            </div>
            <div>
                <EmailsTable emailsData={emails} setEmailsData={setEmails} />
            </div>
        </div>
    )
}

export default AddEmails;