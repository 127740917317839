import { Button, Grid, Paper, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import userAlternateImage from "../../../images/userImage.png";
// import Form from 'react-bootstrap/Form'
import Swal from 'sweetalert2';
import * as api from "../../../api/index";
import { USERS_IMAGES } from '../../../constants/actionTypes';
import { useNavigate } from 'react-router-dom';

const infoInitialState = {
    firstName: "",
    lastName: "",
    email: "",
}
const passInitialState = {
    currentPassword: "",
    password: "",
    confirmPassword: "",
}

function UserProfile({ user, setUser }) {
    const [userForm, setUserForm] = useState(infoInitialState);
    const [passwordsForm, setPasswordsForm] = useState(passInitialState);
    const [userImage, setUserImage] = useState(null);
    const [requesting, setRequesting] = useState(false);
    const [passRequesting, setPassRequesting] = useState();
    const [userProfileImage, setUserProfileImage] = useState(userAlternateImage);
    const navigate = useNavigate();

    const handleUserInfoSubmit = async (e) => {
        e.preventDefault();

        if (requesting) {
            Swal.fire({
                title: 'Please wait',
                text: 'Please Wait your request is in progress',
                icon: 'info',
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: true,
            });
            return;
        }

        setRequesting(true);

        var imageUpdate = 0;

        if (userImage) {
            imageUpdate = 1;
        }
        else {
            imageUpdate = 0;
        }

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };

        const formData = new FormData();
        formData.append('userId', user.result.id);
        formData.append('firstName', userForm.firstName);
        formData.append('lastName', userForm.lastName);
        formData.append('imageUpdate', imageUpdate);
        formData.append('userImage', userImage);

        const { data } = await api.udpateUserProfile(formData, config);
        if (data.success) {
            Swal.fire({
                title: 'Success!',
                text: data.message,
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            setUser({ ...user, result: data.user });
            setRequesting(false);
            setUserImage(null);
        }
        else {
            Swal.fire({
                title: 'Error!',
                text: data.error,
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            setRequesting(false);
            setUserImage(null);
        }

    }
    const handleInfoChange = (e) => {
        setUserForm({ ...userForm, [e.target.name]: e.target.value });
    }

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();

        if (passRequesting) {
            Swal.fire({
                title: 'Please wait',
                text: 'Please Wait your request is in progress',
                icon: 'info',
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: true,
            });
            return;
        }

        setPassRequesting(true);

        if (passwordsForm.currentPassword === "" || passwordsForm.password === "" || passwordsForm.confirmPassword === "") {
            Swal.fire({
                title: 'Error!',
                text: 'All Password input fields are required',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            setPassRequesting(false);
            return;
        }

        if (passwordsForm.password !== passwordsForm.confirmPassword) {
            Swal.fire({
                title: 'Error!',
                text: 'Passwords do not Match',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            setPassRequesting(false);
            return;
        }

        const formData = new FormData();
        formData.append('userId', user.result.id);
        formData.append('currentPassword', passwordsForm.currentPassword);
        formData.append('password', passwordsForm.password);
        formData.append('confirmPassword', passwordsForm.confirmPassword);

        const { data } = await api.udpateUserPassword(formData);
        if (data.success) {
            Swal.fire({
                title: 'Success!',
                text: data.message,
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            setPassRequesting(false);
            setPasswordsForm(passInitialState);
        }
        else {
            Swal.fire({
                title: 'Error!',
                text: data.error,
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            setPassRequesting(false);
        }

    }

    const handlePasswordChange = (e) => {
        setPasswordsForm({ ...passwordsForm, [e.target.name]: e.target.value });
    }



    const onSelectUserImage = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setUserImage(null)
            return
        }
        setUserImage(e.target.files[0]);
        setUserProfileImage(URL.createObjectURL(e.target.files[0]));
    }


    useEffect(() => {
        if (!user) {
            navigate("/");
            return;
        }

        setUserForm({
            firstName: user.result.firstName,
            lastName: user.result.lastName,
            email: user.result.email,
        });
        setUserProfileImage(`${USERS_IMAGES}${user.result.userImage}`);

    }, [user, navigate]);


    return (
        <div>
            <Container className='my-4'>
                <div className='card'>
                    <div className='card-header'>
                        <Grid
                            container
                            spacing={3}
                            justifyContent="center"
                            alignItems="center"
                            wrap='wrap'
                            direction="row"
                        >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <img src={userProfileImage}
                                    className="mx-auto"
                                    alt="Profile ..."
                                    style={{
                                        borderRadius: "50%",
                                        width: "250px",
                                        height: "250px",
                                        cursor: "pointer"
                                    }}
                                    onClick={() => {
                                        document.getElementById("userImageInput").click();
                                    }}
                                    title="Click to change profile image"
                                />
                                <input type="file" id="userImageInput" style={{ display: "none" }} onChange={onSelectUserImage} />
                            </Grid>
                            {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className="text-center">
                                    <Form.Group controlId="formFileSm" className="mb-3 w-25 m-auto">
                                        <Form.Control type="file" size="sm" onChange={onSelectUserImage} />
                                        <Form.Label className='fw-bold'>Update Profile Image</Form.Label>
                                    </Form.Group>
                                </div>
                            </Grid> */}
                        </Grid>
                    </div>
                    <div className='card-body p-2'>
                        <Paper style={{ padding: '15px' }} elevation={3}>
                            <form onSubmit={handleUserInfoSubmit}>
                                <h3 className='heading'>Update Info</h3>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <TextField
                                            label="First Name"
                                            value={userForm.firstName}
                                            name="firstName"
                                            onChange={handleInfoChange}
                                            size="small"
                                            inputProps={{ maxLength: 30 }}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <TextField
                                            label="Last Name"
                                            value={userForm.lastName}
                                            name="lastName"
                                            onChange={handleInfoChange}
                                            size="small"
                                            inputProps={{ maxLength: 30 }}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <TextField
                                            label="Email"
                                            value={userForm.email}
                                            name="email"
                                            onChange={handleInfoChange}
                                            size="small"
                                            inputProps={{ maxLength: 100 }}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            disabled={true}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} mt={1} justifyContent="center">
                                    <Grid item xs={12} md={4} lg={3}>
                                        <Button variant="contained" color="primary" type="submit" fullWidth>
                                            SAVE CHANGES
                                        </Button>
                                    </Grid>
                                </Grid>
                                <div>
                                </div>
                            </form>
                        </Paper>
                    </div>
                </div>
            </Container>
            <Container className='my-4'>
                <div className='card'>
                    <div className='card-header'>
                        <h3 className='heading'>Change Password</h3>
                    </div>
                    <div className='card-body p-2'>
                        <Paper style={{ padding: '15px' }} elevation={3}>
                            <form onSubmit={handlePasswordSubmit} autoComplete="off">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <TextField
                                            label="Current Password"
                                            value={passwordsForm.currentPassword}
                                            name="currentPassword"
                                            onChange={handlePasswordChange}
                                            size="small"
                                            type={"password"}
                                            inputProps={{ maxLength: 100 }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <TextField
                                            label="Password"
                                            value={passwordsForm.password}
                                            name="password"
                                            onChange={handlePasswordChange}
                                            size="small"
                                            type={"password"}
                                            inputProps={{ maxLength: 100 }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <TextField
                                            label="Confrim Password"
                                            value={passwordsForm.confirmPassword}
                                            name="confirmPassword"
                                            onChange={handlePasswordChange}
                                            size="small"
                                            type={"password"}
                                            inputProps={{ maxLength: 100 }}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} mt={1} justifyContent="center">
                                    <Grid item xs={12} md={4} lg={3}>
                                        <Button variant="contained" color="primary" type="submit" fullWidth>
                                            SAVE CHANGES
                                        </Button>
                                    </Grid>
                                </Grid>
                                <div>
                                </div>
                            </form>
                        </Paper>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default UserProfile;