// ***********************************************  --------> local server
// export const URL = "http://127.0.0.1:8000/api/";
// export const STATIC_URL = "http://127.0.0.1:8000/";

// **********************************************   ----------> production server
export const URL = "https://api.courseedx.com/api";
export const STATIC_URL = "https://api.courseedx.com/";

export const COURSE_THUMBNAIL_URL = `${STATIC_URL}courses/coursesThumbnails/`;
export const VIDEO_THUMBNAIL_URL = `${STATIC_URL}courses/courseVideosThumbnails/`;
export const YOUTUBE_EMBEDDED_VIDEO_THUMBNAIL_URL = `${STATIC_URL}courses/youtubeEmbeddedVideosThumbnails/`;
export const DRIVE_EMBEDDED_VIDEO_THUMBNAIL_URL = `${STATIC_URL}courses/driveEmbeddedVideosThumbnails/`;
export const VIDEO_URL = `${STATIC_URL}courses/courseVideos/`;
export const COURSE_PROMOTION_VIDEO_URL = `${STATIC_URL}courses/coursesPromotionVideos/`;
export const TRANSACTION_THUMBNAIL_URL = `${STATIC_URL}transactionsScreenShots/`;
export const USERS_IMAGES = `${STATIC_URL}usersImages/`;

// STAFF IMAGES URL VARIABLES START HERE
export const INSTITUTE_IMAGE_URL = `${STATIC_URL}institute/institutesImages/`;
export const INSTITUTE_LOGO_URL = `${STATIC_URL}institute/institutesLogos/`;
export const INSTITUTE_STAFF_IMAGE_URL = `${STATIC_URL}institute/teachers/`;

export const PAST_PAPER_IMAGES = `${STATIC_URL}pastPapers/papersImages/`;
export const PAST_PAPER_FILES = `${STATIC_URL}pastPapers/papersFiles/`;

export const ADMISSION_IMAGES = `${STATIC_URL}admissions/admissionImages/`;
export const ADMISSION_PDFS = `${STATIC_URL}admissions/admissionPdfs/`;

export const BOOKS_THUMBNAILS = `${STATIC_URL}books/bookThumnails/`;
export const BOOKS_FILES = `${STATIC_URL}books/bookFiles/`;

export const SOFTWARES_THUMBNAILS = `${STATIC_URL}softwares/softwareThumnails/`;
export const SOFTWARES_FILES = `${STATIC_URL}softwares/softwareFiles/`;

export const HOME_POSTERS_IMAGES = `${STATIC_URL}postersImages/`;



export const FETCHQUOTE_URL = 'https://api.quotable.io/random';

export const FETCH_COURSES_FOR_INSTUCTOR = "FETCH_COURSES_FOR_INSTUCTOR";
export const ADD_COURSE_TO_INSTRUCTOR = "ADD_COURSE_TO_INSTRUCTOR";
export const DELETE_COURSE_OF_INSTRUCTOR = "DELETE_COURSE_OF_INSTRUCTOR";

export const FETCH_COURSES_FOR_NON_AUTH_USERS = "FETCH_COURSES_FOR_NON_AUTH_USERS";


export const GET_VIDEOS_OF_COURSE_OF_INSTRUCTOR = "GET_VIDEOS_OF_COURSE_OF_INSTRUCTOR";
export const ADD_VIDEO_TO_COURSE_OF_INSTRUCTOR = "ADD_VIDEO_TO_COURSE_OF_INSTRUCTOR";
export const DELETE_COURSE_VIDEO_OF_INSTRUCTOR = "DELETE_COURSE_VIDEO_OF_INSTRUCTOR";


export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const GET_STUDENTS_COURSES_FOR_ADMIN = "GET_STUDENTS_COURSES_FOR_ADMIN";
export const GET_INSTRUCTORS_COURSES_FOR_ADMIN = "GET_INSTRUCTORS_COURSES_FOR_ADMIN";

export const CHANGE_STUDENT_COURSE_STATUS_FROM_TRANSACTION = "CHANGE_STUDENT_COURSE_STATUS_FROM_TRANSACTION";

export const FETCH_COURSES_FOR_STUDENT = "FETCH_COURSES_FOR_STUDENT";
export const GET_VIDEOS_OF_COURSE_OF_STUDENT = "GET_VIDEOS_OF_COURSE_OF_STUDENT";

export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";

