import { CircularProgress } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import * as api from "../../api/index.js";
import "./css/BlogDetails.css";

function BlogDetails() {
  window.scrollTo(0, 0);
  const [blog, setBlog] = useState(null);
  let { blogId } = useParams();


  const fetchBlog = useCallback(async () => {
    try {
      const { data } = await api.blogForNonAuthUsers(blogId);
      setBlog(data);
    } catch (error) {
      window.alert("Failed to load blog");
    }
  }
    , [blogId]);

  useEffect(() => {
    fetchBlog();
  }, [fetchBlog]);

  return (
    <div style={{ marginTop: "55px" }} className="BlogDetails">

      {!blog ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <Container>
            <div>
              <h3>{blog.blogTitle}</h3>
            </div>
            <div dangerouslySetInnerHTML={{ __html: blog.blogContent }} />
          </Container>
        </>
      )}

    </div>
  )
}

export default BlogDetails;