import React, { useCallback, useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import * as api from "../../../api/index";
import Swal from 'sweetalert2';
import { CircularProgress } from "@mui/material";
import Modal from 'react-bootstrap/Modal';
import TableVerifiedStudents from './TableVerifiedStudents';
import AddStudentVerification from './AddStudentVerification';
import EditVerificationDetailsForm from './EditVerificationDetailsForm';



function VerifiedStudents({ user, setUser }) {
    const [verifiedStudentsData, setVerifiedStudentsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);


    const fetchAllVerifiedStudents = useCallback(async () => {
        const { data } = await api.fetchAllVerifiedStudents();
        if (data.success) {
            setVerifiedStudentsData(data.verfiedStudents);
            setLoading(false);
            setError(false);
        }
        else {
            Swal.fire({
                title: 'Error!',
                text: data.message,
                icon: 'error',
                confirmButtonText: 'Ok',
            });
            setLoading(false);
            setError(true);
        }
    }, []);

    useEffect(() => {
        fetchAllVerifiedStudents();
    }, [fetchAllVerifiedStudents]);


    const [editStudentVerificationModalShow, setEditStudentVerificationModalShow] = useState(false);
    const [editVerifiedStudentData, setEditVerifiedStudentData] = useState(null);
    const [editVerifiedStudentDataRequesting, setEditVerifiedStudentDataRequesting] = useState(false);

    const handleStudentVerificationModalShowAndEditStudentVerification = (data) => {
        setEditVerifiedStudentData(data);
        setEditStudentVerificationModalShow(true);
    }


    const handleEditStudentVerificationSubmit = async (e) => {
        e.preventDefault();

        if (editVerifiedStudentDataRequesting) {
            Swal.fire({
                title: 'Please wait!',
                text: 'We are currently processing one of your request.',
                icon: 'info',
                showConfirmButton: false,
                allowOutsideClick: true,
                allowEscapeKey: false,
                allowEnterKey: true,
            });
            return;
        }

        setEditVerifiedStudentDataRequesting(true);

        if (
            !editVerifiedStudentData.student_name ||
            !editVerifiedStudentData.father_name ||
            !editVerifiedStudentData.roll_no ||
            !editVerifiedStudentData.course ||
            !editVerifiedStudentData.issue_date ||
            !editVerifiedStudentData.registeration_id ||
            !editVerifiedStudentData.session_name ||
            !editVerifiedStudentData.grade ||
            !editVerifiedStudentData.obtained_marks ||
            !editVerifiedStudentData.total_marks ||
            !editVerifiedStudentData.duration ||
            !editVerifiedStudentData.percentage ||
            !editVerifiedStudentData.result ||
            !editVerifiedStudentData.institute
        ) {
            Swal.fire({
                title: 'Error!',
                text: 'Please fill required fields',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            setEditVerifiedStudentDataRequesting(false);
            return;
        }

        const formData = new FormData();
        formData.append('record_id', editVerifiedStudentData.id);
        formData.append('student_name', editVerifiedStudentData.student_name);
        formData.append('father_name', editVerifiedStudentData.father_name);
        formData.append('roll_no', editVerifiedStudentData.roll_no);
        formData.append('course', editVerifiedStudentData.course);
        formData.append('issue_date', editVerifiedStudentData.issue_date);
        formData.append('registeration_id', editVerifiedStudentData.registeration_id);
        formData.append('session_name', editVerifiedStudentData.session_name);
        formData.append('grade', editVerifiedStudentData.grade);
        formData.append('obtained_marks', editVerifiedStudentData.obtained_marks);
        formData.append('total_marks', editVerifiedStudentData.total_marks);
        formData.append('duration', editVerifiedStudentData.duration);
        formData.append('percentage', editVerifiedStudentData.percentage);
        formData.append('result', editVerifiedStudentData.result);
        formData.append('institute', editVerifiedStudentData.institute);

        const { data } = await api.updateVerificationDetails(formData);
        if (data.success) {
            Swal.fire({
                title: 'Success!',
                text: data.message,
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            let index = verifiedStudentsData.findIndex(record => record.id === editVerifiedStudentData.id);
            verifiedStudentsData[index] = data.studentDetails;
            setVerifiedStudentsData([...verifiedStudentsData]);
            setEditVerifiedStudentDataRequesting(false);
        }
        else {
            Swal.fire({
                title: 'Error!',
                text: data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            setEditVerifiedStudentDataRequesting(false);
        }
    }

    const handleEditStudentVerificationFormChange = (e) => {
        setEditVerifiedStudentData({ ...editVerifiedStudentData, [e.target.name]: e.target.value });
    }





    return (
        <div>
            <div className='m-1 p-4'>
                {error && <div className='text-danger text-center'>Something went wrong. Please try again later. or contact the developer sahib to check the masla</div>}
                {loading ?
                    <div className='text-center'>
                        <CircularProgress />
                    </div>
                    : (
                        <Tabs
                            defaultActiveKey="verifiedStudentsTable"
                            transition={true}
                            id="noanim-tab-example"
                            className="mb-3"
                            style={{
                                padding: "3px",
                                backgroundColor: "rgb(11 142 150)",
                            }}
                        >
                            <Tab eventKey="verifiedStudentsTable" title="Verified Students">
                                <TableVerifiedStudents
                                    verifiedStudentsData={verifiedStudentsData}
                                    setVerifiedStudentsData={setVerifiedStudentsData}

                                    handleStudentVerificationModalShowAndEditStudentVerification={handleStudentVerificationModalShowAndEditStudentVerification}
                                />
                            </Tab>
                            <Tab eventKey="newVerificationEntry" title="New Verification Entry">
                                <AddStudentVerification
                                    verifiedStudentsData={verifiedStudentsData}
                                    setVerifiedStudentsData={setVerifiedStudentsData}

                                    user={user}
                                    setUser={setUser}
                                />
                            </Tab>
                        </Tabs>
                    )}
            </div>
            <div>
                <Modal
                    show={editStudentVerificationModalShow}
                    onHide={() => setEditStudentVerificationModalShow(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Update Past Paper Record
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {editVerifiedStudentData && editVerifiedStudentData.id ? (
                            <div>
                                <EditVerificationDetailsForm
                                    editVerifiedStudentData={editVerifiedStudentData}
                                    setEditVerifiedStudentData={setEditVerifiedStudentData}
                                    handleEditStudentVerificationSubmit={handleEditStudentVerificationSubmit}
                                    handleEditStudentVerificationFormChange={handleEditStudentVerificationFormChange}
                                />
                            </div>
                        ) : (
                            <div>
                                Loading...
                            </div>
                        )}

                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}

export default VerifiedStudents;