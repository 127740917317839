import { Button, Divider, Grid, Paper, TextField, Typography } from '@mui/material';
import React from 'react';
import { Form } from 'react-bootstrap';

function EditeditSoftware({
    editSoftware,
    setEditSoftware,
    onSelectEditSoftwareThumnail,
    onSelectEditSoftwareFile,
    handleEditSoftwareSubmit,
}) {
    return (
        <div>
            <Paper style={{ padding: '15px' }} elevation={8}>
                <form onSubmit={handleEditSoftwareSubmit} id="editSoftware">
                    <Typography className="heading" variant="h4">Softwares</Typography>
                    <Divider
                        variant="fullWidth"
                        component="hr"
                    />
                    <Grid container spacing={2} mt={1}>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                id="softwareName"
                                name="softwareName"
                                label="Software Name"
                                variant="outlined"
                                size='small'
                                fullWidth
                                value={editSoftware?.softwareName}
                                onChange={(e) => {
                                    setEditSoftware({ ...editSoftware, softwareName: e.target.value })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Form.Control type="file" onChange={(e) => onSelectEditSoftwareThumnail(e)} size="md" />
                            <Form.Label className='fw-bolder'>Change Thumnail</Form.Label>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Form.Control type="file" onChange={(e) => onSelectEditSoftwareFile(e)} size="md" />
                            <Form.Label className='fw-bolder'>Change Setup File</Form.Label>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={1} justifyContent="center">
                        <Grid item xs={12} md={4} lg={3}>
                            <Button variant="contained" color="primary" type="submit" fullWidth>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                    <div>
                    </div>
                </form>
            </Paper>
        </div>
    )
}

export default EditeditSoftware;