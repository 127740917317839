import React, { useCallback, useEffect, useState } from 'react'
import Course from './Course';
import "./css/MyCourses.css";
import { CircularProgress, Typography } from '@mui/material';
import * as api from "../../../api/index";
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import UpdateCourseForm from './UpdateCourseForm';

function MyCourses({ user, setUser }) {
    const [instructorCourses, setInstructorCourses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const [editCourseThumbnail, setEditCourseThumbnail] = useState(null);
    const [editCoursePromotionVideo, setEditCoursePromotionVideo] = useState(null);
    const [editCourseRequesting, setEditCourseRequesting] = useState(false);
    const [editCourseError, setEditCourseError] = useState(false);
    const [editCourseProgress, setEditCourseProgress] = useState(null);


    const [editCourseModalShow, setEditCourseModalShow] = useState(false);
    const [editCourse, setEditCourse] = useState(null);

    const handleCourseModalShowAndEditCourse = (course) => {
        setEditCourse(course);
        setEditCourseModalShow(true);
    }



    const getCourses = useCallback(
        async () => {
            try {
                setLoading(true);
                const { data } = await api.fetchCoursesForInstructor(user.result.id);
                if (data.success) {
                    setInstructorCourses(data.instructorCourses);
                    setLoading(false);
                    setError(false);
                }
                else {
                    setError(true);
                    Swal.fire(
                        'Error!',
                        data.error,
                        'error'
                    );
                }
            } catch (error) {
                setError(true);
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong please report the issue in support section, Thank you!"
                });
            }
        }, [user.result.id]);

    useEffect(() => {
        getCourses();
    }, [getCourses]);

    const handleEditCourseSubmit = async (e) => {
        e.preventDefault();
        if (editCourseRequesting) {
            Swal.fire({
                icon: "warning",
                title: "Oops...",
                text: "You are already updating this course, please wait for the update to finish!"
            });
            return;
        }
        setEditCourseRequesting(true);
        try {
            const formData = new FormData();
            formData.append('id', editCourse.id);
            formData.append('courseTitle', editCourse.courseTitle);
            formData.append('courseSubTitle', editCourse.courseSubTitle);
            formData.append('courseCategory', editCourse.courseCategory);
            formData.append('whatWillStudentLearn', editCourse.whatWillStudentLearn);
            formData.append('whatArePrerequisites', editCourse.whatArePrerequisites);
            formData.append('whoIsThisCourseFor', editCourse.whoIsThisCourseFor);
            formData.append('courseLevel', editCourse.courseLevel);
            formData.append('coursePricing', editCourse.coursePricing);
            formData.append('courseLanguage', editCourse.courseLanguage);
            formData.append('welcomeMessage', editCourse.welcomeMessage);
            formData.append('congratsMessage', editCourse.congratsMessage);
            formData.append('courseDescription', editCourse.courseDescription);
            window.scrollTo(0, 0);

            if (editCourseThumbnail !== null) {
                formData.append('courseThumbnail', editCourseThumbnail);
            }
            if (editCoursePromotionVideo !== null) {
                formData.append('coursePromotionVideo', editCoursePromotionVideo);
            }

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                },
                onUploadProgress: data => {
                    //Set the progress value to show the progress bar
                    setEditCourseProgress(Math.round((100 * data.loaded) / data.total))
                },
            }


            const { data } = await api.updateInstructorCourseDetails(formData, config);
            if (data.success) {
                let index = instructorCourses.findIndex(course => course.id === editCourse.id);
                instructorCourses[index] = data.course;
                setInstructorCourses([...instructorCourses]);
                setEditCourseRequesting(false);
                setEditCourseThumbnail(null);
                setEditCoursePromotionVideo(null);
                setEditCourseProgress(null);
                setEditCourseError(false);
                Swal.fire({
                    title: "Success!",
                    text: data.message,
                    icon: "success",
                    confirmButtonText: "OK",
                    allowOutsideClick: false
                });
            }
            else {
                Swal.fire({
                    title: "Failed!",
                    text: data.message,
                    icon: "warning",
                    confirmButtonText: "OK",
                    allowOutsideClick: false
                });
                setEditCourseRequesting(false);
                setEditCourseError(true);
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong please report the issue in support section, Thank you!"
            });
            setEditCourseRequesting(false);
            setEditCourseError(true);
        }
    }

    const handleEditCourseChange = (e) => {
        setEditCourse({ ...editCourse, [e.target.name]: e.target.value });
    }

    const onSelectEditCourseThumbnail = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setEditCourseThumbnail(null);
            return
        }
        setEditCourseThumbnail(e.target.files[0]);
    }

    const onSelectEditCoursePromotionVideo = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setEditCoursePromotionVideo(null);
            return
        }
        setEditCoursePromotionVideo(e.target.files[0]);
    }


    return (
        <div>
            <div>
                <div>
                    <Typography className="allemployees__title" variant="h6">Your Courses</Typography>
                </div>
                {error === true && (
                    <div className='text-center fw-bold'>
                        Some error occured Please report us your issue in support section.
                    </div>
                )}
                {loading === true && error === false && (
                    <div className='text-center fw-bold'>
                        <CircularProgress />
                    </div>
                )}

                {!error && !loading && (

                    <div className='MyCourses__component'>
                        {instructorCourses.length <= 0 ? (
                            <div className="" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                You are not studying any course currently
                            </div>
                        ) : (
                            <>
                                {instructorCourses.map((course) => {
                                    return (
                                        <Course
                                            key={course.id}
                                            course={course}
                                            showButtons={true}
                                            handleCourseModalShowAndEditCourse={handleCourseModalShowAndEditCourse}
                                        />
                                    )
                                })}
                            </>
                        )}
                    </div>

                )}

            </div>
            <div>
                <Modal
                    show={editCourseModalShow}
                    onHide={() => setEditCourseModalShow(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Edit Course
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* if edit course or editcourse.id then show else dont show */}
                        {editCourse && editCourse.id ? (
                            <div>
                                <UpdateCourseForm
                                    editCourse={editCourse}
                                    setEditCourse={setEditCourse}
                                    handleEditCourseSubmit={handleEditCourseSubmit}
                                    handleEditCourseChange={handleEditCourseChange}
                                    setEditCourseModalShow={setEditCourseModalShow}
                                    onSelectEditCourseThumbnail={onSelectEditCourseThumbnail}
                                    onSelectEditCoursePromotionVideo={onSelectEditCoursePromotionVideo}
                                    editCourseError={editCourseError}
                                    editCourseProgress={editCourseProgress}
                                />
                            </div>
                        ) : (
                            <div>
                                Loading...
                            </div>
                        )}

                    </Modal.Body>
                </Modal>

            </div>
        </div>
    )
}

export default MyCourses;