import { CircularProgress } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Swal from 'sweetalert2';
import AddBoards from './AddBoards';
import AddClasses from './AddClasses';
import AddSubjects from './AddSubjects';
import AddYears from './AddYears';
import Cities from './Cities';
import Countries from './Countries';
import "./css/GeneralManagement.css";
import InstitutePrograms from './InstitutePrograms';
import InstituteSectors from './InstituteSectors';
import InstituteTypes from './InstituteTypes';
import Provinces from './Provinces';
import * as api from "../../../api/index";
import GeneralAffiliations from './GeneralAffiliations';
import HomePosters from './HomePosters';


function GeneralManagement() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [instituteSectorsData, setInstituteSectorsData] = useState([]);
    const [instituteTypesData, setInstitutesTypesData] = useState([]);
    const [instituteProgramsData, setInstituteProgramsData] = useState([]);
    const [countriesData, setCountriesData] = useState([]);
    const [provincesData, setProvincesData] = useState([]);
    const [citiesData, setCitiesData] = useState([]);
    const [generalClassesData, setGeneralClassesData] = useState([]);
    const [generalBoardsData, setGeneralBoardsData] = useState([]);
    const [generalAffiliations, setGeneralAffiliations] = useState([]);
    const [generalYearsData, setGeneralYearsData] = useState([]);
    const [generalSubjectsData, setGeneralSubjectsData] = useState([]);
    const [homePostersData, setHomePostersData] = useState([]);

    const [instituteSectorsOptions, setInstituteSectorsOptions] = useState([]);
    const [instituteTypesOptions, setInstituteTypesOptions] = useState([]);
    const [countriesOptions, setCountriesOptions] = useState([]);
    const [provincesOptions, setProvincesOptions] = useState([]);


    const getManagementData = useCallback(async () => {
        const { data } = await api.getManagementDataForAdmin();
        if (data.success) {
            setInstituteSectorsData(data.instituteSectorsData);
            setInstitutesTypesData(data.instituteTypesData);
            setInstituteProgramsData(data.instituteProgramsData);
            setCountriesData(data.countriesData);
            setProvincesData(data.provincesData);
            setCitiesData(data.citiesData);
            setGeneralClassesData(data.generalClassesData);
            setGeneralBoardsData(data.generalBoardsData);
            setGeneralAffiliations(data.generalAffiliationsData);
            setGeneralYearsData(data.generalYearsData);
            setGeneralSubjectsData(data.generalSubjectsData);

            setInstituteSectorsOptions(data.instituteSectorsOptions);
            setInstituteTypesOptions(data.instituteTypesOptions);
            setCountriesOptions(data.countriesOptions);
            setProvincesOptions(data.provincesOptions);

            setHomePostersData(data.homePosters);

            setLoading(false);
        }
        else {
            Swal.fire({
                title: 'Error!',
                text: data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            setLoading(false);
            setError(true);
        }
    }, []);

    useEffect(() => {
        getManagementData();
    }, [getManagementData]);


    return (
        <div className='m-1 p-4'>
            {error && <div className='text-danger text-center'>Something went wrong. Please try again later. or contact the developer sahib to check the masla</div>}
            {loading ?
                <div className='text-center'>
                    <CircularProgress />
                </div>
                : (
                    <div className='GeneralManagement_tab'>
                        <Tabs
                            defaultActiveKey="instituteSectors"
                            transition={true}
                            id="noanim-tab-example"
                            className="mb-3"
                            style={{
                                padding: "3px",
                                backgroundColor: "rgb(11 142 150)",
                            }}
                        >
                            <Tab eventKey="instituteSectors" title="Institute Sectors">
                                <InstituteSectors
                                    instituteSectorsData={instituteSectorsData}
                                    setInstituteSectorsData={setInstituteSectorsData}

                                    instituteSectorsOptions={instituteSectorsOptions}
                                    setInstituteSectorsOptions={setInstituteSectorsOptions}
                                />
                            </Tab>
                            <Tab eventKey="instituteType" title="Institute Types">
                                <InstituteTypes
                                    instituteTypesData={instituteTypesData}
                                    setInstitutesTypesData={setInstitutesTypesData}

                                    instituteTypesOptions={instituteTypesOptions}
                                    setInstituteTypesOptions={setInstituteTypesOptions}
                                />
                            </Tab>
                            <Tab eventKey="instituteProgram" title="Institute Programs">
                                <InstitutePrograms
                                    instituteProgramsData={instituteProgramsData}
                                    setInstituteProgramsData={setInstituteProgramsData}

                                    instituteTypesOptions={instituteTypesOptions}
                                />
                            </Tab>
                            <Tab eventKey="generalCountries" title="Countries">
                                <Countries
                                    countriesData={countriesData}
                                    setCountriesData={setCountriesData}

                                    countriesOptions={countriesOptions}
                                    setCountriesOptions={setCountriesOptions}
                                />
                            </Tab>
                            <Tab eventKey="generalProvinces" title="Provinces">
                                <Provinces
                                    provincesData={provincesData}
                                    setProvincesData={setProvincesData}

                                    countriesOptions={countriesOptions}

                                    provincesOptions={provincesOptions}
                                    setProvincesOptions={setProvincesOptions}
                                />

                            </Tab>
                            <Tab eventKey="generalCities" title="Cities">
                                <Cities
                                    citiesData={citiesData}
                                    setCitiesData={setCitiesData}

                                    provincesOptions={provincesOptions}
                                />
                            </Tab>
                            <Tab eventKey="generalClasses" title="Classes">
                                <AddClasses
                                    generalClassesData={generalClassesData}
                                    setGeneralClassesData={setGeneralClassesData}
                                />
                            </Tab>
                            <Tab eventKey="generalBoards" title="Boards">
                                <AddBoards
                                    generalBoardsData={generalBoardsData}
                                    setGeneralBoardsData={setGeneralBoardsData}
                                />
                            </Tab>
                            <Tab eventKey="generalAffiliations" title="Affiliations">
                                <GeneralAffiliations
                                    generalAffiliations={generalAffiliations}
                                    setGeneralAffiliations={setGeneralAffiliations}
                                />
                            </Tab>
                            <Tab eventKey="generalYears" title="Years">
                                <AddYears
                                    generalYearsData={generalYearsData}
                                    setGeneralYearsData={setGeneralYearsData}
                                />
                            </Tab>
                            <Tab eventKey="generalSubjects" title="Subjects">
                                <AddSubjects
                                    generalSubjectsData={generalSubjectsData}
                                    setGeneralSubjectsData={setGeneralSubjectsData}
                                />
                            </Tab>
                            <Tab eventKey="homePosters" title="Home Posters">
                                <HomePosters
                                    homePostersData={homePostersData}
                                    setHomePostersData={setHomePostersData}
                                />
                            </Tab>
                        </Tabs>
                    </div>
                )}
        </div>
    )
}

export default GeneralManagement;