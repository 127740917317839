import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Button, CircularProgress, TextField } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { PAGE_SIZES, tableDataToPdf } from '../../../Globals/PDFVariables';
import { CSVLink } from 'react-csv';
import * as api from "../../../api/index";
import Swal from 'sweetalert2';

const columns = [
    { id: 'sno', label: 'S.No', minWidth: 10, width: '5%' },
    { id: 'instituteName', label: 'Institute Name', minWidth: 10, width: '20%' },
    { id: 'emailFor', label: 'Email For', minWidth: 10, width: '20%' },
    { id: 'email', label: 'Email', minWidth: 10, width: '20%' },
    { id: 'action', label: 'Action', minWidth: 10, width: '10%' }
];


function EmailsTable({ emailsData, setEmailsData }) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchTerm, setSearchTerm] = useState([]);
    const [emails, setEmails] = useState([]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };



    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        const searchText = e.target.value.replace(/\s+/g, '');
        if (e.target.value) {
            const results = emailsData.filter(email =>
                email.instituteName.toLowerCase().includes(searchText.toLowerCase()) ||
                email.emailFor.toLowerCase().includes(searchText.toLowerCase()) ||
                email.email.toLowerCase().includes(searchText.toLowerCase())
            );
            setEmails(results);
        } else {
            setEmails(emailsData);
        }
    }


    const createPdf = () => {
        const tableColumns = [['S.No', 'Institute Name', 'Email For', 'Email']];
        const tableRows = emails.map((email, index) => [
            index + 1,
            email.instituteName,
            email.emailFor,
            email.email,
        ]);

        tableDataToPdf(tableColumns, tableRows, "Institutes Emails");
    }

    const deleteEmail = (emailId) => {
        try {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.value) {
                    const { data } = await api.deleteInstituteEmail(emailId);
                    if (data.success) {
                        Swal.fire({
                            title: 'Success!',
                            text: data.message,
                            icon: 'success',
                            confirmButtonText: 'Ok'
                        });
                        setEmailsData(emailsData.filter(email => email.id !== emailId));
                    }
                    else {
                        Swal.fire({
                            title: 'Error!',
                            text: data.error,
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        });
                    }
                }
            });
        }
        catch (err) {
            Swal.fire({
                title: 'Error!',
                text: err.message,
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        }
    }

    useEffect(() => {
        setEmails(emailsData);
    }, [setEmails, emailsData]);

    return (
        <div className='mt-3'>
            <div className='card'>
                <div className='card-header'>
                    <h3 className='heading'>Emails list</h3>
                </div>
            </div>
            <div className='d-flex flex-row justify-content-start align-items-center flex-wrap'>
                <div>
                    <TextField
                        label="Search Here"
                        name="search"
                        size="small"
                        className='m-2'
                        type={'search'}
                        value={searchTerm}
                        style={{
                            width: "300px",
                        }}
                        onChange={(e) => handleSearch(e)}
                    />
                </div>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        className='m-1'
                        startIcon={<CollectionsBookmarkIcon />}
                    >
                        <CSVLink style={{
                            textDecoration: 'none',
                            color: 'white',
                        }}
                            data={
                                emails.map((email, index) => {
                                    return {
                                        "S.No": index + 1,
                                        "Institute Name": email.instituteName,
                                        "Email For": email.emailFor,
                                        "Email": email.email,
                                    }
                                })
                            }
                            filename="institutes_emails.csv"
                        >CSV/EXCEL</CSVLink>
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className='m-1'
                        startIcon={<PictureAsPdfIcon />}
                        onClick={createPdf}
                    >
                        PDF
                    </Button>
                </div>
            </div>
            <div className='mt-2'>
                <Paper sx={{
                    width: '100%', overflow: 'hidden',
                    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
                    borderRadius: '8px',
                    backgroundColor: '#fafafa'
                }}>
                    <TableContainer sx={{ maxHeight: 570 }}>
                        <Table stickyHeader aria-label="sticky table"
                            size='small'
                        >
                            <TableHead
                                sx={{
                                    backgroundColor: '#81d690',
                                    borderRadius: '8px',
                                    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
                                }}
                            >
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                            width={column.width}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {!emails && (
                                    <TableRow>
                                        <TableCell colSpan={6}>
                                            <CircularProgress />
                                        </TableCell>
                                    </TableRow>
                                )}

                                {emails && emails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((email, index) => {
                                    return (
                                        <TableRow hover role="checkbox" key={email.id}>

                                            <TableCell component="td" scope="row">
                                                {++index}
                                            </TableCell>
                                            <TableCell component="td" scope="row">
                                                {email.instituteName}
                                            </TableCell>
                                            <TableCell component="td" scope="row">
                                                {email.emailFor}
                                            </TableCell>
                                            <TableCell component="td" scope="row">
                                                {email.email}
                                            </TableCell>
                                            <TableCell component="td" scope="row">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className='m-1'
                                                    size='small'
                                                    onClick={() => deleteEmail(email.id)}
                                                >
                                                    <DeleteRoundedIcon />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}

                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={PAGE_SIZES}
                        component="div"
                        count={emails.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        className='table_pagination'
                    />
                </Paper>
            </div>
        </div>
    )
}

export default EmailsTable;