import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom';
import InstituteDetails from './InstituteDetails';
import SearchInstitute from './SearchInstitute';
import * as api from '../../../api/index';
import Swal from 'sweetalert2';

function Institutes() {
  const [instituteSectorsOptions, setInstituteSectorsOptions] = useState([]);
  const [instituteTypesOptions, setInstituteTypesOptions] = useState([]);
  const [instituteProgramsOptions, setInstituteProgramsOptions] = useState([]);
  const [instituteProvincesOptions, setInstituteProvincesOptions] = useState([]);
  const [instituteCitiesOptions, setInstituteCitiesOptions] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(false);


  const fetchOptions = async () => {
    setLoadingOptions(true);
    try {
      const { data } = await api.getInstitutesManagementOptions();
      if (data.success) {
        setInstituteSectorsOptions(data.instituteSectorsOptions);
        setInstituteTypesOptions(data.instituteTypesOptions);
        setInstituteProgramsOptions(data.instituteProgramsOptions);
        setInstituteProvincesOptions(data.instituteProvincesOptions);
        setInstituteCitiesOptions(data.instituteCitiesOptions);
        setLoadingOptions(false);
      }
      else {
        Swal.fire({
          title: 'Error',
          text: data.error,
          icon: 'error',
          confirmButtonText: 'Ok'
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchOptions();
  }, []);


  return (
    <div>
      <Routes>
        <Route path="/" exact element={<SearchInstitute
          instituteSectorsOptions={instituteSectorsOptions}
          instituteTypesOptions={instituteTypesOptions}
          instituteProgramsOptions={instituteProgramsOptions}
          instituteProvincesOptions={instituteProvincesOptions}
          instituteCitiesOptions={instituteCitiesOptions}
          loadingOptions={loadingOptions}
        />} />
        <Route path="/instituteDetails/:instituteId" element={<InstituteDetails />} />
      </Routes>
    </div>
  )
}

export default Institutes;