import { CircularProgress, Grid, Paper } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as api from '../../../api/index';
import { PAST_PAPER_FILES, PAST_PAPER_IMAGES } from '../../../constants/actionTypes';

function PastPaperDetails() {
    window.scrollTo(0, 0);
    let { pastPaperId } = useParams();
    const [pastPaper, setPastPaper] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);

    const fetchPastPaperDetails = useCallback(async () => {
        try {
            const { data } = await api.getPastPaperDetails(pastPaperId);
            if (data.success) {
                setLoading(false);
                setPastPaper(data.pastPaper);
                setError(false);
            }
            else {
                setError(true);
                setLoading(false);
                Swal.fire({
                    title: 'Failed',
                    text: data.message,
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: "Failed to fetch past paper details",
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        }
    }, [pastPaperId]);

    useEffect(() => {
        fetchPastPaperDetails();
    }, [fetchPastPaperDetails]);


    return (
        <div>

            {loading && (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="m-4">
                    <CircularProgress />
                </div>
            )}
            {error && (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="m-4">
                    <h5 className='heading'>
                        Error loading institute details.
                    </h5>
                </div>
            )}
            {!loading && !error && pastPaper === null && (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="m-4">
                    <h5 className='heading'>
                        No results found yet...
                    </h5>
                </div>
            )}
            {!loading && !error && pastPaper !== null && (
                <>

                    <Paper elevation={3} className="m-4 p-2">
                        <Grid container spacing={3}>

                            <Grid item xs={12} md={3} lg={3}>
                                <div>
                                    <h5 className='text-start fw-bold'>Board: </h5>
                                </div>
                                <div>
                                    <h5 className='text-start text-success'>{pastPaper?.boardName} </h5>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={3} lg={3}>
                                <div>
                                    <h5 className='text-start fw-bold'>Class: </h5>
                                </div>
                                <div>
                                    <h5 className='text-start text-success'>{pastPaper?.className} </h5>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={3} lg={3}>
                                <div>
                                    <h5 className='text-start fw-bold'>Year: </h5>
                                </div>
                                <div>
                                    <h5 className='text-start text-success'>{pastPaper?.fullYear} </h5>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={3} lg={3}>
                                <div>
                                    <h5 className='text-start fw-bold'>Subject: </h5>
                                </div>
                                <div>
                                    <h5 className='text-start text-success'>{pastPaper?.subjectName} </h5>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>

                    <Paper elevation={3} className="m-4 p-2">
                        <Grid
                            container
                            spacing={3}
                            style={{
                                height: '1000px',
                            }}
                        >
                            <Grid item xs={12} md={6} lg={6}>
                                <img
                                    src={`${PAST_PAPER_IMAGES}${pastPaper?.paperImage}`}
                                    alt="paper"
                                    className="w-100"
                                    style={{ height: '100%' }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div
                                    className="w-100"
                                    style={{
                                        height: '100%',
                                    }}
                                >
                                    <iframe
                                        src={`${PAST_PAPER_FILES}${pastPaper?.paperFile}`}
                                        className="w-100 h-100"
                                        title='pdf'
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>

                </>
            )}

        </div>
    )
}

export default PastPaperDetails;