import { Button, Divider, Grid, Paper, TextField, Typography } from '@mui/material'
import React from 'react';
import Select from 'react-select';
import { Form } from 'react-bootstrap';


function EditeditAdmission({
    editAdmission,
    setEditAdmission,
    onSelectEditAdmissionImage,
    onSelectEditadmissionPdf,
    handleEditAdmissionSubmit,
    classesOptions,
    yearsOptions,
    countriesOptions,
    provincesOptions,
    citiesOptions,
}) {


    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            textAlign: 'start',
            color: 'black',
        }),
        control: (provided, state) => ({
            ...provided,
            textAlign: 'start',
            color: 'black',
        }),
        option: (provided, state) => ({
            ...provided,
            textAlign: 'start',
            color: 'black',
        }),
    }

    return (
        <div>
            <Paper style={{ padding: '15px' }} elevation={8}>
                <form onSubmit={handleEditAdmissionSubmit} id="editAdmission">
                    <Typography className="heading" variant="h4">Add Admission Data</Typography>
                    <Divider
                        variant="fullWidth"
                        component="hr"
                    />
                    <Grid container spacing={2} mt={1}>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField

                                id="instituteName"
                                name="instituteName"
                                label="Institute Name"
                                variant="outlined"
                                size='small'
                                fullWidth
                                value={editAdmission?.instituteName}
                                onChange={(e) => {
                                    setEditAdmission({ ...editAdmission, instituteName: e.target.value })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                id="admissionLink"
                                name="admissionLink"
                                label="Admission Link"
                                variant="outlined"
                                size='small'
                                fullWidth
                                value={editAdmission?.admissionLink}
                                onChange={(e) => {
                                    setEditAdmission({ ...editAdmission, admissionLink: e.target.value })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Select
                                options={classesOptions}
                                placeholder="-- Select Class --"
                                styles={customStyles}
                                onChange={(e) => {
                                    setEditAdmission({ ...editAdmission, classId: e.value })
                                }}
                                defaultValue={
                                    classesOptions.filter(option => option.value === editAdmission.classId)
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Select
                                options={yearsOptions}
                                placeholder="-- Select Year --"
                                styles={customStyles}
                                onChange={(e) => {
                                    setEditAdmission({ ...editAdmission, yearId: e.value })
                                }}
                                defaultValue={
                                    yearsOptions.filter(option => option.value === editAdmission.yearId)
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Select
                                options={countriesOptions}
                                placeholder="-- Select Country --"
                                styles={customStyles}
                                onChange={(e) => {
                                    setEditAdmission({ ...editAdmission, countryId: e.value })
                                }}
                                defaultValue={
                                    countriesOptions.filter(option => option.value === editAdmission.countryId)
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Select
                                options={provincesOptions}
                                placeholder="-- Select Province --"
                                styles={customStyles}
                                onChange={(e) => {
                                    setEditAdmission({ ...editAdmission, provinceId: e.value })
                                }}
                                defaultValue={
                                    provincesOptions.filter(option => option.value === editAdmission.provinceId)
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Select
                                options={citiesOptions}
                                placeholder="-- Select City --"
                                styles={customStyles}
                                onChange={(e) => {
                                    setEditAdmission({ ...editAdmission, cityId: e.value })
                                }}
                                defaultValue={
                                    citiesOptions.filter(option => option.value === editAdmission.cityId)
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={1}>
                        <Grid item xs={12} md={6} lg={3}>
                            <Form.Control type="file" onChange={(e) => onSelectEditAdmissionImage(e)} size="sm" />
                            <Form.Label>Change Image</Form.Label>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Form.Control type="file" onChange={(e) => onSelectEditadmissionPdf(e)} size="sm" />
                            <Form.Label>Change PDF</Form.Label>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={1} justifyContent="center">
                        <Grid item xs={12} md={4} lg={3}>
                            <Button variant="contained" color="primary" type="submit" fullWidth>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                    <div>
                    </div>
                </form>
            </Paper>
        </div>
    )
}

export default EditeditAdmission;