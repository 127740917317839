import React, { useCallback, useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import * as api from "../../../api/index";
import Swal from 'sweetalert2';
import { CircularProgress } from "@mui/material";
import TableAdmissions from './TableAdmissions';
import NewAdmission from './NewAdmission';
import Modal from 'react-bootstrap/Modal';
import EditAdmissionForm from "./EditAdmissionForm";

function Admissions({ user, setUser }) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    // admissionsData
    const [admissionsData, setAdmissionsData] = useState([]);
    const [classesOptions, setClassesOptions] = useState([]);
    const [yearsOptions, setYearsOptions] = useState([]);
    const [countriesOptions, setCountriesOptions] = useState([]);
    const [provincesOptions, setProvincesOptions] = useState([]);
    const [citiesOptions, setCitiesOptions] = useState([]);

    const getAdmissionsData = useCallback(async () => {
        const { data } = await api.getAdmissionsDataForAdmin();
        if (data.success) {
            setAdmissionsData(data.admissionsData);
            setClassesOptions(data.classesOptions);
            setYearsOptions(data.yearsOptions);
            setCountriesOptions(data.countriesOptions);
            setProvincesOptions(data.provincesOptions);
            setCitiesOptions(data.citiesOptions);

            setLoading(false);
            setError(false);
        }
        else {
            Swal.fire({
                title: 'Error!',
                text: data.error,
                icon: 'error',
                confirmButtonText: 'Ok',
            });
            setLoading(false);
            setError(true);
        }
    }, []);

    useEffect(() => {
        getAdmissionsData();
    }, [getAdmissionsData]);



    const [editAdmissionModalShow, setEditAdmissionModalShow] = useState(false);
    const [editAdmission, setEditAdmission] = useState(null);
    const [editAdmissionRequesting, setEditAdmissionRequesting] = useState(false);
    const [editAdmissionImage, setEditAdmissionImage] = useState(null);
    const [editadmissionPdf, setEditadmissionPdf] = useState(null);

    const handleAdmissionModalShowAndEditAdmission = (admission) => {
        setEditAdmission(admission);
        setEditAdmissionModalShow(true);
    }


    const onSelectEditAdmissionImage = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setEditAdmissionImage(null);
            return
        }
        setEditAdmissionImage(e.target.files[0]);
    }

    const onSelectEditadmissionPdf = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setEditadmissionPdf(null);
            return
        }
        setEditadmissionPdf(e.target.files[0]);
    }


    const handleEditAdmissionSubmit = async (e) => {
        e.preventDefault();

        if (editAdmissionRequesting) {
            Swal.fire({
                title: 'Please wait!',
                text: 'We are currently processing one of your requests.',
                icon: 'info',
                showConfirmButton: false,
                allowOutsideClick: true,
                allowEscapeKey: false,
                allowEnterKey: true,
            });
            return;
        }

        setEditAdmissionRequesting(true);

        if (
            !editAdmission.classId
            || !editAdmission.yearId
            || !editAdmission.countryId
            || !editAdmission.provinceId
            || !editAdmission.cityId
            || !editAdmission.instituteName
            || !editAdmission.admissionLink
        ) {
            Swal.fire({
                title: 'Error!',
                text: 'Please fill required fields',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            setEditAdmissionRequesting(false);
            return;
        }

        const formData = new FormData();
        formData.append('id', editAdmission.id);
        formData.append("entryUserId", user.result.id);
        formData.append('classId', editAdmission.classId);
        formData.append('yearId', editAdmission.yearId);
        formData.append('countryId', editAdmission.countryId);
        formData.append('provinceId', editAdmission.provinceId);
        formData.append('cityId', editAdmission.cityId);
        formData.append('instituteName', editAdmission.instituteName);
        formData.append('admissionLink', editAdmission.admissionLink);

        if (editAdmissionImage !== null) {
            formData.append('admissionImage', editAdmissionImage);
        }
        if (editadmissionPdf !== null) {
            formData.append('admissionPdf', editadmissionPdf);
        }

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };

        const { data } = await api.updateAdmissionData(formData, config);
        if (data.success) {
            Swal.fire({
                title: 'Success!',
                text: data.message,
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            setEditAdmissionImage(null);
            setEditadmissionPdf(null);
            let index = admissionsData.findIndex(ad => ad.id === editAdmission.id);
            admissionsData[index] = data.admission;
            setAdmissionsData([...admissionsData]);

            document.getElementById('editAdmission').reset();
            setEditAdmissionRequesting(false);
        }
        else {
            Swal.fire({
                title: 'Error!',
                text: data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            setEditAdmissionRequesting(false);
        }
    }





    return (
        <div>
            <div className='m-1 p-4'>
                {error && <div className='text-danger text-center'>Something went wrong. Please try again later. or contact the developer sahib to check the masla</div>}
                {loading ?
                    <div className='text-center'>
                        <CircularProgress />
                    </div>
                    : (
                        <Tabs
                            defaultActiveKey="admissionsData"
                            transition={true}
                            id="noanim-tab-example"
                            className="mb-3"
                            style={{
                                padding: "3px",
                                backgroundColor: "rgb(11 142 150)",
                            }}
                        >
                            <Tab eventKey="admissionsData" title="Admissions Data">
                                <TableAdmissions
                                    admissionsData={admissionsData}
                                    setAdmissionsData={setAdmissionsData}

                                    handleAdmissionModalShowAndEditAdmission={handleAdmissionModalShowAndEditAdmission}
                                />
                            </Tab>
                            <Tab eventKey="newAddmission" title="New Addmission">
                                <NewAdmission
                                    classesOptions={classesOptions}
                                    yearsOptions={yearsOptions}
                                    countriesOptions={countriesOptions}
                                    provincesOptions={provincesOptions}
                                    citiesOptions={citiesOptions}

                                    admissionsData={admissionsData}
                                    setAdmissionsData={setAdmissionsData}

                                    user={user}
                                    setUser={setUser}
                                />
                            </Tab>
                        </Tabs>
                    )}
            </div>
            <div>
                <Modal
                    show={editAdmissionModalShow}
                    onHide={() => setEditAdmissionModalShow(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Update Admission Record
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* if edit course or editcourse.id then show else dont show */}
                        {editAdmission && editAdmission.id ? (
                            <div>
                                <EditAdmissionForm

                                    editAdmission={editAdmission}
                                    setEditAdmission={setEditAdmission}
                                    onSelectEditAdmissionImage={onSelectEditAdmissionImage}
                                    onSelectEditadmissionPdf={onSelectEditadmissionPdf}
                                    handleEditAdmissionSubmit={handleEditAdmissionSubmit}

                                    classesOptions={classesOptions}
                                    yearsOptions={yearsOptions}
                                    countriesOptions={countriesOptions}
                                    provincesOptions={provincesOptions}
                                    citiesOptions={citiesOptions}
                                />
                            </div>
                        ) : (
                            <div>
                                Loading...
                            </div>
                        )}

                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}


export default Admissions;