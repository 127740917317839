import { Button, Divider, Grid, Paper, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import Swal from 'sweetalert2';
import * as api from "../../../api/index";
import { Form } from 'react-bootstrap';
import TableBooks from './TableBooks';
import Modal from 'react-bootstrap/Modal';
import EditBookForm from './EditBookForm';


const initialState = {
    "bookTitle": "",
    "bookAuthor": "",
}


function AddBook({ booksData, setBooksData }) {
    const [bookForm, setBookForm] = useState(initialState);
    const [requesting, setRequesting] = useState(false);
    const [bookThumnail, setBookThumnail] = useState(null);
    const [bookFile, setBookFile] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (requesting) {
            Swal.fire({
                title: "Please wait",
                text: "Your request is being processed",
                icon: "info",
                showConfirmButton: false,
                showCancelButton: false,
                allowOutsideClick: true,
                allowEscapeKey: true,
                allowEnterKey: true,
            });
            return;
        }

        if (!bookThumnail && !bookFile) {
            Swal.fire({
                title: "Error",
                text: "Please select admission image and pdf file both",
                icon: "error",
            });
            return;
        }

        if (!bookForm.bookTitle || !bookForm.bookAuthor) {
            Swal.fire({
                title: "Warning",
                text: "Please fill all the fields",
                icon: "warning",
            });
            return;
        }

        setRequesting(true);
        try {

            const formData = new FormData();
            formData.append('bookTitle', bookForm.bookTitle);
            formData.append('bookAuthor', bookForm.bookAuthor);
            formData.append('bookThumnail', bookThumnail);
            formData.append('bookFile', bookFile);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            const { data } = await api.addNewBook(formData, config);
            if (data.success) {
                Swal.fire({
                    title: "Success",
                    text: data.message,
                    icon: "success",
                });
                setRequesting(false);
                setBooksData([...booksData, data.newBook]);
                setBookThumnail(null);
                setBookFile(null);
                window.document.getElementById("bookForm").reset();
            } else {
                Swal.fire({
                    title: "Error",
                    text: data.message,
                    icon: "error",
                });
                setRequesting(false);
            }

        } catch (error) {
            Swal.fire({
                title: "Error",
                text: "Failed to add admission data, Please contact the one and only Mr. Programmer",
                icon: "error",
            });
            setRequesting(false);
        }

    }

    const onSelectbookThumnail = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setBookThumnail(null);
            return;
        }
        setBookThumnail(e.target.files[0]);
    }

    const onSelectbookFile = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setBookFile(null);
            return;
        }
        setBookFile(e.target.files[0]);
    }


    const [editBookModalShow, setEditBookModalShow] = useState(false);
    const [editBook, setEditBook] = useState(null);
    const [editBookRequesting, setEditBookRequesting] = useState(false);
    const [editBookThumnail, setEditBookThumnail] = useState(null);
    const [editBookFile, setEditBookFile] = useState(null);

    const handleBookModalShowAndEditBook = (book) => {
        setEditBook(book);
        setEditBookModalShow(true);
    }

    const onSelectEditBookThumnail = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setEditBookThumnail(null);
            return
        }
        setEditBookThumnail(e.target.files[0]);
    }

    const onSelectEditBookFile = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setEditBookFile(null);
            return
        }
        setEditBookFile(e.target.files[0]);
    }


    const handleEditBookSubmit = async (e) => {
        e.preventDefault();

        if (editBookRequesting) {
            Swal.fire({
                title: 'Please wait!',
                text: 'We are currently processing one of your request.',
                icon: 'info',
                showConfirmButton: false,
                allowOutsideClick: true,
                allowEscapeKey: false,
                allowEnterKey: true,
            });
            return;
        }

        setEditBookRequesting(true);

        if (
            !editBook.bookTitle
            || !editBook.bookAuthor
        ) {
            Swal.fire({
                title: 'Error!',
                text: 'Please fill required fields',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            setEditBookRequesting(false);
            return;
        }

        const formData = new FormData();
        formData.append('id', editBook.id);
        formData.append('bookTitle', editBook.bookTitle);
        formData.append('bookAuthor', editBook.bookAuthor);

        if (editBookThumnail !== null) {
            formData.append('bookThumnail', editBookThumnail);
        }
        if (editBookFile !== null) {
            formData.append('bookFile', editBookFile);
        }

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };

        const { data } = await api.updateBookData(formData, config);
        if (data.success) {
            Swal.fire({
                title: 'Success!',
                text: data.message,
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            setEditBookThumnail(null);
            setEditBookFile(null);
            let index = booksData.findIndex(b => b.id === editBook.id);
            booksData[index] = data.book;
            setBooksData([...booksData]);

            document.getElementById('editBook').reset();
            setEditBookRequesting(false);
        }
        else {
            Swal.fire({
                title: 'Error!',
                text: data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            setEditBookRequesting(false);
        }
    }



    return (
        <div>
            <div>
                <Paper style={{ padding: '15px' }} elevation={8}>
                    <form onSubmit={handleSubmit} id="bookForm">
                        <Typography className="heading" variant="h4">Books</Typography>
                        <Divider
                            variant="fullWidth"
                            component="hr"
                        />
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={12} md={6} lg={3}>
                                <TextField
                                    id="bookTitle"
                                    name="bookTitle"
                                    label="Book Title"
                                    variant="outlined"
                                    size='small'
                                    fullWidth
                                    onChange={(e) => {
                                        setBookForm({ ...bookForm, bookTitle: e.target.value })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <TextField
                                    id="bookAuthor"
                                    name="bookAuthor"
                                    label="Book Author"
                                    variant="outlined"
                                    size='small'
                                    fullWidth
                                    onChange={(e) => {
                                        setBookForm({ ...bookForm, bookAuthor: e.target.value })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <Form.Control type="file" onChange={(e) => onSelectbookThumnail(e)} size="md" />
                                <Form.Label className='fw-bolder'>Book Thumnail</Form.Label>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <Form.Control type="file" onChange={(e) => onSelectbookFile(e)} size="md" />
                                <Form.Label className='fw-bolder'>Book PDF</Form.Label>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} mt={1} justifyContent="center">
                            <Grid item xs={12} md={4} lg={3}>
                                <Button variant="contained" color="primary" type="submit" fullWidth>
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                        <div>
                        </div>
                    </form>
                </Paper>
                <Paper style={{ marginTop: '15px' }} elevation={8}>
                    <TableBooks
                        booksData={booksData}
                        setBooksData={setBooksData}

                        handleBookModalShowAndEditBook={handleBookModalShowAndEditBook}
                    />
                </Paper>
            </div>
            <div>
                <Modal
                    show={editBookModalShow}
                    onHide={() => setEditBookModalShow(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Update Past Paper Record
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* if edit course or editcourse.id then show else dont show */}
                        {editBook && editBook.id ? (
                            <div>
                                <EditBookForm
                                    editBook={editBook}
                                    setEditBook={setEditBook}
                                    onSelectEditBookThumnail={onSelectEditBookThumnail}
                                    onSelectEditBookFile={onSelectEditBookFile}
                                    handleEditBookSubmit={handleEditBookSubmit}
                                />
                            </div>
                        ) : (
                            <div>
                                Loading...
                            </div>
                        )}

                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}


export default AddBook;