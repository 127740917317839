import { Button, CircularProgress, Divider, Grid, Paper, TextField, Typography } from '@mui/material';
import React, { useState } from 'react'
import { Container } from 'react-bootstrap';
import Swal from 'sweetalert2';
import * as api from "../../../api/index.js";
import BooksList from './BooksList.js';

function SearchBooks() {
    const [bookTitle, setBookTitle] = useState('');
    const [bookAuthor, setBookAuthor] = useState('');
    const [loading, setLoading] = useState(false);
    const [zeroResults, setZeroResults] = useState(false);
    const [foundBooks, setFoundBooks] = useState([]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const formData = {
                bookTitle: bookTitle,
                bookAuthor: bookAuthor,
            }
            const { data } = await api.searchBooksData(formData);
            if (data.success) {
                setLoading(false);
                setFoundBooks(data.books);
                if (data.books.length === 0) {
                    setZeroResults(true);
                }
                else {
                    setZeroResults(false);
                }
            }
            else {
                setLoading(false);
                Swal.fire({
                    title: 'Failed!',
                    text: data.message,
                    icon: 'warning',
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                title: 'Failed!',
                text: "Failed to Search for Books",
                icon: 'warning',
            });
        }
    }


    return (
        <div>
            <Container maxwidth="lg">
                <Paper elevation={12}>
                    <form autoComplete='false' onSubmit={handleSubmit}>
                        <Grid mx={2} my={2} className="p-4">
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography constiant="h6" className="mt-4 heading">
                                        Search for your desired book through Book Title or Book Author.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider
                                variant='fullWidth'
                            />
                            <br />
                            <Grid
                                container
                                spacing={3}
                                className="text-start"
                                justifyContent={"center"}
                            >
                                <Grid item xs={12} md={4} lg={4}>
                                    <TextField
                                        id="bookTitle"
                                        name="bookTitle"
                                        label="Book Title"
                                        variant="outlined"
                                        size='small'
                                        fullWidth
                                        onChange={(e) => {
                                            setBookTitle(e.target.value)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                    <TextField
                                        id="bookAuthor"
                                        name="bookAuthor"
                                        label="Book Author"
                                        variant="outlined"
                                        size='small'
                                        fullWidth
                                        onChange={(e) => {
                                            setBookAuthor(e.target.value)
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} className="mt-1" justifyContent={"center"}>
                                <Grid item xs={12} md={6} lg={4}>
                                    <Button type='submit' variant="contained" color="primary" className="mt-4">
                                        Search
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Container>
            {loading && (
                <Container maxwidth="lg">
                    <CircularProgress />
                </Container>
            )
            }
            {zeroResults && (
                <div
                    style={{
                        marginTop: '2.5rem',
                    }}
                >
                    <Paper elevation={12} className="m-3">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography constiant="h6" className="mt-4 heading">
                                    No data found.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            )
            }
            {
                foundBooks.length > 0 && (
                    <Paper elevation={6} className="m-3">
                        <Grid>
                            <BooksList foundBooks={foundBooks} />
                        </Grid>
                    </Paper>
                )
            }
        </div>
    )
}

export default SearchBooks;