import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Button, CircularProgress, TextField } from '@mui/material';
// import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
// import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import VisibilityIcon from '@mui/icons-material/Visibility';
// import { PAGE_SIZES, tableDataToPdf } from "../../../Globals/PDFVariables";
import { PAGE_SIZES } from "../../../Globals/PDFVariables";
// import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';

const columns = [
    { id: 'sno', label: 'S.No', minWidth: 10, width: '5%' },
    { id: 'instituteName', label: 'Institute Name', minWidth: 10, width: '15%' },
    { id: 'website', label: 'Website', minWidth: 10, width: '15%' },
    { id: 'founderName', label: 'Founder Name', minWidth: 10, width: '10%' },
    { id: 'dateOfEstablishment', label: 'Date Of Establishment', minWidth: 10, width: '10%' },
    { id: 'cityName', label: 'City Name', minWidth: 10, width: '10%' },
    { id: 'provinceName', label: 'Province Name', minWidth: 10, width: '10%' },
    { id: 'action', label: 'Action', minWidth: 10, width: '10%' }
];

function FoundInstitutes({ foundInstitutesData }) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchTerm, setSearchTerm] = useState([]);
    const [institutes, setInstitutes] = useState([]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };



    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        const searchText = e.target.value.replace(/\s+/g, '');
        if (e.target.value) {
            const results = institutes.filter(institute =>
                `
                ${institute.instituteName}
                ${institute.website}
                ${institute.founderName}
                ${institute.dateOfEstablishment}
                ${institute.cityName}
                ${institute.provinceName}
                `.toLowerCase().includes(searchText.toLowerCase())
            );
            setInstitutes(results);
        } else {
            setInstitutes(foundInstitutesData);
        }
    }


    // const createPdf = () => {
    //     const tableColumns = [['S.No', 'Institute Name', 'Website', 'Founder Name', 'Date of Establishment', 'City Name', 'Province Name']];
    //     const tableRows = institutes.map((institute, index) => [
    //         index + 1,
    //         institute.instituteName,
    //         institute.website,
    //         institute.founderName,
    //         institute.dateOfEstablishment,
    //         institute.cityName,
    //         institute.provinceName
    //     ]);

    //     tableDataToPdf(tableColumns, tableRows, "Institutes");
    // }

    useEffect(() => {
        setInstitutes(foundInstitutesData);
    }, [foundInstitutesData]);

    return (
        <div className='p-2'>
            <div className='card'>
                <div className='card-header'>
                    <h3 className='heading'>Found Institutes are listed below.</h3>
                </div>
            </div>
            <div className='all_institutes_top_div'>
                <div>
                    <TextField
                        label="Search Here"
                        name="search"
                        size="small"
                        className='m-2'
                        type={'search'}
                        value={searchTerm}
                        style={{
                            width: "300px",
                        }}
                        onChange={(e) => handleSearch(e)}
                    />
                </div>
                {/* <div className='users__button_div'>
                    <Button
                        variant="contained"
                        color="primary"
                        className='users__button m-1'
                        startIcon={<CollectionsBookmarkIcon />}
                        size="small"
                    >
                        <CSVLink style={{
                            textDecoration: 'none',
                            color: 'white',
                        }}
                            data={
                                institutes.map((institute, index) => {
                                    return {
                                        "S.No": index + 1,
                                        "Institute Name": institute.instituteName,
                                        "Website": institute.website,
                                        "Founder Name": institute.founderName,
                                        "Date of Establishment": institute.dateOfEstablishment,
                                        "City Name": institute.cityName,
                                        "Province Name": institute.provinceName,
                                    }
                                })
                            }
                            filename="students_courses.csv"
                        >CSV/EXCEL</CSVLink>
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className='users__button m-1'
                        startIcon={<PictureAsPdfIcon />}
                        size="small"
                        onClick={createPdf}
                    >
                        PDF
                    </Button>
                </div> */}
            </div>
            <div>
                <Paper
                elevation={3}
                >
                    <TableContainer sx={{ maxHeight: 570 }}>
                        <Table stickyHeader aria-label="sticky table"
                            size='small'
                        >
                            <TableHead
                                sx={{
                                    backgroundColor: '#81d690',
                                    borderRadius: '8px',
                                    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
                                }}
                            >
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                            width={column.width}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {!institutes && (
                                    <TableRow>
                                        <TableCell colSpan={6}>
                                            <CircularProgress />
                                        </TableCell>
                                    </TableRow>
                                )}

                                {institutes && institutes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((course, index) => {
                                    return (
                                        <TableRow hover role="checkbox" key={course.id}>

                                            <TableCell component="td" scope="row">
                                                {++index}
                                            </TableCell>
                                            <TableCell component="td" scope="row">
                                                {course.instituteName}
                                            </TableCell>
                                            <TableCell component="td" scope="row">
                                                {course.website}
                                            </TableCell>
                                            <TableCell component="td" scope="row">
                                                {course.founderName}
                                            </TableCell>
                                            <TableCell component="td" scope="row">
                                                {course.dateOfEstablishment}
                                            </TableCell>
                                            <TableCell component="td" scope="row">
                                                {course.cityName}
                                            </TableCell>
                                            <TableCell component="td" scope="row">
                                                {course.provinceName}
                                            </TableCell>

                                            <TableCell component="td" scope="row">
                                                <Link to={`/institutes/instituteDetails/${course.id}`}>
                                                    <Button variant="contained"
                                                        color="primary"
                                                        size='small'
                                                        startIcon={<VisibilityIcon />}
                                                    >Details</Button>
                                                </Link>
                                            </TableCell>

                                        </TableRow>
                                    );
                                })}

                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={PAGE_SIZES}
                        component="div"
                        count={institutes.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        className='table_pagination'
                    />
                </Paper>
            </div>
        </div>
    );
}


export default FoundInstitutes;