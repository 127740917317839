import { Button, Card, CardContent, CardMedia, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as api from "../../../api/index.js";
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import LanguageIcon from '@mui/icons-material/Language';
import { INSTITUTE_IMAGE_URL, INSTITUTE_LOGO_URL, INSTITUTE_STAFF_IMAGE_URL } from "../../../constants/actionTypes";
import { Col, Container, Row } from 'react-bootstrap';

function InstituteDetails() {
    window.scrollTo(0, 0);
    let { instituteId } = useParams();
    const [institute, setInstitute] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [emails, setEmails] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [addresses, setAddresses] = useState([]);
    const [staff, setStaff] = useState([]);
    const [institutePrograms, setInstitutePrograms] = useState([]);
    const [instituteAffiliations, setInstituteAffiliations] = useState([]);
    const [instituteTypes, setInstituteTypes] = useState([]);

    const redirectTo = (url) => {
        window.open(url, '_blank');
    }


    const fetchInstituteDetails = useCallback(async () => {
        try {
            const { data } = await api.getInstituteDetailsNonAuth(instituteId);
            if (data.success) {
                setInstitute(data.institute);
                setEmails(data.instituteEmails);
                setContacts(data.instituteContacts);
                setAddresses(data.instituteAddresses);
                setStaff(data.instituteStaff);
                setInstitutePrograms(data.institutePrograms);
                setInstituteAffiliations(data.instituteAffiliations);
                setInstituteTypes(data.instituteTypes);
                setLoading(false);
            }
            else {
                setError(true);
                setLoading(false);
                Swal.fire({
                    title: 'Error',
                    text: data.error,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                });
            }
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: error,
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        }
    }, [instituteId]);

    useEffect(() => {
        fetchInstituteDetails();
    }, [fetchInstituteDetails]);


    return (
        <div>
            {loading && (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="m-4">
                    <CircularProgress />
                </div>
            )}
            {error && (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="m-4">
                    <Typography variant="h5">
                        Error loading institute details.
                    </Typography>
                </div>
            )}
            {!loading && !error && institute === null && (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="m-4">
                    <Typography variant="h5">
                        Institute not found.
                    </Typography>
                </div>
            )}
            {!loading && !error && institute !== null && (


                <Grid container spacing={3}>

                    <Grid item xs={12} md={12} lg={12} m={2} p={2}>
                        <Paper elevation={8} className="d-flex flex-column align-items-start justify-content-start p-3">
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12} lg={12} m={2} p={2}>
                                    <Card>
                                        <CardMedia
                                            component="img"
                                            alt="Contemplative Reptile"
                                            height={window.innerWidth > 900 ? 500 : 200}
                                            image={`${INSTITUTE_IMAGE_URL}/${institute?.instituteImage}`}
                                            title="Contemplative Reptile"
                                        />
                                        <CardContent>
                                            <div className='mt-2'>
                                                <img
                                                    src={`${INSTITUTE_LOGO_URL}/${institute?.InstituteLogo}`}
                                                    alt="Institute logo"
                                                    width={80}
                                                    height={80}
                                                    className="rounded-circle border border-dark"
                                                />
                                            </div>
                                            <div>
                                                <h3 className='heading mt-2 p-2'>{institute?.instituteName}</h3>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>

                        </Paper>
                    </Grid>

                    {/* code of new design here */}

                    <Grid container spacing={3} className="mx-2">
                        <Grid item xs={12} md={12} lg={5} m={2} p={1}>
                            {/* institute details */}
                            <Paper elevation={8} className="d-flex flex-column align-items-start justify-content-start p-3 m-1">
                                <div className='col-12'>
                                    <h6
                                        className='p-2 rounded'
                                        style={{
                                            background: "rgb(11 142 150)",
                                            color: "white"
                                        }}
                                    >Details of the Institute</h6>
                                </div>
                                <div>
                                    <p className='text-start'>
                                        <span className='heading'>Founder Name: </span>
                                        {/* <br /> */}
                                        <span>{institute?.founderName}</span>
                                    </p>
                                </div>
                                <div>
                                    <p className='text-start'>
                                        <span className='heading'>Date of Establishment: </span>
                                        <span>{
                                            institute?.dateOfEstablishment ?
                                                institute?.dateOfEstablishment.split('-')[2] + '-' + institute?.dateOfEstablishment.split('-')[1] + '-' + institute?.dateOfEstablishment.split('-')[0]
                                                :
                                                '-'
                                        }</span>
                                    </p>
                                </div>
                                <div>
                                    <p className='text-start'>
                                        <span className='heading'>Results: </span>
                                        {/* <br /> */}
                                        <span>{institute?.results}</span>
                                    </p>
                                </div>
                                <div>
                                    <div className='text-start'>
                                        <span className='heading'>Programs: </span>
                                        <br />
                                        <div>
                                            <ol>
                                                {institutePrograms.length > 0 && institutePrograms.map((program, index) =>
                                                    <li key={index}>
                                                        {`${program.programName}`}
                                                    </li>
                                                )}
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='text-start'>
                                        <span className='heading'>Affiliations: </span>
                                        <br />
                                        <ol>
                                            {instituteAffiliations.length > 0 && instituteAffiliations.map((affiliation, index) =>
                                                <li key={index}>
                                                    {`${affiliation.affiliation}`}
                                                </li>
                                            )}
                                        </ol>
                                    </div>
                                </div>
                                <div>
                                    <div className='text-start'>
                                        <span className='heading'>Institute Type: </span>
                                        <br />
                                        <ol>
                                            {instituteTypes.length > 0 && instituteTypes.map((type, index) =>
                                                <li key={index}>
                                                    {`${type.instituteType}`}
                                                </li>
                                            )}
                                        </ol>
                                    </div>
                                </div>
                                <div>
                                    <p className='text-start'>
                                        <span className='heading'>Sector: </span>
                                        {/* <br /> */}
                                        <span>{institute?.sectorName}</span>
                                    </p>
                                </div>
                                <div>
                                    <p className='text-start'>
                                        <span className='heading'>Province: </span>
                                        {/* <br /> */}
                                        <span>{institute?.provinceName}</span>
                                    </p>
                                </div>
                                <div>
                                    <p className='text-start'>
                                        <span className='heading'>City: </span>
                                        {/* <br /> */}
                                        <span>{institute?.cityName}</span>
                                    </p>
                                </div>
                            </Paper>

                            {/* social links */}
                            <Paper elevation={8} className="d-flex flex-column align-items-start justify-content-start p-3 m-1">
                                <div className='col-12'>
                                    <h6
                                        className='p-2 rounded'
                                        style={{
                                            background: "rgb(11 142 150)",
                                            color: "white"
                                        }}
                                    >Social Links of the Institute</h6>
                                </div>

                                <Container className='m-0 p-0'>
                                    <Row className='m-2'>
                                        <Col className='text-start'>
                                            <span className='heading'><FacebookOutlinedIcon /> Facebook :</span>
                                        </Col>
                                        <Col>
                                            <span>
                                                <Button variant="contained" color="primary" className='w-75' size='small' onClick={() => redirectTo(institute?.facebook)}>
                                                    Facebook
                                                </Button>
                                            </span>
                                        </Col>
                                    </Row>

                                    <Row className='m-2'>
                                        <Col className='text-start'>
                                            <span className='heading'><TwitterIcon /> Twitter :</span>
                                        </Col>
                                        <Col>
                                            <Button variant="contained" color="primary" size='small' onClick={() => redirectTo(institute?.twitter)} className='w-75'>
                                                Twitter
                                            </Button>
                                        </Col>
                                    </Row>

                                    <Row className='m-2'>
                                        <Col className='text-start'>
                                            <span className='heading'><LinkedInIcon /> LinkedIn :</span>
                                        </Col>
                                        <Col>
                                            <Button variant="contained" color="primary" size='small' onClick={() => redirectTo(institute?.linkedin)} className='w-75'>
                                                LinkedIn
                                            </Button>
                                        </Col>
                                    </Row>

                                    <Row className='m-2'>
                                        <Col className='text-start'>
                                            <span className='heading'><InstagramIcon /> Instagram :</span>
                                        </Col>
                                        <Col>
                                            <Button variant="contained" color="primary" size='small' onClick={() => redirectTo(institute?.instagram)} className='w-75'>
                                                Instagram
                                            </Button>
                                        </Col>
                                    </Row>

                                    <Row className='m-2'>
                                        <Col className='text-start'>
                                            <span className='heading'><LanguageIcon /> Website :</span>
                                        </Col>
                                        <Col>
                                            <Button variant="contained" color="primary" size='small' onClick={() => redirectTo(institute?.website)} className='w-75'>
                                                Website
                                            </Button>
                                        </Col>
                                    </Row>
                                </Container>

                            </Paper>

                        </Grid>

                        <Grid item xs={12} md={12} lg={5} m={2} p={1}>

                            {/* Contacts */}
                            <Paper elevation={8} className="d-flex flex-column align-items-start justify-content-start p-3 m-1">
                                <div className='col-12'>
                                    <h6
                                        className='p-2 rounded'
                                        style={{
                                            background: "rgb(11 142 150)",
                                            color: "white"
                                        }}
                                    >Contact Numbers</h6>
                                </div>

                                {!contacts.length ? (
                                    <div>
                                        <p className='text-start'>
                                            <span className='heading'>No Contacts Found</span>
                                        </p>
                                    </div>
                                ) : (
                                    contacts.map((contact, index) => (
                                        <div key={index}>
                                            <p className='text-start'>
                                                <span className='heading'>{contact.contactFor}:</span>
                                                {/* <br /> */}
                                                <span>{contact.contact}</span>
                                            </p>
                                        </div>
                                    ))
                                )}
                            </Paper>

                            {/* Emails */}
                            <Paper elevation={8} className="d-flex flex-column align-items-start justify-content-start p-3 m-1">
                                <div className='col-12'>
                                    <h6
                                        className='p-2 rounded'
                                        style={{
                                            background: "rgb(11 142 150)",
                                            color: "white"
                                        }}
                                    >E-Mails</h6>
                                </div>

                                {!emails.length ? (
                                    <div>
                                        <p className='text-start'>
                                            <span className='heading'>No Emails Found</span>
                                        </p>
                                    </div>
                                ) : (
                                    emails.map((email, index) => (
                                        <div key={index}>
                                            <p className='text-start'>
                                                <span className='heading'>{email.emailFor}:</span>
                                                {/* <br /> */}
                                                <span>{email.email}</span>
                                            </p>
                                        </div>
                                    ))
                                )}
                            </Paper>

                            {/* Addresses */}
                            <Paper elevation={8} className="d-flex flex-column align-items-start justify-content-start p-3 m-1">
                                <div className='col-12'>
                                    <h6
                                        className='p-2 rounded'
                                        style={{
                                            background: "rgb(11 142 150)",
                                            color: "white"
                                        }}
                                    >Addresses</h6>
                                </div>

                                {!addresses.length ? (
                                    <div className='text-start'>
                                        <span className='heading'>No Addresses Found</span>
                                    </div>
                                ) : (
                                    addresses.map((address, index) => (
                                        <div key={index}>
                                            <div className='text-start p-2'>
                                                <div>
                                                    <span className='heading'>{address.addressFor}:</span>
                                                    <span className='m-1'>{address.address}</span>
                                                </div>
                                                <div>
                                                    <Button size="small" variant="contained" color="primary" className='mx-4' onClick={() => redirectTo(address.googleMapLocation)}>
                                                        View on Google Map
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </Paper>

                        </Grid>

                        <Grid item xs={12} md={12} lg={12} m={2} p={1}>

                            {/* Institute stafff */}
                            <Paper elevation={8} className="p-3 m-1">
                                <div>
                                    <h6
                                        className='p-2 rounded'
                                        style={{
                                            background: "rgb(11 142 150)",
                                            color: "white"
                                        }}
                                    >Staff of the Institute</h6>
                                </div>

                                <Grid container spacing={3} justifyContent="center" className='mt-2'>

                                    {!staff.length ? (
                                        <div className='text-start'>
                                            <span className='heading'>No Staff Found</span>
                                        </div>
                                    ) : (
                                        staff.map((teacher, index) => (
                                            <Grid item xs={12} md={4} lg={3} key={index}>
                                                <Card sx={{ maxWidth: 250 }} className="m-2">
                                                    <CardMedia
                                                        component="img"
                                                        height="200"
                                                        image={`${INSTITUTE_STAFF_IMAGE_URL}/${teacher.teacherImage}`}
                                                        alt="green iguana"
                                                    />
                                                    <CardContent className='text-start'>
                                                        <Typography gutterBottom variant="h5" component="div">
                                                            {teacher.teacherName}
                                                        </Typography>
                                                        <div>
                                                            <div>
                                                                <span className='heading'>Degree:</span>
                                                                <span>{teacher.teacherDegree}</span>
                                                            </div>
                                                            <div>
                                                                <span className='heading'>Experience:</span>
                                                                <span>{teacher.teacherExperience}</span>
                                                            </div>
                                                            <div>
                                                                <span className='heading'>Subjects:</span>
                                                                <span>{teacher.teacherSubjects}</span>
                                                            </div>
                                                        </div>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        ))
                                    )}

                                </Grid>
                            </Paper>

                        </Grid>


                    </Grid>

                </Grid>



            )}
        </div>
    )
}

export default InstituteDetails;