import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import { Button, CircularProgress, Grid, TextField } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { PAGE_SIZES, tableDataToPdf } from '../../../Globals/PDFVariables';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import DeleteIcon from '@mui/icons-material/Delete';
import * as api from '../../../api/index';
import EditRoundedIcon from '@mui/icons-material/EditRounded';



const columns = [
    { id: 'sno', label: 'S.No', minWidth: 10, width: '5%' },
    { id: 'studentName', label: 'Student Name', minWidth: 10, width: '20%' },
    { id: 'rollNo', label: 'Roll No', minWidth: 10, width: '5%' },
    { id: 'registerationId', label: 'Reg ID', minWidth: 10, width: '5%' },
    { id: 'issueDate', label: 'Issue Date', minWidth: 10, width: '15%' },
    { id: 'course', label: 'Course', minWidth: 10, width: '15%' },
    { id: 'session', label: 'Session', minWidth: 10, width: '5%' },
    { id: 'action', label: 'Action', minWidth: 10, width: '30%' }
];

function TableVerifiedStudents({ verifiedStudentsData, setVerifiedStudentsData, handleStudentVerificationModalShowAndEditStudentVerification }) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchTerm, setSearchTerm] = useState([]);
    const [verifiedStudents, setVerifiedStudents] = useState([]);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleDelete = (id) => {
        try {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.value) {
                    const { data } = await api.deleteVerificationDetails(id);
                    if (data.success) {
                        Swal.fire({
                            title: 'Success!',
                            text: data.message,
                            icon: 'success',
                            confirmButtonText: 'Ok'
                        });
                        setVerifiedStudentsData(verifiedStudentsData.filter(data => data.id !== id));
                    }
                    else {
                        Swal.fire({
                            title: 'Error!',
                            text: data.error,
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        });
                    }
                }
            });
        }
        catch (err) {
            Swal.fire({
                title: 'Error!',
                text: err.message,
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        }
    }


    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        const searchText = e.target.value.replace(/\s+/g, '');
        if (e.target.value) {
            const results = verifiedStudentsData.filter(data =>
                data.student_name.toLowerCase().includes(searchText.toLowerCase()) ||
                data.roll_no.toLowerCase().includes(searchText.toLowerCase()) ||
                data.registeration_id.toLowerCase().includes(searchText.toLowerCase())
            );
            setVerifiedStudents(results);
        } else {
            setVerifiedStudents(verifiedStudentsData);
        }
    }



    const createPdf = () => {
        const tableColumns = [['S.No', 'Student Name', 'Roll No', 'Registeration ID', 'Issue Date', 'Course', "Session"]];
        const tableRows = verifiedStudents.map((data, index) => [
            index + 1,
            data.student_name,
            data.roll_no,
            data.registeration_id,
            data.issue_date,
            data.course,
            data.session_name,
        ]);

        tableDataToPdf(tableColumns, tableRows, "Verified Students");
    }

    useEffect(() => {
        setVerifiedStudents(verifiedStudentsData);
    }, [verifiedStudentsData]);


    return (
        <div className='all_pastPapers_main_div'>
            <div className='card'>
                <div className='card-header'>
                    <h3 className='heading'>Past Papers list</h3>
                </div>
            </div>
            <Paper elevation={3} className="p-0">
                <Grid container spacing={3} className="mt-1">
                    <Grid item xs={12} md={6} lg={6}>
                        <TextField
                            label="Search Here"
                            name="search"
                            size="small"
                            className='m-2'
                            type={'search'}
                            value={searchTerm}
                            onChange={(e) => handleSearch(e)}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4} className="text-start">
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<CollectionsBookmarkIcon />}
                            size="small"
                            className='m-3'
                        >
                            <CSVLink style={{
                                textDecoration: 'none',
                                color: 'white',
                            }}
                                data={
                                    verifiedStudents.map((data, index) => {
                                        return {
                                            "S.No": index + 1,
                                            "Student Name": data.student_name,
                                            "Roll No": data.roll_no,
                                            "Reg Id": data.registeration_id,
                                            "Issue Date": data.issue_date,
                                            "Course": data.course,
                                            "Session": data.session_name,
                                        }
                                    })
                                }
                                filename="past_papers_list.csv"
                            >CSV/EXCEL</CSVLink>
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<PictureAsPdfIcon />}
                            size="small"
                            className='m-3'
                            onClick={createPdf}
                        >
                            PDF
                        </Button>
                    </Grid>
                </Grid>
            </Paper>

            <div>
                <Paper sx={{
                    width: '100%', overflow: 'hidden',
                    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
                    borderRadius: '8px',
                    backgroundColor: '#fafafa',
                    marginTop: '1rem',
                }}>
                    <TableContainer sx={{ maxHeight: 570 }}>
                        <Table stickyHeader aria-label="sticky table"
                            size='small'
                        >
                            <TableHead
                                sx={{
                                    backgroundColor: '#81d690',
                                    borderRadius: '8px',
                                    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
                                }}
                            >
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                            width={column.width}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {!verifiedStudents && (
                                    <TableRow>
                                        <TableCell colSpan={6}>
                                            <CircularProgress />
                                        </TableCell>
                                    </TableRow>
                                )}

                                {verifiedStudents && verifiedStudents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, index) => {
                                    return (
                                        <TableRow hover role="checkbox" key={data.id}>

                                            <TableCell component="td" scope="row">
                                                {++index}
                                            </TableCell>
                                            <TableCell component="td" scope="row">
                                                {data.student_name}
                                            </TableCell>
                                            <TableCell component="td" scope="row">
                                                {data.roll_no}
                                            </TableCell>
                                            <TableCell component="td" scope="row">
                                                {data.registeration_id}
                                            </TableCell>
                                            <TableCell component="td" scope="row">
                                                {data.issue_date}
                                            </TableCell>
                                            <TableCell component="td" scope="row">
                                                {data.course}
                                            </TableCell>
                                            <TableCell component="td" scope="row">
                                                {data.session_name}
                                            </TableCell>

                                            <TableCell component="td" scope="row">
                                                <Link to={`/studentVerification/checkSingleStudentVerification/${data.id}`} target="_blank">
                                                    <Button variant="contained"
                                                        color="primary"
                                                        size='small'
                                                        className='m-1'
                                                    >
                                                        <VisibilityIcon />
                                                    </Button>
                                                </Link>
                                                <Button variant="contained"
                                                    color="secondary"
                                                    size='small'
                                                    onClick={() => handleStudentVerificationModalShowAndEditStudentVerification(data)}
                                                    className="m-1"
                                                >
                                                    <EditRoundedIcon />
                                                </Button>
                                                <Button variant="contained"
                                                    color="secondary"
                                                    size='small'
                                                    onClick={() => handleDelete(data.id)}
                                                    className="m-1"
                                                >
                                                    <DeleteIcon />
                                                </Button>
                                            </TableCell>

                                        </TableRow>
                                    );
                                })}

                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={PAGE_SIZES}
                        component="div"
                        count={verifiedStudents.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        className='table_pagination'
                    />
                </Paper>
            </div>
        </div>
    )
}

export default TableVerifiedStudents;