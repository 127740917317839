import React, { useEffect } from 'react'
import "./css/Home.css";
import MainNavbar from './MainNavbar';
import { useDispatch } from "react-redux";
import HomeMain from './HomeMain';
import { Routes, Route, useNavigate } from "react-router-dom";
import CourseDetails from './CourseDetails';
import SignIn from './SignIn';
import SignUp from './SignUp';
import decode from "jwt-decode";
import { logoutFunc } from '../../actions/auth';
import { Divider } from '@mui/material';
import Checkout from './Checkout';
import Blogs from './Blogs';
import BlogDetails from './BlogDetails';
import Footer from './Footer';
import PrivacyPolicy from './PrivacyPolicy';
import TermsAndConditions from './TermsAndConditions';
import Institutes from './Institutes/Institutes';
import SearchCourses from './SearchCourses';
import UserProfile from './User/UserProfile';
import ComingSoon from './ComingSoon';
import PastPapers from './PastPapers/PastPapers';
import Admissions from './Admissions/Admissions';
import Books from './Books/Books';
import Softwares from './Softwares/Softwares';
import StudentVerification from './StudentVerification';


function Home({ user, setUser, cartItems, setCartItems }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const closeProfileContentModal = () => {
        const main_navbar_user_profile_content = document.getElementsByClassName("main_navbar_user_profile_content")[0];
        if (main_navbar_user_profile_content) {
            if (!main_navbar_user_profile_content.classList.contains("main_navbar_user_profile_content_show")) {
                main_navbar_user_profile_content.classList.add("main_navbar_user_profile_content_show");
            };
        }
    }

    useEffect(() => {
        const token = user?.token;
        if (token) {
            try {
                const decodedToken = decode(token);
                if (decodedToken.exp * 1000 < new Date().getTime()) {
                    dispatch(logoutFunc(setUser, navigate));
                }
            } catch (error) {
                localStorage.removeItem("profile");
            }
        }
        else {
            localStorage.removeItem("profile");
        }
    }, [dispatch, navigate, user, setUser]);




    return (
        <div>
            <MainNavbar user={user} setUser={setUser} cartItems={cartItems} setCartItems={setCartItems} />
            <Divider variant="fullWidth" className='nav_divider' />
            <div onClick={closeProfileContentModal} className="content_divider">
                <Routes>
                    <Route path="/" exact element={<HomeMain user={user} cartItems={cartItems} setCartItems={setCartItems} />} />
                    <Route path="/courseDetails/:courseId" element={<CourseDetails user={user} setUser={setUser} cartItems={cartItems} setCartItems={setCartItems} />} />
                    <Route path="/checkout" element={<Checkout user={user} setUser={setUser} cartItems={cartItems} setCartItems={setCartItems} />} />
                    <Route path="/blogs" element={<Blogs user={user} setUser={setUser} />} />
                    <Route path="/blogDetails/:blogId" element={<BlogDetails user={user} cartItems={cartItems} setCartItems={setCartItems} />} />
                    <Route path="/signin" element={<SignIn user={user} setUser={setUser} />} />
                    <Route path="/signup" element={<SignUp user={user} setUser={setUser} />} />
                    <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
                    <Route path="/termsAndConditions" element={<TermsAndConditions />} />
                    <Route path="/institutes/*" element={<Institutes />} />
                    <Route path="/pastPapers/*" element={<PastPapers />} />
                    <Route path="/admissions/*" element={<Admissions />} />
                    <Route path="/searchCourses/:searchTerm" element={<SearchCourses />} />
                    <Route path="/userProfile" element={<UserProfile user={user} setUser={setUser} />} />
                    <Route path="/ComingSoon" element={<ComingSoon />} />
                    <Route path="/studentVerification" element={<StudentVerification />} />
                    <Route path="/books/*" element={<Books />} />
                    <Route path="/softwares/*" element={<Softwares />} />
                </Routes>
            </div>
            <Divider variant="fullWidth" />
            <Footer />
        </div>
    )
}

export default Home;