import { combineReducers } from "redux";
import { courseVideosOfInstructor } from "./courseVideoOfInstructor";
import { authReducer } from "./auth";
import { nonAuthCourses } from "./nonAuthCourses";
import { instructorCourses } from "./instructorCourse";
import { transactions } from "./transactions";
import { studentsCourses } from "./studentsCourses";
import { instructorsCoursesForAdmin } from "./instructorsCoursesForAdmin";
import { videosOfCourseOfStudent } from "./videosOfCourseOfStudent";
import { studentsCoursesForAdmin } from "./studentCoursesForAdmin";

export const reducers = combineReducers({ instructorCourses, courseVideosOfInstructor, authReducer, nonAuthCourses, transactions, studentsCoursesForAdmin, instructorsCoursesForAdmin, studentsCourses, videosOfCourseOfStudent });
