import { Button, Grid, Paper, TextField } from '@mui/material';
import React from 'react'
import { Container, ProgressBar } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import ReactQuill from 'react-quill';


function UpdateCourseForm({ editCourse, setEditCourse, handleEditCourseSubmit, handleEditCourseChange, setEditCourseModalShow, onSelectEditCourseThumbnail, handleEditCourseDescriptionChange, onSelectEditCoursePromotionVideo, editCourseError, editCourseProgress }) {

    return (
        <div>

            <Paper elevation={6} className="p-2">
                <div className="card m-3">
                    <div className="card-body">
                        <h5 className="card-title">Edit Course Details</h5>
                    </div>
                    {!editCourseError && editCourseProgress && (
                        <div className="card-footer">
                            <span>
                                Please wait we are updating course info ...
                            </span>
                            <ProgressBar now={editCourseProgress} label={`${editCourseProgress}%`} />
                        </div>
                    )}
                </div>
                <Container>
                    <form onSubmit={handleEditCourseSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4} lg={4}>
                                <TextField
                                    label="Course Title"
                                    value={editCourse.courseTitle}
                                    name="courseTitle"
                                    onChange={handleEditCourseChange}
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <TextField
                                    label="Course Subtitle"
                                    value={editCourse.courseSubTitle}
                                    name='courseSubTitle'
                                    onChange={handleEditCourseChange}
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <Form.Select
                                    name='courseCategory'
                                    value={editCourse.courseCategory}
                                    onChange={handleEditCourseChange}
                                >
                                    <option value="">-- Select Course Category --</option>
                                    <option value="Development">Development</option>
                                    <option value="Business">Business</option>
                                    <option value="Finance / Accounting">Finance / Accounting</option>
                                    <option value="IT / Software">IT / Software</option>
                                    <option value="Office Productivity">Office Productivity</option>
                                    <option value="Personal Development">Personal Development</option>
                                    <option value="Design">Design</option>
                                    <option value="Marketing">Marketing</option>
                                    <option value="Lifestyle">Lifestyle</option>
                                    <option value="Photography / Video">Photography / Video</option>
                                    <option value="Health / Fitness">Health / Fitness</option>
                                    <option value="Music">Music</option>
                                    <option value="Teaching / Academics">Teaching / Academics</option>
                                </Form.Select>
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <TextField
                                    label="What will student learn"
                                    value={editCourse.whatWillStudentLearn}
                                    name="whatWillStudentLearn"
                                    onChange={handleEditCourseChange}
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <TextField
                                    label="What are Prerequisites"
                                    value={editCourse.whatArePrerequisites}
                                    name="whatArePrerequisites"
                                    onChange={handleEditCourseChange}
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <TextField
                                    label="Who is this course for"
                                    value={editCourse.whoIsThisCourseFor}
                                    name="whoIsThisCourseFor"
                                    onChange={handleEditCourseChange}
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <Form.Select
                                    value={editCourse.courseLevel}
                                    onChange={handleEditCourseChange}
                                    name="courseLevel"
                                >
                                    <option value={''}>Course Level</option>
                                    <option value="Beginner">Beginner</option>
                                    <option value="Intermediate">Intermediate</option>
                                    <option value="Advance">Advance</option>
                                </Form.Select>
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <TextField
                                    label="Course Pricing"
                                    value={editCourse.coursePricing}
                                    name="coursePricing"
                                    type={"number"}
                                    onChange={handleEditCourseChange}
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <Form.Select
                                    name='courseLanguage'
                                    value={editCourse.courseLanguage}
                                    onChange={handleEditCourseChange}
                                >
                                    <option value="">-- Select Course Language --</option>
                                    <option value="Afrikaans">Afrikaans</option>
                                    <option value="Albanian">Albanian</option>
                                    <option value="Arabic">Arabic</option>
                                    <option value="Armenian">Armenian</option>
                                    <option value="Basque">Basque</option>
                                    <option value="Bengali">Bengali</option>
                                    <option value="Bulgarian">Bulgarian</option>
                                    <option value="Catalan">Catalan</option>
                                    <option value="Cambodian">Cambodian</option>
                                    <option value="Chinese (Mandarin)">Chinese (Mandarin)</option>
                                    <option value="Croatian">Croatian</option>
                                    <option value="Czech">Czech</option>
                                    <option value="Danish">Danish</option>
                                    <option value="Dutch">Dutch</option>
                                    <option value="English">English</option>
                                    <option value="Estonian">Estonian</option>
                                    <option value="Fiji">Fiji</option>
                                    <option value="Finnish">Finnish</option>
                                    <option value="French">French</option>
                                    <option value="Georgian">Georgian</option>
                                    <option value="German">German</option>
                                    <option value="Greek">Greek</option>
                                    <option value="Gujarati">Gujarati</option>
                                    <option value="Hebrew">Hebrew</option>
                                    <option value="Hindi">Hindi</option>
                                    <option value="Hungarian">Hungarian</option>
                                    <option value="Icelandic">Icelandic</option>
                                    <option value="Indonesian">Indonesian</option>
                                    <option value="Irish">Irish</option>
                                    <option value="Italian">Italian</option>
                                    <option value="Japanese">Japanese</option>
                                    <option value="Javanese">Javanese</option>
                                    <option value="Korean">Korean</option>
                                    <option value="Latin">Latin</option>
                                    <option value="Latvian">Latvian</option>
                                    <option value="Lithuanian">Lithuanian</option>
                                    <option value="Macedonian">Macedonian</option>
                                    <option value="Malay">Malay</option>
                                    <option value="Malayalam">Malayalam</option>
                                    <option value="Maltese">Maltese</option>
                                    <option value="Maori">Maori</option>
                                    <option value="Marathi">Marathi</option>
                                    <option value="Mongolian">Mongolian</option>
                                    <option value="Nepali">Nepali</option>
                                    <option value="Norwegian">Norwegian</option>
                                    <option value="Pashto">Pashto</option>
                                    <option value="Persian">Persian</option>
                                    <option value="Polish">Polish</option>
                                    <option value="Portuguese">Portuguese</option>
                                    <option value="Punjabi">Punjabi</option>
                                    <option value="Quechua">Quechua</option>
                                    <option value="Romanian">Romanian</option>
                                    <option value="Russian">Russian</option>
                                    <option value="Samoan">Samoan</option>
                                    <option value="Serbian">Serbian</option>
                                    <option value="Slovak">Slovak</option>
                                    <option value="Slovenian">Slovenian</option>
                                    <option value="Spanish">Spanish</option>
                                    <option value="Swahili">Swahili</option>
                                    <option value="Swedish ">Swedish </option>
                                    <option value="Tamil">Tamil</option>
                                    <option value="Tatar">Tatar</option>
                                    <option value="Telugu">Telugu</option>
                                    <option value="Thai">Thai</option>
                                    <option value="Tibetan">Tibetan</option>
                                    <option value="Tonga">Tonga</option>
                                    <option value="Turkish">Turkish</option>
                                    <option value="Ukrainian">Ukrainian</option>
                                    <option value="Urdu">Urdu</option>
                                    <option value="Uzbek">Uzbek</option>
                                    <option value="Vietnamese">Vietnamese</option>
                                    <option value="Welsh">Welsh</option>
                                    <option value="Xhosa">Xhosa</option>
                                </Form.Select>
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <TextField
                                    label="Welcome Message for course"
                                    value={editCourse.welcomeMessage}
                                    name="welcomeMessage"
                                    onChange={handleEditCourseChange}
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <TextField
                                    label="Congrats Message after completion"
                                    value={editCourse.congratsMessage}
                                    name="congratsMessage"
                                    onChange={handleEditCourseChange}
                                    size="small"
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={4} lg={4}>
                                <Form.Control type="file"
                                    onChange={(e) => onSelectEditCourseThumbnail(e)}
                                    size="sm"
                                />
                                <Form.Label className='fw-bold'>Change Thumbnail</Form.Label>
                            </Grid>

                            <Grid item xs={12} md={4} lg={4}>
                                <Form.Control type="file"
                                    onChange={(e) => onSelectEditCoursePromotionVideo(e)}
                                    size="sm"
                                />
                                <Form.Label className='fw-bold'>Change Promotion Video</Form.Label>
                            </Grid>

                            <Grid item xs={12} md={12} lg={12}
                                style={{
                                    maxHeight: "500px",
                                    overflowY: "auto",
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                    marginTop: "10px",
                                    marginLeft: "20px"
                                }}
                            >
                                <ReactQuill
                                    onChange={(e) => {
                                        setEditCourse({
                                            ...editCourse,
                                            courseDescription: e
                                        })
                                    }}
                                    modules={UpdateCourseForm.modules}
                                    formats={UpdateCourseForm.formats}
                                    value={editCourse.courseDescription || ""}
                                    style={{
                                        width: '100%',
                                        margin: '0 auto',
                                    }}
                                    className="react-quill"
                                />
                            </Grid>

                            <Grid item xs={12} md={12} lg={12}>
                                <Button style={{ margin: "10px" }} variant="contained" color="primary" type="submit">
                                    Update Course Details
                                </Button>
                                <Button style={{ margin: "10px" }} variant="contained" color="error" onClick={() => setEditCourseModalShow(false)} >
                                    Cancel
                                </Button>
                            </Grid>

                        </Grid>
                    </form>
                </Container>

            </Paper>

        </div>
    )
}

export default UpdateCourseForm;




UpdateCourseForm.modules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
        [
            'link',
            'image',
            // 'video',
        ],
        ['clean']
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    }
}

UpdateCourseForm.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'color',
]


// "react-quill": "^1.3.5",