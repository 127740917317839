import React from 'react';
import "./css/NavServices.css";
import { Link } from 'react-router-dom';
import HomeRoundedIcon from '@mui/icons-material/HomeOutlined';
import ArticleRoundedIcon from '@mui/icons-material/ArticleOutlined';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import AutoStoriesRoundedIcon from '@mui/icons-material/AutoStoriesRounded';
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import DvrRoundedIcon from '@mui/icons-material/DvrRounded';
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';
import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import TravelExploreRoundedIcon from '@mui/icons-material/TravelExploreRounded';
import SavedSearchRoundedIcon from '@mui/icons-material/SavedSearchRounded';
import CastForEducationRoundedIcon from '@mui/icons-material/CastForEducationRounded';
import DownloadIcon from '@mui/icons-material/Download';


function NavServices() {
    return (
        <div className='main_navbar_services'>
            <div className='main_navbar_service'>
                <Link to='/' className='main_navbar_service_link'>
                    <div className='main_navbar_service_icon'>
                        <HomeRoundedIcon />
                    </div>
                    <div className='main_navbar_service_text'>
                        <p>Home</p>
                    </div>
                </Link>
            </div>

            <div className='main_navbar_service'>
                <Link to='/blogs' className='main_navbar_service_link'>
                    <div className='main_navbar_service_icon'>
                        <ArticleRoundedIcon />
                    </div>
                    <div className='main_navbar_service_text'>
                        <p>Blogs</p>
                    </div>
                </Link>
            </div>

            <div className='main_navbar_service'>
                <Link to='/institutes' className='main_navbar_service_link'>
                    <div className='main_navbar_service_icon'>
                        <AccountBalanceRoundedIcon />
                    </div>
                    <div className='main_navbar_service_text'>
                        <p>Institutions</p>
                    </div>
                </Link>
            </div>

            <div className='main_navbar_service'>
                <Link to='/pastPapers' className='main_navbar_service_link'>
                    <div className='main_navbar_service_icon'>
                        <ReceiptRoundedIcon />
                    </div>
                    <div className='main_navbar_service_text'>
                        <p>Past Papers</p>
                    </div>
                </Link>
            </div>

            <div className='main_navbar_service'>
                <Link to='/ComingSoon' className='main_navbar_service_link'>
                    <div className='main_navbar_service_icon'>
                        <AutoStoriesRoundedIcon />
                    </div>
                    <div className='main_navbar_service_text'>
                        <p>Courses</p>
                    </div>
                </Link>
            </div>

            <div className='main_navbar_service'>
                <Link to='/admissions' className='main_navbar_service_link'>
                    <div className='main_navbar_service_icon'>
                        <AccountBoxRoundedIcon />
                    </div>
                    <div className='main_navbar_service_text'>
                        <p>Admissions</p>
                    </div>
                </Link>
            </div>

            <div className='main_navbar_service'>
                <Link to='/ComingSoon' className='main_navbar_service_link'>
                    <div className='main_navbar_service_icon'>
                        <AutoStoriesRoundedIcon />
                    </div>
                    <div className='main_navbar_service_text'>
                        <p>Lectures</p>
                    </div>
                </Link>
            </div>

            <div className='main_navbar_service'>
                <Link to='/ComingSoon' className='main_navbar_service_link'>
                    <div className='main_navbar_service_icon'>
                        <DvrRoundedIcon />
                    </div>
                    <div className='main_navbar_service_text'>
                        <p>Online Test</p>
                    </div>
                </Link>
            </div>

            <div className='main_navbar_service'>
                <Link to='/ComingSoon' className='main_navbar_service_link'>
                    <div className='main_navbar_service_icon'>
                        <TodayRoundedIcon />
                    </div>
                    <div className='main_navbar_service_text'>
                        <p>Date Sheets</p>
                    </div>
                </Link>
            </div>

            <div className='main_navbar_service'>
                <Link to='/studentVerification' className='main_navbar_service_link'>
                    <div className='main_navbar_service_icon'>
                        <TravelExploreRoundedIcon />
                    </div>
                    <div className='main_navbar_service_text'>
                        <p>Verification</p>
                    </div>
                </Link>
            </div>


            <div className='main_navbar_service'>
                <Link to='/ComingSoon' className='main_navbar_service_link'>
                    <div className='main_navbar_service_icon'>
                        <SavedSearchRoundedIcon />
                    </div>
                    <div className='main_navbar_service_text'>
                        <p>Jobs</p>
                    </div>
                </Link>
            </div>

            <div className='main_navbar_service'>
                <Link to='/ComingSoon' className='main_navbar_service_link'>
                    <div className='main_navbar_service_icon'>
                        <CastForEducationRoundedIcon />
                    </div>
                    <div className='main_navbar_service_text'>
                        <p>Tutor</p>
                    </div>
                </Link>
            </div>

            <div className='main_navbar_service'>
                <Link to='/ComingSoon' className='main_navbar_service_link'>
                    <div className='main_navbar_service_icon'>
                        <TravelExploreRoundedIcon />
                    </div>
                    <div className='main_navbar_service_text'>
                        <p>Study Abroad</p>
                    </div>
                </Link>
            </div>

            <div className='main_navbar_service'>
                <Link to='/books' className='main_navbar_service_link'>
                    <div className='main_navbar_service_icon'>
                        <DownloadIcon />
                    </div>
                    <div className='main_navbar_service_text'>
                        <p>Books</p>
                    </div>
                </Link>
            </div>

            <div className='main_navbar_service'>
                <Link to='/softwares' className='main_navbar_service_link'>
                    <div className='main_navbar_service_icon'>
                        <DownloadIcon />
                    </div>
                    <div className='main_navbar_service_text'>
                        <p>Softwares</p>
                    </div>
                </Link>
            </div>

        </div>
    )
}

export default NavServices;