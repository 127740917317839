import React, { useCallback, useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import * as api from "../../../api/index";
import Swal from 'sweetalert2';
import { CircularProgress } from "@mui/material";
import TablePastPapers from './TablePastPapers';
import AddPastPaper from './AddPastPaper';
import EditPastPaperForm from './EditPastPaperForm';
import Modal from 'react-bootstrap/Modal';


function PastPapers({ user, setUser }) {
    const [pastPapersData, setPastPapersData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [boardsOptions, setBoardsOptions] = useState([]);
    const [classesOptions, setClassesOptions] = useState([]);
    const [yearsOptions, setYearsOptions] = useState([]);
    const [subjectsOptions, setSubjectsOptions] = useState([]);

    const getPastPapersData = useCallback(async () => {
        const { data } = await api.getPastPapersDataForAdmin();
        if (data.success) {
            setPastPapersData(data.pastPapers);

            setBoardsOptions(data.generalBoardsOptions);
            setClassesOptions(data.generalClassesOptions);
            setYearsOptions(data.generalYearsOptions);
            setSubjectsOptions(data.generalSubjectsOptions);

            setLoading(false);
            setError(false);
        }
        else {
            Swal.fire({
                title: 'Error!',
                text: data.error,
                icon: 'error',
                confirmButtonText: 'Ok',
            });
            setLoading(false);
            setError(true);
        }
    }, []);

    useEffect(() => {
        getPastPapersData();
    }, [getPastPapersData]);


    const [editPastPaperModalShow, setEditPastPaperModalShow] = useState(false);
    const [editPastPaper, setEditPastPaper] = useState(null);
    const [editPastPaperRequesting, setEditPastPaperRequesting] = useState(false);
    const [editPaperImage, setEditPaperImage] = useState(null);
    const [editPaperFile, setEditPaperFile] = useState(null);

    const handlePastPaperModalShowAndEditPastPaper = (pastPaper) => {
        setEditPastPaper(pastPaper);
        setEditPastPaperModalShow(true);
    }

    const onSelectEditPaperImage = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setEditPaperImage(null);
            return
        }
        setEditPaperImage(e.target.files[0]);
    }

    const onSelectEditPaperFile = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setEditPaperFile(null);
            return
        }
        setEditPaperFile(e.target.files[0]);
    }


    const handleEditPastPaperSubmit = async (e) => {
        e.preventDefault();

        if (editPastPaperRequesting) {
            Swal.fire({
                title: 'Please wait!',
                text: 'We are currently processing one of your request.',
                icon: 'info',
                showConfirmButton: false,
                allowOutsideClick: true,
                allowEscapeKey: false,
                allowEnterKey: true,
            });
            return;
        }

        setEditPastPaperRequesting(true);

        if (
            !editPastPaper.boardId
            || !editPastPaper.classId
            || !editPastPaper.yearId
            || !editPastPaper.subjectId
        ) {
            Swal.fire({
                title: 'Error!',
                text: 'Please fill required fields',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            setEditPastPaperRequesting(false);
            return;
        }

        const formData = new FormData();
        formData.append('id', editPastPaper.id);
        formData.append("entryUserId", user.result.id);
        formData.append('boardId', editPastPaper.boardId);
        formData.append('classId', editPastPaper.classId);
        formData.append('yearId', editPastPaper.yearId);
        formData.append('subjectId', editPastPaper.subjectId);

        if (editPaperImage !== null) {
            formData.append('paperImage', editPaperImage);
        }
        if (editPaperFile !== null) {
            formData.append('paperFile', editPaperFile);
        }

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };

        const { data } = await api.updatePastPaperData(formData, config);
        if (data.success) {
            Swal.fire({
                title: 'Success!',
                text: data.message,
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            setEditPaperImage(null);
            setEditPaperFile(null);
            let index = pastPapersData.findIndex(pp => pp.id === editPastPaper.id);
            pastPapersData[index] = data.pastPaper;
            setPastPapersData([...pastPapersData]);

            document.getElementById('editPastPaper').reset();
            setEditPastPaperRequesting(false);
        }
        else {
            Swal.fire({
                title: 'Error!',
                text: data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            setEditPastPaperRequesting(false);
        }
    }



    return (
        <div>
            <div className='m-1 p-4'>
                {error && <div className='text-danger text-center'>Something went wrong. Please try again later. or contact the developer sahib to check the masla</div>}
                {loading ?
                    <div className='text-center'>
                        <CircularProgress />
                    </div>
                    : (
                        <Tabs
                            defaultActiveKey="pastPapersTable"
                            transition={true}
                            id="noanim-tab-example"
                            className="mb-3"
                            style={{
                                padding: "3px",
                                backgroundColor: "rgb(11 142 150)",
                            }}
                        >
                            <Tab eventKey="pastPapersTable" title="Past Papers">
                                <TablePastPapers
                                    pastPapersData={pastPapersData}
                                    setPastPapersData={setPastPapersData}

                                    handlePastPaperModalShowAndEditPastPaper={handlePastPaperModalShowAndEditPastPaper}
                                />
                            </Tab>
                            <Tab eventKey="addPastPaper" title="Add Past Paper">
                                <AddPastPaper
                                    boardsOptions={boardsOptions}
                                    classesOptions={classesOptions}
                                    yearsOptions={yearsOptions}
                                    subjectsOptions={subjectsOptions}

                                    pastPapersData={pastPapersData}
                                    setPastPapersData={setPastPapersData}

                                    user={user}
                                    setUser={setUser}
                                />
                            </Tab>
                        </Tabs>
                    )}
            </div>
            <div>
                <Modal
                    show={editPastPaperModalShow}
                    onHide={() => setEditPastPaperModalShow(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Update Past Paper Record
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* if edit course or editcourse.id then show else dont show */}
                        {editPastPaper && editPastPaper.id ? (
                            <div>
                                <EditPastPaperForm
                                    editPastPaper={editPastPaper}
                                    setEditPastPaper={setEditPastPaper}
                                    onSelectEditPaperImage={onSelectEditPaperImage}
                                    onSelectEditPaperFile={onSelectEditPaperFile}
                                    handleEditPastPaperSubmit={handleEditPastPaperSubmit}

                                    boardsOptions={boardsOptions}
                                    classesOptions={classesOptions}
                                    yearsOptions={yearsOptions}
                                    subjectsOptions={subjectsOptions}
                                />
                            </div>
                        ) : (
                            <div>
                                Loading...
                            </div>
                        )}

                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}


export default PastPapers;