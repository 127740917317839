import { Button, Grid, Paper, TextField, Typography } from '@mui/material';
import React, { useState } from 'react'
// import { Form } from 'react-bootstrap';
import "./css/UploadVideo.css";
import * as api from "../../../api/index";
import { ProgressBar } from 'react-bootstrap';
import Swal from "sweetalert2";
import { Form } from 'react-bootstrap';


const initialState = {
    videoTitle: "",
    videoDescription: "",
}

function UploadVideo({ courseId, user, setUser, uploadedVideos, setUploadedVideos, uploadedVideosCount, setUploadedVideosCount }) {
    const [uploadVideoForm, setUploadVideoForm] = useState(initialState);
    const [videoThumbnail, setVideoThumbnail] = useState(null);
    const [videoFile, setVideoFile] = useState(null);
    const [progress, setProgress] = useState(null);
    const [error, setError] = useState(false);
    const [requesting, setRequesting] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (requesting) {
            Swal.fire({
                title: 'Processing your request...',
                text: 'Please wait...',
                showConfirmButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: true,
            });
            return;
        }
        setRequesting(true);
        if (uploadVideoForm.videoTitle === "" || uploadVideoForm.videoDescription === "" || videoThumbnail === null || videoFile === null) {
            window.alert("Please fill all the fields");
            setRequesting(false);
        }
        else {
            const formData = new FormData();
            formData.append("userId", user.result.id);
            formData.append("courseId", courseId);
            formData.append("videoTitle", uploadVideoForm.videoTitle);
            formData.append("videoDescription", uploadVideoForm.videoDescription);
            formData.append("videoThumbnail", videoThumbnail);
            formData.append("videoFile", videoFile);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                },
                onUploadProgress: data => {
                    //Set the progress value to show the progress bar
                    setProgress(Math.round((100 * data.loaded) / data.total))
                },
            }

            try {
                const { data } = await api.addVideoToCourseOfInstructor(formData, config);
                if (data.success) {
                    Swal.fire(
                        'Good job!',
                        data.message,
                        'success'
                    )
                    setUploadedVideos([...uploadedVideos, data.video]);
                    setError(false);
                    setProgress(null);
                    setUploadVideoForm(initialState);
                    setVideoThumbnail(null);
                    setVideoFile(null);
                    document.getElementById("uploadVideoHtmlForm").reset();
                    setRequesting(false);
                    setUploadedVideosCount(uploadedVideosCount + 1);
                }
                else {
                    Swal.fire(
                        'Failed!',
                        data.error,
                        'error'
                    )
                    setError(true);
                    setProgress(null);
                    setRequesting(false);
                }
            } catch (error) {
                setError(true);
                Swal.fire(
                    'Failed!',
                    'Video is not uploaded please try again, if problem persists contact Programmer',
                    'error'
                )
                setRequesting(false);
                setProgress(null);
            }
        }

    }


    const handleChange = (e) => {
        setUploadVideoForm({ ...uploadVideoForm, [e.target.name]: e.target.value });
    }

    const onSelectVideoThumbnail = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setVideoThumbnail(null)
            return
        }
        setVideoThumbnail(e.target.files[0])
    }

    const onSelectVideoFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setVideoFile(null)
            return
        }
        setVideoFile(e.target.files[0]);
    }

    return (
        <Grid container spacing={0.5}>
            <Grid item sm={12} md={12} lg={12} mx={2}>
                <Paper elevation={3}>

                    <Typography className='fw-bold p-2 bg-light' variant="h5">Upload video</Typography>
                    {!error && progress && (
                        <div className='py-4 mx-3'>
                            <ProgressBar now={progress} label={`${progress}%`} />
                        </div>
                    )
                    }

                    <form className='m-2' id="uploadVideoHtmlForm" onSubmit={(e) => handleSubmit(e)}>

                        <Grid container spacing={2} paddingRight={2} paddingLeft={2}>
                            <Grid item xs={12} md={3} lg={3}>
                                <TextField
                                    label="Video Title"
                                    value={uploadVideoForm.videoTitle}
                                    name="videoTitle"
                                    onChange={handleChange}
                                    size="small"
                                    inputProps={{ maxLength: 100 }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={3} lg={3}>
                                <TextField
                                    label="Video Description"
                                    value={uploadVideoForm.videoDescription}
                                    name='videoDescription'
                                    onChange={handleChange}
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={3} lg={3}>
                                <Form.Control type="file"
                                    multiple={false}
                                    onChange={(e) => onSelectVideoThumbnail(e)}
                                />
                                <Form.Label className='fw-bold'>Video Thumbnail</Form.Label>
                            </Grid>
                            <Grid item xs={12} md={3} lg={3}>
                                <Form.Control type="file"
                                    multiple={false}
                                    onChange={(e) => onSelectVideoFile(e)}
                                />
                                <Form.Label className='fw-bold'>Video File</Form.Label>
                            </Grid>

                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Button style={{ margin: "10px" }} size="small" variant="contained" color="primary" type="submit">
                                Upload Video
                            </Button>
                            <Button style={{ margin: "10px" }} size="small" variant="contained" color="primary" type="reset">
                                Reset
                            </Button>
                        </Grid>
                    </form>

                </Paper>
            </Grid>
        </Grid>
    )
}

export default UploadVideo;