import { FETCH_COURSES_FOR_INSTUCTOR, ADD_COURSE_TO_INSTRUCTOR, DELETE_COURSE_OF_INSTRUCTOR } from "../constants/actionTypes";

export const instructorCourses = (instructorCoursesState = [], action) => {
    switch (action.type) {
        case FETCH_COURSES_FOR_INSTUCTOR:
            return action.payload;
        case ADD_COURSE_TO_INSTRUCTOR:
            return [...instructorCoursesState, action.payload];
        case DELETE_COURSE_OF_INSTRUCTOR:
            return instructorCoursesState.filter((course) => course.id !== action.payload);
        default:
            return instructorCoursesState;
    }
}